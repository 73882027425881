import { HTMLAttributeAnchorTarget, useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "underscore";
import DOMPurify from 'dompurify';
import Quill from "quill";
import { Delta, Parchment, Range } from "quill/core";
import Toolbar from "quill/modules/toolbar";

import QuillToolbar from "./quill-toolbar";

import "quill/dist/quill.snow.css";
import "./quill-editor.scss";
import { QuillEditorProps } from "./types";

// type Selector = string | Node['TEXT_NODE'] | Node['ELEMENT_NODE'];
// type Matcher = (node: Node, delta: Delta, scroll: Parchment.ScrollBlot) => Delta;

// BEGIN CUSTOM STYLE REGISTRATIONS
// registeStyle does not add the options to the toolbar, it just prevents quill from stripping inline styles on load
const registerStyle = (styleTag: string) => {
	const lowerCaseTag = styleTag.toLowerCase();
	const Style = new Parchment.StyleAttributor(lowerCaseTag, lowerCaseTag);
	Quill.register(Style, true);
};
registerStyle('margin');
registerStyle('margin-top');
registerStyle('margin-right');
registerStyle('margin-bottom');
registerStyle('margin-left');
registerStyle('padding');
registerStyle('padding-top');
registerStyle('padding-right');
registerStyle('padding-bottom');
registerStyle('padding-left');
registerStyle('font-family');
registerStyle('text-transform');
registerStyle('text-decoration');
registerStyle('font-weight');
registerStyle('text-align');
registerStyle('text-indent');
registerStyle('opacity');
registerStyle('height');
registerStyle('content');
registerStyle('white-space');

// quill has poor types so marking as any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SizeStyle = Quill.import("attributors/style/size") as any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Link = Quill.import("formats/link") as any;

export const QUILL_COLORS: (boolean | string)[] = [false, 'rgb(0, 0, 0)', 'rgb(255, 255 ,255)', 'rgb(230, 0, 0)', 'rgb(255, 153, 0)', 'rgb(255, 255, 0)', 'rgb(0, 138, 0)', 'rgb(0, 102, 204)', 'rgb(153, 51, 255)', 'rgb(250, 204, 204)', 'rgb(255, 235, 204)', 'rgb(255, 255, 204)', 'rgb(204, 232, 204)', 'rgb(204, 224, 245)', 'rgb(235, 214, 255)', 'rgb(187, 187, 187)', 'rgb(240, 102, 102)', 'rgb(255, 194, 102)', 'rgb(255, 255, 102)', 'rgb(102, 185, 102)', 'rgb(102, 163, 224)', 'rgb(194, 133, 255)', 'rgb(136, 136, 136)', 'rgb(161, 0, 0)', 'rgb(178, 107, 0)', 'rgb(178, 178, 0)', 'rgb(0, 97, 0)', 'rgb(0, 71, 178)', 'rgb(107, 36, 178)', 'rgb(68, 68, 68)', 'rgb(92, 0, 0)', 'rgb(102, 61, 0)', 'rgb(102, 102, 0)', 'rgb(0, 55, 0)', 'rgb(0, 41, 102)', 'rgb(61, 20, 102)'];

const FONT_SIZE_ARRAY: (string | boolean)[] = [false];
Array.from({ length: 100 }).forEach((_, idx) => {
	FONT_SIZE_ARRAY.push(idx + 1 + 'px');
});
SizeStyle.whitelist = FONT_SIZE_ARRAY;
Quill.register(SizeStyle, true);

// line-height
const LINE_HEIGHT_ARRAY: (string | boolean)[] = [false];
for (let i = 1.0; i < 2.1; i += 0.1) {
	// Very important that whole numbers do not have a decimal and that everything else is fixed to 1 decimal
	const valFixed = parseFloat(i.toFixed(1));
	const val = valFixed % 1 === 0 ? parseInt(valFixed.toString()).toString() : valFixed.toFixed(1);
	LINE_HEIGHT_ARRAY.push(val);
}
const LineHeightClass = new Parchment.ClassAttributor('lineheight', 'ql-line-height');
const LineHeightStyle = new Parchment.StyleAttributor('lineheight', 'line-height', {
	scope: Parchment.Scope.ANY,
	whitelist: LINE_HEIGHT_ARRAY.filter(val => typeof val === 'string') as string[],
});
Quill.register(LineHeightClass, true);
Quill.register(LineHeightStyle, true);

// text-align
const TextAlignClass = new Parchment.ClassAttributor('textalign', 'ql-text-align');
const TextAlignStyle = new Parchment.StyleAttributor('textalign', 'text-align', {
	whitelist: ['', 'left', 'center', 'right', 'justify'],
	scope: Parchment.Scope.ANY,
});
Quill.register(TextAlignClass, true);
Quill.register(TextAlignStyle, true);

// text-indent
const TEXT_INDENT_ARRAY: (string | boolean)[] = [false];
// 1 - 100%, incrememnt by 1%
for (let i = 1; i <= 100; i++) {
	TEXT_INDENT_ARRAY.push(i + '%');
}
const TextIndentClass = new Parchment.ClassAttributor('textindent', 'ql-text-indent');
const TextIndentStyle = new Parchment.StyleAttributor('textindent', 'text-indent', {
	scope: Parchment.Scope.BLOCK,
	whitelist: TEXT_INDENT_ARRAY.map(val => typeof val === 'string' ? val : '') as string[],
});
Quill.register(TextIndentClass, true);
Quill.register(TextIndentStyle, true);

const fillEmptyParagraphsInHtml = (htmlString: string) => {
	const newHtml = new DOMParser().parseFromString(htmlString, 'text/html');
	const pTags = newHtml.querySelectorAll('p');
	pTags.forEach(p => {
		if (p.innerHTML.trim() === '') {
			p.innerHTML = '<br>';
		}
	});
	return newHtml.body.innerHTML;
};

const QuillEditor = (props: QuillEditorProps) => {
	const {
		html,
		onChange,
		onDebounceChange,
		onBlur,
		onBlurChanged,
		onFocus,
		debounceTime = 300,
		controlled,
		colors = [],
		disabled = false,
		disableAnchorTargets = false,
		maxLength,
		fillEmptyParagraphs,
	} = props;

	const containerRef = useRef<HTMLDivElement | null>(null);
	const editorRef = useRef<HTMLDivElement | null>(null);
	const toolbarRef = useRef<HTMLDivElement | null>(null);

	const [firstLoad, setFirstLoad] = useState(true);
	const [quill, setQuill] = useState<Quill | undefined>();
	const [hasTextChanged, setHasTextChanged] = useState(false);

	const stripAdditionalSpace = useCallback((_editor: Quill) => {
		// For some reason quill adds extra spaces before text-indent.
		// We remove them here:
		_editor.clipboard.addMatcher("p", (_node, delta) => {
			const firstOp = delta?.ops?.[0];
			const secondOp = delta?.ops?.[1];
			// if there's a space added before a textindent, remove it
			if (firstOp?.insert === '\t' && secondOp?.attributes?.['text-indent']) {
				firstOp.insert = firstOp.insert.replace("\t", ""); // remove auto created space
			}
			return delta;
		});
	}, []);

	useEffect(() => {
		const editorEl = editorRef.current;
		if (!containerRef.current || !editorEl || quill) return;

		const _quill = new Quill(editorEl, {
			theme: "snow",
			debug: "error",
			modules: {
				toolbar: toolbarRef.current,
			}
		});

		_quill.blur();
		_quill.disable(); // prevent auto focus and scroll to editor

		// prevent quill from adding extra breaks at the end of the editor
		// this doesn't seem to be happening anymore, but if it comes back, we can uncomment the below
		// const htmlString = _quill.getSemanticHTML();
		// if (htmlString.endsWith("<p></p>") || htmlString.endsWith("<p><br></p>")) {
		// 	const newHtml = htmlString.slice(0, htmlString.length - 7);
		// 	_quill.clipboard.dangerouslyPasteHTML(newHtml);
		// }
		// if (html.endsWith("<p></p>") || html.endsWith("<p><br></p>")) {
		// 	const newHtml = html + "<p></p>";
		// 	_quill.clipboard.dangerouslyPasteHTML(newHtml);
		// }

		stripAdditionalSpace(_quill);

		const toolbar = _quill.getModule("toolbar") as Toolbar;
		if (toolbar.container) {
			toolbar.container.tabIndex = 0;
		}

		setQuill(_quill);

		setFirstLoad(false);

		setTimeout(() => {
			if (!disabled) {
				_quill.enable();
			}
		}, 1000);
	}, [colors, disabled, html, quill, stripAdditionalSpace]);

	const timer = useRef<NodeJS.Timer>();
	const forceCloseTimer = useRef<NodeJS.Timer>();
	const forcingClose = useRef(false);

	useEffect(() => {
		const container = containerRef.current;
		if (!quill || !container) return;

		const editor = quill.container.querySelector('.ql-editor');
		const toolbar = quill.getModule('toolbar') as Toolbar;

		const handleFocus = () => {
			timer.current && clearTimeout(timer.current);
			toolbar?.container?.classList.add('display-toolbar');
			editor?.classList.add('editor-focused');
			// timeout needed to ensure the toolbar is displayed before the opacity transition
			setTimeout(() => {
				toolbar?.container?.classList.add('show-toolbar-opacity');
			}, 0);

			// set toolbar top/left/right to be at the top of the editor
			if (toolbar.container) {
				const editorRect = editor?.getBoundingClientRect();
				if (editorRect) {
					toolbar.container.style.top = `${editorRect?.top - 2}px`;
					// left position should be in the middle of the editor
					const left = editorRect?.left + (editorRect?.width || 0) / 2 - (toolbar.container.offsetWidth || 0) / 2;
					toolbar.container.style.left = `${left}px`;
				}
			}
		};

		const handleBlur = () => {
			timer.current && clearTimeout(timer.current);
			if (editor?.classList.contains('editor-focused')) {
				let htmlString = quill.getSemanticHTML();

				// if fillEmptyParagraphs is true, we need to fill empty paragraphs with a <br> so they don't collapse
				// inside of emails
				if (fillEmptyParagraphs) {
					htmlString = fillEmptyParagraphsInHtml(htmlString);
				}

				onBlur?.(htmlString);
				onBlurChanged?.(hasTextChanged ? htmlString : undefined);
				setHasTextChanged(false);
			}
			toolbar?.container?.classList.remove('show-toolbar-opacity');
			editor?.classList.remove('editor-focused');
			timer.current = setTimeout(() => {
				toolbar?.container?.classList.remove('display-toolbar');
			}, 200);
		};

		// if active elemnt is container, show toolbar
		const handleContainerClick = (e: FocusEvent | Event | PointerEvent) => {
			// if active element is the editor or toolbar, show toolbar
			if (
				(
					document.activeElement === container
					|| container.contains(document.activeElement)
					|| document.activeElement === toolbar.container
					|| e.target === toolbar.container
					|| toolbar?.container?.contains(document.activeElement)
				)
				&& !forcingClose.current
			) {
				if (e instanceof FocusEvent) {
					onFocus?.();
				}
				handleFocus();
			} else {
				handleBlur();
				forceCloseTimer.current && clearTimeout(forceCloseTimer.current);
				forceCloseTimer.current = setTimeout(() => {
					forcingClose.current = false;
				}, 200);
			}
		};

		// escape key should blur the editor
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Escape' && editor?.classList.contains('editor-focused')) {
				forceCloseTimer.current && clearTimeout(forceCloseTimer.current);
				forcingClose.current = true;
				// quill.focus(); // first focus the editor so if we have any child button focused, it will blur
				quill.blur(); // then blur the editor
				document.body.click(); // then click the document body to blur the editor
			}
		};

		const handleScroll = debounce(() => {
			if (toolbar.container && toolbar.container.classList.contains('display-toolbar')) {
				// set toolbar top/left/right to be at the top of the editor
				if (toolbar.container) {
					const editorRect = editor?.getBoundingClientRect();
					if (editorRect) {
						toolbar.container.style.top = `${editorRect?.top - 2}px`;
						// left position should be in the middle of the editor
						const left = editorRect?.left + (editorRect?.width || 0) / 2 - (toolbar.container.offsetWidth || 0) / 2;
						toolbar.container.style.left = `${left}px`;
					}
				}
			}
		}, 0);

		window.addEventListener('scroll', handleScroll, true);
		window.addEventListener('keydown', handleKeyDown, true);
		window.addEventListener('focus', handleContainerClick, true);
		window.addEventListener('click', handleContainerClick, true);

		return () => {
			window.removeEventListener('keydown', handleKeyDown, true);
			window.removeEventListener('focus', handleContainerClick, true);
			window.removeEventListener('click', handleContainerClick, true);
			window.removeEventListener('scroll', handleScroll, true);
		};
	}, [hasTextChanged, onBlur, onBlurChanged, onFocus, quill]);

	useEffect(() => {
		if (!quill) return;
		if (disabled) {
			quill.disable();
		} else {
			quill.enable();
		}
	}, [disabled, quill]);

	useEffect(() => {
		const toolbar = toolbarRef.current;
		if (!toolbar) return;
		return () => {
			toolbar?.remove();
		};
	}, []);

	const debounced = useRef(debounce((content: string) => {
		const clean = DOMPurify.sanitize(content, { ADD_ATTR: ['target'] });
		onDebounceChange?.(clean);
	}, debounceTime)).current;

	useEffect(() => {
		if (!quill) return;

		const handleTextChange = (_delta: Delta, _oldDelta: Delta, source: string) => {
			if (source === 'api') {
				// console.log('An API call triggered this change.');
			} else if (source === 'silent') {
				// console.log('A silent change triggered this change.');
			} else if (source === 'user') {
				// console.log('User triggered this change.');
				let htmlString = quill.getSemanticHTML();

				setHasTextChanged(true);

				if (maxLength && maxLength > 0 && quill.getLength() > maxLength) {
					// reset the html to the updated htmlString, but track the range so we don't lose the cursor position
					const range = quill.getSelection();
					// delete the text after the max length
					quill.deleteText(maxLength, quill.getLength());
					// refetch the html after deleting the excess text
					htmlString = quill.getSemanticHTML();
					// paste the html back into quill
					quill.clipboard.dangerouslyPasteHTML(htmlString);
					// set the cursor back to the original position
					if (range) {
						quill.setSelection(range.index, range.length, 'silent');
					}
				}

				const clean = DOMPurify.sanitize(htmlString, { ADD_ATTR: ['target'] });
				if (onChange) {
					onChange?.(clean);
				}
				debounced(clean);
			}
		};

		quill.on("text-change", handleTextChange);

		return () => {
			quill.off("text-change", handleTextChange);
		};
	}, [debounced, fillEmptyParagraphs, maxLength, onChange, quill]);

	useEffect(() => {
		if (!quill) return;
		if (!disableAnchorTargets) return;

		class LinkBlot extends Link {
			static blotName = "link";
			static tagName = "A";

			static create(value: string) {
				const node = super.create(value) as HTMLAnchorElement;
				node.setAttribute("href", value);
				node.setAttribute("target", "_blank");

				const toolbar = quill?.getModule("toolbar") as Toolbar;
				const targetEl = toolbar?.container?.querySelector('.ql-target');
				if (targetEl) {
					targetEl.classList.add('ql-active');
				}

				return node;
			}

			static formats(node: HTMLElement) {
				return node.getAttribute("href");
			}
		}

		Quill.register(LinkBlot);
	}, [disableAnchorTargets, quill]);

	useEffect(() => {
		if (!quill) return;
		if (disableAnchorTargets) return;
		const toolbar = quill.getModule("toolbar") as Toolbar;
		if (!toolbar) return;

		const handleTargetButtonClick = () => {
			const range = quill.getSelection();
			if (!range) return;

			const targetEl = toolbar?.container?.querySelector('.ql-target') as HTMLElement;
			if (!targetEl) return;
			const leaf = quill.getLeaf(range.index + 1);
			if (leaf?.[0]?.parent?.statics?.blotName === 'link') {
				const el = leaf[0].parent.domNode as HTMLAnchorElement;
				if (el?.target === '_blank') {
					el.target = "_self";
					targetEl.classList.remove('ql-active');
					targetEl?.blur();
				} else {
					el.target = "_blank";
					targetEl.classList.add('ql-active');
				}
				const content = quill.getSemanticHTML();
				onChange?.(content);
				debounced(content);

				const start = range.index - leaf[1];
				quill.setSelection(start + 1, leaf[0].length(), 'silent');
			}
		};

		toolbar.container?.addEventListener('click', handleTargetButtonClick);

		return () => {
			toolbar.container?.removeEventListener('click', handleTargetButtonClick);
		};
	}, [debounced, disableAnchorTargets, fillEmptyParagraphs, onChange, quill]);

	useEffect(() => {
		if (!quill) return;

		const handleSelectionChange = (range: Range | null) => {
			if (!range) return;

			const leaf = quill.getLeaf(range.index + 1);
			const toolbar = quill.getModule('toolbar') as Toolbar;
			const targetEl = toolbar?.container?.querySelector('.ql-target');

			if (!disableAnchorTargets) {
				if (leaf?.[0]?.parent?.statics?.blotName === 'link') {
					if (targetEl) {
						const target = (leaf[0].parent.domNode as HTMLAnchorElement)?.target as HTMLAttributeAnchorTarget;
						if (target === '_blank') {
							targetEl.classList.add('ql-active');
						} else {
							targetEl.classList.remove('ql-active');
						}
					}
				} else {
					if (targetEl) {
						targetEl.classList.remove('ql-active');
					}
				}
			}
		};

		quill.on('selection-change', handleSelectionChange);

		return () => {
			quill.off('selection-change', handleSelectionChange);
		};
	}, [disableAnchorTargets, quill]);

	// update html on controlled value change
	useEffect(() => {
		if (!quill) return;
		if (firstLoad) return;
		if (!controlled) return;

		let range: Range | null = null;
		const hasFocus = quill.hasFocus();
		if (hasFocus) {
			range = quill.getSelection();
		} else {
			quill.disable(); // prevent auto focus
		}

		quill.clipboard.dangerouslyPasteHTML(html);
		if (range && hasFocus) {
			quill.setSelection(range.index, range.length, 'silent');
		}

		stripAdditionalSpace(quill);

		if (!disabled) {
			quill.enable();
		}
	}, [controlled, disabled, firstLoad, html, quill, stripAdditionalSpace]);

	return (
		<div
			ref={containerRef}
			style={{ position: 'relative' }}
			className={`quill-wrapper quill-wrap ${disabled ? 'quill-disabled' : ''}`}
		>
			<QuillToolbar
				ref={toolbarRef}
				quill={quill}
				colors={Array.from(new Set([...QUILL_COLORS, ...(colors || [])]))}
				fontSizes={FONT_SIZE_ARRAY}
				lineHeights={LINE_HEIGHT_ARRAY}
				textIndents={TEXT_INDENT_ARRAY}
				disableAnchorTargets={disableAnchorTargets}
			/>
			<div ref={editorRef}>
				{controlled ? null : (
					<div dangerouslySetInnerHTML={{ __html: html }} />
				)}
			</div>
		</div>
	);
};

export default QuillEditor;
