import { combineReducers } from 'redux';

import AdminEventChatReducer from './admin/event-chat';
import AnalyticsReducer from './admin/analytics';
import AssistantReducer from './admin/assistant';
import AuthReducer from './authentication';
import BreakoutChatReducer from './event/breakout-chat-reducer';
import BreakoutRoomsReducer from './event/breakout-rooms';
import BroadcastReducer from './admin/broadcasting';
import ChannelAdminReducer from './admin/bl-admin';
import ChatReducer from './event/chat-reducer';
import ConnectedAdminsReducer from './admin/connected-admins';
import ContentReducer from './admin/content-reducer';
import CreateSessionReducer from './admin/create-event/sessions';
import CreateEventReducer from './admin/create-event';
import DocumentsReducer from './admin/documents';
import EventChatReducer from './event/event-chat-reducer';
import EventPanelReducer, { initialEventPanelState } from './admin/event-panel';
import EventsReducer from './admin/events';
import FeatureFlagsReducer from './feature-flags';
import FiresidesReducer from './event/firesides-reducer';
import IntegrationsReducer from './admin/integrations';
import LiveEventReducer from './event/event-reducer';
import MagicLinkReducer from './admin/magic-links';
import MessagesReducer from './messages/messages';
import ModeratorReducer from './moderator/moderator';
import PageReducer from './admin/page';
import PaidListMemberReducer from './admin/paid-list-members';
import PasscodeListMembersReducer from './admin/passcode-list-members';
import PasscodeListReducer from './admin/passcode-lists';
import ProductsReducer from './admin/products';
import QuestionsReducer from './event/questions-reducer';
import QuizzesReducer from './admin/quizzes';
import ReactionsReducer from './admin/reactions';
import RegisteredUsersReducer from './admin/people';
import RegistrationQuestionsReducer from './admin/registration-questions';
import SpeakersReducer from './admin/speakers';
import SponsorsReducer from './admin/sponsors';
import SurveysReducer from './admin/surveys';
import VideosReducer from './admin/videos';
import VideoCallReducer from './event/video-call-reducer';
import LeaderBoardActionPointReducer from './admin/leaderboard-action-point';
import AccessReducer from './admin/access';
import CourseReducer from './event/courses';

import { SIGN_OUT } from '../actions/authentication';
import { Action } from '../../types/actions';
import { removeStorageItem } from '../../utils/local-storage';
import store from '../main';
import { interpretAction } from '../../utils/event-tracker';

const appReducers = combineReducers({
	AccessReducer,
	AdminEventChatReducer,
	AnalyticsReducer,
	AssistantReducer,
	AuthReducer,
	BreakoutChatReducer,
	BreakoutRoomsReducer,
	BroadcastReducer,
	ChannelAdminReducer,
	ChatReducer,
	ConnectedAdminsReducer,
	ContentReducer,
	CourseReducer,
	CreateEventReducer,
	CreateSessionReducer,
	DocumentsReducer,
	EventChatReducer,
	EventPanelReducer,
	EventsReducer,
	FeatureFlagsReducer,
	FiresidesReducer,
	IntegrationsReducer,
	LeaderBoardActionPointReducer,
	LiveEventReducer,
	MagicLinkReducer,
	MessagesReducer,
	ModeratorReducer,
	PageReducer,
	PaidListMemberReducer,
	PasscodeListMembersReducer,
	PasscodeListReducer,
	ProductsReducer,
	QuestionsReducer,
	QuizzesReducer,
	ReactionsReducer,
	RegisteredUsersReducer,
	RegistrationQuestionsReducer,
	SpeakersReducer,
	SponsorsReducer,
	SurveysReducer,
	VideoCallReducer,
	VideosReducer,
});

export type RootState = ReturnType<typeof appReducers>;

export type AppDispatch = typeof store.dispatch;

// I cannot figure out what type state should be,
// setting it to EventsState makes createStore inside of './store/main.ts' throw an error,
// so I'm disabling it:
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: Action): RootState => {
	if (state && state?.AuthReducer?.user?.email) {  // Amplitude event tracker
		// interpretAction(action, state.CreateEventReducer?.workingEvent, state.AuthReducer.user, state.AuthReducer?.token);
		interpretAction(action, state);
	}
	// on signout, clear event panel reducer so it renders appropriately when logging back in
	if (action.type === SIGN_OUT) {
		removeStorageItem('activityTime');
		const updatedState = {
			...state,
			EventPanelReducer: { ...initialEventPanelState },
		};
		return appReducers(updatedState, action);
	}
	return appReducers(state, action);
};

export default rootReducer;
