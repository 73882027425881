import { Attribute, BrandliveEvent, CreateProduct, ModuleStatus, Product, Session } from "../../../types/working-model";
import { toDict } from "../../../utils/utils";
import { Action, Actions, CreateProductReducer } from "./edit-product-reducer";
import { CreateProductFromEvent, UpdateProduct } from "../../../connection/products";
import { updateProduct } from "../../../store/actions/admin/products";
import store from '../../../store/main';
import { showAlert } from "../../general-ui/alert/alert-service";

const getProductFromMap = (fullProductsMap: Record<number, Product>) => (products: Product[], id: number) => {
	const fullProduct: Product = fullProductsMap[id];

	// check if the selected id from the checkboxes has not been deleted
	if (fullProduct.status === ModuleStatus.active) {
		products.push(fullProduct);
	}

	return products;
};

export const getFullProductsOrdered = (selectedProducts: number[], products: Product[]) => {
	const fullProductsMap = toDict('product', products);
	return selectedProducts.reduce<Product[]>(getProductFromMap(fullProductsMap), []);
};

export const removeIncompleteAttributes = (attribute: Attribute) => {
	return attribute.type.trim().length && attribute.value.trim().length;
};

export const replaceOriginalWithEdited = (productToSave: Product) => (existingProduct: Product) => {
	if (existingProduct.product === productToSave.product) {
		return productToSave;
	}

	return existingProduct;
};

export const getCompleteAttributes = (attributes?: Attribute[] | null) => {
	if (!attributes) {
		return [];
	}

	return attributes.filter(removeIncompleteAttributes);
};

export const getLanguagesToSave = (workingEvent?: BrandliveEvent, workingSession?: Session | null): string[] => {
	if (workingSession?.languages) {
		return workingSession.languages;
	} else if (workingEvent?.homepage) {
		return workingEvent.homepage.languages;
	}

	return [];
};

type SaveProductProps = {
	close: () => void;
	hasCompletedRequiredFields: () => boolean;
	initialSelected: Product[];
	onComplete: (products: Product[]) => void;
	productToEdit: Product | null;
	selectedProducts: number[];
	workingProduct: CreateProductReducer;
	setSaving: (value: React.SetStateAction<boolean>) => void;
	dispatchProduct: (value: Action) => void;
	shouldCreateChannelProduct?: boolean;
}
export const saveProduct = async ({
	close,
	hasCompletedRequiredFields,
	initialSelected,
	onComplete,
	productToEdit,
	selectedProducts,
	workingProduct,
	setSaving,
	dispatchProduct,
	shouldCreateChannelProduct
}: SaveProductProps): Promise<Product | undefined | void> => {
	const state = store.getState();
	const workingEvent = state.CreateEventReducer.workingEvent;
	const workingSession = state.CreateSessionReducer.workingSession;
	const products = state.ProductsReducer.products;
	const token = state.AuthReducer.token;

	if (!workingEvent) return;

	try {
		setSaving(true);

		const isSavingProduct = !!workingProduct;
		if (!isSavingProduct) {
			onComplete(getFullProductsOrdered(selectedProducts, products));
			return close();
		}

		// if editing/creating product
		if (!hasCompletedRequiredFields() || !token) {
			return;
		}

		// backfill with complete and valid attributes
		const productToSave = {
			...workingProduct,
			should_create_channel_product: shouldCreateChannelProduct,
			attributes: getCompleteAttributes(workingProduct.attributes)
		};

		// get languages from session if we are within a session, otherwise use languages from homepage
		const languagesToSave = getLanguagesToSave(workingEvent, workingSession);

		const isEditingExistingProduct = "product" in productToSave;
		let returnProduct: Product;
		if (isEditingExistingProduct) {
			// if editing product from parent, update and close the whole modal
			if (productToEdit) {
				const updated = await UpdateProduct(token, productToSave, languagesToSave);
				const updatedModules = initialSelected.map(replaceOriginalWithEdited(updated.should_create_channel_product && updated.parent_id ? { ...productToSave, parent_id: updated.parent_id } : productToSave));
				store.dispatch(updateProduct(updated));
				onComplete(updatedModules);
				close();
				returnProduct = productToEdit;
			} else {
				const updated = await UpdateProduct(token, productToSave, languagesToSave);
				store.dispatch(updateProduct(updated));
				returnProduct = updated;
			}

			// not editing existing, creating new
		} else {
			const productToCreate: CreateProduct = {
				...productToSave,
				event_id: workingEvent.event
			};

			const newProduct = await CreateProductFromEvent(
				token,
				productToCreate,
				languagesToSave,
				workingEvent.channel
			);

			onComplete([...initialSelected, newProduct]);
			close();
			returnProduct = newProduct;
		}

		dispatchProduct({ type: Actions.ClearProduct, payload: true });

		return returnProduct;
	} catch (e) {
		console.error(e);
		showAlert({
			message: "Error",
			description: "We ran into an issue saving. Please try again.",
			type: "error"
		});
	} finally {
		setSaving(false);
	}
};
