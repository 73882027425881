import { ICourse, ICourseCertificate, ICourseRequirement, ICreateCourse, ICreateCourseRequirement, ICreateCourseCertificate, ICourseCertificateDesign, UserCourseCompletionState, UserCourseCompletionStats } from "types/courses";
import blAuth from "./bl-auth/bl-auth";
import { DeleteJson, GetHvJson, GetJson, HvHostMap, PatchJson, PostJson, PostHvJson, PutJson, FetchError } from "./helpers";

export function GetCourses(channel: number, page = 1, limit = 10000) {
	return GetJson<ICourse[]>({
		path: `/v4/admin/courses/${channel}?page=${page}&limit=${limit}`,
		token: blAuth.accessToken ?? ''
	});
}

export function GetFullCourse(channel: number, courseUuid: string) {
	return GetJson<Required<ICourse>>({
		path: `/v4/admin/courses/${channel}/${courseUuid}`,
		token: blAuth.accessToken ?? ''
	});
}

export function GetCourseForEvent(channel: number, eventUuid: string) {
	return GetJson<Required<ICourse>>({
		path: `/v4/admin/courses/${channel}/event/${eventUuid}`,
		token: blAuth.accessToken ?? ''
	});
}

export function CreateCourse(channel: number, course: ICreateCourse) {
	return PostJson<ICreateCourse, Required<ICourse>>({
		path: `/v4/admin/courses/${channel}`,
		token: blAuth.accessToken ?? '',
		data: course
	});
}

export function DuplicateCourse(channel: number, eventId: number, courseUuid: string) {
	return PostJson<{ eventId: number }, Required<ICourse>>({
		path: `/v4/admin/courses/${channel}/course/duplicate/${courseUuid}`,
		token: blAuth.accessToken ?? '',
		data: { eventId }
	});
}

export function UpdateCourse(channel: number, courseUuid: string, course: ICreateCourse) {
	return PatchJson<ICreateCourse, Required<ICourse>>({
		path: `/v4/admin/courses/${channel}/${courseUuid}`,
		token: blAuth.accessToken ?? '',
		data: course
	});
}

export function DeleteCourse(channel: number, courseUuid: string) {
	return DeleteJson({
		path: `/v4/admin/courses/${channel}/${courseUuid}`,
		token: blAuth.accessToken ?? '',
		data: {},
		returnRaw: true
	});
}

export function GetCertificateForCourseUuid(channel: number, courseUuid: string) {
	return GetJson<Required<ICourseCertificate>>({
		path: `/v4/admin/courses/${channel}/${courseUuid}/certificate`,
		token: blAuth.accessToken ?? ''
	});
}

export function GetCourseCertificate(channel: number, certificateUuid: string) {
	return GetJson<Required<ICourseCertificate>>({
		path: `/v4/admin/courses/${channel}/certificate/${certificateUuid}`,
		token: blAuth.accessToken ?? ''
	});
}

export function CreateCourseCertificate(channel: number, certificate: ICreateCourseCertificate) {
	return PostJson<ICreateCourseCertificate, Required<ICourseCertificate>>({
		path: `/v4/admin/courses/${channel}/certificate`,
		token: blAuth.accessToken ?? '',
		data: certificate
	});
}

export function UpdateCourseCertificate(channel: number, certificateUuid: string, certificate: ICreateCourseCertificate) {
	return PatchJson<ICreateCourseCertificate, Required<ICourseCertificate>>({
		path: `/v4/admin/courses/${channel}/certificate/${certificateUuid}`,
		token: blAuth.accessToken ?? '',
		data: certificate
	});
}

export function DeleteCourseCertificate(channel: number, certificateUuid: string) {
	return DeleteJson({
		path: `/v4/admin/courses/${channel}/certificate/${certificateUuid}`,
		token: blAuth.accessToken ?? '',
		data: {}
	});
}

export function GetCourseRequirements(channel: number, courseUuid: string) {
	return GetJson<ICourseRequirement[]>({
		path: `/v4/admin/courses/${channel}/${courseUuid}/requirements`,
		token: blAuth.accessToken ?? ''
	});
}

export function CreateCourseRequirement(channel: number, courseUuid: string, requirement: ICreateCourseRequirement) {
	return PostJson<ICreateCourseRequirement, Required<ICourseRequirement>>({
		path: `/v4/admin/courses/${channel}/${courseUuid}/requirements`,
		token: blAuth.accessToken ?? '',
		data: requirement
	});
}

export function UpdateCourseRequirement(channel: number, courseUuid: string, requirementUuid: string, requirement: ICreateCourseRequirement) {
	return PatchJson<ICreateCourseRequirement, Required<ICourseRequirement>>({
		path: `/v4/admin/courses/${channel}/${courseUuid}/requirements/${requirementUuid}`,
		token: blAuth.accessToken ?? '',
		data: requirement
	});
}

export function DeleteCourseRequirement(channel: number, courseUuid: string, requirementUuid: string) {
	return DeleteJson({
		path: `/v4/admin/courses/${channel}/${courseUuid}/requirements/${requirementUuid}`,
		token: blAuth.accessToken ?? '',
		data: {},
		returnRaw: true
	});
}

export function GetCourseAttendee(token: string, eventUuid: string) {
	return GetHvJson<ICourse>({
		path: `/e3-get-course/${eventUuid}`,
		service: HvHostMap.eventData,
		headers: {
			'Bl-Profile-Token': token
		}
	});
}

export function SendPulseCheckReceipt(token: string, pulseCheckId: number) {
	return PostHvJson<ICourse>({
		path: `/scheduled-pulse-check-receipt`,
		service: HvHostMap.eventData,
		blProfileToken: token,
		data: { pulseCheckId, token }
	}).catch((e: unknown) => {
		if (e instanceof FetchError && e.status === 200 && e.error.startsWith('<?xml')) {
			return;
		} else {
			throw e;
		}
	});
}

export function SendPulseCheckAcknowledgement(token: string, pulseCheckId: number) {
	return PostHvJson<ICourse>({
		path: `/scheduled-pulse-check-acknowledgement`,
		service: HvHostMap.eventData,
		blProfileToken: token,
		data: { pulseCheckId, token }
	}).catch((e: unknown) => {
		if (e instanceof FetchError && e.status === 200 && e.error.startsWith('<?xml')) {
			return;
		} else {
			throw e;
		}
	});
}

export function GetCourseCertificateDesigns(channel: number) {
	return GetJson<ICourseCertificateDesign[]>({
		path: `/v4/admin/courses/${channel}/certificate-designs`,
		token: blAuth.accessToken ?? ''
	});
}

export function GetCanonicalCoursePassState(token: string, eventUuid: string) {
	const params = new URLSearchParams();
	if (localStorage.getItem('NO-CACHE')) {
		params.set('no-cache', 'true');
	}
	return GetHvJson<UserCourseCompletionStats>({
		path: `/e3-get-course-completion/${eventUuid}?${params.toString()}`,
		headers: {
			'bl-profile-token': token
		},
		service: HvHostMap.eventData
	});
}

export function ForceCompleteCourse(channelId: number, courseUuid: string, blProfileId: number) {
	return PutJson<{ blProfileId: number }, {
		completed: boolean;
		certificateLocation: string | null;
	}>({
		path: `/v4/admin/courses/${channelId}/${courseUuid}/completions`,
		token: blAuth.accessToken ?? '',
		data: { blProfileId }
	});
}

export function CheckCertificateGenerating(channelId: number, courseUuid: string, blProfileId: number) {
	const params = new URLSearchParams({ blProfileId: String(blProfileId) });
	return GetJson<{
		generating: boolean
	}>({
		path: `/v4/admin/courses/${channelId}/${courseUuid}/certificate-generating?${params.toString()}`,
		token: blAuth.accessToken ?? '',
	});
}

export function GetCourseCertDownloadForUser(channelId: number, courseUuid: string, blProfileId: number) {
	return GetJson<{ url: string }>({
		path: `/v4/admin/courses/${channelId}/${courseUuid}/certificate-download/${blProfileId}`,
		token: blAuth.accessToken ?? ''
	});
}