import { GetCanonicalCoursePassState, GetCourseAttendee, GetCourseForEvent } from "connection/courses";
import { DispatchedAction, DispatchedAsyncAction } from "types/actions";
import { ICourse, UserCourseCompletionState, UserCourseCompletionStats } from "types/courses";
import { BrandliveEvent } from "types/working-model";

export const LOAD_COURSE = 'COURSE#LOAD_COURSE';
type LoadCourseAction = DispatchedAsyncAction<typeof LOAD_COURSE, ICourse>;
export const loadCourse = (token: string, eventUuid: string): LoadCourseAction => {
	return {
		type: LOAD_COURSE,
		promise: GetCourseAttendee(token, eventUuid)
	};
};

export const LOAD_COURSE_ADMIN = 'COURSE#LOAD_COURSE_ADMIN';
type LoadCourseAdminAction = DispatchedAsyncAction<typeof LOAD_COURSE_ADMIN, ICourse>;
export const loadCourseAdmin = (channel: number, eventUuid: string): LoadCourseAdminAction => {
	return {
		type: LOAD_COURSE_ADMIN,
		promise: GetCourseForEvent(channel, eventUuid)
	};
};

export const WATCHED_SECONDS = 'COURSE#WATCHED_SECONDS';
type WatchedSecondsAction = DispatchedAction<typeof WATCHED_SECONDS, { session_id: number, seconds: number }>;
export const watchedSecondsLocalTrack = (session_id: number, seconds: number): WatchedSecondsAction => {
	return {
		type: WATCHED_SECONDS,
		payload: { session_id, seconds }
	};
};

export const PULSE_CHECK = 'COURSE#PULSE_CHECK';
type PulseCheckAction = DispatchedAction<typeof PULSE_CHECK, { session_id: number }>;
export const pulseCheckLocalTrack = (session_id: number): PulseCheckAction => {
	return {
		type: PULSE_CHECK,
		payload: { session_id }
	};
};

export const CHECK_CANONICAL_PASS_STATE = "COURSE#CHECK_CANONICAL_PASS_STATE";
type CheckCanonicalPassStateAction = DispatchedAsyncAction<
	typeof CHECK_CANONICAL_PASS_STATE,
	UserCourseCompletionStats,
	unknown,
	{ shouldUpdate: boolean } | undefined
>;
export const checkCanonicalPassState = (token: string, eventUuid: string, opts?: { shouldUpdate: boolean }): CheckCanonicalPassStateAction => {
	return {
		type: CHECK_CANONICAL_PASS_STATE,
		promise: GetCanonicalCoursePassState(token, eventUuid),
		meta: opts
	};
};

export const RESET_CANONICAL_PASS_STATE = "COURSE#RESET_CANONICAL_PASS_STATE";
type ResetCanonicalPassStateAction = DispatchedAction<typeof RESET_CANONICAL_PASS_STATE, undefined>;
export const resetCanonicalPassState = (): ResetCanonicalPassStateAction => {
	return {
		type: RESET_CANONICAL_PASS_STATE,
		payload: undefined
	};
};

export const SET_PASS_CHECK_LOADING = "COURSE#SET_PASS_CHECK_LOADING";
type SetPassCheckLoadingAction = DispatchedAction<typeof SET_PASS_CHECK_LOADING, boolean>;
export const setPassCheckLoading = (loading: boolean): SetPassCheckLoadingAction => {
	return {
		type: SET_PASS_CHECK_LOADING,
		payload: loading
	};
};

export const ESTIMATE_PASSABILITY = "COURSE#ESTIMATE_PASSABILITY";
type EstimatePassabilityAction = DispatchedAction<typeof ESTIMATE_PASSABILITY, BrandliveEvent>;
export const estimatePassability = (event: BrandliveEvent): EstimatePassabilityAction => {
	return {
		type: ESTIMATE_PASSABILITY,
		payload: event
	};
};

export type CourseActions = ResetCanonicalPassStateAction | EstimatePassabilityAction | SetPassCheckLoadingAction | CheckCanonicalPassStateAction | LoadCourseAction | LoadCourseAdminAction | WatchedSecondsAction | PulseCheckAction;