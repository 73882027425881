import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from '@dnd-kit/core';
import {
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
	arrayMove,
} from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { isNumber } from "underscore";

import { ESort } from "../../../../../../../../connection/sessions-panel/types";
import { updatePageModule, updatePageModuleAndSave } from "../../../../../../../../store/actions/admin/create-event/session";
import { useAppDispatch, useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { Document, PageModule, PageModuleGroupModules, PageModuleType, SessionPanelLayoutsTypes } from "../../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../../utils/admin-routing-utils";
import { getSessionPanelRouteState } from "../../../../../../../../utils/path-utils";
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import SmallSelect from "../../../../../../../general-ui/select/small-select";
import TextInput from "../../../../../../../general-ui/text-input/text";
import DocumentCard from "../../components/document-card";
import SessionPanelAddFooter from "../../components/session-panel-add-footer";
import { customResourcesItems, extrasResourcesItems } from "../../empty-state-panel/constants/empty-panel";
import { usePageModule } from "../../hooks/panel.hooks";
import { SessionPanelMap } from "../../session-panel-route-map";
import Icon, { COLORS, ICONS } from "@general-ui/icon";

const sortOptions = [
	{ label: 'Newest', value: ESort.dateDesc },
	{ label: 'Oldest', value: ESort.date },
	{ label: 'Name A-Z', value: ESort.name },
	{ label: 'Name Z-A', value: ESort.nameDesc },
];

const documentSearch = (term: string, documents: Document[]): Document[] => {
	return [...documents].filter(document => {
		// search across the entire stringified JSON
		// as a naive way of doing multi-language search
		const terms = JSON.stringify(document?.display_name).toLowerCase() + JSON.stringify(document?.display_name).toLowerCase();
		return terms.includes(term);
	});
};

const sortDocuments = (sort: ESort | undefined, documents: Document[]): Document[] => {
	return [...documents].sort((a: Document, b: Document) => {
		// many products have the same creation date
		// use the product id as a tiebreaker
		const aId = (a.document as number);
		const bId = (b.document as number);
		switch (sort) {
			case ESort.date: {
				return (a.created_at.toString() + aId) > (b.created_at.toString() + bId) ? -1 : 1;
			}
			case ESort.dateDesc: {
				return (a.created_at.toString() + aId) < (b.created_at.toString() + bId) ? -1 : 1;
			}
			case ESort.name: {
				return a.display_name.base.toLowerCase() < b.display_name.base.toLowerCase() ? -1 : 1;
			}
			case ESort.nameDesc: {
				return a.display_name.base.toLowerCase() > b.display_name.base.toLowerCase() ? -1 : 1;
			}
			default: {
				return (a.document as number) < (b.document as number) ? -1 : 1;
			}
		}
	});
};

const ActiveResourcesList: React.FC<unknown> = () => {
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const [documents, setDocuments] = useState<Document[]>([]);
	const [sortOrder, setSortOrder] = useState<ESort | undefined>(ESort.dateDesc);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [addButtonIsOpen, setAddButtonIsOpen] = useState(false);

	const searchTermRef = useRef<string>();
	const containerRef = useRef<HTMLDivElement | null>(null);
	const scrollRef = useRef<HTMLDivElement | null>(null);
	const moduleGroupingRef = useRef<PageModuleGroupModules[] | undefined>(workingSession?.module_grouping);
	const pageModulesRef = useRef<PageModule[] | undefined>(workingSession?.modules);

	const { customPath } = useParams<{ customPath?: string }>();
	const pageModule = usePageModule();
	const history = useHistory();
	const getAdmin = useGetAdminUrl();
	const dispatch = useAppDispatch();

	const { isExtrasCustom } = getSessionPanelRouteState(location.pathname);
	const extrasItems = isExtrasCustom ? customResourcesItems : extrasResourcesItems(!!customPath);
	const moduleGrouping = workingSession?.module_grouping;
	const pageModules = workingSession?.modules;

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 8
			}
		}),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	function handleDragEnd(event: DragEndEvent) {
		if (!pageModule?.modules) return;
		const _modules = [...pageModule.modules];

		const startIndex = event?.active?.data?.current?.sortable.index;
		const newIndex = event?.over?.data?.current?.sortable.index;

		if (isNumber(startIndex) && isNumber(newIndex) && startIndex !== newIndex) {
			const newModuleOrder = arrayMove(_modules, startIndex, newIndex);

			dispatch(
				updatePageModule({
					...pageModule,
					modules: newModuleOrder,
					content_modules: newModuleOrder.map(
						(document: Document) => document.document
					),
					is_edited: true,
				})
			);
		}
	}

	useEffect(() => {
		if (moduleGrouping) {
			moduleGroupingRef.current = moduleGrouping;
		}

		if (pageModules) {
			pageModulesRef.current = pageModules;
		}
	}, [moduleGrouping, pageModules]);

	const getExistingDocuments = useCallback(() => {
		if (pageModule?.modules) {
			return pageModule.modules as Document[];
		}

		return [];
	}, [pageModule]);

	const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		searchTermRef.current = e.target.value;
	}, []);

	const handleSearch = useCallback(() => {
		// not doing typeahead search, user has to press enter or blur field
		// so we aren't updating the state every keystroke, just holding the value in a ref
		setSearchTerm(searchTermRef.current ?? '');
	}, []);

	useEffect(() => {
		const documents = getExistingDocuments();
		const filteredDocuments = documentSearch(searchTerm, documents);
		const sortedDocuments = sortDocuments(sortOrder, filteredDocuments);
		setDocuments(sortedDocuments);
	}, [searchTerm, getExistingDocuments, sortOrder]);

	useEffect(() => {
		setDocuments(getExistingDocuments());
	}, [getExistingDocuments]);

	const handleSort = useCallback((value: string) => {
		if (value) {
			setSortOrder(value as ESort);
		} else {
			setSortOrder(undefined);
		}
	}, []);

	const handleEdit = useCallback((document: Document) => {
		if (!pageModule?.id) return;

		history.push(getAdmin({
			path: SessionPanelMap[SessionPanelLayoutsTypes.CreateExtraResource],
			customPath,
			page_module: pageModule?.id,
		}), { documentToEdit: document, panelTitle: 'Edit Resource' });
	}, [customPath, getAdmin, history, pageModule?.id]);

	const handleDelete = useCallback((document: Document) => {
		if (!pageModule) return;

		dispatch(updatePageModuleAndSave({
			...pageModule,
			content_modules: pageModule.content_modules?.filter(module => module !== document.document),
			modules: pageModule.modules?.filter((module: Document) => module.document !== document.document),
			type: PageModuleType.documents
		}));
	}, [dispatch, pageModule]);

	return (
		<div className={"session-panel resources-list"} ref={containerRef}>
			<div className="session-panel-header-options">
				<TextInput
					defaultValue={''}
					onChange={handleSearchChange}
					onBlur={handleSearch}
					onEnterKey={handleSearch}
					placeholder="Search..."
					className="small"
					isAdmin
				/>
				<SmallSelect
					options={sortOptions}
					selected={sortOrder || ''}
					onChange={handleSort}
				/>
			</div>
			{documents.length && pageModule ? (
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
					modifiers={[restrictToParentElement]}
					autoScroll={false}
				>
					<StaggerChildren
						ref={scrollRef}
						className={classNames(
							"session-panel-content added-items",
							{
								'overflow-y-visible': documents.length === 1 && addButtonIsOpen,
							}
						)}
						enableFades={true}
						footer={
							(
								<SessionPanelAddFooter
									items={extrasItems}
									scrollRef={scrollRef}
									onMenuToggle={setAddButtonIsOpen}
									dropdownPosition="top"
								/>
							)
						}
					>
						<SortableContext
							items={pageModule.modules?.map((module: Document) => String(module.document)) || []}
							strategy={verticalListSortingStrategy}
							disabled={!!searchTerm}
						>
							<div className="documents-list  documents-list-session-page-editor">
								{pageModule.modules?.map((document: Document, index: number) => (
									<SortableDocument 
										key={document.document}
										id={document.document}
										document={document}
										handleEdit={handleEdit} 
										handleDelete={handleDelete}
									/>
								))}
							</div>
						</SortableContext>
					</StaggerChildren>
				</DndContext>
			) : (
				<div className="session-panel-no-results"><section>No results</section></div>
			)}
		</div>
	);
};

interface SortableDocumentProps {
	document: Document;
	id: number;
	handleEdit: (document: Document) => void;
	handleDelete: (document: Document) => void;
}

function SortableDocument({ document, handleEdit, id, handleDelete }: SortableDocumentProps) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
	} = useSortable({ id: String(id) });

	const style = {
		transform: CSS.Transform.toString(transform),
		display: 'flex',
		cursor: 'grabbing'
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
			className="document-wrapper registration-option-v2-container"
		>
			<div className="drag-handle">
				<Icon
					name={ICONS.DRAG_HANDLE}
					size={16}
					color={COLORS.GRAY} 
				/>
			</div>
			<DocumentCard
				key={document.document}
				document={document}
				handleEdit={handleEdit}
				handleDelete={handleDelete}
			/>
		</div>
	);
}

export default ActiveResourcesList;