import React, { useCallback, useMemo, useState, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';
import { TFunction } from 'react-i18next';
import classNames from 'classnames';

import { completeRegistrationProfile, register, setRegistrationStep, finishedStripePayment, clearErrorBoundaryComponents } from '../../../store/actions/event/event-actions';
import { EventsState } from '../../../store/types';
import { TemplateNames } from '../../../types/template-layouts';
import {
	BlProfile,
	BrandliveEvent,
	ChannelFeaturesEnum,
	EPreviewTypes,
	IRegistrationQuestionTranslations,
	LanguagesAbbr,
	RegFieldsEnum,
	RegistrationQuestion,
	RegistrationQuestionSettings,
	RegistrationStep,
	RegistrationStepType,
	TicketingSet,
	TranslateString,
} from '../../../types/working-model';
import { getStartTime } from '../../../utils/get-event-start-time';
import { SelectOption } from '../../general-ui/select/select-native';
import WaitingIndicator from '../../general-ui/waiting-indicator/waiting-indicator';
import { eventPath, getDefaultLanguage } from '../utils';
import { RegistrationFooterProps } from './registration-footer';
import { RegistrationLayout } from './registration-layout/registration-layout';
import { getAllStoredQueryParams, getPathKey, getStoredReferrer } from '../../../utils/utils';
import useTranslationModules, { useTranslate } from '../../../i18n/useTranslationModules';
import { ParamsProps } from '../live-event';
import { getStorageItem, getStorageObject } from '../../../utils/local-storage';
import { TicketingContent } from './steps/ticketing';
import { AvatarContent } from './steps/avatar';
import { GeneralContent } from './steps/generalContent';
import { hasEventAccess } from '../../../utils/registration-utils';
import { SignIn } from './steps/signIn';
import { VerificationView } from './steps/verificationView';
import { handleRecaptcha } from '../../../utils/execute-recaptcha';
import RecaptchaV2 from '../../general-ui/recaptcha-v2/recaptcha-v2';
import { OptionalComponent } from '../../../utils/optional-component';
import useShowRecaptchaBadge from './use-show-recaptcha-badge';
import ErrorBoundary, { TEST_ERROR_BOUNDARIES } from '../../../utils/error-boundary';
import { IRegistrationGDPR } from '../../../connection/registration';
import useCheckIfCookieAccepted from '../../general-ui/cookie-banner/use-check-if-cookie-accepted';
import { Redirects } from '../../../utils/redirects';
import { TReactSelectOption } from '../../general-ui/react-select/react-select-styles';
import { useTypedSelector } from '../../../store/reducers/use-typed-selector';
import { IPasswordGatingProps } from '../registration-v2/registration-v2';
const LazyStripeWrapped = React.lazy(() => import('./lazy-stripe-wrapped'));

import '../../../scss/live-event/base/general-ui/input.scss';
import '../../../scss/live-event/base/registration/registration.scss';

const getOptions = (options: string[]): SelectOption[] => {
	return options.map((o) => ({
		label: o,
		value: o,
	}));
};

// type GlobalRegField = "registration.registration_fields.1" | "registration.registration_fields.2" | "registration.registration_fields.3" | "registration.registration_fields.4" | "registration.registration_fields.5" | "registration.registration_fields.6" | "registration.registration_fields.7" | "registration.registration_fields.8" | "registration.registration_fields.9" | "registration.registration_fields.10" | "registration.registration_fields.11" | "registration.registration_fields.18" | "registration.registration_fields.21" | "registration.registration_fields.38" | "registration.registration_fields.47";
export interface IRegistrationFormField {
	type: string;
	field_id: number;
	label: string;
	placeholder: string | undefined;
	options?: SelectOption[];
	optionMap?: Map<number, string>;
	required: boolean;
	persistent: boolean;
	global: boolean;
	translation?: TranslateString;
	settings?: RegistrationQuestionSettings;
}

export const parseFields = ({
	questions,
	requiredFields,
	language,
	t,
	eventRegistrationTranslations
}: {
	questions: RegistrationQuestion[];
	requiredFields: number[];
	language: string;
	t: TFunction;
	eventRegistrationTranslations?: IRegistrationQuestionTranslations;
}): [IRegistrationFormField[], Map<string, string>] => {
	const selectFieldMap = new Map<string, string>();

	const data = questions?.map(({
		name,
		registration_question,
		type,
		options,
		persistent,
		global,
		placeholder,
		name_translation,
		options_translation,
	}) => {

		// initialize label and placeholder values with v1 or v2 translations
		let translatedLabel = global ?
			(t(`question.${registration_question}.name`, t(`homepage:registration.registration_fields.${registration_question}`, ''))) as string
			: t(`question.${registration_question}.name`, name_translation?.[language] as string || name);

		// first check for a translation json placeholder, fall back to a user-set string, fall back from there to the translated field from static, fall back from there to name
		let translatedPlaceholder = t(
			`registrations:question.${registration_question}.placeholder`,
			(placeholder?.[language] as string || placeholder?.base) ?? t(`homepage:registration.registration_fields.${registration_question}`, name_translation?.[language] as string || name)
		);

		// check if v3 translations exists, if so, override the label and placeholder values
		if (eventRegistrationTranslations) {
			const dbLabelTranslation = eventRegistrationTranslations[registration_question]?.name?.[language];
			if (dbLabelTranslation && typeof dbLabelTranslation === 'string') {
				translatedLabel = dbLabelTranslation;
			}
			const dbPlaceholderTranslation = eventRegistrationTranslations[registration_question]?.placeholder?.[language];
			if (dbPlaceholderTranslation && typeof dbPlaceholderTranslation === 'string') {
				translatedPlaceholder = dbPlaceholderTranslation;
			}
		}


		const translatedOptions = (): string[] | undefined => {
			if (!options) return;

			// first pass - if it's global first check the user uploaded translations, then fall back to the static translations, then fall back to the option in plain text
			if (global && registration_question !== RegFieldsEnum.state) {
				const translated = options.map((option, index) => {
					let translation = t(
						`registrations:question.${registration_question}.options.${index}`,
						t(`homepage:registration.registration_fields.options.${option.replace(/\./g, "")}`, option)
					);
					// check if v3 available
					const v3Option = eventRegistrationTranslations?.[registration_question]?.options?.[index]?.[language];
					if (v3Option && typeof v3Option === 'string') {
						translation = v3Option;
					}

					selectFieldMap.set(translation, option);
					return translation;
				});

				return registration_question === RegFieldsEnum.country ? translated.sort() : translated;

				// second pass - if the option has been passed in uploaded translations use it, fall back to the translated options map, fall back to the option in plain text
			} else {
				let optsMap: Map<number, TranslateString>;
				if (options_translation) {
					optsMap = new Map(options_translation.map((opt, i) => [i, opt]));
				}

				return options.map((option, index) => {
					let translation = t(`registrations:question.${registration_question}.options.${index}`, optsMap?.get(index)?.[language] ?? option);
					// check if v3 available
					const v3Option = eventRegistrationTranslations?.[registration_question]?.options?.[index]?.[language];
					if (v3Option && typeof v3Option === 'string') {
						translation = v3Option;
					}
					selectFieldMap.set(translation, option);
					return translation;
				});
			}
		};

		const translatedOptionsWithBaseValue = (): TReactSelectOption[] | undefined => {
			if (!options) return;

			const regQn = eventRegistrationTranslations?.[registration_question];
			if (!regQn?.options) {
				return getOptions(translatedOptions() ?? []);
			}

			return Object.values(regQn.options).map(option => ({
				label: (option[language] ?? option.base) as string,
				value: option.base
			}));
		};

		const result: IRegistrationFormField = {
			type,
			field_id: registration_question,
			label: translatedLabel,
			placeholder: translatedPlaceholder,
			options: options ? translatedOptionsWithBaseValue() : undefined,
			required: requiredFields?.includes(registration_question),
			persistent,
			global,
		};

		return result;
	}) || [];

	return [[...data], selectFieldMap];
};

const getUTMParams = (
	queryParams: Record<string, string>
): Record<string, string> | undefined => {
	if (!queryParams) {
		return {
			...getStorageObject(`utm_params-${getPathKey()}`)
		};
	}

	return {
		...Object.fromEntries(
			Object.entries(queryParams).filter(([key]) => key.toLowerCase().startsWith('utm'))
		),
		...getStorageObject(`utm_params-${getPathKey()}`)
	};
};


interface RegistrationPageProps {
	previewLanguage?: LanguagesAbbr
}

const RegistrationPage: React.FC<RegistrationPageProps> = ({ previewLanguage }): JSX.Element => {
	const {
		LiveEventReducer: {
			eventBundle,
			registrationStep,
			finishedRegistering,
			blProfileUserToken,
			registering,
			completingProfile,
			registrationError,
			blProfileUser,
			registrationId,
			validSessions,
			userVerified,
			recaptchaActionRequired,
			verifyingUser,
			firesidesHostSessions,
			registration_bypass,
			loadingValidSessions,
			shouldRedirectToAlreadyRegisteredEmail
		},
		CreateEventReducer: {
			workingEvent,
			previewMode,
			registrationStep: previewRegistrationStep
		}
	} = useSelector((event: EventsState) => event);

	const { uuid, eventName, language }: ParamsProps = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const location: any = useLocation();

	const [signIn, setSignIn] = useState(false);
	const [widgetId, setWidgetId] = useState<number | null>(null);
	const [isMarketingOptInChecked, setIsMarketingOptInChecked] = useState<boolean>(false);

	useShowRecaptchaBadge();

	useEffect(() => {
		const hasSigInHash = window.location.hash.substring(1) === 'sign-in';
		if (hasSigInHash) {
			setSignIn(true);
		}
	}, []);

	// Check to make sure language is in translated list, if not, push them to the default language
	// Need to force back to ACTUAL BASE lanuage, base is not always English.
	const languageExists = eventBundle?.sessionsPreviews?.some(session => session.languages.includes(language));

	// we are only going to override the path language IF the event bundle has actually loaded and it does not exist
	const notValidTranslation = eventBundle && !languageExists;

	const defaultLangage = eventBundle ? getDefaultLanguage(eventBundle) : 'en';
	const languageToUse = previewLanguage //if in preview module/portal use the passed language beacuse params is not avalable
		? previewLanguage
		: notValidTranslation ? defaultLangage : language;

	useTranslationModules({ language: languageToUse });

	const isPreview = previewMode === 'registration';
	const event = eventBundle ?? workingEvent;
	const registration = event?.registration_settings;
	const registrationSteps = event?.registration_steps;
	const singleSignOns = event?.registration_settings?.singleSignOn?.singleSignOns;
	const requiredFields = event?.required_registration_questions;
	const template = event?.template.name || TemplateNames.Classic;
	const logo = event?.registration_settings?.logo;
	const recaptchaEnabled = !!event?.registration_settings?.enableRecaptcha;

	const startDateTicks = event ? getStartTime(event, false) : undefined;
	const startDate = startDateTicks ? new Date(startDateTicks) : undefined;
	const activeSteps = useMemo(
		() => registrationSteps?.filter((step) => step.isOn) || [],
		[registrationSteps]
	);

	// const ticketingStep = activeSteps.findIndex(step => step.ticketing);
	const isFinalStep = registrationStep >= activeSteps.length - 1;
	const isFirstStep = registrationStep === 0;
	const activeStep = activeSteps?.[registrationStep];

	useEffect(() => {
		// on first render of preview, set active step to match current reg step in editor
		if (previewMode === EPreviewTypes.Registration && activeSteps.length) {
			const startingStep = activeSteps.findIndex(step => step.type === previewRegistrationStep);
			dispatch(setRegistrationStep(startingStep));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (TEST_ERROR_BOUNDARIES) {
			return () => {
				dispatch(clearErrorBoundaryComponents());
			};
		}
	}, [dispatch]);

	const handlePrevStep = useCallback((e: any) => {
		e.preventDefault();
		if (isFirstStep) return;
		dispatch(setRegistrationStep(registrationStep - 1));
	}, [isFirstStep, dispatch, registrationStep]);

	// used for registration integrations. We want to send out reg/profile data on last registration step,
	// but not if final step is ticketing. If this is the case, return true when on the final non ticketing step
	const isFinalNonTicketStep = useCallback(() => {
		// if final step and type not ticketing, is last non ticketing reg step
		if (isFinalStep && activeStep?.type !== RegistrationStepType.ticketing) {
			return true;
		}
		// if we are on the second to last reg step and the final reg question is of type ticketing
		if (
			registrationStep >= activeSteps.length - 2 &&
			activeSteps?.[activeSteps.length - 1]?.type === RegistrationStepType.ticketing
		) {
			return true;
		}
		return false;
	}, [registrationStep, activeStep, isFinalStep, activeSteps]);

	const { userRegGDPR } = useCheckIfCookieAccepted();

	const cookiesEnabled = !!eventBundle?.settings.gdpr?.enable_cookie_banner;
	const enableCookieOptIn = !!eventBundle?.channel_features?.includes(ChannelFeaturesEnum.cookie_opt_in);

	const handleSaveData = useCallback(async (data: SubmissionValues, authCode?: string) => {
		// if event settings have cookies enabled, but user has not clicked accept, do not let them pass,
		// exception for cookie opt in channel setting
		if (!enableCookieOptIn && cookiesEnabled && !userRegGDPR) return;

		// if in preview mode don't submit but progress through registration
		if (previewMode === EPreviewTypes.Registration) {
			if (isFinalStep) return;
			return dispatch(setRegistrationStep(registrationStep + 1));
		}
		//drop "/registration" from the current path to redirect the user back to the page.
		const path = window.location.href.split("/");
		path.pop();
		const source = path.join("/");

		if (!event) return;
		switch (activeStep?.type) {
			case RegistrationStepType.general: {
				const userCode = getStorageItem('user-code');
				const queryParams = getAllStoredQueryParams(event.uuid);

				try {
					const ACTION = 'signup';

					// recaptcha (runs v3, if v3 fails, runs v2)
					const recaptchaResponse = recaptchaEnabled && await handleRecaptcha({
						action: ACTION,
						useV2: recaptchaActionRequired,
						widgetId,
					});

					const headers = recaptchaResponse?.headers ?? {};

					const utmParams = getUTMParams(queryParams);

					return dispatch(register(
						{
							fields: data,
							lang: language,
							source: source,
							userCode: userCode,
							timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
							isFinalStep: isFinalStep,
							utmParams,
							referrer: getStoredReferrer(event.uuid),
							allQueryParams: { ...queryParams, ...utmParams },
							action: ACTION, // for recaptcha v3
							hostname: window.location.hostname, // for recaptcha
							isFinalNonTicketStep: isFinalNonTicketStep(),
							authCode,
							redirect_uri: authCode ? `${window.location.origin}/sso-redirect` : undefined,
							step: RegistrationStepType.general,
							registered_language: languageToUse,
							isMarketingOptInChecked,
							// if user has not made a cookie banner choice and event has cookie banner enabled, then we default them to opt out
							gdpr: userRegGDPR || (
								event?.settings?.gdpr?.enable_cookie_banner
									? { cookies_accepted: false }
									: undefined
							),
						},
						event.uuid,
						blProfileUserToken,
						headers,
					));
				} catch (e) {
					console.error(e);
					return;
				}
			}
			case RegistrationStepType.profile: {
				const queryParams = getAllStoredQueryParams(event.uuid);
				//if this is the final step, we must send the email
				const options = {
					fields: data,
					lang: language,
					avatar: null,
					channel: event.channel,
					isFinalStep: isFinalStep,
					source: source,
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					eventUuid: event.uuid,
					registrationId: registrationId as string,
					event_title: event.name || "",
					allQueryParams: queryParams,
					isFinalNonTicketStep: isFinalNonTicketStep(),
					step: RegistrationStepType.profile,
				};
				return dispatch(completeRegistrationProfile(options, blProfileUserToken));
			}
			case RegistrationStepType.avatar: {
				const queryParams = getAllStoredQueryParams(event.uuid);
				//if this is the final step, we must send the email
				const options = {
					fields: data,
					lang: language,
					avatar: null,
					channel: event.channel,
					isFinalStep: isFinalStep,
					source: source,
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					eventUuid: event.uuid,
					registrationId: registrationId as string,
					event_title: event.name || "",
					allQueryParams: queryParams,
					isFinalNonTicketStep: isFinalNonTicketStep(),
					step: RegistrationStepType.avatar,
				};
				return dispatch(completeRegistrationProfile(options, blProfileUserToken));
			}
			case RegistrationStepType.ticketing: {
				return dispatch(finishedStripePayment());
			}
		}
	}, [
		previewMode,
		activeStep?.type,
		isFinalStep,
		dispatch,
		registrationStep,
		event,
		language,
		blProfileUserToken,
		registrationId,
		recaptchaActionRequired,
		widgetId,
		recaptchaEnabled,
		isFinalNonTicketStep,
		languageToUse,
		isMarketingOptInChecked,
		userRegGDPR,
		cookiesEnabled,
		enableCookieOptIn,
	]);

	const cancelSignIn = useCallback(() => {
		setSignIn(false);
		history.push(`${location.pathname}`);
	}, [history, location.pathname]);

	const handleSignIn = useCallback((e: any) => {
		e.preventDefault();
		if (!eventBundle) return;

		setSignIn(true);
		const defaultLanguage = getDefaultLanguage(eventBundle);

		// using a router hash instead of a new route so we can easily keep our animations
		history.push(`/${eventPath(eventName, uuid)}/${language || defaultLanguage}/registration#sign-in`);
	}, [history, uuid, eventName, language]);

	useEffect(() => {
		// Handles linking from a calendar invite link (or any link) for an already registered user
		// who's token is expired or visiting on a different device
		if (!eventBundle || !shouldRedirectToAlreadyRegisteredEmail) return;

		setSignIn(true);
		const defaultLanguage = getDefaultLanguage(eventBundle);

		// using a router hash instead of a new route so we can easily keep our animations
		history.push(`/${eventPath(eventName, uuid)}/${language || defaultLanguage}/registration#sign-in`);
	}, [shouldRedirectToAlreadyRegisteredEmail]);

	const footerProps = useMemo(() => {
		return {
			isFirstStep,
			isFinalStep,
			onPrevStepClick: handlePrevStep,
			singleSignOns: singleSignOns || [],
			waiting: registering || completingProfile,
			error: registrationError,
			eventBundle
		};
	}, [handlePrevStep, isFinalStep, isFirstStep, singleSignOns, registering, completingProfile, registrationError, eventBundle]);

	const getWidgetId = useCallback((widgetId) => {
		setWidgetId(widgetId);
	}, []);

	const fieldDefaults: { [key: string]: number | string; } = {};
	activeStep?.questions?.forEach((question) => {
		if (question.default_value) {
			fieldDefaults[question?.registration_question?.toString()] = question?.default_value;
		}
	});

	if (isPreview && workingEvent && registration) {
		return (
			<RegistrationLayout
				registration={registration}
				startDate={startDate}
				template={template}
			>
				<div className="registration-logo">
					<img
						src={logo}
						alt=""
						aria-hidden="true"
					/>
				</div>
				{activeStep && (
					<RegistrationContent
						defaultValues={fieldDefaults ?? {}}
						activeStep={activeStep}
						requiredFields={requiredFields || []}
						onSaveData={handleSaveData}
						footerProps={footerProps}
						template={template}
						registrationStep={registrationStep}
						steps={activeSteps}
						profile={blProfileUser}
						channel={workingEvent.channel}
						handleSignIn={handleSignIn}
						eventBundle={workingEvent ? workingEvent : eventBundle}
						userRegGDPR={userRegGDPR}
						setIsMarketingOptInChecked={setIsMarketingOptInChecked}
						isMarketingOptInChecked={isMarketingOptInChecked}
					/>
				)}
			</RegistrationLayout>
		);
	}

	if (!registration || !eventBundle) {
		return <WaitingIndicator />;
	}

	// Redirect to if user has access
	if (hasEventAccess(eventBundle, validSessions, finishedRegistering, userVerified, firesidesHostSessions, !!registration_bypass, loadingValidSessions)) {
		return (
			<Redirect to={Redirects.fromRegistration(eventBundle)} />
		);
	}

	const defaults = { ...fieldDefaults, ...blProfileUser?.profile?.[eventBundle.channel], } ?? {};

	return (
		<RegistrationLayout
			registration={registration}
			startDate={startDate}
			template={template}
		>
			<div className="registration-logo">
				<img
					src={logo}
					alt=""
					aria-hidden="true"
				/>
			</div>
			{signIn ? (
				<>
					{verifyingUser && !userVerified ? (
						<VerificationView template={template} />
					) : (
						<SignIn onPrevStepClick={cancelSignIn} template={template} />
					)}
				</>
			) : (
				<>
					{!userVerified && finishedRegistering ? (
						<VerificationView template={template} />
					) : (
						<>
							{activeStep ? (
								<RegistrationContent
									defaultValues={defaults}
									activeStep={activeStep}
									requiredFields={requiredFields || []}
									onSaveData={handleSaveData}
									footerProps={footerProps}
									template={template}
									registrationStep={registrationStep}
									steps={activeSteps}
									profile={blProfileUser}
									channel={eventBundle.channel}
									handleSignIn={handleSignIn}
									eventBundle={eventBundle}
									setIsMarketingOptInChecked={setIsMarketingOptInChecked}
									isMarketingOptInChecked={isMarketingOptInChecked}
									renderRecaptcha={() => (
										<OptionalComponent display={recaptchaActionRequired}>
											<RecaptchaV2
												// we need the widget id to pass to grecaptcha's `getResponse` method to ensure we're using the correct widget
												// just in case we have multiple recaptcha widget's on the screen at any time
												getWidgetId={getWidgetId}
											/>
										</OptionalComponent>
									)}
									userRegGDPR={userRegGDPR}
								/>
							) : <></>}
						</>
					)}
				</>
			)}
		</RegistrationLayout>
	);
};

const RegistrationWrapped = (props: RegistrationPageProps): JSX.Element => {
	const steps = useSelector((event: EventsState) => event.LiveEventReducer.eventBundle?.registration_steps);

	const hasTicketing = useMemo(() => steps?.some(step => step.ticketing && step.isOn), [steps]);

	if (hasTicketing) {
		return (
			<Suspense fallback={<WaitingIndicator />}>
				<LazyStripeWrapped {...props}>
					<RegistrationPage {...props} />
				</LazyStripeWrapped>
			</Suspense>
		);
	}

	return <RegistrationPage {...props} />;
};

export default RegistrationWrapped;


export interface ContentProps {
	fields: IRegistrationFormField[];
	defaultValues: SubmissionValues;
	onSaveData: (data: SubmissionValues, authCode?: string) => void;
	footerProps: RegistrationFooterProps;
	template: string;
	profile: BlProfile | null;
	channel: number;
	ticketing?: TicketingSet[];
	isDisplayOnly?: boolean;
	handleSignIn?: (e: any) => void;
	eventBundle?: BrandliveEvent | null;
	currentStep?: RegistrationStep;
	language: LanguagesAbbr;
	renderRecaptcha?: () => JSX.Element;
	selectBaseLanguageFieldMap: Map<string, string>;
	gdprStatus?: IRegistrationGDPR;
	setIsMarketingOptInChecked?: React.Dispatch<React.SetStateAction<boolean>>;
	isMarketingOptInChecked?: boolean;
	passwordGatingProps?: IPasswordGatingProps
}

const RegistrationContent = ({
	activeStep,
	requiredFields,
	footerProps,
	defaultValues,
	onSaveData,
	template,
	registrationStep,
	steps,
	profile,
	channel,
	handleSignIn,
	eventBundle,
	renderRecaptcha,
	userRegGDPR,
	setIsMarketingOptInChecked,
	isMarketingOptInChecked,
}: {
	activeStep: RegistrationStep;
	requiredFields: number[];
	footerProps: RegistrationFooterProps;
	onSaveData: (props: SubmissionValues) => void;
	defaultValues: SubmissionValues;
	template: string;
	registrationStep: number;
	steps: RegistrationStep[];
	profile: BlProfile | null;
	channel: number;
	selectedTicket?: TicketingSet;
	handleSignIn?: (e: any) => void;
	eventBundle?: BrandliveEvent | null;
	renderRecaptcha?: () => JSX.Element;
	userRegGDPR?: IRegistrationGDPR;
	setIsMarketingOptInChecked: React.Dispatch<React.SetStateAction<boolean>>;
	isMarketingOptInChecked: boolean;
}): JSX.Element => {
	const { questions, type } = activeStep;
	const { language } = useParams<ParamsProps>();
	const { t } = useTranslate(['registrations', 'homepage']);

	const liveEventRegTranslations = useTypedSelector(state => state.LiveEventReducer.eventBundle?.registration_settings?.registration_questions_translations);
	const workingEventRegTranslations = useTypedSelector(state => state.CreateEventReducer.workingEvent?.registration_settings?.registration_questions_translations);
	const regTranslations = liveEventRegTranslations ?? workingEventRegTranslations;

	const [fields, selectBaseLanguageFieldMap] = parseFields({
		questions: questions || [],
		requiredFields: requiredFields || [],
		language,
		t,
		eventRegistrationTranslations: regTranslations,
	});

	const stepsMap = ["one", "two", "three", "four", "five"];

	const ticketingStep = steps.find(step => step.type == RegistrationStepType.ticketing && step.isOn);

	const getStep = (step: RegistrationStep) => {
		switch (step.type) {
			case RegistrationStepType.ticketing:
				return (
					<ErrorBoundary uniqueLabel="Ticketing registration step">
						<TicketingContent
							defaultValues={defaultValues}
							template={template}
							fields={fields}
							footerProps={footerProps}
							onSaveData={onSaveData}
							profile={profile}
							channel={channel}
							ticketing={ticketingStep?.ticketing}
							language={language}
							selectBaseLanguageFieldMap={selectBaseLanguageFieldMap}
						/>
					</ErrorBoundary>
				);
			case RegistrationStepType.avatar:
				return (
					<ErrorBoundary uniqueLabel="Avatar registration step">
						<AvatarContent
							defaultValues={defaultValues}
							template={template}
							fields={fields}
							onSaveData={onSaveData}
							footerProps={footerProps}
							profile={profile}
							channel={channel}
							language={language}
							selectBaseLanguageFieldMap={selectBaseLanguageFieldMap}
						/>
					</ErrorBoundary>
				);
			case RegistrationStepType.profile:
				return (
					<ErrorBoundary uniqueLabel="Profile registration step">
						<GeneralContent
							defaultValues={defaultValues}
							template={template}
							fields={fields}
							footerProps={footerProps}
							onSaveData={onSaveData}
							profile={profile}
							channel={channel}
							handleSignIn={handleSignIn}
							currentStep={step}
							language={language}
							eventBundle={eventBundle}
							selectBaseLanguageFieldMap={selectBaseLanguageFieldMap}
							gdprStatus={userRegGDPR}
							setIsMarketingOptInChecked={setIsMarketingOptInChecked}
							isMarketingOptInChecked={isMarketingOptInChecked}
						/>
					</ErrorBoundary>
				);
			case RegistrationStepType.general:
				return (
					<ErrorBoundary uniqueLabel="General registration step">
						<GeneralContent
							defaultValues={defaultValues}
							template={template}
							fields={fields}
							footerProps={footerProps}
							onSaveData={onSaveData}
							profile={profile}
							channel={channel}
							ticketing={ticketingStep?.ticketing}
							handleSignIn={handleSignIn}
							eventBundle={eventBundle}
							currentStep={step}
							language={language}
							renderRecaptcha={renderRecaptcha}
							selectBaseLanguageFieldMap={selectBaseLanguageFieldMap}
							gdprStatus={userRegGDPR}
							setIsMarketingOptInChecked={setIsMarketingOptInChecked}
							isMarketingOptInChecked={isMarketingOptInChecked}
						/>
					</ErrorBoundary>
				);
			default:
				return <>Not implemented</>;
		}
	};

	return (
		<div className={classNames("registration-slider", stepsMap[registrationStep])}>
			{steps.map((step, index) => {
				const currentStep = type === step.type;

				return (
					<fieldset
						className={classNames("registration-panel", { active: currentStep })}
						key={index}
						disabled={!currentStep}
						tabIndex={currentStep ? 0 : -1}
						aria-hidden={!currentStep}
					>
						{getStep(step)}
					</fieldset>
				);
			})}
		</div>
	);
};

export interface SubmissionValues {
	[key: string]: string | boolean | number | Array<string> | undefined;
}
