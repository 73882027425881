// For new images, upload them to the asset server S3 bucket and match the file path to the string in this file
// (In events-api) yarn upload-asset -f ~/path_to/my_image.svg -d bl-platform/images

const imageBasePath = `${process.env.REACT_APP_ASSET_SERVER}/bl-platform/images/`;

enum BaseFolder {
	AddThis = 'addthis-icon.svg',
	AssistantWelcome1 = 'assistant-welcome-1.png',
	BlueLinesLeft = 'blue-lines-left.svg',
	BlueLinesRight = 'blue-lines-right.svg',
	BrandLiveLogo = 'BrandLive_Logo.svg', // This one is styled with CSS, can't remove file
	EventsEmpty = 'events-empty.png',
	Events4_Welcome2 = 'Events4_Welcome2.png',
	Events4_Welcome3 = 'Events4_Welcome3.png',
	Events4_Welcome4 = 'Events4_Welcome4.png',
	FormLeft = 'form-left.svg',
	FormRight = 'form-right.svg',
	RegistrationLayoutAboveTheFold = 'registration-layout-above-the-fold.png',
	RegistrationLayoutBelowTheFold = 'registration-layout-below-the-fold.png',
	RegistrationLayoutPopup = 'registration-layout-popup.png',
	RegistrationLayoutFullPage = 'registration-layout-full-page.png',
	GreenroomLogo = 'greenroom-logo.svg',
	HeaderImage = 'header-image.png',
	Hubspot1 = 'hubspot-1.svg',
	IcAnalytics = 'ic_analytics.svg',
	KollectiveLogo = 'kollective_logo.svg',
	HiveLogo = 'hive_logo.png',
	LimelightBanner = 'limelight-banner.webp',
	NewAssistnantWelcome51 = 'new-assistant-welcome-5-1.png',
	NoEventsStars = 'no_events_stars.svg',
	NowStreamingOnBrandlive = 'now-streaming-on-brandlive.svg', // This one is styled with CSS, can't remove file
	OIDC = 'open-id.svg',
	OrangeLinesLeft = 'orange-lines-left.svg',
	Orbits = 'orbits.gif',
	OrbitsVid = 'orbits.mp4',
	OrbitsStill = 'orbits.jpg',
	PinkCircularGradient = 'pink_circular_gradient.svg',
	Salesforce2 = 'salesforce-2.svg',
	SAMLLogo = 'saml-logo.svg',
	SpaceBackground = 'space-background.png',
	Stripe = 'stripe_alt.png',
	TellyMarch = 'telly-march.svg',
	TellyMegaphone = 'Telly-megaphone.svg',
	TellySpace = 'TellySpace.png',
	Welcome2 = 'welcome-2.png',
	Welcome3 = 'welcome-3.png',
	Welcome4 = 'welcome-4.png',
	TellyClapperboard = 'TellyClapperboard.svg',
	TellyDancePose = 'Telly_DancePose.svg',
	TellyLogo = 'telly-logo-no-shadow.png',
	TellyPresenting = 'Telly_Presenting.svg',
	TellyTired = 'Telly_Tired.svg',
	TellyVictory = 'Telly_Victory.png',
	TellyChair = 'telly-chair.svg'
}

enum IconsReactionsBlFolder {
	heart = 'heart.png', /** @deprecated */
	shocked = 'shocked.png', /** @deprecated */
	thumbsUp = 'thumbs-up.png', /** @deprecated */
	oneHundred = '100.png',
	bottle = 'Bottle.png',
	brainbuster = 'Brainbuster.png',
	cap = 'Cap.png',
	clap = 'Clap.png',
	cowboy = 'Cowboy.png',
	cute = 'Cute.png',
	fan = 'Fan.png',
	flame = 'Flame.png',
	flirt = 'Flirt.png',
	foldedHands = 'FoldedHands.png',
	heartPink = 'HeartPink.png',
	heartEyes = 'HeartEyes.png',
	heartWink = 'HeartWink.png',
	lol = 'LOL.png',
	party = 'Party.png',
	partyPopper = 'PartyPopper.png',
	praise = 'Praise.png',
	rockOn = 'RockOn.png',
	sad = 'Sad.png',
	smile1 = 'Smile1.png',
	smile2 = 'Smile2.png',
	smile3 = 'Smile3.png',
	smile4 = 'Smile4.png',
	starEyes1 = 'StarEyes1.png',
	starEyes3 = 'StarEyes3.png',
	sunglasses1 = 'Sunglasses1.png',
	sunglasses2 = 'Sunglasses2.png',
	thumbsUp2 = 'ThumbsUp.png',
	thumbsUpSmiley = 'ThumbsUpSmiley.png'
}

enum LogosFolder {
	Google = 'Google.svg',
	JiraLogo = 'jira-logo.png',
	Oauth2SM = 'oauth-2-sm.png'
}

enum UiAssetsFolder {
	ChatOverlay = 'chat-overlay.svg',
	ChatSidebar = 'chat-sidebar.svg',
	NavigationNoMenu = 'navigation-no-menu.svg',
	NavigationWithMenu = 'navigation-with-menu.svg',
}

const FilePaths = {
	...BaseFolder,
	...IconsReactionsBlFolder,
	...LogosFolder,
	...UiAssetsFolder
};

const appendBasePath = (folder: Record<string, string>, additionalPath = '') => Object.keys(folder).reduce((acc, cur) => ({
	...acc,
	[cur]: imageBasePath + additionalPath + folder[cur]
}), {});

export const announcementAvatar = `${process.env.REACT_APP_ASSET_SERVER}/announcement-avatar.png`;

export default {
	...appendBasePath(BaseFolder),
	...appendBasePath(IconsReactionsBlFolder, 'icons/reactions/bl/'),
	...appendBasePath(LogosFolder, 'logos/'),
	...appendBasePath(UiAssetsFolder, 'ui-assets/'),
} as (typeof FilePaths);