import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { showAlert, showAlertLong } from "../components/general-ui/alert/alert-service";
import { useAppDispatch, useTypedSelector } from "../store/reducers/use-typed-selector";
import { MeetApp, MeetAppBuilder, PresentationSupport, StartCallResult } from "../types/meet";
import { BrandliveEvent, FiresideSessionSettings, ModuleGroupingTypes, PageModule, PageModuleGroupModules, PageModuleType, Session, SessionPreview, SessionTabsLayoutTypes, SessionTypesEnum, TranslateString } from "../types/working-model";
import { shouldDisplayHomepage, shouldDisplayLandingPage, toDictTyped } from "../utils/utils";
import { useIsNewNavigation } from "./navigation.hooks";
import { useIsSessionPage, useIsSingleSessionPage } from "./path.hooks";
import { useEventCanUseMeetFiresides, useSessionDetailsV2 } from "./channel.hooks";
import { useSessionUuid, useSessionUuidParamAdmin } from "../utils/use-session-uuid-param";
import { ValidateFiresideAccess } from "connection/firesides";
import { setStorageItem } from "utils/local-storage";
import { inMeetWaitingRoom } from "store/actions/event/firesides-actions";
import store from "store/main";
import { isObject } from "underscore";
import { EPaletteModes } from "types/theme-packs";
import { PATHNAMES } from "utils/admin-routing-utils";
import { pathToRegexp } from "path-to-regexp";
import { setViewingSessionUuid } from "store/actions/event/event-actions";
import { getPlaybackUrlLiveState, playbackUrlToPathname } from "store/utils/event-reducer-utils";

export const useGetEvent = (): BrandliveEvent | null => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);

	return eventBundle || workingEvent || null;
};
export const useIsSingleSession = (): boolean => {
	const event = useGetEvent();

	return event?.sessions?.length === 1;
};

export const useIsSingleSessionWithoutHome = (): boolean => {
	const showNewNav = useIsNewNavigation();
	const event = useGetEvent();
	const isSingleSessionPage = useIsSingleSessionPage();
	const displayHomepage = shouldDisplayHomepage(event);
	return isSingleSessionPage && showNewNav && !displayHomepage;
};

export const useIsSingleNoHomepage = (): boolean => {
	const event = useGetEvent();
	const displayHomepage = shouldDisplayHomepage(event);
	return event?.sessionsPreviews?.length === 1 && !displayHomepage;
};

export function notSupposedToHaveModules(type: PageModuleType) {
	switch (type) {
		case PageModuleType.similar_sessions:
		case PageModuleType.description:
		case PageModuleType.blank:
		case PageModuleType.breakout_room:
		case PageModuleType.embed_widget: return true;
		default: return false;
	}
}

export const useActiveModulesInGroup = (session: Session | undefined, moduleGroup: PageModuleGroupModules | undefined): PageModule[] => {
	if (!session || !moduleGroup) return [];

	//If there is no content in the module don't make the tab
	const _modules = session.modules.filter(module => module?.id && moduleGroup.modules.includes(module.id) && module.is_on);
	const modulesWithContent = _modules.filter(_module => notSupposedToHaveModules(_module.type) || !!_module.modules?.length || !!_module.content_modules?.length);

	return modulesWithContent;
};

export const filterPageModulesWithContent = (module: PageModule | undefined) => {
	return module?.is_on &&
		(notSupposedToHaveModules(module.type) || !!module.modules?.length);
};

export const filterModuleGroupsWithContent = (modules: PageModule[], includeOverviewModule: boolean) => (group: PageModuleGroupModules) => {
	if (group.type === 'details') {
		return true;
	}

	const content = modules.filter(
		module =>
			group.modules.includes((module as Required<PageModule>).id) &&
			module.is_on &&
			(module.type === PageModuleType.blank
				|| (module.type === PageModuleType.similar_sessions && !!module.content_modules?.length)
				|| (includeOverviewModule && group.type === ModuleGroupingTypes.Overview)
				|| !!module.modules?.length
			)
	);

	return content.length > 0;
};

export const useActiveModuleGroupsInSession = (session: Session | undefined, isEditor: boolean | undefined, includeOverviewModule = false, isModuleGroupingV2 = true): PageModuleGroupModules[] => {

	const activeModules = useMemo(() => {
		if (!session) return [];
		if (!isModuleGroupingV2 && session?.module_grouping) return session.module_grouping;
		const modulesWithContent = session.module_grouping?.filter(group => group.is_on) || [];
		if (!isEditor) {
			// by default all modules and all module groups are ON unless updated in the event
			// we should check that the published modules actually contain content items before considering them "active"
			return modulesWithContent.filter(filterModuleGroupsWithContent(session.modules, includeOverviewModule));
		}
		return modulesWithContent;
	}, [includeOverviewModule, isEditor, isModuleGroupingV2, session]);

	return activeModules;
};

export const useIsNewModuleGrouping = (): boolean => {
	const event = useGetEvent();
	const sessionDetailsV2 = useSessionDetailsV2();

	const isModuleGroupingV2 = event?.settings?.is_module_grouping_v2;

	return !!(sessionDetailsV2 && isModuleGroupingV2);
};

export const useIsNewModuleGroupingSession = (): boolean => {
	const event = useGetEvent();
	const sessionDetailsV2 = useSessionDetailsV2();
	const sessionUuid = useGetSession()?.uuid;

	const isModuleGroupingV2 = event?.settings?.is_module_grouping_v2;

	return !!(sessionDetailsV2 && isModuleGroupingV2 && sessionUuid);
};

/** Note: useIsAboveTheFold is not an actual react hook  */
export const useIsAboveTheFold = (session: Session | null | undefined): boolean => {
	return !!(session?.layout?.tabs?.layout === SessionTabsLayoutTypes.AboveTheFold);
};

/** Note: useIsBelowTheFold is not an actual react hook  */
export const useIsBelowTheFold = (session: Session | null | undefined): boolean => {
	return !!(session?.layout?.tabs?.layout === SessionTabsLayoutTypes.BelowTheFold);
};

// Dark mode is enabled only for the new navigation, specifically above/below the fold.
// There seem to be cases where the dark mode is toggled as enabled on the session, 
//  even when the new nav is not enabled for the session.  Thus checking for above/below.
/** @deprecated */
export const useDarkMode = (session: Session | null | undefined): boolean => {
	const isBelow = useIsBelowTheFold(session);
	const isAbove = useIsAboveTheFold(session);

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const event = eventBundle || workingEvent;
	const isOnSessionPage = useIsSessionPage();
	// ignore session page if on theme page and we have a landing/home/registration page
	const shouldUseSessionSettingOnThemeTab = shouldDisplaySessionOnThemePage(event);

	let isDarkMode = event?.settings?.design?.color_theme === EPaletteModes.Dark || false;

	if (isOnSessionPage && shouldUseSessionSettingOnThemeTab) {
		isDarkMode = (session?.session_chat_dark_mode_enabled || false) && (isBelow || isAbove);
	}

	return isDarkMode;
};

export const shouldDisplaySessionOnThemePage = (event: BrandliveEvent | null): boolean => {
	let shouldUseSessionSettingOnThemeTab = true;
	const regex = pathToRegexp(PATHNAMES.Event.Theme, undefined, { end: false });
	const onThemePage = regex.exec(window.location.pathname);
	if (onThemePage) {
		const hasLanding = shouldDisplayLandingPage(event);
		const hasHomepage = shouldDisplayHomepage(event);
		const hasRegistration = event?.registration_on;
		if (hasLanding || hasHomepage || hasRegistration) {
			shouldUseSessionSettingOnThemeTab = false;
		}
	}

	return shouldUseSessionSettingOnThemeTab;
};

export const useGetModuleGroupName = (moduleGroup: PageModuleGroupModules | undefined): string => {
	const moduleGroupName = moduleGroup?.name as TranslateString;
	const _name = moduleGroupName?.base ?? moduleGroupName;

	return _name;
};

export const useGetSession = (): Session | undefined | null => {
	const sessionUuidAdmin = useSessionUuidParamAdmin();
	const sessionUuidAttendee = useSessionUuid();
	const sessionUuid = sessionUuidAdmin || sessionUuidAttendee;
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const sessions = useTypedSelector(state => state.LiveEventReducer.eventBundle?.sessions);

	return useMemo(() => {
		if (!sessions?.length) return workingSession;

		if (!sessionUuid && sessions?.length === 1) return sessions[0];

		return sessions?.find(session => session.uuid === sessionUuid);
	}, [sessionUuid, sessions, workingSession]);
};

export const useGetSessionPreview = (): Session | SessionPreview | undefined | null => {
	const sessionUuidAdmin = useSessionUuidParamAdmin();
	const sessionUuidAttendee = useSessionUuid();
	const sessionUuid = sessionUuidAdmin || sessionUuidAttendee;
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const sessionsPreviews = useTypedSelector(state => state.LiveEventReducer.eventBundle?.sessionsPreviews);

	return useMemo(() => {
		if (!sessionsPreviews?.length) return workingSession;

		if (!sessionUuid && sessionsPreviews?.length === 1) return sessionsPreviews[0];

		return sessionsPreviews?.find(session => session.uuid === sessionUuid);
	}, [sessionUuid, sessionsPreviews, workingSession]);
};

export const useGetSessionTracks = (session: Session): {
	label: string;
	value: string;
	color: string;
}[] => {
	const eventTags = useTypedSelector(state => state.LiveEventReducer.eventBundle?.tags);
	const eventTagsMap = useMemo(() => toDictTyped('uuid', eventTags ?? []), [eventTags]);

	const _defaultValues = useMemo(
		() =>
			(session.tags || []).reduce((accumulator: { label: string; value: string; color: string; }[], tagUuid: string) => {
				const eventTag = eventTagsMap[tagUuid];
				if (eventTag) {
					accumulator.push({
						label: eventTag.name,
						value: eventTag.uuid,
						color: eventTag.color,
					});
				}
				return accumulator;
			}, [])
		, [eventTagsMap, session.tags]);

	return _defaultValues;
};

// Pulled directly from session.tsx
interface MeetAppBuiltProps {
	app: MeetApp;
	builder: MeetAppBuilder;
}

export async function joinGoogleMeetRoom(
	element: HTMLDivElement,
	url: string,
	token: string | null,
	meetAppBuilt: MeetAppBuiltProps | undefined,
	setMeetAppBuilt?: React.Dispatch<React.SetStateAction<MeetAppBuiltProps | undefined>>,
	setSelectedTabUuid?: React.Dispatch<React.SetStateAction<string | undefined>>,
	session?: Session | undefined,
	useDarkMode = false,
	oidcIdToken?: string,
	username?: string
): Promise<[StartCallResult, MeetApp | undefined, MeetAppBuilder | undefined]> {
	try {
		// only build meet app if not already built
		if (!meetAppBuilt) {
			const builder = window.meet();
			// Phase 0 requires an oAuth user token to access that user's
			// Meet data.  Phase 1 allows for annoymous access to Meet.
			const buildOptions: { apiKey: string, parentElement: HTMLDivElement, accessToken?: string } = {
				apiKey: process.env.REACT_APP_MEET_API_KEY ?? '',
				parentElement: element,
			};
			if (token) buildOptions.accessToken = token;
			store.dispatch(inMeetWaitingRoom(true));
			const darkModeLightMode = useDarkMode ? EPaletteModes.Dark : EPaletteModes.Light;
			builder.setTheme(darkModeLightMode);
			builder.setEnableEndCallRating(false);
			builder.setPresentationSupport(PresentationSupport.ANYTHING);
			builder.setShowMeetingInfo(false);
			builder.setHideChatWhenDisabled(true);

			if (username) {
				builder.setPreferredAnonymousUserName(username);
			}

			if (oidcIdToken) {
				builder.setOidcIdToken(oidcIdToken);
			}

			builder.on('callStarted', (e) => {
				console.log('Meet call started', e);
				store.dispatch(inMeetWaitingRoom(false));
			});

			builder.setIgnoreBrowserCompatibilityChecks(true);
			const app = builder.build(buildOptions);
			await app.init();

			// save app and builder to state for joining subsequent rooms
			setMeetAppBuilt?.({ app, builder });
			const joinReturn = await app.joinCallFromUrl(url);
			return [joinReturn, app, builder];
		} else {
			//join call from state meet app
			setSelectedTabUuid?.(prev => session?.module_grouping?.find(module => module.uuid !== prev)?.uuid);
			const joinReturn = await meetAppBuilt.app.joinCallFromUrl(url);
			return [joinReturn, meetAppBuilt.app, meetAppBuilt.builder];
		}
	} catch (e: unknown) {
		if (isObject(e) && e?.message?.includes("compatibility")) {
			console.error(`Meet device incompatibile. Error message: ${e.message}`);

			showAlertLong({
				message: "Device not supported",
				description: "Your device does not support Google Meet. Please try again on a different device.",
				type: "error",
			});
		} else {
			console.error(e);
			showAlert({
				message: "Error joining room",
				description: "There was an error joining the room, please try again later",
				type: "error",
				duration: 5000
			});
		}

		return [StartCallResult.UNKNOWN_START_CALL_RESULT, undefined, undefined];
	}
}

export const useFiresidesIsHost = (session?: Session) => {
	const firesidesHostSessions = useTypedSelector(state => state.LiveEventReducer.firesidesHostSessions);
	const sessionId = session?.session;

	return useMemo(() => {
		if (!firesidesHostSessions?.length) return false;

		if (!sessionId) return false;

		return firesidesHostSessions.includes(sessionId);
	}, [firesidesHostSessions, sessionId]);
};

export const useFiresidesHostToken = (session?: Session): [string | undefined, boolean] => {
	const blProfileUserToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const [firesidesHostToken, setFiresidesHostToken] = useState<string>();
	const [hostTokenReady, setHostTokenReady] = useState<boolean>(true);
	const sessionUuid = session?.uuid;
	const mounted = useRef(true);

	const isHost = useFiresidesIsHost(session);

	const isAuthenticatedHost = useCallback(async () => {
		if (isHost && blProfileUserToken && sessionUuid) {
			const result = await ValidateFiresideAccess(blProfileUserToken, sessionUuid);
			if (!mounted.current) return;

			setStorageItem(`firesidesHost.${sessionUuid}`, result.hostToken);
			setFiresidesHostToken(result.hostToken);
			setHostTokenReady(true);
		} else if (blProfileUserToken && sessionUuid) {
			setHostTokenReady(true);
		}
	}, [isHost, blProfileUserToken, sessionUuid]);

	useEffect(() => {
		isAuthenticatedHost();
	}, [isAuthenticatedHost]);

	useEffect(() => {
		return () => {
			mounted.current = false;
		};
	}, []);

	return [firesidesHostToken, hostTokenReady];
};

export const usePassiveGoogleMeetViewer = (firesideSettings?: FiresideSessionSettings, session?: Session) => {
	const isFiresides = session?.session_type === SessionTypesEnum.fireside;
	const isHost = useFiresidesIsHost(session);
	const isMeetFireside = firesideSettings?.settings?.use_google_meet;
	const canActivate = useEventCanUseMeetFiresides();

	return useMemo(() => {
		return canActivate && isFiresides && !isHost && isMeetFireside;
	}, [isFiresides, isHost, isMeetFireside, canActivate]);
};

export const useHasLiveStream = ({ session, language }: { session: Session | SessionPreview | undefined, language: string }): boolean => {
	const liveStates = useTypedSelector(state => state.LiveEventReducer.liveStates);
	const playbackUrls = useTypedSelector(state => state.LiveEventReducer.playbackUrls);

	const [hasLiveStream, setHasLiveStream] = useState(false);

	useEffect(() => {
		if (!session?.uuid || !language) return;
		const playbackUrl = playbackUrls?.[`${session.uuid}-${language}`];
		if (getPlaybackUrlLiveState(liveStates, playbackUrl)?.state === 'live') {
			setHasLiveStream(true);
		} else {
			setHasLiveStream(false);
		}
	}, [language, liveStates, playbackUrls, session?.uuid]);

	return hasLiveStream;
};

export const useViewingSessionUuid = (sessionUuid?: string | null): void => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setViewingSessionUuid(sessionUuid || null));

		return () => {
			dispatch(setViewingSessionUuid(null));
		};
	}, [dispatch, sessionUuid]);
};
