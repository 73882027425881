import { useEffect } from "react";

import { checkCanonicalPassState, loadCourse, loadCourseAdmin } from "store/actions/event/courses";
import { useAppDispatch, useTypedSelector } from "store/reducers/use-typed-selector";

// this hook is intended to be loaded in ONCE in the live event
// please read the course data from redux
export const useCourse = () => {
	const dispatch = useAppDispatch();

	const token = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const eventUuid = useTypedSelector(state => state.LiveEventReducer.eventBundle?.uuid);
	const hasCourse = useTypedSelector(state => state.LiveEventReducer.eventBundle?.settings?.course_enabled);
	const registered = useTypedSelector(state => state.LiveEventReducer.finishedRegistering);
	const course = useTypedSelector(state => state.CourseReducer.course);
	const loadingCourse = useTypedSelector(state => state.CourseReducer.loadingCourse);
	const courseError = useTypedSelector(state => state.CourseReducer.errorLoadingCourse);
	const courseUuid = course?.uuid;

	useEffect(() => {
		if (token && registered && hasCourse && eventUuid && !course && !loadingCourse && !courseError) {
			dispatch(loadCourse(token, eventUuid));
		}
	}, [course, dispatch, eventUuid, hasCourse, loadingCourse, registered, token, courseError]);

	useEffect(() => {
		if (courseUuid && eventUuid && token) {
			dispatch(checkCanonicalPassState(token, eventUuid, { shouldUpdate: true }));
		}
	}, [courseUuid, eventUuid, token, dispatch]);

	useEffect(() => {
		if (courseError) {
			console.error(`Error getting course attendee: ${courseError}`);
		}
	}, [courseError]);
};

export const useCourseAdmin = () => {
	const dispatch = useAppDispatch();

	const user = useTypedSelector(state => state.AuthReducer.user);
	const eventUuid = useTypedSelector(state => state.CreateEventReducer.selectedEvent?.uuid);
	const hasCourse = useTypedSelector(state => state.CreateEventReducer.selectedEvent?.settings?.course_enabled);
	const course = useTypedSelector(state => state.CourseReducer.course);

	useEffect(() => {
		if (user && hasCourse && eventUuid) {
			dispatch(loadCourseAdmin(user.active_channel, eventUuid));
		}
	}, [dispatch, eventUuid, hasCourse, user]);

	return course;
};