import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { IRegistrationResponse, Register, ValidateRegistration } from "../../connection/registration";
import { getPublishedEventUrl } from "../../store/actions/admin/create-event";
import { setModeratorRegistration } from "../../store/actions/moderator/moderator";
import { useTypedSelector } from "../../store/reducers/use-typed-selector";
import { BLAdmin, BrandliveEvent, LanguagesAbbr, RegFieldsEnum } from "../../types/working-model";
import { getStorageItem, setStorageItem } from "../../utils/local-storage";
import { showAlertLong } from "../general-ui/alert/alert-service";
import { ParamsProps } from "../live-event/live-event";
import { getDefaultLanguage } from "../live-event/utils";
import db from '../../utils/datastore/indexed-db';

interface IProps {
	activeEvent?: BrandliveEvent | null;
	adminUser?: BLAdmin | null;
	waitForAdminUser?: boolean;
	propsToken?: string | null;
	propsLanguage?: LanguagesAbbr | null;
	propsRegistration?: IRegistrationResponse | null;
	useCache?: boolean;
}

const useCreateModeratorRegistration = ({
	activeEvent,
	adminUser,
	waitForAdminUser = false,
	propsToken,
	propsLanguage,
	propsRegistration,
	useCache = false,
}: IProps): IRegistrationResponse | null => {
	const [checking, setChecking] = useState(false);
	const [registration, setRegistration] = useState<IRegistrationResponse | null>(propsRegistration || null);

	const reduxToken = useTypedSelector(state => state.AuthReducer.token);
	const _user = useTypedSelector(state => state.AuthReducer.user);
	const publishedUrl = useTypedSelector(state => state.CreateEventReducer?.publishedUrl);
	const fetchingPublishedStatus = useTypedSelector(state => state.CreateEventReducer.fetchingPublishedStatus);

	const user = adminUser || _user;
	const token = propsToken || reduxToken;

	const eventUuid = activeEvent?.uuid;
	const channel = activeEvent?.channel;

	const dispatch = useDispatch();

	const { language: paramsLanguage } = useParams<ParamsProps>();
	const language = propsLanguage || paramsLanguage;

	useEffect(() => {
		if (publishedUrl === null && eventUuid && token) {
			dispatch(getPublishedEventUrl(token, eventUuid));
		}
	}, [publishedUrl, eventUuid, dispatch, token]);

	const addToIndexedDb = useCallback(async (registration: IRegistrationResponse, key: string) => {
		await db.putItem({
			uuid: key,
			type: 'MODERATOR_REGISTRATION',
			data: registration,
		}, { hours: 1 });
	}, []);

	useEffect(() => {
		if (propsRegistration) return;
		if (waitForAdminUser && !adminUser && !propsToken) return;

		const checkAndCreateReg = async () => {
			if (
				!user
				|| !activeEvent
				|| !activeEvent?.registration_on
				|| !token
				|| !eventUuid
				|| !channel
				|| fetchingPublishedStatus
				|| (!fetchingPublishedStatus && !publishedUrl)
				|| checking
			) {
				return;
			}

			setChecking(true);
			try {
				const dbKey = `${user.id}-${user.active_channel}-${activeEvent.uuid}`;
				if (useCache) {
					const cachedRegistration = await db.getItem<IRegistrationResponse>('MODERATOR_REGISTRATION', dbKey);
					if (cachedRegistration) {
						setRegistration(cachedRegistration);
						dispatch(setModeratorRegistration(cachedRegistration));
						return;
					}
				}

				const path = window.location.href.split("/");
				path.pop();
				const source = path.join("/");
				const moderatorRegistrationId = getStorageItem(`moderator_reg.${eventUuid}`);
				const moderatorBlToken = getStorageItem(`moderator_bl_token.${eventUuid}`);

				if (moderatorRegistrationId && moderatorBlToken) {
					// fetch user data
					// and set in redux
					const valid = await ValidateRegistration(eventUuid, moderatorRegistrationId, null, moderatorBlToken ?? undefined, true);
					dispatch(setModeratorRegistration(valid));
					setRegistration(valid);
					await addToIndexedDb(valid, dbKey);
				} else {
					// register the user as a moderator for the event
					// note: if this email is already registered, it will keep all fields but add is_moderator true
					// if the email is already logged into the event, they will have to log out and
					// log back in using the "already registered" link to get moderator privelages in the live event.
					const response = await Register(
						{
							fields: {
								[RegFieldsEnum.first_name]: user.profile.first_name,
								[RegFieldsEnum.last_name]: user.profile.last_name,
								[RegFieldsEnum.email]: user.email,
							},
							lang: language || getDefaultLanguage(activeEvent),
							source,
							timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
							isFinalStep: true,
							isFinalNonTicketStep: true,
							registered_language: language || getDefaultLanguage(activeEvent)
						},
						eventUuid,
						undefined,
						{
							'BL-TOKEN': token
						},
						true,
					);
					setRegistration(response);
					setStorageItem(`moderator_bl_token.${eventUuid}`, response.token);
					setStorageItem(`moderator_reg.${eventUuid}`, response.registrationId);
					dispatch(setModeratorRegistration(response));
					await addToIndexedDb(response, dbKey);

					let description = `You may signin to the live event by clicking "already registered" using your email: ${user.email}`;
					if (activeEvent?.registration_settings?.password_gating_enabled) {
						description = 'You may signin to the live event by clicking "Enter Event"';
					}
					showAlertLong({
						message: 'Moderator live event auto signin',
						description,
					});
				}
			} catch (e: any) {
				console.error('ERROR creating moderator registration');
				console.error(e?.response || e);
			}
		};

		checkAndCreateReg();

	}, [
		activeEvent,
		adminUser,
		waitForAdminUser,
		checking,
		token,
		eventUuid,
		user,
		channel,
		language,
		dispatch,
		fetchingPublishedStatus,
		publishedUrl,
		propsToken,
		propsRegistration,
		addToIndexedDb,
		useCache,
	]);

	return registration;
};

export default useCreateModeratorRegistration;
