import { handle } from 'redux-pack';
import { Action } from '../../../types/actions';
import { BrandliveEvent, BrandliveEventListItem, EventGroup, EventGroupAssociation, EventsTotals, LoadingStatus, SelectedBrandliveEvent } from '../../../types/working-model';
import {
	ADD_ADMIN_EVENT,
	CLEAR_EVENTS,
	GET_EVENTS,
	UPDATE_ADMIN_EVENT,
	UPDATE_EVENTS_NAME,
	REMOVE_SESSION_FROM_EVENT,
	REMOVE_EVENT_FROM_EVENTS,
	SET_EVENTS_LOADING,
	GET_EVENT_GROUPS,
	CREATE_EVENT_GROUP,
	ADD_EVENTS_TO_EVENT_GROUP,
	UPDATE_EVENT_GROUP,
	DELETE_EVENT_GROUP,
	REMOVE_EVENT_FROM_EVENT_GROUPS,
	SET_SELECTED_EVENT_GROUP,
	CLEAR_GROUP_ERROR,
	GET_EVENTS_LIST,
	UPDATE_SELECTED_EVENT_SETTINGS_IN_LIST,
	FETCH_EVENT_GROUP_TREE,
	MODIFY_EVENT_GROUPS_FOR_ADMIN
} from '../../actions/admin/events';
import { EventGroupTreeEntry } from 'types/access';

export interface AdminEventsState {
	events: SelectedBrandliveEvent[];
	eventsError: boolean;
	eventsLoading: boolean;
	eventsTotals: EventsTotals | null;
	eventsList: BrandliveEventListItem[];
	eventsListLoading: boolean;
	eventsListError: boolean;
	eventGroups: EventGroup[];
	loadingEventGroupsStatus: LoadingStatus;
	selectedEventGroup: string;
	groupError: string;
	eventGroupTree: EventGroupTreeEntry;
	eventGroupTreeNameMap: Record<string, string>;
	gettingGroupTree?: boolean;
}

const initialState: AdminEventsState = {
	eventGroups: [],
	events: [],
	eventsError: false,
	eventsList: [],
	eventsListError: false,
	eventsListLoading: false,
	eventsLoading: false,
	eventsTotals: null,
	groupError: '',
	loadingEventGroupsStatus: LoadingStatus.PreLoading,
	selectedEventGroup: '',
	eventGroupTree: { ungrouped: { events: {} } } as EventGroupTreeEntry,
	eventGroupTreeNameMap: {},
	gettingGroupTree: false
};

export default function EventsReducer(
	state: AdminEventsState = initialState,
	action: Action
): AdminEventsState {
	switch (action.type) {
		case GET_EVENTS: {
			return handle(state, action, {
				success: (state) => ({
					...state,
					events: action.payload.events,
					// payload is null when paginating, since the totals don't change in that case.
					eventsTotals: action.payload.totals ? action.payload.totals : state.eventsTotals,

					eventsError: false,
				}),
				start: (state) => ({ ...state, eventsLoading: true }),
				failure: (state) => ({ ...state, eventsError: true }),
				finish: (state) => ({ ...state, eventsLoading: false }),
			});
		}
		case GET_EVENTS_LIST: {
			return handle(state, action, {
				success: (state) => ({
					...state,
					eventsList: action.payload,
					eventsListError: false,
				}),
				start: (state) => ({ ...state, eventsListLoading: true }),
				failure: (state) => ({ ...state, eventsListError: true }),
				finish: (state) => ({ ...state, eventsListLoading: false }),
			});
		}
		case ADD_ADMIN_EVENT: {
			return {
				...state,
				events: [...state.events, action.payload]
			};
		}
		case UPDATE_ADMIN_EVENT: {
			const updatedEvent: SelectedBrandliveEvent = action.payload;
			return {
				...state,
				events: state.events.map((event) => {
					if (event.uuid === updatedEvent.uuid) { return updatedEvent; }
					return event;
				}),
			};
		}
		case UPDATE_EVENTS_NAME: {
			const updatedEvent: BrandliveEvent = action.payload;
			return {
				...state,
				events: state.events.map((event) => {
					if (event.event === updatedEvent.event) {
						return {
							...event,
							name: updatedEvent.name,
						};
					}
					return event;
				}),
			};
		}
		case CLEAR_EVENTS: {
			return {
				...state,
				events: [],
				eventsTotals: null,
			};
		}
		case REMOVE_SESSION_FROM_EVENT: {
			const matchedEvent = state.events.find(event => event.uuid === action.payload.eventUuid);
			if (!matchedEvent) return state;
			return {
				...state,
				events: state.events.map(event => {
					if (event.uuid !== action.payload.eventUuid) return event;
					return {
						...event,
						sessions: event.sessions.filter(session => session.session !== action.payload.sessionID),
					};
				}),
			};
		}
		case REMOVE_EVENT_FROM_EVENTS: {
			return {
				...state,
				events: state.events.filter(event => event.uuid !== action.payload),
			};
		}
		case SET_EVENTS_LOADING: {
			return {
				...state,
				eventsLoading: action.payload
			};
		}
		case GET_EVENT_GROUPS: {
			return handle(state, action, {
				start: state => ({
					...state,
					loadingEventGroupsStatus: LoadingStatus.Loading,
				}),
				success: state => ({
					...state,
					eventGroups: action.payload,
				}),
				finish: state => {
					return {
						...state,
						loadingEventGroupsStatus: LoadingStatus.Done,
					};
				}
			});
		}

		case CREATE_EVENT_GROUP: {
			return handle(state, action, {
				success: state => ({
					...state,
					groupError: '',
					eventGroups: [...state.eventGroups, action.payload],
				}),
				failure: state => {
					return {
						...state,
						groupError: action.payload,
					};
				},
			});
		}

		case ADD_EVENTS_TO_EVENT_GROUP: {
			return handle(state, action, {
				success: state => {
					const groupUuid = action.payload[0].group_uuid;
					const eventUuids = action.payload.map((ega: EventGroupAssociation) => ega.event_uuid);

					return ({
						...state,
						eventGroups: state.eventGroups.map(eg => eg.uuid === groupUuid
							? { ...eg, event_uuids: [...eg.event_uuids, ...eventUuids] }
							: eg
						)
					});
				}
			});
		}

		case UPDATE_EVENT_GROUP: {
			return handle(state, action, {
				success: state => ({
					...state,
					groupError: '',
					eventGroups: state.eventGroups.map(eg => eg.uuid === action.payload.uuid ? action.payload : eg)
				}),
				failure: state => {
					return {
						...state,
						groupError: action.payload,
					};
				},
			});
		}

		case CLEAR_GROUP_ERROR: {
			return {
				...state,
				groupError: ''
			};
		}

		case DELETE_EVENT_GROUP: {
			return handle(state, action, {
				success: state => {
					const undeletedEventGroups = state.eventGroups.filter(eg => eg.uuid !== action.payload.deletedGroupUuid);

					return {
						...state,
						eventGroups: undeletedEventGroups
					};
				}
			});
		}

		case REMOVE_EVENT_FROM_EVENT_GROUPS: {
			return {
				...state,
				eventGroups: state.eventGroups.map(eg => ({
					...eg,
					event_uuids: eg.event_uuids.filter(uuid => uuid !== action.payload)
				}))
			};
		}

		case SET_SELECTED_EVENT_GROUP: {
			return {
				...state,
				selectedEventGroup: action.payload
			};
		}

		case UPDATE_SELECTED_EVENT_SETTINGS_IN_LIST: {
			return {
				...state,
				eventsList: state.eventsList.map(event => {
					if (event.event === action.payload.event) {
						return {
							...event,
							settings: action.payload.settings,
						};
					}
					return event;
				}),
				events: state.events.map(event => {
					if (event.event === action.payload.event) {
						return {
							...event,
							settings: action.payload.settings,
						};
					}
					return event;
				}),
			};
		}

		case FETCH_EVENT_GROUP_TREE: {
			return handle(state, action, {
				start: state => {
					return {
						...state,
						gettingGroupTree: true,
					};
				},
				success: state => {
					const { tree, nameMap } = action.payload;
					return {
						...state,
						eventGroupTree: tree,
						eventGroupTreeNameMap: nameMap,
					};
				},
				failure: state => {
					return {
						...state,
						eventGroupTree: { ungrouped: { events: {} } } as EventGroupTreeEntry,
						eventGroupTreeNameMap: {},
					};
				},
				finish: state => {
					return {
						...state,
						gettingGroupTree: false,
					};
				}
			});
		}

		case MODIFY_EVENT_GROUPS_FOR_ADMIN: {
			return handle(state, action, {
				success: state => {
					const { added, deleted, adminId } = action.payload;
					return {
						...state,
						eventGroups: state.eventGroups.map(eg => {
							if (added.includes(eg.uuid)) {
								return {
									...eg,
									adminIds: [...eg.adminIds, adminId]
								};
							}
							if (deleted.includes(eg.uuid)) {
								return {
									...eg,
									adminIds: eg.adminIds.filter(admin => admin !== adminId)
								};
							}
							return eg;
						})
					};
				},
				failure: state => {
					return {
						...state,
						groupError: 'Error modifying event groups for admin'
					};
				}
			});
		}

		default:
			return state;
	}
}
