import { EMessageButtonType } from '../../../components/general-ui/message-card/message-card';
import {
	FetchModeratorChatMessages,
	FetchQuestionsAndQuestionComments,
	GetModeratorVideo,
	BanChatUser,
	UpdateModeratorProfile,
} from '../../../connection/moderator';
import { GetStream } from '../../../connection/ivs';
import { Action, AsyncAction } from '../../../types/actions';
import {
	EMessageTypes,
	IAnnouncement,
	IModeratorProfile,
	IQuestionSocket,
	ISocketCommentProps,
	IUserSurveyDB,
	LanguagesAbbr,
} from '../../../types/working-model';
import {
	deleteAnnouncement,
	getScheduledAnnouncement,
	updateAnnouncement,
	saveScheduledAnnouncement,
	GetUserSurveys,
	SaveAnnouncement_V2,
	UpdateAnnouncement_V2
} from '../../../connection/notifications';
import { GetHighestConcurrentLang } from '../../../connection/analytics';
import { GetChatPaginated } from '../../../connection/chat';
import { GetMuxStreamData } from 'connection/mux';

export const FETCH_MODERATOR_CHAT_MESSAGES = 'FETCH_MODERATOR_CHAT_MESSAGES';
export function fetchModeratorChatMessages({
	token,
	session_uuid,
	language,
	lastFetchId,
	limit = 0
}: {
	token: string;
	session_uuid: string;
	language: LanguagesAbbr;
	lastFetchId: number | null;
	limit?: number;
}): AsyncAction {
	return {
		type: FETCH_MODERATOR_CHAT_MESSAGES,
		promise: FetchModeratorChatMessages({
			token,
			session_uuid,
			language,
			lastFetchId,
			limit
		}),
	};
}

type FetchChatMessagesPageProps = {
	sessionUuid: string;
	lang: string | null;
	blProfile?: number;
	cursor?: number;
	size?: number;
	moderator: true;
	token?: string;
}

export const FETCH_MODERATOR_CHAT_MESSAGES_PAGE = 'FETCH_MODERATOR_CHAT_MESSAGES_PAGE';
export function fetchModeratorChatMessagesPage(props: FetchChatMessagesPageProps): AsyncAction {
	return {
		type: FETCH_MODERATOR_CHAT_MESSAGES_PAGE,
		promise: GetChatPaginated(props),
		meta: {
			chatKey: `${props.sessionUuid}-${props.lang ?? 'all'}}`
		}
	};
}

export const ADD_MODERATOR_CHAT_MESSAGE = 'ADD_MODERATOR_CHAT_MESSAGE';
export function addModeratorChatMessage(message: ISocketCommentProps): Action {
	return {
		type: ADD_MODERATOR_CHAT_MESSAGE,
		payload: message,
	};
}

export const UPDATE_MODERATOR_CHAT_MESSAGE = 'UPDATE_MODERATOR_CHAT_MESSAGE';
export function updateModeratorChatMessage(message: ISocketCommentProps, actionType?: EMessageButtonType): Action {
	return {
		type: UPDATE_MODERATOR_CHAT_MESSAGE,
		payload: { ...message, actionType },
	};
}

export const UNAPPROVE_MODERATOR_CHAT_MESSAGE = 'UNAPPROVE_MODERATOR_CHAT_MESSAGE';
export function unapproveModeratorChatMessage(id: number): Action {
	return {
		type: UNAPPROVE_MODERATOR_CHAT_MESSAGE,
		payload: id,
	};
}

export const DELETED_MODERATOR_CHAT_MESSAGE = 'DELETED_MODERATOR_CHAT_MESSAGE';
export function deletedModeratorChatMessage(id: number, deleted_at: Date): Action {
	return {
		type: DELETED_MODERATOR_CHAT_MESSAGE,
		payload: { id, deleted_at },
	};
}

export const TOGGLE_SAVE_MODERATOR_CHAT_MESSAGE = 'TOGGLE_SAVE_MODERATOR_CHAT_MESSAGE';
export function toggleSaveModeratorChatMessage(id: number, saved: boolean): Action {
	return {
		type: TOGGLE_SAVE_MODERATOR_CHAT_MESSAGE,
		payload: { id, saved },
	};
}

export const ADD_MODERATOR_QUESTION = 'ADD_MODERATOR_QUESTION';
export function addModeratorQuestion(msg: IQuestionSocket): Action {
	return {
		type: ADD_MODERATOR_QUESTION,
		payload: msg,
	};
}

export const ADD_MODERATOR_QUESTION_COMMENT = 'ADD_MODERATOR_QUESTION_COMMENT';
export function addModeratorQuestionComment(msg: IQuestionSocket): Action {
	return {
		type: ADD_MODERATOR_QUESTION_COMMENT,
		payload: msg,
	};
}

export const ADD_MODERATOR_QUESTION_LIKE = 'ADD_MODERATOR_QUESTION_LIKE';
export function addModeratorQuestionLike(msg: IQuestionSocket): Action {
	return {
		type: ADD_MODERATOR_QUESTION_LIKE,
		payload: msg,
	};
}

export const ADD_MODERATOR_QUESTION_UNLIKE = 'ADD_MODERATOR_QUESTION_UNLIKE';
export function addModeratorQuestionUnlike(msg: IQuestionSocket): Action {
	return {
		type: ADD_MODERATOR_QUESTION_UNLIKE,
		payload: msg,
	};
}

export const FETCH_MODERATOR_QUESTIONS_AND_COMMENTS = 'FETCH_MODERATOR_QUESTIONS_AND_COMMENTS';
export function fetchQuestionsAndQuestionComments(token: string, language: LanguagesAbbr, session_uuid: string): AsyncAction {
	return {
		type: FETCH_MODERATOR_QUESTIONS_AND_COMMENTS,
		promise: FetchQuestionsAndQuestionComments({ token, language, session_uuid }),
	};
}

export const UPDATE_MODERATOR_QUESTION = 'UPDATE_MODERATOR_QUESTION';
export function updateModeratorQuestion(message: IQuestionSocket, actionType?: EMessageButtonType): Action {
	return {
		type: UPDATE_MODERATOR_QUESTION,
		payload: { ...message, actionType },
	};
}

export const GET_MODERATOR_VIDEO = "GET_MODERATOR_VIDEO";
export function getModeratorVideo(token: string, sessionUuid: string, language: string): AsyncAction {
	return {
		type: GET_MODERATOR_VIDEO,
		promise: GetModeratorVideo(token, sessionUuid, language)
	};
}

export const UPDATE_MODERATOR_VIDEO = "UPDATE_MODERATOR_VIDEO";
export function updateModeratorVideo(playbackUrl: string): Action {
	return {
		type: UPDATE_MODERATOR_VIDEO,
		payload: playbackUrl
	};
}

export const CLEAR_MODERATOR_STATE = "CLEAR_MODERATOR_STATE";
export function clearModeratorState(clearProfile = true): Action {
	return {
		type: CLEAR_MODERATOR_STATE,
		payload: clearProfile,
	};
}

export const MODERATOR_DELETE_QUESTION = "MODERATOR_DELETE_QUESTION";
export function moderatorDeleteQuestion(id: number, question_id?: number): Action {
	return {
		type: MODERATOR_DELETE_QUESTION,
		payload: { id, question_id },
	};
}

export const SENT_TO_PRESENTER = 'SENT_TO_PRESENTER';
export function sentToPresenter(id: number, messageType: EMessageTypes, isSent: boolean): Action {
	return {
		type: SENT_TO_PRESENTER,
		payload: { id, messageType, isSent },
	};
}

export const MOD_GET_LIVE_VIDEO_STREAM = 'MOD_GET_LIVE_VIDEO_STREAM';
export function getLiveVideoStream(token: string, sessionUuid: string, language: string, mux?: boolean): AsyncAction {
	return {
		type: MOD_GET_LIVE_VIDEO_STREAM,
		promise: mux ? GetMuxStreamData(token, sessionUuid, language) : GetStream(token, sessionUuid, language)
	};
}

export const SET_MODERATOR_REGISTRATION = 'SET_MODERATOR_REGISTRATION';
export function setModeratorRegistration(data: Record<string, any> | null): Action {
	return {
		type: SET_MODERATOR_REGISTRATION,
		payload: data,
	};
}

// this is for session chat NOT event chat
export const BAN_CHAT_USER = 'BAN_CHAT_USER';
export function banChatUser(token: string, sessionUUID: string, blProfile: number | null, blProfileNonReg: number | null, moderator: number): AsyncAction {
	return {
		type: BAN_CHAT_USER,
		promise: BanChatUser(token, sessionUUID, blProfile, blProfileNonReg, moderator)
	};
}

export const MODERATOR_HIGHEST_CONCURRENT = "MODERATOR_HIGHEST_CONCURRENT";
export function getHighestConcurrent(uuid: string, lang: string, token: string): AsyncAction {
	return {
		type: MODERATOR_HIGHEST_CONCURRENT,
		promise: GetHighestConcurrentLang(uuid, lang, token)
	};
}

export const ADD_SCHEDULED_ANNOUNCEMENT_V2 = 'ADD_SCHEDULED_ANNOUNCEMENT';
export function saveAnnouncementV2(token: string, channel: number, announcement: IAnnouncement, defaultLanguage: string): AsyncAction {
	return {
		type: ADD_SCHEDULED_ANNOUNCEMENT,
		promise: SaveAnnouncement_V2(announcement, defaultLanguage, token, channel)
	};
}

export const UPDATE_SCHEDULED_ANNOUNCEMENT_V2 = 'UPDATE_SCHEDULED_ANNOUNCEMENT';
export function updateAnnouncementV2(token: string, channel: number, announcement: IAnnouncement, defaultLanguage: string, previouslyScheduledSwitchedToImmediate: boolean): AsyncAction {
	return {
		type: UPDATE_SCHEDULED_ANNOUNCEMENT,
		promise: UpdateAnnouncement_V2(announcement, defaultLanguage, token, channel, false, false, previouslyScheduledSwitchedToImmediate)
	};
}

export const DELETE_ANNOUNCEMENT_V2 = 'DELETE_SCHEDULED_ANNOUNCEMENT';
export function deleteAnnouncementV2(token: string, channel: number, announcement: IAnnouncement): AsyncAction {
	return {
		type: DELETE_ANNOUNCEMENT_V2,
		promise: UpdateAnnouncement_V2(announcement, '', token, channel, true, false)
	};
}

export const CANCEL_ANNOUNCEMENT_V2 = 'UPDATE_SCHEDULED_ANNOUNCEMENT';
export function cancelAnnouncementV2(token: string, channel: number, announcement: IAnnouncement): AsyncAction {
	return {
		type: CANCEL_ANNOUNCEMENT_V2,
		promise: UpdateAnnouncement_V2(announcement, '', token, channel, false, true)
	};
}

export const ADD_SCHEDULED_ANNOUNCEMENT = 'ADD_SCHEDULED_ANNOUNCEMENT';
export function addScheduledAnnouncement(token: string, announcement: IAnnouncement): AsyncAction {
	return {
		type: ADD_SCHEDULED_ANNOUNCEMENT,
		promise: saveScheduledAnnouncement(token, announcement)
	};
}

export const GET_SCHEDULED_ANNOUNCEMENTS = 'GET_SCHEDULED_ANNOUNCEMENTS';
export function getScheduledAnnouncements(token: string, event_uuid: string): AsyncAction {
	return {
		type: GET_SCHEDULED_ANNOUNCEMENTS,
		promise: getScheduledAnnouncement(token, event_uuid)
	};
}

export const UPDATE_SCHEDULED_ANNOUNCEMENT = 'UPDATE_SCHEDULED_ANNOUNCEMENT';
export function updateScheduledAnnouncement(token: string, announcement: IAnnouncement): AsyncAction {
	return {
		type: UPDATE_SCHEDULED_ANNOUNCEMENT,
		promise: updateAnnouncement(token, announcement)
	};
}

export const DELETE_SCHEDULED_ANNOUNCEMENT = 'DELETE_SCHEDULED_ANNOUNCEMENT';
export function deleteScheduledAnnouncement(token: string, announcement: IAnnouncement): AsyncAction {
	return {
		type: DELETE_SCHEDULED_ANNOUNCEMENT,
		promise: deleteAnnouncement(token, announcement)
	};
}

export const SEND_USER_SURVEY = 'SEND_USER_SURVEY';
export function sendUserSurvey(survey: IUserSurveyDB): Action {
	return {
		type: SEND_USER_SURVEY,
		payload: survey
	};
}

export const GET_USER_SURVEYS = 'GET_USER_SURVEYS';
export function getUserSurveys(token: string, sessionID: number): AsyncAction {
	return {
		type: GET_USER_SURVEYS,
		promise: GetUserSurveys(token, sessionID)
	};
}

export const UPDATE_USER_SURVEY = 'UPDATE_USER_SURVEY';
export function updateUserSurvey(survey: IUserSurveyDB): Action {
	return {
		type: UPDATE_USER_SURVEY,
		payload: survey
	};
}

export const DELETE_USER_SURVEY = 'DELETE_USER_SURVEY';
export function deleteUserSurvey(surveyUUID: string): Action {
	return {
		type: DELETE_USER_SURVEY,
		payload: surveyUUID
	};
}

export const UPDATE_MODERATOR_PROFILE = 'UPDATE_MODERATOR_PROFILE';
export const updateModeratorProfile = (
	moderatorProfile: IModeratorProfile,
	profileId: number,
	channel: number,
	blProfileUserToken: string,
	adminToken: string,
	eventUUID: string,
): AsyncAction => ({
	type: UPDATE_MODERATOR_PROFILE,
	promise: UpdateModeratorProfile(moderatorProfile, profileId, channel, blProfileUserToken, adminToken, eventUUID),
});

export const CLEAR_HAS_NEW_MESSAGE = 'CLEAR_HAS_NEW_MESSAGE';
export const clearHasNewMessage = (): Action => ({
	type: CLEAR_HAS_NEW_MESSAGE,
	payload: null,
});

// Prevents questions from auto scrolling while the moderator is typing a reply
export const SET_CURRENTLY_REPLYING = 'SET_CURRENTLY_REPLYING';
export const setCurrentlyReplying = (replying: boolean): Action => ({
	type: SET_CURRENTLY_REPLYING,
	payload: replying
});