import { handle } from "redux-pack";

import { UpdateSessionInfo } from "../../../../connection/create-event";
import {
	CreateVideo,
	DBSession,
	HomepageVideo,
	IBreakoutSession,
	PageModule,
	PageModuleType,
	SecondarySessionVideo,
	Session,
	SessionPanelLayoutsTypes,
	SessionTabNames
} from "../../../../types/working-model";
import { isSavingEvent } from "../../../actions/admin/create-event";
import {
	CreateSessionActions,
	ADD_SESSION_PAGE_MODULE,
	CLEAR_WORKING_SESSION,
	GET_SESSIONS_FOR_EVENT,
	LOAD_WORKING_SESSION,
	SET_SESSION_ACTIVE_MODULE,
	SET_SESSION_LAYOUT,
	SET_SESSION_MODULES,
	SET_SESSION_USE_NAV,
	TOGGLE_PAGE_MODULE,
	UPDATE_PAGE_MODULE,
	UPDATE_PAGE_MODULE_AND_SAVE,
	SET_SESSION_ACTIVE_MODULE_BY_ID,
	UPDATE_WORKING_SESSION,
	UPDATING_SESSION,
	UPDATE_SESSION,
	UPDATE_MODULE_GROUPINGS,
	SET_ACTIVE_SESSION_GROUP_MODULE,
	SET_ON_DEMAND_VIDEO,
	SET_SESSION_BROADCAST_TYPE,
	SET_VIDEO_CUSTOMIZATIONS,
	UPDATE_MODERATION_SETTINGS,
	UPDATE_SHOULD_RECORD,
	SET_SESSION_CSS,
	REMOVE_SESSION_FROM_CREATE_SESSION,
	UPDATE_SESSION_STYLING,
	UPDATE_SESSIONS_LIST_FOR_EVENT,
	UPDATE_USE_BREAKOUT_IMAGE,
	TOGGLE_REPLAY_ON,
	TOGGLE_AUTO_SEND_CALENDAR_INVITE_ON,
	TOGGLE_SESSION_FEEDBACK_SURVEY,
	TOGGLE_REACTIONS,
	UPDATE_SECONDARY_SESSION_VIDEO,
	GET_SECONDARY_SESSION_VIDEO,
	DELETE_SECONDARY_SESSION_VIDEO,
	TOGGLE_DISPLAY_VIEW_COUNT,
	UPDATE_BREAKOUT_BANNER_IMAGE,
	UPDATE_BREAKOUT_BACKGROUND_IMAGE,
	TOGGLE_DISPLAY_SESSION_CHAT,
	SET_SESSION_CHAT_STYLE,
	SET_SESSION_CHAT_DARK_MODE,
	RESET_WORKING_SESSION_CHAT_DARK_MODE,
	SET_SESSION_INTERNAL_DESCRIPTOR,
	SET_CURRENT_LANGUAGE,
	SET_SESSION_LOADING,
	updateWorkingSession,
	SET_SESSION_COMPILED_CSS,
	SET_SESSION_USE_SAFE_CSS,
	UPDATE_SESSION_CALENDAR_SETTINGS,
	SET_SESSION_PANEL_LAYOUT_TYPE,
	SHOW_EDIT_SESSION_DETAILS_MODAL,
	SAVE_WORKING_SESSION,
	TOGGLE_SESSION_SHARE,
	UPDATE_SESSION_CUSTOM_SOCIAL_LINK,
	UPDATE_CHAT_CONTENT_SETTINGS,
	TOGGLE_COURSES_ENABLED
} from "../../../actions/admin/create-event/session";
import { homepageVideoToCreateVideo } from "../../../utils/event-reducer-utils";

export interface CreateSessionState {
	workingSession: Session | null;
	useNavigation: boolean;
	selectedPage: PageModuleType;
	selectedPageId: number | null;
	sessionsList: DBSession[];
	isUpdating: boolean;
	selectedPreviewGroupUuid: string | null;
	secondarySessionVideo: SecondarySessionVideo | null;
	sessionLoading: boolean;
	openSessionDetailsEditModal: boolean;
	sessionPanelLayoutType: SessionPanelLayoutsTypes | SessionTabNames | null;
	originalWorkingSessionChatDarkMode?: boolean;
}

const initialState: CreateSessionState = {
	isUpdating: false,
	secondarySessionVideo: null,
	selectedPage: PageModuleType.description,
	selectedPageId: null,
	selectedPreviewGroupUuid: null,
	sessionLoading: false,
	openSessionDetailsEditModal: false,
	sessionPanelLayoutType: null,
	sessionsList: [],
	useNavigation: true,
	workingSession: null,
	originalWorkingSessionChatDarkMode: undefined,
};

function getState(
	state: CreateSessionState = initialState,
	action: CreateSessionActions
): CreateSessionState {
	switch (action.type) {
		// just immedately return the state, we're using this
		// for the side effect (saving the session and yes I know)
		case SAVE_WORKING_SESSION: {
			return state;
		}
		case LOAD_WORKING_SESSION: {
			if (!action.payload) return state;

			// reconcile incorrect type from API side
			// @ts-expect-error will require a decent refactor just to "fix" this one line
			action.payload.breakout_session = action.payload.breakout_session ?? action.payload.breakout_sessions?.[0];
			return { ...state, workingSession: action.payload };
		}
		case CLEAR_WORKING_SESSION: {
			// clear everything back to the initial state
			return { ...initialState };
		}
		case TOGGLE_PAGE_MODULE: {
			if (!state.workingSession) { return state; }

			const _workingSession = { ...state.workingSession };
			_workingSession.modules = _workingSession.modules.map(
				(module: PageModule) => {
					if (module.id === action.payload.id) {
						module.is_on = !module.is_on;
					}

					return module;
				}
			);

			return { ...state, workingSession: _workingSession };
		}
		case UPDATE_PAGE_MODULE_AND_SAVE:
		case UPDATE_PAGE_MODULE: {
			if (!state.workingSession) { return state; }

			const _workingSession = { ...state.workingSession };
			_workingSession.modules = _workingSession.modules.map(
				(module: PageModule) => {
					if (module.id === action.payload.id) {
						return action.payload;
					}

					return module;
				}
			);

			return { ...state, workingSession: _workingSession };
		}
		case SHOW_EDIT_SESSION_DETAILS_MODAL: {
			return {
				...state,
				openSessionDetailsEditModal: action.payload
			};
		}
		case SET_SESSION_LAYOUT: {
			if (!state.workingSession) { return state; }

			const _workingSession = { ...state.workingSession };
			_workingSession.layout = action.payload;
			return { ...state, workingSession: _workingSession };
		}
		case SET_SESSION_MODULES: {
			if (!state.workingSession) { return state; }

			const _workingSession = { ...state.workingSession };
			_workingSession.modules = action.payload;

			return { ...state, workingSession: _workingSession };
		}
		case ADD_SESSION_PAGE_MODULE: {
			if (!state.workingSession) { return state; }
			return {
				...state,
				workingSession: {
					...state.workingSession,
					modules: [...state.workingSession.modules, action.payload]
				}
			};
		}
		case SET_SESSION_ACTIVE_MODULE: {
			return { ...state, selectedPage: action.payload };
		}
		case SET_SESSION_ACTIVE_MODULE_BY_ID: {
			const _workingSession = { ...state.workingSession };
			let moduleId: number | null = action.payload || null;
			if (!moduleId && _workingSession?.modules?.length) {
				moduleId = _workingSession.modules[0].id || null;
			}
			if (!moduleId && moduleId !== 0) return state; // do a check for zero since ids are numbers and zero is falsy
			const module = _workingSession?.modules?.find(module => module?.id === moduleId);
			return {
				...state,
				selectedPageId: module?.id || null,
				selectedPage: (module as unknown) as PageModuleType
			};
		}
		case SET_ACTIVE_SESSION_GROUP_MODULE: {
			return {
				...state,
				selectedPreviewGroupUuid: action.payload,
			};
		}
		case SET_SESSION_USE_NAV: {
			return { ...state, useNavigation: action.payload };
		}
		case GET_SESSIONS_FOR_EVENT: {
			return handle(state, action, {
				success: state => ({ ...state, sessionsList: action.payload || [] })
			});
		}
		case UPDATE_SESSIONS_LIST_FOR_EVENT: {
			return {
				...state,
				sessionsList: state.sessionsList.map(session => {
					if (session.session === action.payload.session) {
						return action.payload;
					}
					return session;
				}),
			};
		}
		case UPDATE_WORKING_SESSION: {
			return {
				...state,
				workingSession: action.payload,
			};
		}
		case UPDATING_SESSION: {
			return {
				...state,
				isUpdating: action.payload,
			};
		}
		case UPDATE_SESSION: {
			return handle(state, action, {
				finish: (state) => ({ ...state, isUpdating: false })
			});
		}
		case UPDATE_MODULE_GROUPINGS: {
			if (!state.workingSession) return state;
			return {
				...state,
				workingSession: {
					...state.workingSession,
					module_grouping: action.payload,
				}
			};
		}
		case UPDATE_SESSION_STYLING: {
			if (!state.workingSession) return state;
			return {
				...state,
				workingSession: {
					...state.workingSession,
					layout: {
						...state.workingSession.layout,
						styling_overrides: action.payload
					}
				}
			};
		}
		case SET_ON_DEMAND_VIDEO: {
			if (!state.workingSession) return state;
			let newOnDemandVideo: CreateVideo[];
			const incomingVideo = homepageVideoToCreateVideo(action.payload as (CreateVideo | HomepageVideo));
			if (!state.workingSession.streaming_options.single_stream && state.workingSession.languages.length > 1) {
				let otherVideos = state.workingSession.on_demand_video?.filter(video => video.session_language !== incomingVideo.session_language);
				otherVideos?.push(incomingVideo);

				if (otherVideos === undefined) {
					otherVideos = [incomingVideo];
				}

				newOnDemandVideo = otherVideos;
			} else {
				newOnDemandVideo = [incomingVideo];
			}

			return {
				...state,
				workingSession: {
					...state.workingSession,
					on_demand_video: newOnDemandVideo
				}
			};
		}
		case SET_SESSION_BROADCAST_TYPE: {
			if (!state.workingSession) return state;
			return {
				...state,
				workingSession: {
					...state.workingSession,
					broadcast_type: action.payload
				}
			};
		}
		case SET_VIDEO_CUSTOMIZATIONS: {
			if (!state.workingSession) return state;

			//if video is set, we're just going to override the customizations, otherwise make an empty object
			const video = state.workingSession.video ?? {};

			//set the customizations to what's passed
			video.customizations = action.payload;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					video: video
				}
			};
		}
		case UPDATE_MODERATION_SETTINGS: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					moderation: action.payload
				}
			};
		}
		case UPDATE_SHOULD_RECORD: {
			// This is for fireside recording
			if (!state.workingSession) return state;
			return {
				...state,
				workingSession: {
					...state.workingSession,
					should_record: action.payload
				}
			};
		}
		case UPDATE_CHAT_CONTENT_SETTINGS: {
			if (!state.workingSession) return state;
			return {
				...state,
				workingSession: {
					...state.workingSession,
					chat_content_settings: action.payload
				}
			};
		}
		case TOGGLE_DISPLAY_VIEW_COUNT: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					layout: {
						...state.workingSession.layout,
						display_viewer_count: action.payload
					}
				}
			};
		}
		case SET_SESSION_CSS: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					custom_css: action.payload
				}
			};
		}
		case SET_SESSION_COMPILED_CSS: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					custom_css_compiled: action.payload || null
				}
			};
		}
		case SET_SESSION_USE_SAFE_CSS: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					use_safe_css: action.payload
				}
			};
		}
		case REMOVE_SESSION_FROM_CREATE_SESSION: {
			if (!state?.workingSession?.session || (state.workingSession.session !== action.payload.sessionID)) {
				return state;
			}

			return {
				...state,
				workingSession: null,
			};
		}
		case UPDATE_BREAKOUT_BANNER_IMAGE:
		case UPDATE_USE_BREAKOUT_IMAGE:
		case UPDATE_BREAKOUT_BACKGROUND_IMAGE: {
			// These 3 actions all return the full session that was updated. Update the session list
			// and working session.
			let updatedBreakoutSession: IBreakoutSession | undefined;
			if (action?.payload?.session === state?.workingSession?.session) {
				updatedBreakoutSession = action.payload;
			}
			return handle(state, action, {
				success: state => ({
					...state,
					sessionsList: state.sessionsList.map(session => {
						if (session.session === action?.payload?.session) {
							return {
								...session,
								breakout_sessions: session?.breakout_sessions?.map?.(bs => {
									if (bs.session === action.payload?.session) {
										return action.payload;
									}
									return bs;
								}),
							};
						}
						return session;
					}),
					workingSession: state?.workingSession ? {
						...state.workingSession,
						breakout_session: updatedBreakoutSession || state?.workingSession?.breakout_session
					} : null,
				})
			});
		}
		case TOGGLE_REPLAY_ON: {
			if (!state.workingSession) return state;
			return {
				...state,
				workingSession: {
					...state.workingSession,
					replay_on: action.payload
				}
			};
		}
		case TOGGLE_AUTO_SEND_CALENDAR_INVITE_ON: {
			if (!state.workingSession) return state;
			return {
				...state,
				workingSession: {
					...state.workingSession,
					calendar_settings: state.workingSession.calendar_settings ? {
						...state.workingSession.calendar_settings,
						auto_send_calendar_invite: action.payload
					} : {
						auto_send_calendar_invite: action.payload
					}
				}
			};
		}
		case TOGGLE_SESSION_FEEDBACK_SURVEY: {
			if (!state.workingSession) return state;
			return {
				...state,
				workingSession: {
					...state.workingSession,
					enable_feedback_survey: action.payload
				}
			};
		}
		case TOGGLE_SESSION_SHARE: {
			if (!state.workingSession) return state;
			return {
				...state,
				workingSession: {
					...state.workingSession,
					enable_share: action.payload
				}
			};
		}
		case TOGGLE_REACTIONS: {
			if (!state.workingSession) return state;
			return {
				...state,
				workingSession: {
					...state.workingSession,
					reaction_settings: {
						...state.workingSession.reaction_settings ?? { enabled: action.payload, reactions: [] },
						enabled: action.payload
					}
				}
			};
		}
		case UPDATE_SECONDARY_SESSION_VIDEO: {
			return handle(state, action, {
				success: state => ({
					...state,
					secondarySessionVideo: action.payload || null
				})
			});
		}
		case GET_SECONDARY_SESSION_VIDEO: {
			return handle(state, action, {
				success: state => {
					if (action.payload && 'response' in action.payload) {
						return {
							...state,
							secondarySessionVideo: null
						};
					}
					return {
						...state,
						secondarySessionVideo: action.payload || null
					};
				}
			});
		}
		case DELETE_SECONDARY_SESSION_VIDEO: {
			return handle(state, action, {
				success: state => {
					return ({
						...state,
						secondarySessionVideo: null
					});
				}
			});
		}
		case TOGGLE_DISPLAY_SESSION_CHAT: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					session_chat_enabled: action.payload
				}
			};
		}
		// this triggers a save, TEMP_SET_WORKING_SESSION_CHAT_DARK_MODE does not
		case SET_SESSION_CHAT_DARK_MODE: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					session_chat_dark_mode_enabled: action.payload
				},
			};
		}
		case RESET_WORKING_SESSION_CHAT_DARK_MODE: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					session_chat_dark_mode_enabled: state.originalWorkingSessionChatDarkMode ?? state.workingSession.session_chat_dark_mode_enabled
				},
				originalWorkingSessionChatDarkMode: undefined
			};
		}
		case SET_SESSION_INTERNAL_DESCRIPTOR: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					internal_descriptor: action.payload
				}
			};
		}
		case SET_CURRENT_LANGUAGE: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					current_language: action.payload
				}
			};
		}
		case SET_SESSION_LOADING: {
			return {
				...state,
				sessionLoading: action.payload
			};
		}
		case SET_SESSION_PANEL_LAYOUT_TYPE: {
			return {
				...state,
				sessionPanelLayoutType: action.payload
			};
		}
		case UPDATE_SESSION_CALENDAR_SETTINGS: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					calendar_settings: action.payload
				}
			};
		}
		case SET_SESSION_CHAT_STYLE: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					session_chat_style: action.payload
				}
			};
		}

		case UPDATE_SESSION_CUSTOM_SOCIAL_LINK: {
			if (!state.workingSession) return state;
			const { linkType, link } = action.payload;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					layout: {
						...state.workingSession.layout,
						custom_social_links: {
							...state.workingSession.layout.custom_social_links,
							[linkType]: link
						}
					}
				}
			};
		}

		case TOGGLE_COURSES_ENABLED: {
			if (!state.workingSession) return state;

			return {
				...state,
				workingSession: {
					...state.workingSession,
					courses_settings: {
						...(state.workingSession.courses_settings || {}),
						enabled: action.payload
					}
				}
			};
		}

		default:
			return state;
	}
}

const SAVING_ACTION = [
	TOGGLE_PAGE_MODULE,
	UPDATE_PAGE_MODULE,
	SET_SESSION_LAYOUT,
	SET_ON_DEMAND_VIDEO,
	SET_SESSION_LAYOUT,
	SET_SESSION_BROADCAST_TYPE,
	SET_VIDEO_CUSTOMIZATIONS,
	UPDATE_MODERATION_SETTINGS,
	UPDATE_SHOULD_RECORD,
	TOGGLE_DISPLAY_VIEW_COUNT,
	SET_SESSION_CSS,
	UPDATE_SESSION_STYLING,
	TOGGLE_REPLAY_ON,
	TOGGLE_AUTO_SEND_CALENDAR_INVITE_ON,
	TOGGLE_SESSION_FEEDBACK_SURVEY,
	TOGGLE_SESSION_SHARE,
	TOGGLE_REACTIONS,
	TOGGLE_DISPLAY_SESSION_CHAT,
	SET_SESSION_CHAT_STYLE,
	SET_SESSION_CHAT_DARK_MODE,
	SET_SESSION_INTERNAL_DESCRIPTOR,
	SET_CURRENT_LANGUAGE,
	SET_SESSION_LOADING,
	UPDATE_SESSION_CALENDAR_SETTINGS,
	UPDATE_PAGE_MODULE_AND_SAVE,
	ADD_SESSION_PAGE_MODULE,
	UPDATE_MODULE_GROUPINGS,
	SAVE_WORKING_SESSION,
	UPDATE_SESSION_CUSTOM_SOCIAL_LINK,
	UPDATE_CHAT_CONTENT_SETTINGS,
	TOGGLE_COURSES_ENABLED
];

let timeout: NodeJS.Timeout;

export default function CreateSessionReducer(
	state: CreateSessionState = initialState,
	action: CreateSessionActions
): CreateSessionState {
	const newState = getState(state, action);

	if (SAVING_ACTION.includes(action.type)) {
		// clearTimeout must go inside the if statement, otherwise it can be accidentally cancelled
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const store = require("../../../main");
			if (store.default) {
				const {
					CreateSessionReducer: { workingSession },
					AuthReducer: { token }
				} = store.default.getState();

				if (workingSession) {
					store.default.dispatch(isSavingEvent(true));
					UpdateSessionInfo(workingSession, token).then((d: Session) => {
						// Fireside needs to save the newest opentok_id session
						if (action.type === UPDATE_SHOULD_RECORD) {
							store.default.dispatch(updateWorkingSession(d));
						}

						store.default.dispatch(isSavingEvent(false));
					});
				}
			}
		}, 1000);
	}

	return newState;
}
