import { AnimatePresence, m, LazyMotion, domAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Action, AnyAction, Dispatch } from "redux";
import { useHistory, useParams } from "react-router-dom";

import { useAppDispatch, useTypedSelector } from "../../../store/reducers/use-typed-selector";
import { ChannelFeaturesEnum, LanguagesAbbr, NavProfileItems } from "../../../types/working-model";
import { PATHNAMES } from "../../../utils/admin-routing-utils";
import { ParamsProps } from "../../live-event/live-event";
import { eventPath } from "../../live-event/utils";
import { useTranslate } from "../../../i18n/useTranslationModules";
import { COOKIES_ACCEPTED_INDEX_DB } from "../../../types/gdpr";
import db from "../../../utils/datastore/indexed-db";
import { IRegistrationGDPR } from "../../../connection/registration";
import useCheckIfCookieAccepted from "./use-check-if-cookie-accepted";
import { OptionalComponent } from "../../../utils/optional-component";
import useTrackHeight from "../../../utils/use-track-height";
import { forceRecheckCookieStatus, confirmGDPRCookies } from "../../../store/actions/event/event-actions";

import './cookie-banner.scss';

export const getCookieIndexDB = async (eventUuid: string) => {
	try {
		return db.getItem<IRegistrationGDPR>(COOKIES_ACCEPTED_INDEX_DB, eventUuid);
	} catch (e) {
		console.error(e);
	}
};

export const setCookieIndexDB = async (eventUuid: string, data: IRegistrationGDPR) => {
	try {
		await db.putItem({
			type: COOKIES_ACCEPTED_INDEX_DB,
			uuid: eventUuid,
			data,
		});
	} catch (e) {
		console.error(e);
	}
};

export const handleAcceptCookies = async (
	accepted: boolean,
	isEditor: boolean | undefined,
	eventUuid: string | undefined,
	dispatch: Dispatch<AnyAction | Action>,
	setClickedAccept: React.Dispatch<React.SetStateAction<boolean>> | null,
	userRegUUID: string | null,
	blProfileUserToken: string | undefined
) => {
	if (isEditor || !eventUuid) return; // do nothing in admin editor

	try {
		// Banner isn't shown on the profile page (and in the profile page the user can change their cookie consent)
		setClickedAccept && setClickedAccept(accepted);

		const data: IRegistrationGDPR = { cookies_accepted: accepted, cookies_accepted_at: Date.now() };

		await db.putItem({
			type: COOKIES_ACCEPTED_INDEX_DB,
			uuid: eventUuid,
			data
		});

		dispatch(forceRecheckCookieStatus());

		if (userRegUUID && blProfileUserToken) {
			// update db registration gdpr
			dispatch(confirmGDPRCookies(blProfileUserToken, userRegUUID, data));
		}
	} catch (e) {
		console.error(e);
	}
};

interface ICookieBannerProps {
	forceShowBanner?: boolean; /** used for showing in admin */
	isEditor?: boolean;
	containerHeight?: (height: number) => void;
	forceHeightRecalcutation?: number;
	language?: LanguagesAbbr;
	eventName?: string;
	forceHideBanner?: boolean;
}

const CookieBanner = ({
	forceShowBanner,
	forceHideBanner = false,
	isEditor,
	containerHeight,
	forceHeightRecalcutation,
	language,
	eventName,
}: ICookieBannerProps): JSX.Element | null => {
	const { t } = useTranslate("gdpr");

	const dispatch = useAppDispatch();

	const cookieBannerRef = useRef<HTMLDivElement | null>(null);

	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const userRegUUID = useTypedSelector(state => state.LiveEventReducer.registrationId);
	const blProfileUserToken = useTypedSelector(state => state.LiveEventReducer.blProfileUserToken);
	const channelFeatures = useTypedSelector(state => state.AuthReducer?.channels?.find(c => c.channel === state.AuthReducer?.user?.active_channel)?.enabled_features);

	const [clickedAccept, setClickedAccept] = useState(false);

	const event = workingEvent || eventBundle;

	const eventUuid = event?.uuid;

	const cookieBannerEnabled = event?.settings?.gdpr?.enable_cookie_banner;

	const { userRegGDPR, checkingUserCookieStatus } = useCheckIfCookieAccepted({ isEditor });

	const history = useHistory();

	const { language: paramsLanguage, eventName: paramsEventName, uuid: eventParamUuid } = useParams() as ParamsProps;

	const cookieBannerHeight = useTrackHeight({ ref: cookieBannerRef.current, forceRerender: forceHeightRecalcutation });
	useEffect(() => {
		containerHeight?.(cookieBannerHeight.height);
	}, [containerHeight, cookieBannerHeight]);

	const handleLearnMore = () => {
		const _language = language || paramsLanguage;
		const _eventName = eventName || paramsEventName;
		if (isEditor && eventUuid) {
			history.push(PATHNAMES.Event[NavProfileItems.PrivacyPolicy](eventUuid, _language));
		} else {
			const policyPath = `/${eventPath(_eventName, eventParamUuid)}/${_language}/privacy-policy`;
			window.open(policyPath, '_blank');
		}
	};

	// Doesn't show the cookie banner if IndexDB has some GDPR data for them already.
	useEffect(() => {
		if (isEditor) return;

		async function getData() {
			if (!eventUuid) return;
			const gdprAcceptedPreReg = await db.getItem<IRegistrationGDPR>(COOKIES_ACCEPTED_INDEX_DB, eventUuid);
			setClickedAccept(!!gdprAcceptedPreReg);
		}
		getData();
	}, [eventBundle, eventUuid, isEditor]);

	const shouldDisplay = (
		!checkingUserCookieStatus
		&& !clickedAccept
		&& (
			forceShowBanner
			|| (
				cookieBannerEnabled
				&& !userRegGDPR
			)
		)
		&& !forceHideBanner
	);

	const handleCookieDecision = (value: boolean) => {
		handleAcceptCookies(value, isEditor, eventUuid, dispatch, setClickedAccept, userRegUUID, blProfileUserToken);
		setClickedAccept(true);
	};

	const cookieOptInEnabled = isEditor
		? channelFeatures?.includes(ChannelFeaturesEnum.cookie_opt_in)
		: event?.channel_features?.includes(ChannelFeaturesEnum.cookie_opt_in);

	return (
		<AnimatePresence>
			<OptionalComponent display={!!shouldDisplay}>
				<LazyMotion features={domAnimation}>
					<m.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className="cookie-banner-container"
						ref={cookieBannerRef}
					>
						<div className="cookie-banner-info">
							<div className="cookie-banner-title">
								{t("gdpr:This website uses cookies")}
							</div>
							<div className="cookie-banner-subtitle">
								{t("gdpr:This site uses cookies to deliver and enhance the quality of its services and to analyze traffic")}
								<span className="learn-more-link" onClick={handleLearnMore}> {t("gdpr:Learn more")}</span>
							</div>
						</div>
						<div className="cookie-banner-actions">
							<OptionalComponent display={cookieOptInEnabled}>
								<button onClick={() => handleCookieDecision(false)} className="learn-more">
									{t("gdpr:Opt Out")}
								</button>
							</OptionalComponent>
							<button onClick={() => handleCookieDecision(true)} className="accept">
								{cookieOptInEnabled
									? t('gdpr:Accept all cookies')
									: t('gdpr:Ok, Got it')}
							</button>

						</div>
					</m.div>
				</LazyMotion>
			</OptionalComponent>
		</AnimatePresence>
	);
};

export default CookieBanner;
