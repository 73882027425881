import { handle } from 'redux-pack';
import { isFunction, isObject } from 'underscore';

import {
	BrandliveEvent,
	CreateEventRequest,
	Template,
	Session,
	PageModule,
	Requirement,
	CreateSession,
	GateTypes,
	RegistrationStepType,
	PrivacyPolicy,
	TermsConditions,
	RegistrationStep,
	EPreviewTypes,
	SelectedBrandliveEvent,
	NavMainItems,
	CustomPage,
	SessionTypesEnum,
	GlobalCurrency,
	IScheduledEmail,
	IEventTag,
	ChannelCustomizedIcon,
	ICreateAccordion,
	LoadingStatus,
	GetEventsResponse,
	Language,
	RegFieldsEnum,
	RegistrationTypes,
	EventSettings,
	EmailTypeDisplayNames,
} from "../../../types/working-model";
import {
	CreateEventActions,
	ADD_NEW_COLOR_PACK,
	ADD_NEW_FONT_PACK,
	ADD_NEW_SESSION,
	ADD_REGISTRATION_REQUIREMENT,
	CREATE_NEW_EVENT,
	CREATE_NEW_SESSION,
	LOAD_COLOR_PACKS,
	LOAD_FONT_PACKS,
	LOAD_TEMPLATES,
	LOAD_WORKING_EVENT,
	ABORT_WORKING_EVENT_LOAD,
	SAVE_COLOR_PACK,
	SET_CUSTOM_CSS,
	SET_DESIGN_SHAPE,
	SET_DESIGN_STYLE,
	SET_EVENT_DESCRIPTION,
	SET_IS_TEST_EVENT,
	SET_IS_COURSE_EVENT,
	SET_EVENT_NAME,
	SET_EVENT_TYPE,
	SET_EVENT_IMAGE,
	SET_EVENT_TEMPLATE,
	SET_REGISTRATION_FEATURE,
	SET_CUSTOM_PASSCODE_LABEL,
	SET_REGISTRATION_ON,
	SET_REGISTRATION_QUESTIONS,
	SET_REGISTRATION_TYPE,
	SET_SELECTED_COLOR,
	SET_SELECTED_FONT,
	SET_WORKING_EVENT,
	TOGGLE_AUTOMATIC_SESSION_FILTER_BY_USER_LANGUAGE,
	TOGGLE_REGISTRATION_QUESTION_REQUIRED,
	TOGGLE_HOMEPAGE_MODULE,
	UPDATE_HOMEPAGE_MODULE,
	UPDATE_HOMEPAGE_HEADER,
	UPDATE_HOMEPAGE_FOOTER,
	UPDATE_HOMEPAGE_MAIN_NAV,
	UPDATE_HOMEPAGE_PROFILE_NAV,
	UPDATE_NAVBAR_TYPE,
	UPDATE_NAVBAR_IS_STICKY,
	UPDATE_POST_REGISTER_HOME_MODULES,
	SET_HOMEPAGE_MODULES,
	ADD_PAGE_MODULE,
	ADD_POST_REGISTER_HOME_PAGE_MODULE,
	CLEAR_WORKING_EVENT,
	SET_REGISTRATION_HEADER,
	SET_REGISTRATION_DESCRIPTION,
	SET_REGISTRATION_IMAGE,
	SET_REGISTRATION_LOGO,
	REMOVE_REGISTRATION_REQUIREMENT,
	UPDATE_REGISTRATION_REQUIREMENT,
	SET_BLOCKED_DOMAIN_EMAILS,
	DELETE_SESSION,
	REMOVE_SESSION,
	UPDATE_SESSION_INFO,
	UPDATE_SESSION_CARD,
	REMOVE_IN_CREATION_PROGRESS_SESSION,
	EDIT_SESSION_AGENDA,
	UPDATE_EDITING_SESSION_BY_UID,
	CLEAR_NEW_EVENT_REQUEST,
	UPDATE_WORKING_EVENT,
	MODULE_LAYOUT_UPDATE,
	DUPLICATE_SESSION,
	IS_SAVING,
	SET_AGENDA_IMAGE,
	DELETE_FONT_PACKS,
	DELETE_COLOR_PACKS,
	PUBLISH_EVENT,
	SET_REGISTRATION_SINGLE_SIGN_ON,
	CHECK_REGISTRATION_SINGLE_SIGN_ON,
	SET_REGISTRATION_SINGLE_SIGN_ON_TYPES,
	CHOOSE_REGISTRATION_STEP,
	UPDATE_REGISTRATION_STEPS,
	UPDATE_REGISTRATION_SETTINGS,
	UPDATE_ALL_EVENT_SESSIONS_OVERLAY_CHAT,
	UPDATE_WORKING_EVENT_SESSION,
	UPDATE_ALL_EVENT_SESSIONS_LAYOUT_TABS,
	SET_DESIGN_COLORS,
	TOGGLE_POST_REGISTER_HOME_MODULE,
	ADD_REGISTRATION_STEP,
	ADD_TICKETING_SET,
	UPDATE_TICKETING_SET,
	REMOVE_TICKETING_SET,
	SET_SELECTED_EVENT,
	CLEAR_SELECTED_EVENT,
	SET_EDITOR_SIZE,
	TOGGLE_PREVIEW_MODE,
	ADD_WORKING_EVENT_HOMEPAGE,
	SET_EVENT_CUSTOM_CSS,
	UPDATE_REQUIRED_REGISTRATION_QUESTIONS,
	SET_SOCIAL_SHARING,
	GET_PUBLISHED_EVENT_URL,
	CLEAR_PUBLISHED_URL,
	UPDATE_PUBLISHED_EVENT_URL,
	UPDATE_EVENT_FAVICON,
	DELETE_EVENT_FAVICON,
	REMOVE_EVENT,
	ADD_NEW_SESSION_TO_STORE,
	ADD_HOMEPAGE_TO_WORKING_EVENT,
	TOGGLE_CUSTOMIZE_FORM_MODAL,
	loadWorkingEvent,
	isSavingEvent,
	UPDATE_CURRENT_POST_REGISTER_PAGE,
	BLUR_EDITOR,
	UPDATE_EMAIL_COLOR,
	UPDATE_EMAIL_FONT,
	TOGGLE_EMAIL_SETTINGS_HIDE_BRANDING,
	UPDATE_EVENT_NAVBAR,
	SET_REGISTRATION_SSO_DISPLAY_TOP,
	ADD_CUSTOM_PAGE,
	UPDATE_CUSTOM_PAGES,
	UPDATE_CUSTOM_PAGE,
	TOGGLE_CUSTOM_PAGE_MODULE,
	UPDATE_CUSTOM_PAGE_MODULE,
	DELETE_CUSTOM_PAGE,
	SET_ADD_MODULE_MODAL_OPEN,
	TOGGLE_DISPLAY_SESSION_FOOTER,
	TOGGLE_DISPLAY_SESSIONS,
	SET_SOCIAL_SETTINGS,
	SET_SOCIAL_IN_STORE_ONLY,
	TOGGLE_DISPLAY_DIRECTORY,
	TOGGLE_DISPLAY_PROFILES,
	TOGGLE_MESSAGING,
	TOGGLE_BOOKMARK_ATTENDEES,
	TOGGLE_PROFILE_APPEARANCE,
	TOGGLE_TOPIC_GROUP_CHATS,
	UPDATE_EVENT_SETTINGS,
	TOGGLE_FILTERS,
	SET_FILTERS,
	MODAL_IN_EDITOR,
	CREATE_EVENT_CHAT_CHANNEL,
	TOGGLE_RECAPTCHA,
	TOGGLE_OVERVIEW_CONTENT,
	TOGGLE_SPEAKERS,
	TOGGLE_PERSONALIZED_ATTENDEE_LIST,
	TOGGLE_SHOW_ADD_TO_CAL_BTN,
	TOGGLE_TRACK_MODAL,
	SET_VOD_ORDER,
	IS_UPDATING_SETTINGS,
	TOGGLE_DISPLAY_LANDING_PAGE,
	TOGGLE_DISPLAY_HOMEPAGE,
	TOGGLE_DISPLAY_LIVE_EVENT_BANNER,
	SET_REJECTED_PASSCODE_MESSAGE,
	ADMIN_TOGGLE_IN_PERSON_ATTENDEE_MODE,
	TOGGLE_CLOSE_REGISTRATION,
	GET_GLOBAL_CURRENCIES,
	GET_EVENT_SCHEDULED_EMAILS,
	CREATE_SCHEDULED_EMAIL,
	EDIT_SCHEDULED_EMAIL,
	EDIT_SCHEDULED_EMAIL_IN_ADMIN,
	CLEAR_SCHEDULED_EMAILS,
	SET_EVENT_CUSTOM_COMPILED_CSS,
	SET_EVENT_USE_SAFE_CSS,
	SET_DISPLAY_SCHEDULED_EMAIL,
	DELETE_SCHEDULED_EMAIL,
	TOGGLE_TRANSLATIONS_V2,
	TOGGLE_TRANSLATIONS_V3,
	GET_CHANNEL_SCHEDULED_EMAILS,
	CLEAR_CHANNEL_SCHEDULED_EMAILS,
	ADD_EVENT_TEMPLATE,
	UPDATE_EVENT_TEMPLATE,
	SET_TRANSLATION_MANIFESTS,
	REMOVE_CUSTOM_THEMES,
	REMOVE_CUSTOM_TEMPLATES,
	REMOVE_CUSTOM_TEMPLATES_IS_UPDATING,
	UPDATE_EVENT_PREVIEW_PASSWORD,
	SET_EVENT_TAGS,
	SETUP_EVENT_PREVIEW,
	PUBLISH_EVENT_PREVIEW,
	TOGGLE_DISPLAY_SESSION_LANGUAGE_DROPDOWN,
	UPDATE_SESSION_CUSTOM_URL,
	UPDATE_CHANNEL_ICON,
	SET_EMAIL_LINK_LIMIT,
	SET_ACTIVE_ACCORDION_CONTAINER,
	resetHomepageModules,
	RESET_HOMEPAGE_MODULES,
	ADD_LEADERBOARD_NAV_ITEM,
	UPDATE_SESSION_FEEDBACK_SURVEY_TEXT,
	SET_MARKETING_OPT_IN_TYPE,
	GET_TEMPLATE_EVENTS,
	CREATE_TEMPLATE_EVENT,
	CLEAR_TEMPLATE_EVENTS_ERROR,
	RESET_TEMPLATE_EVENTS,
	UPDATE_SESSION_TITLE_AND_TRACKS,
	RESET_FETCH_EVENT_LOADING_STATUS,
	SET_LIVE_SESSION_IDS,
	UPDATE_EVENT_TYPE,
	SET_TRANSLATIONS_UPDATING,
	SET_EVENT_DEFAULT_LANGUAGE,
	UPDATE_SELECTED_EVENT_SETTINGS,
	SET_A_DROPDOWN_IS_OPEN,
	TOGGLE_A_DROPDOWN_IS_OPEN,
	LOCK_EVENT_SAVE,
	RELEASE_EVENT_SAVE_LOCK,
	UPDATE_NAVBAR_BLUR_LEVEL,
	SESSION_LANGUAGES_UPDATED,
	GET_RSVP_EVENT_LINK,
	APPROVE_RSVP_EVENT_LINK,
	SET_ADMIN_HEADER_HEIGHT,
	SET_ADMIN_SUB_PANEL_HEADER_HEIGHT,
	UPDATE_EVENT_PUBLISH_SETTINGS,
	SET_EDITING_NAVBAR,
	SET_COLOR_PACKS,
	DISABLE_PUBLISH_BUTTON,
	UPDATE_NATIVE_REGISTRATION,
	SHOW_EDITOR_LOADER,
	UPDATE_WORKING_THEME_PACK,
	INC_CHECK_THEME_PACK_PROGRESS_COUNT,
	EDIT_CHANNEL_TRACKS,
	UPDATE_REGISTRATION_LIMIT,
	TOGGLE_PASSWORD_GATING,
	SET_TRANASLATION_LOCK,
	EDIT_SCHEDULED_EMAIL_SYNC,
	SET_SESSION_PLACEHOLDER_VIDEO,
	ADMIN_ADD_SESSION_VIDEOS_LIVE,
	ADMIN_ADD_SESSION_VIDEOS_REPLAY,
	ADMIN_DELETE_SESSION_VIDEOS_LIVE,
	CLEAR_SCHEDULED_EMAIL_UPDATE_FAILURE,
	CLEAR_PLACEHOLDER_VIDEO,
	UPDATE_REGISTRATION_SETTINGS_IMMEDIATE,
	SHOULD_CREATE_CHANNEL_PRODUCT_TOGGLE,
} from '../../actions/admin/create-event';
import { updateAdminEvents } from "../../actions/admin/events";
import { GetDefaultEventNavbar, GetDefaultMainNavItems, GetDefaultRegistrationSettings } from "../../utils/create-event";
import { SaveEvent } from "../../../connection/create-event";
import { Status } from '../../../types/event';
import { getRegistrationQuestionsFromRegistrationSteps, sessionVideosWithCleanPlayback, sortRegistrationSteps, sortWorkingEventRegistrationQuestions } from '../../../utils/utils';
import { getDefaultLanguage } from '../../../components/live-event/utils';
import { RSVPLinkSync } from '../../../types/rsvp';
import { mutateV1ColorsToV2Colors } from 'utils/colors/mutate-v1-colors-to-v2-colors';
import { EPaletteModes, FontPack, ThemePack } from 'types/theme-packs';
import { defaultPageGating } from '@admin/create/homepage/navigation/panel/registration/panel-gating';

export interface Index {
	index: number;
}

export interface CreateEventState {
	newEventRequest: CreateEventRequest | null;
	templates: Template[] | null;
	workingEvent: BrandliveEvent | null;
	selectedEvent: SelectedBrandliveEvent | null;
	loadingEvent: boolean;
	/** isLoadingWorkingEvent is initialized to true, as opposed to loadingEvent which is initialized as false */
	isLoadingWorkingEvent: boolean;
	eventNotFound: boolean;
	fontPacks: FontPack[];
	colorPacks: ThemePack[];
	workingSession: Session | null;
	editingSessionAgenda: CreateSession | null;
	saving: boolean;
	registrationStep: RegistrationStepType;
	customizeFormModalOpen: boolean;
	termsConditions: TermsConditions | null;
	privacyPolicy: PrivacyPolicy | null;
	publishingEvent: boolean;
	disablePublishButton: boolean;
	showEditorLoader: boolean;
	errorPublishingEvent: boolean;
	editorSize: 'desktop' | 'tablet' | 'mobile';
	previewMode: EPreviewTypes | null,
	publishedUrl: string | null;
	deletingSession: boolean;
	currentPostRegisterPage: string | null;
	blurEditor: boolean;
	modalInEditor: boolean;
	addModuleModalOpen: boolean;
	isSettingsUpdating: boolean;
	fetchingPublishedStatus: boolean;
	publishStatusKnown: boolean;
	displayLiveBanner: boolean;
	moduleLayoutUpdate: number;
	globalCurrencies: GlobalCurrency[] | null;
	scheduledEmails: {
		scheduledEmails?: IScheduledEmail[];
		displayEmail?: IScheduledEmail['uuid'];
		loadingEmails?: boolean;
	} | null,
	errorFetchingPublishedStatus?: boolean;
	errorFetchingChannelScheduledEmails?: boolean;
	fetchingChannelScheduledEmails?: boolean;
	channelScheduledEmails?: IScheduledEmail[];
	settingUpEventPreview?: boolean;
	updatingEventPreviewPassword?: boolean;
	publishingEventPreview?: boolean;
	channelIcons?: ChannelCustomizedIcon[];
	accordion: ICreateAccordion;
	moduleUpdates: number;
	errorDeletingCustomPage?: boolean;
	loadingTemplatesStatus: LoadingStatus; /** loading status for css templates (i.e., themes)*/
	templateEvents: {
		templateEvents: GetEventsResponse[];
		totalTemplates: number;
		loadingStatus: LoadingStatus; /** loading status for template events */
		error?: string | null;
		templateEventsIsUpdating?: boolean;
	}
	liveSessionIds: Record<string, boolean>;
	adminUpdatingTranslations: string | null;
	defaultLanguage: Language | undefined;
	aDropdownIsOpen: boolean;
	saveLocked: boolean;
	abortWorkingEventLoad: boolean;
	rsvpLinkSync: RSVPLinkSync | null;
	adminHeaderHeight: number;
	adminSubPanelHeaderHeight: number;
	editingNavbar: boolean;
	translationLocked: boolean;
	checkPackProgressCounter: number;
	deletingColor: boolean;
	themePackUpdateInProgress: boolean;
	scheduledEmailUpdateFailure?: string | null;
	shouldCreateChannelProduct: boolean;
}

const initialState: CreateEventState = {
	abortWorkingEventLoad: false,
	accordion: {},
	addModuleModalOpen: false,
	adminUpdatingTranslations: null,
	aDropdownIsOpen: false,
	blurEditor: false,
	channelIcons: [],
	colorPacks: [],
	currentPostRegisterPage: null,
	customizeFormModalOpen: false,
	defaultLanguage: undefined,
	deletingSession: false,
	displayLiveBanner: false,
	editingSessionAgenda: null,
	editorSize: 'desktop',
	errorDeletingCustomPage: false,
	errorFetchingPublishedStatus: false,
	errorPublishingEvent: false,
	eventNotFound: false,
	fetchingPublishedStatus: true,
	fontPacks: [],
	globalCurrencies: null,
	isSettingsUpdating: false,
	liveSessionIds: {},
	loadingEvent: false,
	/* What's the diff between this and loadingEvent? This is initialized as true */
	isLoadingWorkingEvent: true,
	loadingTemplatesStatus: LoadingStatus.PreLoading,
	modalInEditor: false,
	moduleLayoutUpdate: 0,
	moduleUpdates: 0,
	newEventRequest: null,
	previewMode: null,
	privacyPolicy: null,
	publishedUrl: null,
	publishingEvent: false,
	disablePublishButton: false,
	showEditorLoader: false,
	publishingEventPreview: false,
	publishStatusKnown: false,
	registrationStep: RegistrationStepType.general,
	saveLocked: false,
	saving: false,
	scheduledEmails: null,
	selectedEvent: null,
	settingUpEventPreview: false,
	templateEvents: {
		templateEvents: [],
		totalTemplates: 0,
		loadingStatus: LoadingStatus.PreLoading,
		error: null,
		templateEventsIsUpdating: false,
	},
	templates: null,
	termsConditions: null,
	updatingEventPreviewPassword: false,
	workingEvent: null,
	workingSession: null,
	rsvpLinkSync: null,
	adminHeaderHeight: 72,
	adminSubPanelHeaderHeight: 82,
	editingNavbar: false,
	translationLocked: false,
	checkPackProgressCounter: 0,
	deletingColor: false,
	themePackUpdateInProgress: false,
	scheduledEmailUpdateFailure: null,
	shouldCreateChannelProduct: false,
};

let timeout: NodeJS.Timeout;

function getState(state: CreateEventState = initialState, action: CreateEventActions): CreateEventState {
	switch (action.type) {
		case CREATE_NEW_EVENT: {
			return { ...state, newEventRequest: action.payload };
		}
		case LOAD_TEMPLATES: {
			return handle(state, action, {
				start: state => ({
					...state,
					loadingTemplatesStatus: LoadingStatus.Loading,
				}),
				success: state => {
					if (action.payload) return { ...state, templates: action.payload };
					else return state;
				},
				finish: state => ({
					...state,
					loadingTemplatesStatus: LoadingStatus.Done,
				}),
			});
		}
		case CREATE_NEW_SESSION: {
			if (state.newEventRequest) {
				return {
					...state,
					newEventRequest: {
						...state.newEventRequest,
						sessions: [...state.newEventRequest.sessions, action.payload]
					}
				};
			} else return state;
		}
		case SET_EVENT_NAME: {
			if (state.newEventRequest) {
				return {
					...state,
					newEventRequest: { ...state.newEventRequest, name: action.payload }
				};
			} else {
				return state;
			}
		}
		case SET_EVENT_DESCRIPTION: {
			if (state.newEventRequest) {
				return {
					...state,
					newEventRequest: { ...state.newEventRequest, event_description: action.payload }
				};
			} else {
				return state;
			}
		}
		case SET_IS_TEST_EVENT: {
			if (state.newEventRequest) {
				return {
					...state,
					newEventRequest: { ...state.newEventRequest, is_test_event: action.payload }
				};
			} else {
				return state;
			}
		}
		case SET_IS_COURSE_EVENT: {
			if (state.newEventRequest) {
				return {
					...state,
					newEventRequest: { ...state.newEventRequest, is_course_event: action.payload }
				};
			} else {
				return state;
			}
		}
		case SET_EVENT_DEFAULT_LANGUAGE: {
			if (state.newEventRequest) {
				return {
					...state,
					newEventRequest: {
						...state.newEventRequest,
						sessions: state.newEventRequest.sessions.map(session => ({
							...session,
							languages: Array.from(
								new Set(
									[
										...session.languages,
										action.payload.code
									]
								)
							)
						})),
						defaultLanguage: action.payload.code,
					},
					defaultLanguage: action.payload,
				};
			} else if (state.workingEvent) {
				return {
					...state,
					workingEvent: {
						...state.workingEvent,
						settings: {
							...state.workingEvent.settings,
							i18n: {
								...state.workingEvent.settings?.i18n,
								base: action.payload.code
							}
						}
					},
					defaultLanguage: action.payload
				};
			} else {
				return { ...state, defaultLanguage: action.payload };
			}
		}
		case SET_WORKING_EVENT: {
			return {
				...state,
				workingEvent: action.payload
			};
		}
		case SET_SELECTED_EVENT: {
			return {
				...state,
				selectedEvent: action.payload as SelectedBrandliveEvent,
				publishStatusKnown: false
			};
		}
		case CLEAR_SELECTED_EVENT: {
			return {
				...state,
				selectedEvent: null,
				publishStatusKnown: false
			};
		}
		case CLEAR_WORKING_EVENT: {
			return {
				...state,
				workingEvent: null,
				eventNotFound: false,
				loadingEvent: false,
				isLoadingWorkingEvent: true,
				publishStatusKnown: false,
			};
		}
		case BLUR_EDITOR: {
			return {
				...state,
				blurEditor: true,
			};
		}
		case MODAL_IN_EDITOR: {
			return {
				...state,
				modalInEditor: action.payload
			};
		}
		case LOAD_WORKING_EVENT: {
			return handle(state, action, {
				start: (state) => ({
					...state,
					loadingEvent: true,
					isLoadingWorkingEvent: true,
					eventNotFound: false,
					publishStatusKnown: false,
					abortWorkingEventLoad: false // new working event load requested AFTER the last abort request, so reset the abort flag
				}),
				success: state => {
					// short-circuted to prevent the event from being loaded if the user has navigated away from the page
					if (state.abortWorkingEventLoad || !action.payload) {
						return {
							...state,
							loadingEvent: false,
							isLoadingWorkingEvent: false,
							abortWorkingEventLoad: false
						};
					}

					const { channel_event_icons, ...workingEvent } = action.payload;

					return ({
						...state,
						workingEvent,
						eventNotFound: false,
						//if there is nothing in the table, it returned back channel_event_icons = [null]
						//That s why I have a check like this.
						channelIcons: (channel_event_icons?.[0] === null) ? [] : channel_event_icons
					});
				},
				failure: state => {
					return ({ ...state, workingEvent: null, eventNotFound: true });
				},
				finish: state => {
					return {
						...state,
						loadingEvent: false,
						isLoadingWorkingEvent: false,
						blurEditor: false,
					};
				}
			});
		}
		case ABORT_WORKING_EVENT_LOAD: {
			if (state.loadingEvent) {
				return {
					...state,
					abortWorkingEventLoad: true
				};
			}

			return state;
		}
		case ADD_NEW_SESSION_TO_STORE: {
			if (state.workingEvent) {
				const { timestamp, end_timestamp } = action.payload;
				const newSession = {
					...action.payload,
					timestamp: new Date(timestamp || 0).valueOf(),
					end_timestamp: new Date(end_timestamp || 0).valueOf()
				};
				const selectedEvent = state?.selectedEvent ? { ...state.selectedEvent } : null;
				if (selectedEvent && selectedEvent?.uuid === action?.payload?.uuid) {
					selectedEvent.sessions = [newSession, ...(selectedEvent?.sessions || [])];
				}
				return ({
					...state,
					workingEvent: {
						...state.workingEvent,
						sessions: [newSession, ...(state.workingEvent?.sessions ?? [])],
						settings: {
							...state.workingEvent.settings,
							display_homepage: true
						}
					},
					selectedEvent,
				});
			} else {
				return state;
			}
		}
		case ADD_HOMEPAGE_TO_WORKING_EVENT: {
			if (!state?.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					homepage: action.payload
				},
			};
		}
		case ADD_NEW_SESSION: {
			return handle(state, action, {
				success: state => {
					if (state.workingEvent && action.payload) {
						const { timestamp, end_timestamp } = action.payload;
						const newSession = {
							...action.payload,
							timestamp: new Date(timestamp || 0).valueOf(),
							end_timestamp: new Date(end_timestamp || 0).valueOf()
						};
						const selectedEvent = state?.selectedEvent ? { ...state.selectedEvent } : null;
						if (selectedEvent && selectedEvent?.uuid === action?.payload?.uuid) {
							selectedEvent.sessions = [newSession, ...(selectedEvent?.sessions || [])];
						}

						const settings = state.workingEvent.settings;

						//if the new session is of the on-demand type AND there's a vod order
						//we have to add the new session to the vod order
						if (settings.vodOrder && newSession.session_type === SessionTypesEnum.onDemand) {
							settings.vodOrder = [newSession.session, ...settings.vodOrder];
						}

						settings.display_homepage = true;

						return ({
							...state,
							workingEvent: {
								...state.workingEvent,
								sessions: [newSession, ...(state.workingEvent?.sessions ?? [])],
								settings
							},
							selectedEvent,
						});
					} else {
						return state;
					}
				},
			});
		}
		case DUPLICATE_SESSION: {
			return handle(state, action, {
				success: state => {
					if (state.workingEvent && action.payload) {
						const { timestamp, end_timestamp } = action.payload;
						const newSession = {
							...action.payload,
							timestamp: new Date(timestamp || 0).valueOf(),
							end_timestamp: new Date(end_timestamp || 0).valueOf()
						};
						return ({
							...state,
							workingEvent: {
								...state.workingEvent,
								sessions: [newSession, ...(state.workingEvent?.sessions ?? [])],
								settings: {
									...state.workingEvent.settings,
									display_homepage: true
								}
							}
						});
					} else {
						return state;
					}
				},
			});
		}
		case DELETE_SESSION: {
			return handle(state, action, {
				start: state => ({ ...state, deletingSession: true }),
				finish: state => ({ ...state, deletingSession: false }),
				success: state => {
					if (state.workingEvent && action.payload) {
						const { sessions } = state.workingEvent;
						const updatedSessions = sessions.filter(session => session.session !== action.payload?.session);
						return {
							...state,
							workingEvent: { ...state.workingEvent, sessions: updatedSessions },
							selectedEvent: state?.selectedEvent ? {
								...state.selectedEvent,
								sessions: updatedSessions,
								_sessions: updatedSessions,
							} : null,
						};
					}
					if (state.selectedEvent) {
						const { sessions } = state.selectedEvent;
						const updatedSessions = sessions.filter(session => session.session !== action.payload?.session);
						return {
							...state,
							selectedEvent: {
								...state.selectedEvent,
								sessions: updatedSessions,
								_sessions: updatedSessions,
							},
						};
					}
					return state;
				},
			});
		}
		case REMOVE_SESSION: {
			let copy = { ...state };
			if (state.workingEvent) {
				copy = {
					...copy,
					workingEvent: {
						...state.workingEvent,
						sessions: state.workingEvent.sessions.filter(session => session.session !== action.payload.session),
					},
				};
			}
			if (state?.selectedEvent?._sessions || state?.selectedEvent?.sessions) {
				copy = {
					...copy,
					selectedEvent: {
						...state.selectedEvent,
						sessions: state?.selectedEvent?.sessions?.filter(s => s.session !== action.payload.session) ?? null,
						_sessions: state?.selectedEvent?._sessions?.filter(s => s.session !== action.payload.session) ?? null,
					}
				};
			}
			return copy;
		}
		case REMOVE_EVENT: {
			return {
				...state,
				workingEvent: state?.workingEvent?.uuid === action.payload ? null : state.workingEvent,
				selectedEvent: null,
			};
		}
		case UPDATE_SESSION_CARD:
		case UPDATE_SESSION_INFO: {
			return handle(state, action, {
				success: state => {
					if (state.workingEvent && action.payload) {
						const { sessions } = state.workingEvent;
						const updatedSessions = [...sessions];
						const updateIndex = updatedSessions.findIndex((session) => session.uuid === action.payload?.uuid);

						if (updateIndex >= 0) {
							updatedSessions.splice(updateIndex, 1, action.payload);
						}

						return ({ ...state, workingEvent: { ...state.workingEvent, sessions: updatedSessions } });
					} else {
						return state;
					}
				}
			});
		}
		case IS_UPDATING_SETTINGS: {
			return {
				...state,
				isSettingsUpdating: action.payload,
			};
		}
		case UPDATE_EVENT_SETTINGS: {
			if (!state.workingEvent) return state;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: action.payload,
				},
			};
		}
		case SET_REGISTRATION_ON: {
			return {
				...state,
				workingEvent: {
					...state.workingEvent as BrandliveEvent,
					registration_on: action.payload as boolean,
				}
			};
		}
		case SET_REGISTRATION_TYPE: {
			if (state.workingEvent?.registration_settings) {
				return ({
					...state,
					workingEvent: {
						...state.workingEvent as BrandliveEvent,
						registration_settings: {
							...state.workingEvent?.registration_settings,
							type: action.payload,
						},
					},
				});
			} else {
				return state;
			}
		}
		case UPDATE_REGISTRATION_SETTINGS: {
			if (!state.workingEvent) return state;
			return handle(state, action, {
				success: (state) => {
					if (!state.workingEvent) return state;
					return {
						...state,
						workingEvent: {
							...state.workingEvent,
							registration_settings: action.payload
						}
					};
				}
			});
		}
		case UPDATE_REGISTRATION_SETTINGS_IMMEDIATE: {
			if (!state.workingEvent) return state;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_settings: action.payload
				}
			};
		}
		case SET_REGISTRATION_FEATURE: {
			if (state.workingEvent?.registration_settings) {
				return ({
					...state,
					workingEvent: {
						...state.workingEvent as BrandliveEvent,
						registration_settings: {
							...state.workingEvent.registration_settings,
							[action.payload.feature]: action.payload.on
						}
					}
				});
			} else {
				return state;
			}
		}
		case SET_CUSTOM_PASSCODE_LABEL: {
			if (state.workingEvent?.registration_settings) {
				return ({
					...state,
					workingEvent: {
						...state.workingEvent as BrandliveEvent,
						registration_settings: {
							...state.workingEvent.registration_settings,
							customPasscodeLabel: action.payload
						}
					}
				});
			} else {
				return state;
			}
		}
		case SET_REJECTED_PASSCODE_MESSAGE: {
			if (state.workingEvent?.registration_settings) {
				return ({
					...state,
					workingEvent: {
						...state.workingEvent as BrandliveEvent,
						registration_settings: {
							...state.workingEvent.registration_settings,
							customRejectedPasscodeMessage: action.payload
						}
					}
				});
			} else {
				return state;
			}
		}
		case SET_REGISTRATION_SINGLE_SIGN_ON: {
			if (state.workingEvent?.registration_settings) {
				return ({
					...state,
					workingEvent: {
						...state.workingEvent,
						registration_settings: {
							...state.workingEvent.registration_settings,
							// if sso is true, disable recaptcha, otherwise do nothing
							enableRecaptcha: action.payload ? false : state.workingEvent.registration_settings?.enableRecaptcha,
							singleSignOn: {
								...state.workingEvent.registration_settings.singleSignOn,
								isOn: action.payload
							}
						}
					}
				});
			} else {
				return state;
			}
		}

		case CHECK_REGISTRATION_SINGLE_SIGN_ON: {
			const nextState = { ...state };
			if (!nextState.workingEvent?.registration_settings?.singleSignOn) return nextState;

			const isSingleSignOnSwitchedOn = nextState.workingEvent.registration_settings.singleSignOn.isOn;
			const hasConfiguredSingleSignOn = !!nextState.workingEvent.registration_settings.singleSignOn.singleSignOns?.length;

			// we don't just want the feature to be switched on but not configured. If the user has not configured any single sign on, 
			// flip the feature back off.
			if (isSingleSignOnSwitchedOn && !hasConfiguredSingleSignOn) {
				nextState.workingEvent.registration_settings.singleSignOn.isOn = false;
				return nextState;
			} else {
				return nextState;
			}
		}

		case SET_REGISTRATION_SSO_DISPLAY_TOP: {
			if (state.workingEvent?.registration_settings) {
				return ({
					...state,
					workingEvent: {
						...state.workingEvent,
						registration_settings: {
							...state.workingEvent.registration_settings,
							singleSignOn: {
								...state.workingEvent.registration_settings.singleSignOn,
								displayTop: action.payload
							}
						}
					}
				});
			} else {
				return state;
			}
		}
		case SET_REGISTRATION_SINGLE_SIGN_ON_TYPES: {
			if (state.workingEvent?.registration_settings) {
				return ({
					...state,
					workingEvent: {
						...state.workingEvent,
						registration_settings: {
							...state.workingEvent.registration_settings,
							singleSignOn: {
								...state.workingEvent.registration_settings.singleSignOn,
								singleSignOns: action.payload
							}
						}
					}
				});
			} else {
				return state;
			}
		}
		case SET_BLOCKED_DOMAIN_EMAILS: {
			if (state.workingEvent?.registration_settings) {
				return ({
					...state,
					workingEvent: {
						...state.workingEvent as BrandliveEvent,
						registration_settings: {
							...state.workingEvent.registration_settings,
							blockedDomainEmails: action.payload
						}
					}
				});
			} else {
				return state;
			}
		}
		case ADD_REGISTRATION_REQUIREMENT: {
			if (!state.workingEvent) return state;

			const { type, newRequirement }: {
				type: GateTypes;
				newRequirement: Requirement,
			} = action.payload;

			const workingEvent = { ...state.workingEvent };

			const requirementsCopy = { ...workingEvent.registration_settings?.requirements };
			const listsCopy = [...requirementsCopy?.[GateTypes.passcode_list] || []];

			if (type === GateTypes.passcode_list) {
				listsCopy.push(newRequirement);
			} else {
				requirementsCopy[type] = newRequirement;
			}

			if (workingEvent.registration_settings) {
				workingEvent.registration_settings = {
					...workingEvent.registration_settings,
					requirements: {
						...requirementsCopy,
						...(listsCopy.length ? { [GateTypes.passcode_list]: listsCopy } : {})
					}
				};
			} else {
				workingEvent.registration_on = true;
				workingEvent.registration_settings = {
					...GetDefaultRegistrationSettings(),
					requirements: requirementsCopy
				};
			}

			return { ...state, workingEvent };
		}

		case REMOVE_REGISTRATION_REQUIREMENT: {
			if (!state.workingEvent) return state;
			const workingEvent = { ...state.workingEvent };

			const requiredQnsStepsCopy = workingEvent?.required_registration_questions ? [...workingEvent.required_registration_questions] : null;
			const requirementsCopy = { ...workingEvent.registration_settings?.requirements };

			if (action.payload.type === GateTypes.passcode_list) {
				if (requirementsCopy[GateTypes.passcode_list]?.length === 1) {
					delete requirementsCopy[GateTypes.passcode_list];
				} else {
					requirementsCopy[GateTypes.passcode_list]?.splice(action.payload.passcodeListIndex || 0, 1);
				}
			} else {
				delete requirementsCopy[action.payload.type];
			}

			if (workingEvent.registration_settings) {
				workingEvent.registration_settings = {
					...workingEvent.registration_settings,
					requirements: requirementsCopy,
					type: Object.keys(requirementsCopy).length === 0 ?
						RegistrationTypes.open : // if all the gates have been removed then set the registration type to open
						workingEvent.registration_settings.type, // otherwise leave the setting as it was
				};
			}

			workingEvent.required_registration_questions = requiredQnsStepsCopy;

			// if this is now an open session then passcode cannot be required
			// this is really a final fallback. This does not cover whether the gate type is domain-based or not
			// just whether there is no gating at all.
			if (workingEvent.registration_settings?.type === RegistrationTypes.open) {
				workingEvent.required_registration_questions = workingEvent.required_registration_questions?.filter(question => question !== RegFieldsEnum.passcode) ?? null;
				workingEvent.registration_questions = workingEvent.registration_questions?.filter(question => question.registration_question !== RegFieldsEnum.passcode) ?? [];
			}

			return {
				...state,
				workingEvent: { ...workingEvent }
			};
		}

		case UPDATE_REGISTRATION_REQUIREMENT: {
			if (!state.workingEvent) return state;
			const workingEvent = { ...state.workingEvent };
			const { type, passcodeListIndex, updatedRequirement } = action.payload;

			const requirementsCopy = { ...workingEvent.registration_settings?.requirements };

			if (type === GateTypes.passcode_list) {
				requirementsCopy[type] = requirementsCopy[type]?.map((passcodeList, index) => {
					if (index === passcodeListIndex) return updatedRequirement;
					else return passcodeList;
				});
			} else {
				requirementsCopy[type] = updatedRequirement;
			}

			if (workingEvent.registration_settings) {
				workingEvent.registration_settings = {
					...workingEvent.registration_settings,
					requirements: requirementsCopy
				};
			}

			return { ...state, workingEvent: workingEvent };
		}

		case TOGGLE_PASSWORD_GATING: {
			if (!state.workingEvent) return state;
			const workingEvent = { ...state.workingEvent };

			let requiredQnsStepsCopy = workingEvent?.required_registration_questions ? [...workingEvent.required_registration_questions] : null;
			let requirementsCopy = { ...workingEvent.registration_settings?.requirements };

			if (action.payload === true) {
				// When toggling password gating on, we remove every other type of gating.
				// Reasoning behind this is "for simplicity".
				requirementsCopy = {};
				workingEvent.settings.pageGating = defaultPageGating;

				if (workingEvent.registration_settings) {
					workingEvent.registration_settings.password_gating_enabled = true;
					workingEvent.registration_settings.blockedDomainEmails = [];
					workingEvent.registration_settings.type = RegistrationTypes.gated;
					if (workingEvent.registration_settings.singleSignOn) {
						workingEvent.registration_settings.singleSignOn.isOn = false;
					}
					workingEvent.registration_settings.sendEmailConfirmation = true;
					workingEvent.registration_settings.skipEmailOnDuplicateRegistration = false;
				}
				if (requiredQnsStepsCopy) {
					requiredQnsStepsCopy = requiredQnsStepsCopy.filter((questionId: number) => questionId !== RegFieldsEnum.passcode);
				}
			} else {
				// Toggling password gating off
				if (workingEvent.registration_settings) {
					workingEvent.registration_settings.type = RegistrationTypes.none;
					workingEvent.registration_settings.password_gating_enabled = false;
				}
			}

			if (workingEvent.registration_settings) {
				workingEvent.registration_settings = {
					...workingEvent.registration_settings,
					requirements: requirementsCopy
				};
			}

			return {
				...state,
				workingEvent: {
					...workingEvent,
					required_registration_questions: requiredQnsStepsCopy
				},
			};
		}

		case CHOOSE_REGISTRATION_STEP: {
			return { ...state, registrationStep: action.payload };
		}
		case TOGGLE_CUSTOMIZE_FORM_MODAL: {
			return { ...state, customizeFormModalOpen: action.payload };
		}
		case UPDATE_REGISTRATION_STEPS: {
			if (!state.workingEvent) return state;

			const updatedSteps = action.payload.steps;
			const options = action.payload?.options;

			const registrationSteps = sortRegistrationSteps(updatedSteps);
			const registrationQuestions = getRegistrationQuestionsFromRegistrationSteps(registrationSteps);

			const requiredRegistrationQuestions = () => {
				// set required questions to email only if makeNameOptional is true
				// Note: if the event has leaderboard or breakout sessions then first/last are required for those features and this will be overwritten
				// it will be overwritten in `registration-required-fields.ts` -> `useSetRequiredField` -> `dispatch(updateRequiredQuestions(updatedReqQuestions));`
				if (options?.makeNameOptional) {
					return [RegFieldsEnum.email];
				}
				return options?.requiredQuestions || state.workingEvent?.required_registration_questions || null;
			};

			// sort state.workingEvent.registration_questions
			const updatedRegistrationQnOrder = sortWorkingEventRegistrationQuestions({
				registrationSteps,
				registrationQuestions: registrationQuestions,
				registrationQuestionsToAppend: options?.registrationQuestionsToAppend,
			});

			// remove duplicates
			// the order does matter, however the previous sort should have already sorted the questions
			// so any duplicates should be next to each other
			const updatedRegistrationQnOrderNoDups = Array.from(new Map(updatedRegistrationQnOrder.map(q => [q.registration_question, q])).values());

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_steps: registrationSteps,
					required_registration_questions: requiredRegistrationQuestions(),
					registration_questions: updatedRegistrationQnOrderNoDups,
				}
			};


		}
		case UPDATE_REQUIRED_REGISTRATION_QUESTIONS: {
			if (!state.workingEvent) return state;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					required_registration_questions: action.payload
				}
			};
		}

		//not currently in use, but we are planning at some point to add the ability to add more steps
		case ADD_REGISTRATION_STEP: {
			if (!state.workingEvent) return state;

			if (!state.workingEvent.registration_steps) {
				console.error("Tried adding a registration step to an empty set.");
				return state;
			}

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_steps: [
						...state.workingEvent.registration_steps,
						action.payload
					]
				}
			};
		}

		case ADD_TICKETING_SET: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };

			if (!_workingEvent.registration_steps) {
				console.error("Tried adding a registration step to an empty set.");
				return state;
			}

			// turn on the ticketing step if it isn't on already
			const exists = _workingEvent.registration_steps.find(step => step.type == RegistrationStepType.ticketing) !== undefined;
			if (!exists) {
				const step: RegistrationStep = {
					isOn: true,
					type: RegistrationStepType.ticketing,
				};

				_workingEvent.registration_steps.push(step);
			}

			_workingEvent.registration_steps = _workingEvent.registration_steps.map(step => {
				const stepCopy = { ...step };
				if (stepCopy.type === RegistrationStepType.ticketing) {
					stepCopy.ticketing = [...stepCopy.ticketing ?? [], action.payload];
					stepCopy.isOn = true;
				}

				return stepCopy;
			});

			// turn on send email if its not already (emails are needed for ticketing)
			const sendEmails = _workingEvent.registration_settings?.sendEmailConfirmation;
			if (!sendEmails) {
				if (!_workingEvent.registration_settings) {
					console.error("Tried modifying registration settings that don't exist.");
					return state;
				}
				_workingEvent = {
					..._workingEvent,
					registration_settings: {
						..._workingEvent.registration_settings,
						sendEmailConfirmation: true
					}
				};
			}

			return {
				...state,
				workingEvent: _workingEvent
			};
		}

		case UPDATE_TICKETING_SET: {
			if (!state.workingEvent) return state;

			const _workingEvent = { ...state.workingEvent };

			if (!_workingEvent.registration_steps) {
				console.error("Tried adding a registration step to an empty set.");
				return state;
			}

			const { set, index } = action.payload;

			_workingEvent.registration_steps = _workingEvent.registration_steps.map(step => {
				if (step.type === RegistrationStepType.ticketing && step.ticketing) {
					step.ticketing = step.ticketing.map((ticketingSet, _index) => {
						if (_index === index) {
							return set;
						} else {
							return ticketingSet;
						}
					});
				}

				return step;
			});

			return {
				...state,
				workingEvent: _workingEvent
			};
		}

		case GET_GLOBAL_CURRENCIES: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						globalCurrencies: action.payload || []
					};
				}
			});
		}

		case REMOVE_TICKETING_SET: {
			if (!state.workingEvent) return state;

			const _workingEvent = { ...state.workingEvent };

			if (!_workingEvent.registration_steps) {
				console.error("Tried adding a registration step to an empty set.");
				return state;
			}

			let emptyTicketStep = null;

			_workingEvent.registration_steps = _workingEvent.registration_steps.map((step, stepIndex) => {
				const stepCopy = { ...step };
				if (stepCopy.type === RegistrationStepType.ticketing && stepCopy.ticketing) {
					stepCopy.ticketing = stepCopy.ticketing.filter((tick, index) => {
						return index !== action.payload;
					});
					if (!stepCopy?.ticketing?.length) {
						emptyTicketStep = stepIndex;
					}
				}
				return stepCopy;
			});

			// if there are no tickets, ticketing should be removed from registration steps
			if (emptyTicketStep) {
				_workingEvent.registration_steps.splice(emptyTicketStep, 1);
			}

			return {
				...state,
				workingEvent: _workingEvent
			};
		}

		case TOGGLE_REGISTRATION_QUESTION_REQUIRED: {
			if (!state.workingEvent) return state;

			if (action.payload.required) {
				return {
					...state,
					workingEvent: {
						...state.workingEvent,
						required_registration_questions: [
							...state.workingEvent.required_registration_questions ?? [],
							action.payload.question
						]
					}
				};
			} else {
				return {
					...state,
					workingEvent: {
						...state.workingEvent,
						required_registration_questions: state.workingEvent.required_registration_questions?.filter((_question: number) => {
							return _question !== action.payload.question;
						}) ?? []
					}
				};
			}
		}
		case SET_REGISTRATION_QUESTIONS: {
			if (!state.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_questions: action.payload.questions,
					required_registration_questions: action.payload.required_questions
				}
			};
		}
		case LOAD_FONT_PACKS: {
			return handle(state, action, {
				success: state => ({ ...state, fontPacks: action.payload || [] })
			});
		}
		case SET_SELECTED_FONT: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };

			_workingEvent = {
				..._workingEvent,
				settings: {
					..._workingEvent.settings,
					design: {
						..._workingEvent.settings.design,
						font: action.payload
					}
				}
			};

			return {
				...state,
				workingEvent: _workingEvent
			};
		}
		case ADD_NEW_FONT_PACK: {
			return {
				...state,
				fontPacks: [action.payload, ...state.fontPacks]
			};
		}
		case DELETE_FONT_PACKS: {
			return handle(state, action, {
				success: state => {
					if (action.payload) {
						const {
							deletedFonts
						} = action.payload;

						let updatedFonts = [...state.fontPacks];

						if (deletedFonts?.length === 1) {
							const fontIndex = updatedFonts.findIndex(font => font.font_pack === deletedFonts[0]);
							if (fontIndex >= 0) updatedFonts.splice(fontIndex, 1);
						} else {
							updatedFonts = updatedFonts.filter(font => !deletedFonts.includes(font.font_pack));
						}

						return {
							...state,
							fontPacks: updatedFonts
						};
					}

					return state;
				}
			});
		}
		case LOAD_COLOR_PACKS: {
			return handle(state, action, {
				success: state => {
					if (!action.payload) return state;

					return ({
						...state,
						colorPacks: action.payload.map(cp => ({
							...cp,
							colors: mutateV1ColorsToV2Colors(cp.colors),
						})),
					});
				}
			});
		}
		case SET_COLOR_PACKS: {
			return {
				...state,
				colorPacks: action.payload,
			};
		}
		case SET_SELECTED_COLOR: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };

			_workingEvent = {
				..._workingEvent,
				settings: {
					..._workingEvent.settings,
					design: {
						..._workingEvent.settings.design,
						colors: action.payload
					}
				}
			};

			return {
				...state,
				workingEvent: _workingEvent
			};
		}
		case ADD_NEW_COLOR_PACK: {
			return {
				...state,
				colorPacks: [action.payload, ...state.colorPacks]
			};
		}
		case SAVE_COLOR_PACK: {

			let _workingEvent = state.workingEvent;

			if (_workingEvent) {
				_workingEvent = {
					..._workingEvent,
					settings: {
						..._workingEvent.settings,
						design: {
							..._workingEvent.settings.design,
							colors: action.payload
						}
					}
				};
			}

			return {
				...state,
				colorPacks: state.colorPacks.map((pack: ThemePack) => {
					if (pack.color_pack === action.payload.color_pack) {
						return action.payload;
					} else return pack;
				}),
				workingEvent: _workingEvent
			};
		}
		case DELETE_COLOR_PACKS: {
			return handle(state, action, {
				start: state => ({ ...state, deletingColor: true }),
				success: state => {
					if (action.payload) {
						const {
							deletedColors
						} = action.payload;

						let updatedColors = [...state.colorPacks];

						if (deletedColors?.length === 1) {
							const colorIndex = updatedColors.findIndex(color => color.color_pack === deletedColors[0]);
							if (colorIndex >= 0) updatedColors.splice(colorIndex, 1);
						} else {
							updatedColors = updatedColors.filter(color => !deletedColors.includes(color.color_pack));
						}

						return {
							...state,
							colorPacks: updatedColors
						};
					}

					return state;
				},
				finish: state => ({ ...state, deletingColor: false }),
			});
		}
		case SET_DESIGN_STYLE: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };

			_workingEvent = {
				..._workingEvent,
				settings: {
					..._workingEvent.settings,
					design: {
						..._workingEvent.settings.design,
						style: {
							..._workingEvent.settings.design.style,
							style: action.payload
						}
					}
				}
			};

			return {
				...state,
				workingEvent: _workingEvent
			};
		}
		case SET_DESIGN_SHAPE: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };
			_workingEvent = {
				..._workingEvent,
				settings: {
					..._workingEvent.settings,
					design: {
						..._workingEvent.settings.design,
						style: {
							..._workingEvent.settings.design.style,
							shape: action.payload
						}
					}
				}
			};

			return {
				...state,
				workingEvent: _workingEvent
			};
		}
		case SET_DESIGN_COLORS: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };

			_workingEvent = {
				..._workingEvent,
				settings: {
					..._workingEvent.settings,
					design: {
						..._workingEvent.settings.design,
						colors: action.payload
					}
				}
			};

			return {
				...state,
				workingEvent: _workingEvent
			};
		}

		case SET_CUSTOM_CSS: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };

			_workingEvent = {
				..._workingEvent,
				settings: {
					..._workingEvent.settings,
					customCSS: action.payload
				}
			};

			return {
				...state,
				workingEvent: _workingEvent
			};
		}

		case TOGGLE_HOMEPAGE_MODULE: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };
			if (!_workingEvent?.homepage) return state;

			_workingEvent = {
				..._workingEvent,
				homepage: {
					..._workingEvent.homepage,
					modules: _workingEvent.homepage.modules.map((module: PageModule) => {
						if (module.id === action.payload.id) {
							module.is_on = !module.is_on;
						}

						return module;
					})
				}
			};

			return { ...state, workingEvent: _workingEvent };
		}

		case TOGGLE_POST_REGISTER_HOME_MODULE: {
			if (state.workingEvent?.homepage?.post_register_home_modules) {
				return {
					...state,
					workingEvent: {
						...state.workingEvent,
						homepage: {
							...state.workingEvent?.homepage,
							post_register_home_modules: state.workingEvent?.homepage?.post_register_home_modules.map(module => {
								if (module.id === action.payload.moduleId) {
									return {
										...module,
										is_on: action.payload.is_on,
									};
								}
								return module;
							}),
						}
					}
				};
			}
			return state;
		}

		case UPDATE_HOMEPAGE_MODULE: {
			// This code can get a little confusing, but this code has to update matching submodules on different modules,
			// no matter where they are
			// Definition for clarity: Module = speaker, Submodule = speakers within the speaker module. Module's have different ID's, 
			// but Submodules DO NOT have different ID's

			if (!state.workingEvent) return state;

			const updateModules = (modulesToUpdate: PageModule[], updatedModule: PageModule) =>
				modulesToUpdate.map((moduleToUpdate: PageModule) => {
					// Update in state the exact same module that was just updated in the editor
					if (moduleToUpdate.id === updatedModule.id) {
						return updatedModule;
					}

					// Same module type but different ID. Relevant for multiple modules of the same type on the same page
					// *OR* a module of the same type on a different page. If they update a speaker on the homepage, and have
					// that SAME speaker on a session page (different module, but same submodule) then the session page speaker 
					// gets updated as well.
					if (moduleToUpdate.type === updatedModule.type) {
						const updatedSubmodulesMap = updatedModule.modules?.reduce((acc, cur) => ({
							...acc,
							[cur.module_id]: cur
						}), {});

						return {
							...moduleToUpdate,
							modules: moduleToUpdate.modules?.map(m => updatedSubmodulesMap[m.module_id] ?? m)
						};
					}

					// Totally unrelated module, so nothing to update here
					return moduleToUpdate;
				});

			let _workingEvent = { ...state.workingEvent };
			if (!_workingEvent?.homepage) {
				return state;
			}

			_workingEvent = {
				..._workingEvent,
				status: 2,
				homepage: {
					..._workingEvent.homepage,
					post_register_home_modules: updateModules(_workingEvent.homepage.post_register_home_modules, action.payload),
					modules: updateModules(_workingEvent.homepage.modules, action.payload)
				},
				custom_pages: state.workingEvent.custom_pages?.map(page => {
					if (page.path !== state.currentPostRegisterPage || !page.modules) return page;

					const updatedModules = updateModules(page.modules, action.payload);

					return {
						...page,
						modules: updatedModules
					};
				}),
				sessions: state.workingEvent.sessions.map(session => ({
					...session,
					modules: session.modules.map(sessionModule => {
						const updatedModule = action.payload;

						if (sessionModule.type === updatedModule.type) {
							return {
								...sessionModule,
								modules: sessionModule.modules?.map((sessionMod: unknown) => {
									if (!updatedModule.modules || !Array.isArray(updatedModule.modules)) {
										return sessionMod;
									}
									const matchingSubModule = updatedModule.modules.find((updatedSubmodule: unknown) => {
										if (!isObject(updatedSubmodule) || !isObject(sessionMod)) {
											return false;
										}
										return updatedSubmodule.module_id === sessionMod.module_id;
									});
									return matchingSubModule ?? sessionMod;
								})
							};
						}

						return sessionModule;
					})
				}))
			};


			return {
				...state,
				workingEvent: {
					..._workingEvent,
				}
			};
		}

		case SET_HOMEPAGE_MODULES: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };
			if (!_workingEvent?.homepage) return state;

			_workingEvent = {
				..._workingEvent,
				homepage: {
					..._workingEvent.homepage,
					modules: action.payload
				}
			};

			return { ...state, workingEvent: _workingEvent };
		}

		case RESET_HOMEPAGE_MODULES: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };
			if (!_workingEvent?.homepage) return state;
			_workingEvent = {
				..._workingEvent,
				homepage: {
					..._workingEvent.homepage,
					modules: action.payload,
				}
			};

			return {
				...state,
				workingEvent: _workingEvent,
				moduleUpdates: state.moduleUpdates + 1,
			};
		}

		case UPDATE_HOMEPAGE_HEADER: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };
			if (!_workingEvent?.homepage) return state;

			_workingEvent = {
				..._workingEvent,
				homepage: {
					..._workingEvent.homepage,
					header: action.payload
				}
			};

			return { ...state, workingEvent: _workingEvent };
		}

		case UPDATE_HOMEPAGE_FOOTER: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };
			if (!_workingEvent?.homepage) return state;

			_workingEvent = {
				..._workingEvent,
				homepage: {
					..._workingEvent.homepage,
					footer: action.payload
				}
			};

			return { ...state, workingEvent: _workingEvent };
		}

		case UPDATE_HOMEPAGE_MAIN_NAV: {
			if (!state.workingEvent || !state.workingEvent.homepage) return state;

			const _workingEvent = { ...state.workingEvent };
			if (_workingEvent?.homepage) {
				return {
					...state,
					workingEvent: {
						..._workingEvent,
						homepage: {
							..._workingEvent.homepage,
							event_main_nav_items: action.payload,
						},
					},
				};
			}
			return state;
		}

		case UPDATE_HOMEPAGE_PROFILE_NAV: {
			if (!state.workingEvent || !state.workingEvent.homepage) return state;

			const _workingEvent = { ...state.workingEvent };
			if (_workingEvent?.homepage) {
				return {
					...state,
					workingEvent: {
						..._workingEvent,
						homepage: {
							..._workingEvent.homepage,
							event_profile_nav_items: action.payload,
						},
					},
				};
			}
			return state;
		}

		case UPDATE_NAVBAR_TYPE: {
			if (!state.workingEvent?.settings) return state;

			const _workingEvent = { ...state.workingEvent };
			const _settings = { ..._workingEvent.settings };

			// if an old event sets the navbarType since all new events will have event_navbar
			if (!_settings.event_navbar) {
				_settings.event_navbar = GetDefaultEventNavbar();
			}
			_settings.event_navbar.navbarType = action.payload;

			return {
				...state,
				workingEvent: {
					..._workingEvent,
					settings: { ..._settings }
				}
			};
		}

		case UPDATE_NAVBAR_IS_STICKY: {
			if (!state.workingEvent?.settings) return state;

			const _workingEvent = { ...state.workingEvent };
			const _settings = { ..._workingEvent.settings };

			if (!_settings.event_navbar) {
				_settings.event_navbar = GetDefaultEventNavbar();
			}
			_settings.event_navbar.isSticky = action.payload;

			return {
				...state,
				workingEvent: {
					..._workingEvent,
					settings: { ..._settings }
				}
			};
		}

		case UPDATE_NAVBAR_BLUR_LEVEL: {
			if (!state.workingEvent?.settings) return state;

			const _workingEvent = { ...state.workingEvent };
			const _settings = { ..._workingEvent.settings };

			if (!_settings.event_navbar) {
				_settings.event_navbar = GetDefaultEventNavbar();
			}
			_settings.event_navbar.blurLevel = action.payload;

			return {
				...state,
				workingEvent: {
					..._workingEvent,
					settings: { ..._settings }
				}
			};
		}

		case UPDATE_POST_REGISTER_HOME_MODULES: {
			if (!state.workingEvent || !state.workingEvent.homepage) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					homepage: {
						...state.workingEvent.homepage,
						post_register_home_modules: action.payload,
					}
				}
			};
		}

		case ADD_PAGE_MODULE: {
			if (!state.workingEvent) return state;

			let _workingEvent = { ...state.workingEvent };
			if (!_workingEvent?.homepage) return state;

			if (state.currentPostRegisterPage === NavMainItems.Home) {
				// add page_module to post register homepage
				_workingEvent = {
					..._workingEvent,
					homepage: {
						..._workingEvent.homepage,
						post_register_home_modules: [..._workingEvent.homepage.post_register_home_modules, action.payload]
					}
				};
			} else if (state.currentPostRegisterPage) { //Add module to custom page
				//state.currentPostRegisterPage should be custom_page.path for any Custom Page
				const customPages = state.workingEvent.custom_pages?.map(page => {
					if (page.path !== state.currentPostRegisterPage) return page;

					const updatedModules = page.modules ? [...page.modules, action.payload] : [action.payload];
					return {
						...page,
						modules: updatedModules
					};
				});
				_workingEvent = {
					..._workingEvent,
					custom_pages: customPages
				};
			} else {
				// add page_module to workingEvent.homepage.modules
				_workingEvent = {
					..._workingEvent,
					homepage: {
						..._workingEvent.homepage,
						modules: [..._workingEvent.homepage.modules, action.payload]
					}
				};
			}
			return { ...state, workingEvent: _workingEvent };
		}

		case ADD_POST_REGISTER_HOME_PAGE_MODULE: {
			return handle(state, action, {
				success: prevState => {
					if (!prevState.workingEvent || !action.payload || !prevState.workingEvent?.homepage?.post_register_home_modules) return prevState;
					return {
						...prevState,
						workingEvent: {
							...prevState.workingEvent,
							homepage: {
								...prevState.workingEvent.homepage,
								post_register_home_modules: [
									...prevState.workingEvent.homepage.post_register_home_modules,
									action.payload,
								]
							}
						}
					};
				},
				failure: prevState => {
					return prevState;
				},
			});
		}

		case SET_REGISTRATION_HEADER: {
			if (!state.workingEvent) return state;

			const _workingEvent = { ...state.workingEvent };
			if (!_workingEvent?.registration_settings) return state;

			_workingEvent.registration_settings.title = action.payload;

			return {
				...state,
				workingEvent: _workingEvent
			};
		}


		case SET_REGISTRATION_DESCRIPTION: {
			if (!state.workingEvent) return state;

			const _workingEvent = { ...state.workingEvent };
			if (!_workingEvent?.registration_settings) return state;

			_workingEvent.registration_settings.description = action.payload;

			return {
				...state,
				workingEvent: _workingEvent
			};
		}

		case SET_REGISTRATION_IMAGE: {
			if (!state.workingEvent) return state;

			const _workingEvent = { ...state.workingEvent };
			if (!_workingEvent?.registration_settings) return state;

			_workingEvent.registration_settings.image = action.payload;

			return {
				...state,
				workingEvent: _workingEvent
			};
		}

		case SET_REGISTRATION_LOGO: {
			if (!state.workingEvent) return state;

			const _workingEvent = { ...state.workingEvent };
			if (!_workingEvent?.registration_settings) return state;

			_workingEvent.registration_settings.logo = action.payload;

			return {
				...state,
				workingEvent: _workingEvent
			};
		}

		case SET_EVENT_CUSTOM_CSS: {
			if (!state.workingEvent) return state;

			const _workingEvent = { ...state.workingEvent };

			_workingEvent.custom_css = action.payload;

			return {
				...state,
				workingEvent: _workingEvent
			};
		}

		case SET_EVENT_CUSTOM_COMPILED_CSS: {
			if (!state.workingEvent || !action.payload) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					custom_css_compiled: action.payload
				}
			};
		}

		case SET_EVENT_USE_SAFE_CSS: {
			if (!state.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					use_safe_css: action.payload
				}
			};
		}

		case REMOVE_IN_CREATION_PROGRESS_SESSION: {
			if (!state?.newEventRequest?.sessions) return state;
			return {
				...state,
				newEventRequest: {
					...state.newEventRequest,
					sessions: state.newEventRequest.sessions.filter((session) => session.uid !== action.payload),
				},
			};
		}

		case EDIT_SESSION_AGENDA: {
			if (!action.payload) {
				return {
					...state,
					editingSessionAgenda: null,
				};
			}
			const matchedSession: CreateSession | null = state?.newEventRequest?.sessions?.find((value) => value.uid === action.payload) || null;
			if (matchedSession) {
				return {
					...state,
					editingSessionAgenda: matchedSession,
				};
			}
			return state;
		}

		case UPDATE_EDITING_SESSION_BY_UID: {
			if (!action.payload) return state;
			return {
				...state,
				newEventRequest: state?.newEventRequest ? {
					...state.newEventRequest,
					sessions: state?.newEventRequest?.sessions?.map((session) => {
						if (action?.payload?.uid === session?.uid) return action.payload;
						return session;
					}) || [],
				} : null,
				editingSessionAgenda: null,
			};
		}

		case CLEAR_NEW_EVENT_REQUEST: {
			return { ...state, newEventRequest: action.payload };
		}

		case UPDATE_WORKING_EVENT: {
			return ({
				...state,
				workingEvent: action.payload,
			});
		}

		case MODULE_LAYOUT_UPDATE: {
			return ({
				...state,
				moduleLayoutUpdate: state.moduleLayoutUpdate + 1,
			});
		}

		case ADD_WORKING_EVENT_HOMEPAGE: {
			return handle(state, action, {
				success: (state) => {
					if (!state.workingEvent || !action.payload) return state;

					return {
						...state,
						workingEvent: {
							...state.workingEvent,
							homepage: {
								...action.payload,
								event_main_nav_items: GetDefaultMainNavItems(getDefaultLanguage(state.workingEvent))
							}
						}
					};
				},
				failure: state => state,
			});
		}

		case IS_SAVING: {
			return {
				...state,
				saving: action.payload,
			};
		}

		case SET_AGENDA_IMAGE: {
			if (!state.workingEvent) return state;
			const { url, session } = action.payload;

			// find the matching session and set the url to the image field
			const _workingEvent = { ...state.workingEvent };
			return {
				...state,
				workingEvent: {
					..._workingEvent,
					sessions: _workingEvent.sessions.map((_workingSession: Session) => {
						if (_workingSession.session !== session) return _workingSession;
						return {
							..._workingSession,
							image: url,
						};
					}),
				},
			};
		}

		case PUBLISH_EVENT: {
			return handle(state, action, {
				success: (state) => {
					if (!state.workingEvent) return state;
					const _workingEvent = { ...state.workingEvent };
					return {
						...state,
						errorPublishingEvent: false,
						publishingEvent: false,
						workingEvent: {
							..._workingEvent,
							status: 1
						}
					};
				},
				start: (state) => ({ ...state, publishingEvent: true }),
				failure: (state) => {
					return ({ ...state, errorPublishingEvent: true });
				}
			});
		}

		case UPDATE_ALL_EVENT_SESSIONS_OVERLAY_CHAT: {
			if (!state.workingEvent) return state;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					sessions: state.workingEvent.sessions.map(session => ({
						...session,
						layout: {
							...session.layout,
							overlayChat: !!action.payload,
						}
					}))
				}
			};
		}

		case UPDATE_ALL_EVENT_SESSIONS_LAYOUT_TABS: {
			if (!state.workingEvent) return state;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					sessions: state.workingEvent.sessions.map(session => ({
						...session,
						layout: {
							...session.layout,
							tabs: action.payload,
						}
					}))
				}
			};
		}

		case UPDATE_WORKING_EVENT_SESSION: {
			if (!state.workingEvent) return state;
			const updatedSession: Session = action.payload;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					sessions: state.workingEvent.sessions.map(session => {
						if (session.uuid === updatedSession.uuid) return updatedSession;
						return session;
					})
				}
			};
		}

		case SET_EDITOR_SIZE: {
			return {
				...state,
				editorSize: action.payload
			};
		}

		case TOGGLE_PREVIEW_MODE: {
			return {
				...state,
				previewMode: action.payload
			};
		}

		case SET_SOCIAL_SHARING: {
			if (!state.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					social_sharing: action.payload
				}
			};
		}

		case GET_PUBLISHED_EVENT_URL: {
			return handle(state, action, {
				start: state => ({
					...state,
					fetchingPublishedStatus: true,
					errorFetchingPublishedStatus: false
				}),
				success: state => {
					if (action.payload?.status === Status.Published) {
						return {
							...state,
							publishedUrl: action.payload.url,
							errorFetchingPublishedStatus: false,
							publishStatusKnown: true
						};
					} else {
						return {
							...state,
							publishedUrl: '',
							errorFetchingPublishedStatus: false,
							publishStatusKnown: true
						};
					}
				},
				finish: state => ({
					...state,
					fetchingPublishedStatus: false,
				}),
				failure: state => ({
					...state,
					errorFetchingPublishedStatus: true,
					publishStatusKnown: false
				})
			});
		}

		case UPDATE_PUBLISHED_EVENT_URL: {
			return {
				...state,
				publishedUrl: action.payload
			};
		}

		case CLEAR_PUBLISHED_URL: {
			return {
				...state,
				publishedUrl: null
			};
		}

		case UPDATE_EVENT_FAVICON: {
			return handle(state, action, {
				success: state => {
					if (action.payload) {
						return {
							...state,
							workingEvent: state?.workingEvent ? {
								...state.workingEvent,
								favicon: action.payload.favicon,
							} : null,
							selectedEvent: state?.selectedEvent ? {
								...state.selectedEvent,
								favicon: action.payload.favicon,
							} : null,
						};
					}

					return state;
				},
			});
		}

		case DELETE_EVENT_FAVICON: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						workingEvent: state?.workingEvent ? {
							...state.workingEvent,
							favicon: null,
						} : null,
						selectedEvent: state?.selectedEvent ? {
							...state.selectedEvent,
							favicon: null,
						} : null,
					};
				},
			});
		}

		case UPDATE_CURRENT_POST_REGISTER_PAGE: {
			return {
				...state,
				currentPostRegisterPage: action.payload
			};
		}

		case UPDATE_EMAIL_COLOR: {
			if (!state?.workingEvent) { return state; }
			const editedWorkingEvent = state.workingEvent;
			editedWorkingEvent.settings.email = {
				...(state?.workingEvent?.settings?.email || {}),
				detachedEmailFromEventThemePack: true,
				colors: action.payload,
			};

			return {
				...state,
				workingEvent: editedWorkingEvent,
			};
		}

		case UPDATE_EMAIL_FONT: {
			if (!state?.workingEvent) { return state; }
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: {
						...state.workingEvent.settings,
						email: {
							...(state?.workingEvent?.settings?.email || {}),
							font: action.payload,
						},
					},
				},
			};
		}

		case TOGGLE_EMAIL_SETTINGS_HIDE_BRANDING: {
			if (!state?.workingEvent) { return state; }
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: {
						...state.workingEvent.settings,
						email: {
							...(state?.workingEvent?.settings?.email || {}),
							hideBrandliveLogo: action.payload
						},
					},
				},
			};
		}

		case UPDATE_EVENT_NAVBAR: {
			if (!state.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: {
						...state.workingEvent.settings,
						event_navbar: action.payload
					}
				}
			};
		}

		case TOGGLE_DISPLAY_SESSION_FOOTER: {
			if (!state.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: {
						...state.workingEvent.settings,
						display_session_footer: action.payload
					}
				}
			};
		}

		case TOGGLE_DISPLAY_SESSIONS: {
			if (!state.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: {
						...state.workingEvent.settings,
						display_sessions: action.payload
					}
				}
			};
		}

		case TOGGLE_DISPLAY_LANDING_PAGE: {
			if (!state.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: {
						...state.workingEvent.settings,
						display_landing_page: action.payload
					}
				}
			};
		}

		case TOGGLE_DISPLAY_HOMEPAGE: {
			if (!state.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: {
						...state.workingEvent.settings,
						display_homepage: action.payload
					}
				}
			};
		}

		case TOGGLE_DISPLAY_DIRECTORY: {
			if (!state?.workingEvent?.social_settings) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					social_settings: {
						...state.workingEvent.social_settings,
						directory: {
							...(state.workingEvent.social_settings?.directory || {}),
							isOn: action.payload
						}
					}
				}
			};
		}

		case TOGGLE_DISPLAY_PROFILES: {
			if (!state?.workingEvent?.social_settings) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					social_settings: {
						...state.workingEvent.social_settings,
						profiles: {
							...(state.workingEvent.social_settings?.profiles || {}),
							isOn: action.payload
						}
					}
				}
			};
		}

		case TOGGLE_MESSAGING: {
			if (!state?.workingEvent?.social_settings) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					social_settings: {
						...state.workingEvent.social_settings,
						messaging: action.payload
					}
				}
			};
		}

		case CREATE_EVENT_CHAT_CHANNEL: {
			if (!state?.workingEvent?.social_settings) return state;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					social_settings: {
						...state.workingEvent.social_settings,
						eventChatChannels: state.workingEvent.social_settings.eventChatChannels.concat(action.payload),
					}
				}
			};
		}

		case TOGGLE_TOPIC_GROUP_CHATS: {
			if (!state?.workingEvent?.social_settings) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					social_settings: {
						...state.workingEvent.social_settings,
						topicGroupChats: action.payload
					}
				}
			};
		}

		case TOGGLE_PROFILE_APPEARANCE: {
			if (!state?.workingEvent?.social_settings) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					social_settings: {
						...state.workingEvent.social_settings,
						profiles: {
							...(state.workingEvent.social_settings?.profiles || {}),
							displayType: action.payload
						}
					}
				}
			};
		}

		case TOGGLE_FILTERS: {
			if (!state?.workingEvent?.social_settings) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					social_settings: {
						...state.workingEvent.social_settings,
						directory: {
							...(state.workingEvent.social_settings?.directory || {}),
							allowFilters: action.payload
						}
					}
				}
			};
		}

		case SET_FILTERS: {
			if (!state?.workingEvent?.social_settings) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					social_settings: {
						...state.workingEvent.social_settings,
						directory: {
							...(state.workingEvent.social_settings?.directory || {}),
							filters: action.payload
						}
					}
				}
			};
		}

		case TOGGLE_BOOKMARK_ATTENDEES: {
			if (!state?.workingEvent?.social_settings) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					social_settings: {
						...state.workingEvent.social_settings,
						directory: {
							...(state.workingEvent.social_settings?.directory || {}),
							allowBookmarking: action.payload
						}
					}
				}
			};
		}

		case ADD_CUSTOM_PAGE: {
			if (!state.workingEvent) return state;
			const _workingEvent = { ...state.workingEvent };
			const page: CustomPage = action.payload;

			const custom_pages = _workingEvent.custom_pages
				? [..._workingEvent.custom_pages, page]
				: [page];

			return {
				...state,
				workingEvent: {
					..._workingEvent,
					custom_pages
				}
			};
		}
		case UPDATE_CUSTOM_PAGES: {
			if (!state.workingEvent) return state;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					custom_pages: action.payload
				}
			};
		}
		case UPDATE_CUSTOM_PAGE: {
			if (!state.workingEvent) return state;
			const updatedPage = action.payload;

			const customPages = state.workingEvent.custom_pages?.map(page => {
				if (page.uuid === updatedPage.uuid) return updatedPage;
				else return page;
			});
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					custom_pages: customPages
				}
			};
		}
		case DELETE_CUSTOM_PAGE: {
			return handle(state, action, {
				start: state => ({
					...state,
					errorDeletingCustomPage: false
				}),
				success: state => {
					if (!state.workingEvent || !action.payload) return state;

					const customPages = state.workingEvent.custom_pages?.map(page => {
						if (page.uuid === action.payload?.uuid) {
							const deletedpage = {
								...page,
								deleted: true,
							};
							return deletedpage;
						}
						else return page;
					});

					return {
						...state,
						workingEvent: {
							...state.workingEvent,
							custom_pages: customPages,
							errorDeletingCustomPage: false
						}
					};
				},
				failure: state => ({
					...state,
					errorDeletingCustomPage: true
				})
			});
		}
		case TOGGLE_CUSTOM_PAGE_MODULE: {
			if (!state.workingEvent) return state;
			const { pageId, moduleId, is_on } = action.payload;

			const customPages = state.workingEvent.custom_pages?.map(page => {
				if (page.custom_page !== pageId) return page;

				const updatedModules = page.modules?.map(module => {
					if (module.id !== moduleId) return module;
					return {
						...module,
						is_on
					};
				});

				return {
					...page,
					modules: updatedModules
				};
			});

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					custom_pages: customPages
				}
			};
		}
		case UPDATE_CUSTOM_PAGE_MODULE: {
			if (!state.workingEvent) return state;
			const { pageId, updatedModule } = action.payload;

			const customPages = state.workingEvent.custom_pages?.map(page => {
				if (page.custom_page !== pageId) return page;

				const updatedModules = page.modules?.map(module => {
					if (module.id !== updatedModule.id) return module;
					return updatedModule;
				});

				return {
					...page,
					modules: updatedModules
				};
			});

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					custom_pages: customPages
				}
			};
		}
		case SET_ADD_MODULE_MODAL_OPEN: {
			return {
				...state,
				addModuleModalOpen: action.payload
			};
		}
		case SET_SOCIAL_SETTINGS: {
			return handle(state, action, {
				success: state => {
					if (!action.payload) return state;

					return {
						...state,
						workingEvent: state.workingEvent ? {
							...state.workingEvent,
							social_settings: {
								...(state.workingEvent.social_settings || {}),
								...action.payload
							}
						} : null
					};
				}
			});
		}
		case SET_SOCIAL_IN_STORE_ONLY: {
			if (!state?.workingEvent?.social_settings) return state;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					social_settings: {
						...(state.workingEvent.social_settings || {}),
						...action.payload,
					},
				},
			};
		}

		case TOGGLE_RECAPTCHA: {
			if (!state.workingEvent?.registration_settings) return state;

			return ({
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_settings: {
						...state.workingEvent?.registration_settings,
						enableRecaptcha: action.payload,
					},
				},
			});
		}
		case TOGGLE_OVERVIEW_CONTENT: {
			if (!state.workingEvent?.registration_settings) return state;

			return ({
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_settings: {
						...state.workingEvent?.registration_settings,
						enableOverviewContent: action.payload,
					},
				},
			});
		}
		case TOGGLE_SPEAKERS: {
			if (!state.workingEvent?.registration_settings) return state;

			return ({
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_settings: {
						...state.workingEvent?.registration_settings,
						enableSpeakers: action.payload,
					},
				},
			});
		}
		case TOGGLE_PERSONALIZED_ATTENDEE_LIST: {
			if (!state?.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: {
						...state.workingEvent.settings,
						personalizedAttendeeList: action.payload
					}
				}
			};
		}
		case TOGGLE_SHOW_ADD_TO_CAL_BTN: {
			if (!state.workingEvent) return state;

			const newWorkingEvent = { ...state.workingEvent };
			(newWorkingEvent.settings as EventSettings).show_add_to_cal_btn = action.payload;

			return {
				...state,
				workingEvent: newWorkingEvent
			};
		}
		case TOGGLE_DISPLAY_SESSION_LANGUAGE_DROPDOWN: {
			if (!state.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: {
						...state.workingEvent.settings,
						display_session_language_dropdown: action.payload
					}
				}
			};
		}

		case TOGGLE_TRACK_MODAL: {
			if (!state.workingEvent) return state;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: {
						...state.workingEvent.settings,
						personalizedAttendeeListModal: action.payload
					}
				}
			};
		}
		case SET_VOD_ORDER: {
			if (!state?.workingEvent) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					settings: {
						...state.workingEvent.settings,
						vodOrder: action.payload
					}
				}
			};
		}
		case ADMIN_TOGGLE_IN_PERSON_ATTENDEE_MODE: {
			if (!state?.workingEvent?.registration_settings) return state;

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_settings: {
						...state.workingEvent.registration_settings,
						adminInPersonAttendeeModeEnabled: action.payload
					}
				}
			};

		}
		case TOGGLE_DISPLAY_LIVE_EVENT_BANNER: {
			if (!state?.workingEvent) return state;
			return {
				...state,
				displayLiveBanner: action.payload
			};
		}

		case TOGGLE_CLOSE_REGISTRATION: {
			if (!state.workingEvent?.registration_settings) return state;

			return ({
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_settings: {
						...state.workingEvent?.registration_settings,
						isClosed: action.payload,
					},
				},
			});
		}

		case GET_EVENT_SCHEDULED_EMAILS: {
			return handle(state, action, {
				start: state => {
					if (!state.workingEvent) return state;
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							loadingEmails: true
						}
					};
				},
				success: state => {
					if (!state.workingEvent) return state;
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							scheduledEmails: action.payload,
							loadingEmails: false
						}
					};
				},
				finish: state => {
					if (!state.workingEvent) return state;
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							loadingEmails: false
						}
					};
				}
			});
		}

		case GET_CHANNEL_SCHEDULED_EMAILS: {
			return handle(state, action, {
				start: state => ({
					...state,
					errorFetchingChannelScheduledEmails: false,
					fetchingChannelScheduledEmails: true,
				}),
				success: state => ({
					...state,
					channelScheduledEmails: action.payload,
				}),
				failure: state => ({
					...state,
					errorFetchingChannelScheduledEmails: true,
				}),
				finish: state => ({
					...state,
					fetchingChannelScheduledEmails: false,
				}),
			});
		}

		case CLEAR_CHANNEL_SCHEDULED_EMAILS: {
			return {
				...state,
				errorFetchingChannelScheduledEmails: false,
				fetchingChannelScheduledEmails: false,
				channelScheduledEmails: [],
			};
		}

		case CREATE_SCHEDULED_EMAIL: {
			return handle(state, action, {
				start: state => {
					if (!state.workingEvent) return state;
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							loadingEmails: true
						}
					};
				},
				success: state => {
					if (!state.workingEvent || !action.payload) return state;
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							scheduledEmails: [...(state.scheduledEmails?.scheduledEmails ?? []), action.payload],
							displayEmail: action.payload.uuid,
							loadingEmails: false
						}
					};
				},
				finish: state => {
					if (!state.workingEvent) return state;
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							loadingEmails: false
						}
					};
				}
			});
		}

		case EDIT_SCHEDULED_EMAIL: {
			return handle(state, action, {
				start: state => {
					if (!state.workingEvent) return state;
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							loadingEmails: true
						}
					};
				},
				success: state => {
					if (!state.workingEvent || !action.payload) return state;
					const updatedEmailList = state.scheduledEmails?.scheduledEmails?.map(email => {
						if (email.uuid === (action.payload as IScheduledEmail)?.uuid) return action.payload as IScheduledEmail;
						return email;
					});
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							scheduledEmails: updatedEmailList,
							loadingEmails: false
						}
					};
				},
				finish: state => {
					if (!state.workingEvent) return state;
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							loadingEmails: false
						}
					};
				},
				failure: (state, action) => {
					const errorObj = action.payload as { error?: string | IScheduledEmail; } | undefined;
					let err = 'Error updating scheduled email';

					if (typeof errorObj?.error === 'string') {
						err = errorObj.error;
					}

					if (errorObj && isObject(errorObj?.error) && (errorObj.error as IScheduledEmail)?.uuid) {
						const scheduledEmail = errorObj.error;
						const scheduledEmailDate = new Date(scheduledEmail.delivery_date);
						const date = scheduledEmailDate.toLocaleString(navigator.language, { month: 'long', day: '2-digit', year: 'numeric' });
						const time = scheduledEmailDate.toLocaleTimeString(navigator.language, { hour12: true, hour: 'numeric', minute: 'numeric' });
						const emailTypeDisplayName = EmailTypeDisplayNames[scheduledEmail.email_type] ?? scheduledEmail.email_type;
						const errorMsg = `Error updating scheduled email: ${emailTypeDisplayName.toUpperCase()}.\nDelivery date: ${date} ${time}`;
						err = errorMsg;
					}

					return {
						...state,
						scheduledEmailUpdateFailure: err,
					};
				},
			});
		}

		case CLEAR_SCHEDULED_EMAIL_UPDATE_FAILURE: {
			return {
				...state,
				scheduledEmailUpdateFailure: null,
			};
		}

		case EDIT_SCHEDULED_EMAIL_SYNC: {
			if (!state.workingEvent) return state;

			const updatedEmailList = state.scheduledEmails?.scheduledEmails?.map(email => {
				if (email.uuid === action.payload?.uuid) return action.payload;
				return email;
			});

			return {
				...state,
				scheduledEmails: {
					...state.scheduledEmails,
					scheduledEmails: updatedEmailList,
					loadingEmails: false
				}
			};
		}

		case EDIT_SCHEDULED_EMAIL_IN_ADMIN: {
			if (!state.scheduledEmails?.scheduledEmails) return state;
			const editedEmail: IScheduledEmail = action.payload;
			const updatedScheduledEmails = state.scheduledEmails.scheduledEmails.map(email => {
				if (email.uuid === editedEmail.uuid) {
					return editedEmail;
				}
				return email;
			});
			return {
				...state,
				scheduledEmails: {
					...state.scheduledEmails,
					scheduledEmails: updatedScheduledEmails,
				}
			};
		}

		case DELETE_SCHEDULED_EMAIL: {
			return handle(state, action, {
				start: state => {
					if (!state.workingEvent) return state;
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							loadingEmails: true
						}
					};
				},
				success: state => {
					if (!state.workingEvent || !action.payload) return state;
					const updatedEmailList = state.scheduledEmails?.scheduledEmails?.filter(schedule => schedule.uuid !== action.payload?.uuid);
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							scheduledEmails: updatedEmailList,
							loadingEmails: false,
							displayEmail: undefined
						}
					};
				},
				finish: state => {
					if (!state.workingEvent) return state;
					return {
						...state,
						scheduledEmails: {
							...state.scheduledEmails,
							loadingEmails: false
						}
					};
				}
			});
		}

		case SET_DISPLAY_SCHEDULED_EMAIL: {
			if (!state) return state;
			return {
				...state,
				scheduledEmails: {
					...state.scheduledEmails,
					displayEmail: action.payload,
				}
			};
		}

		case CLEAR_SCHEDULED_EMAILS: {
			if (!state) return state;
			return {
				...state,
				scheduledEmails: null
			};
		}

		// Need to move to v3 when enabling
		case TOGGLE_TRANSLATIONS_V2: {
			if (!state.workingEvent) {
				return state;
			} else {
				return {
					...state,
					workingEvent: {
						...state.workingEvent,
						settings: {
							...state.workingEvent.settings,
							translation_version: action.payload ? 2 : 1,
							i18n: state.workingEvent.settings?.i18n ?? { base: 'en' }
						}
					}
				};
			}
		}

		// once V3 is on, it's always on forever
		case TOGGLE_TRANSLATIONS_V3: {
			if (!state.workingEvent) {
				return state;
			} else {
				// if toggling off v2 translations, we need to reset to english for the base language
				const shouldResetEventBaseLanguage = !action.payload;
				return {
					...state,
					workingEvent: {
						...state.workingEvent,
						settings: {
							...state.workingEvent.settings,
							translation_version: 3,
							i18n: shouldResetEventBaseLanguage ? { base: 'en' } : state.workingEvent.settings?.i18n
						}
					}
				};
			}
		}

		case SET_TRANSLATION_MANIFESTS: {
			if (!state.workingEvent) {
				return state;
			} else {
				return {
					...state,
					workingEvent: {
						...state.workingEvent,
						translation_manifests: action.payload
					}
				};
			}
		}

		case SET_TRANSLATIONS_UPDATING: {
			const { req: { isUpdating, admin, eventUuid }, currentUserId, forceEndUpdating } = action.payload;

			if (forceEndUpdating) {
				return {
					...state,
					adminUpdatingTranslations: null
				};
			}

			if ((eventUuid !== state.workingEvent?.uuid) || (admin?.profile_id === currentUserId)) {
				return state;
			}

			return {
				...state,
				adminUpdatingTranslations: isUpdating ? `${admin?.first_name || ''} ${admin?.last_name || ''}` : null
			};
		}

		case ADD_EVENT_TEMPLATE: {
			return { ...state, templates: [...(state.templates ?? []), action.payload] };
		}

		case UPDATE_EVENT_TEMPLATE: {
			return {
				...state,
				templates: (state.templates ?? [])?.map(template => {
					if (template.template === action.payload.template) {
						return action.payload;
					}
					return template;
				})
			};
		}

		case SET_EVENT_TEMPLATE: {
			if (!state.newEventRequest) {
				return state;
			}
			return {
				...state,
				newEventRequest: {
					...state.newEventRequest,
					template: action.payload
				}
			};
		}

		case REMOVE_CUSTOM_THEMES: {
			const templatesToDelete = action.payload as number[];
			return {
				...state,
				templates: (state.templates ?? [])?.filter(template => {
					if (templatesToDelete.includes(template.template)) {
						return false;
					}
					return true;
				})
			};
		}
		case REMOVE_CUSTOM_TEMPLATES: {
			const templatesToDelete = action.payload as string[];
			const templates = (state.templateEvents.templateEvents ?? [])?.filter(template => {
				if (templatesToDelete.includes(template.uuid)) {
					return false;
				}
				return true;
			});

			return {
				...state,
				templateEvents: {
					...state.templateEvents,
					templateEvents: templates,
					templateEventsIsUpdating: true,
				}
			};
		}

		case REMOVE_CUSTOM_TEMPLATES_IS_UPDATING: {
			return {
				...state,
				templateEvents: {
					...state.templateEvents,
					templateEventsIsUpdating: action.payload,
				}
			};
		}

		case SETUP_EVENT_PREVIEW: {
			return handle(state, action, {
				start: state => ({
					...state,
					settingUpEventPreview: true
				}),
				success: state => {
					if (!state.workingEvent) {
						return state;
					}

					return {
						...state,
						settingUpEventPreview: false,
						workingEvent: {
							...state.workingEvent,
							event_preview: action.payload || null
						}
					};
				}
			});
		}

		case UPDATE_EVENT_PREVIEW_PASSWORD: {
			return handle(state, action, {
				start: state => ({
					...state,
					updatingEventPreviewPassword: true
				}),
				success: state => {
					if (!state.workingEvent?.event_preview || !action.payload) {
						return state;
					}

					return {
						...state,
						updatingEventPreviewPassword: false,
						workingEvent: {
							...state.workingEvent,
							event_preview: {
								...state.workingEvent.event_preview,
								password: action.payload.password
							}
						}
					};
				}
			});
		}

		case UPDATE_SESSION_FEEDBACK_SURVEY_TEXT: {
			if (!state.workingEvent) return state;
			return handle(state, action, {
				success: state => {
					if (!state.workingEvent) return state;
					return {
						...state,
						workingEvent: {
							...state.workingEvent,
							settings: {
								...state.workingEvent?.settings,
								feedback_survey_text: action.payload
							}
						}
					};
				}
			});
		}

		case PUBLISH_EVENT_PREVIEW: {
			return handle(state, action, {
				start: state => ({
					...state,
					publishingEventPreview: true
				}),
				success: state => {
					return {
						...state,
						publishingEventPreview: false,
					};
				}
			});
		}

		case SET_EVENT_TAGS: {
			if (!state.workingEvent) return state;

			const tags = action.payload as IEventTag[];
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					tags,
				},
			};
		}

		case TOGGLE_AUTOMATIC_SESSION_FILTER_BY_USER_LANGUAGE: {
			if (!state.workingEvent) {
				return state;
			} else {
				return {
					...state,
					workingEvent: {
						...state.workingEvent,
						automatic_session_filter_by_user_language: action.payload
					}
				};
			}
		}

		case UPDATE_CHANNEL_ICON: {
			if (!state.workingEvent) return state;
			else {
				return {
					...state,
					channelIcons: action.payload
				};
			}
		}

		case SET_EMAIL_LINK_LIMIT: {
			if (!state.workingEvent?.registration_settings) return state;

			return ({
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_settings: {
						...state.workingEvent?.registration_settings,
						emailLinkLimit: action.payload,
					},
				},
			});
		}

		case UPDATE_SESSION_CUSTOM_URL: {
			if (!state.selectedEvent) {
				return state;
			} else {
				const { sessionUuid, enabled, url } = action.payload;
				const newSessions = [...state.selectedEvent.sessions];

				newSessions.forEach((session: Session) => {
					if (session.uuid === sessionUuid) {
						session.custom_url_enabled = enabled;
						session.custom_url = url;
					}
				});

				return {
					...state,
					selectedEvent: {
						...state.selectedEvent,
						sessions: newSessions
					}
				};
			}
		}

		case SET_ACTIVE_ACCORDION_CONTAINER: {
			return {
				...state,
				accordion: {
					...state.accordion,
					focusedContainer: action.payload || undefined,
				}
			};
		}

		case ADD_LEADERBOARD_NAV_ITEM: {
			return handle(state, action, {
				success: state => {
					if (!state?.workingEvent?.homepage?.event_main_nav_items) return state;
					if (!action.payload) return state;
					return {
						...state,
						workingEvent: {
							...state.workingEvent,
							homepage: {
								...state.workingEvent.homepage,
								event_main_nav_items: [
									...state.workingEvent.homepage.event_main_nav_items,
									action.payload
								],
							}
						}
					};
				},
			});
		}

		case SET_MARKETING_OPT_IN_TYPE: {
			if (!state.workingEvent?.registration_settings) return state;

			return ({
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_settings: {
						...state.workingEvent.registration_settings,
						marketing_email_options: action.payload,
					},
				},
			});
		}

		case GET_TEMPLATE_EVENTS: {
			return handle(state, action, {
				start: state => ({
					...state,
					templateEvents: {
						...state.templateEvents,
						loadingStatus: LoadingStatus.Loading,
						error: null,
					}
				}),
				success: state => {
					if (!action.payload) return state;

					return ({
						...state,
						templateEvents: {
							...state.templateEvents,
							templateEvents: action.payload.events,
							totalTemplates: action.payload?.totals?.total_template_events || 0,
							error: null,
						}
					});
				},
				failure: state => {
					return {
						...state,
						templateEvents: {
							...state.templateEvents,
							error: action.payload?.error,
						}
					};
				},
				finish: state => ({
					...state,
					templateEvents: {
						...state.templateEvents,
						loadingStatus: LoadingStatus.Done,
					}
				}),
			});
		}

		case CREATE_TEMPLATE_EVENT: {
			return handle(state, action, {
				start: state => ({
					...state,
					templateEvents: {
						...state.templateEvents,
						loading: true,
						error: null,
					}
				}),
				success: state => {
					if (!action.payload) return state;

					// if template is already there, remove to replace with updated
					const filteredTemplateEvents = state.templateEvents.templateEvents.filter(template => template.uuid !== action.payload?.uuid);
					return {
						...state,
						templateEvents: {
							...state.templateEvents,
							templateEvents: [...filteredTemplateEvents, action.payload]
						}
					};
				},
				failure: state => ({
					...state,
					templateEvents: {
						...state.templateEvents,
						error: action.payload?.message
					}
				}),
				finish: state => ({
					...state,
					templateEvents: {
						...state.templateEvents,
						loading: false
					}
				}),
			});
		}

		case CLEAR_TEMPLATE_EVENTS_ERROR: {
			return {
				...state,
				templateEvents: {
					...state.templateEvents,
					error: null,
				}
			};
		}

		case RESET_TEMPLATE_EVENTS: {
			return {
				...state,
				templateEvents: initialState.templateEvents,
			};
		}

		case RESET_FETCH_EVENT_LOADING_STATUS: {
			return {
				...state,
				templateEvents: {
					...state.templateEvents,
					loadingStatus: LoadingStatus.PreLoading,
				}
			};
		}

		case SET_LIVE_SESSION_IDS: {
			const _liveSessionIds = { ...state.liveSessionIds };
			if (action.payload?.sessionUUID) {
				if (action.payload.isLive) {
					_liveSessionIds[action.payload.sessionUUID] = action.payload.isLive;
				} else {
					delete _liveSessionIds[action.payload.sessionUUID];
				}
			}
			return {
				...state,
				liveSessionIds: _liveSessionIds,
			};
		}

		case UPDATE_SESSION_TITLE_AND_TRACKS: {
			return handle(state, action, {
				start: state => ({
					...state,
				}),
				success: state => {
					if (!state.workingEvent?.sessions) return state;
					return {
						...state,
						workingSession: !state.workingSession ? state.workingSession : {
							...state.workingSession,
							tracks: action.payload?.[state.workingSession.uuid].tracks || [],
						},
						workingEvent: {
							...state.workingEvent,
							sessions: state.workingEvent?.sessions?.map(session => {
								if (action.payload?.[session.uuid]) {
									return {
										...session,
										tracks: action.payload[session.uuid].tracks,
									};
								}
								return session;
							}),
						},
					};
				},
				finish: state => ({
					...state,
				}),
			});
		}

		case EDIT_CHANNEL_TRACKS: {
			return handle(state, action, {
				success: state => {
					if (!state.workingEvent?.sessions || !action.payload) return state;

					return {
						...state,
						workingEvent: {
							...state.workingEvent,
							sessions: state.workingEvent?.sessions?.map(session => {
								return {
									...session,
									tracks: session.tracks ? session.tracks?.map(track => action.payload?.[track] ?? track) : null
								};
							})
						}
					};
				}
			});
		}

		case SET_EVENT_TYPE: {
			if (state.newEventRequest) {
				return {
					...state,
					newEventRequest: { ...state.newEventRequest, event_type: action.payload }
				};
			} else {
				return state;
			}
		}

		case SET_EVENT_IMAGE: {
			if (state.newEventRequest) {
				return {
					...state,
					newEventRequest: { ...state.newEventRequest, event_image: action.payload }
				};
			} else {
				return state;
			}
		}

		case UPDATE_EVENT_TYPE: {
			return handle(state, action, {
				success: state => {
					if (!state.workingEvent) return state;

					return {
						...state,
						workingEvent: {
							...state.workingEvent,
							eventType: action.payload
						},
					};
				}
			});
		}

		case UPDATE_EVENT_PUBLISH_SETTINGS: {
			return handle(state, action, {
				success: state => {
					if (!state.workingEvent || !action.payload) return state;

					return {
						...state,
						workingEvent: {
							...state.workingEvent,
							is_publish_locked: action.payload.is_publish_locked,
							dma_id: action.payload.dma_id
						}
					};
				}
			});
		}

		case UPDATE_SELECTED_EVENT_SETTINGS: {
			if (!state.selectedEvent) return state;

			return {
				...state,
				selectedEvent: {
					...state.selectedEvent,
					settings: action.payload
				}
			};
		}

		case SET_A_DROPDOWN_IS_OPEN: {
			return {
				...state,
				aDropdownIsOpen: action.payload
			};
		}

		case TOGGLE_A_DROPDOWN_IS_OPEN: {
			return {
				...state,
				aDropdownIsOpen: !state.aDropdownIsOpen
			};
		}

		case UPDATE_NATIVE_REGISTRATION: {
			if (!state.workingEvent?.registration_settings || !action.payload) return state;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					registration_settings: {
						...state.workingEvent?.registration_settings,
						externalRegistration: action.payload
					}
				}
			};
		}

		case LOCK_EVENT_SAVE: {
			return {
				...state,
				saveLocked: true
			};
		}

		case RELEASE_EVENT_SAVE_LOCK: {
			return {
				...state,
				saveLocked: false
			};
		}

		case SESSION_LANGUAGES_UPDATED: {
			if (!state.workingEvent) return state;

			// when adding/removing languages from the session editor, we need to update the visible state
			// for the rest of the event for when the user clicks back to the home/landing/registration pages
			// sends nothing of any savable value to the server, just ensures that the UI matches the languages on the sessions
			const [sessionId, langs] = action.payload;
			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					homepage: state.workingEvent.homepage ? {
						...state.workingEvent.homepage,
						languages: Array.from(new Set([...state.workingEvent.sessions.flatMap(session => {
							if (session.session === sessionId) {
								return langs;
							}

							return session.languages;
						})]))
					} : null
				}
			};
		}
		case GET_RSVP_EVENT_LINK: {
			return handle(state, action, {
				success: state => {
					return {
						...state,
						rsvpLinkSync: action.payload?.rsvpLink ?? null,
					};
				}
			});
		}
		case APPROVE_RSVP_EVENT_LINK: {
			return handle(state, action, {
				success: state => {
					if (!state.workingEvent?.registration_settings) {
						console.error("Tried modifying registration settings that don't exist.");
						return state;
					}
					return {
						...state,
						rsvpLinkSync: action.payload ?? null,
						workingEvent: {
							...state.workingEvent,
							registration_settings: {
								...state.workingEvent.registration_settings,
								sendEmailConfirmation: false,
								registrationConfirmation: false
							}
						}
					};
				}
			});
		}

		case SET_ADMIN_HEADER_HEIGHT: {
			return {
				...state,
				adminHeaderHeight: action.payload,
			};
		}

		case SET_ADMIN_SUB_PANEL_HEADER_HEIGHT: {
			return {
				...state,
				adminSubPanelHeaderHeight: action.payload,
			};
		}

		case SET_EDITING_NAVBAR: {
			return {
				...state,
				editingNavbar: action.payload
			};
		}

		case UPDATE_WORKING_THEME_PACK: {
			return handle(state, action, {
				start: state => {
					return {
						...state,
						themePackUpdateInProgress: true,
					};
				},
				success: (state, action) => {
					if (!state.workingEvent) return state;
					if (!action.payload) return state;

					const newThemePack = action.payload.themePack;

					const updatedColors = newThemePack || { ...state.workingEvent?.settings?.design?.colors };

					let newMode: EPaletteModes | undefined | null = action.payload.mode || state.workingEvent.settings.design.color_theme;
					if (action.payload.mode === null) {
						newMode = null;
					}
					const newFontPack = action.payload.themePack?.font_pack
						|| state.workingEvent.settings.design.font;
					const newCustom = action.payload.themePack?.custom_styles
						|| state.workingEvent.settings.design.colors.custom_styles
						|| state.workingEvent?.settings?.design?.custom;

					updatedColors.font_pack = newFontPack;
					updatedColors.custom_styles = newCustom;

					const updatedDesign = {
						...state.workingEvent.settings.design,
						colors: updatedColors,
						// if newMode is null, we want to remove the color_theme key becaue 
						// we want to preserve the events original status. Setting a mode will force
						// the sessions to follow the event's color theme, which only happens when a user
						// explicitly toggles the project mode
						...(newMode ? { color_theme: newMode } : {}),
						// font and custom are legacy/backwards compat
						font: newFontPack,
						custom: newCustom,
					};

					if (newMode === null) {
						delete updatedDesign.color_theme; // remove it in case it pre-exists
					}

					return {
						...state,
						themePackUpdateInProgress: false,
						workingEvent: {
							...state.workingEvent,
							settings: {
								...state.workingEvent.settings,
								design: updatedDesign,
							},
						},
					};
				},
				finish: (state, action) => {
					const _action = action as unknown;
					if (isObject(_action) && isFunction((_action)?.meta?.updateComplete)) {
						_action.meta.updateComplete();
					}
					return {
						...state,
						themePackUpdateInProgress: false,
					};
				},
			});
		}

		case INC_CHECK_THEME_PACK_PROGRESS_COUNT: {
			return {
				...state,
				checkPackProgressCounter: action.payload === 0 ? 0 : state.checkPackProgressCounter + 1,
			};
		}

		case DISABLE_PUBLISH_BUTTON: {
			return {
				...state,
				disablePublishButton: !!action.payload,
			};
		}

		case SHOW_EDITOR_LOADER: {
			return {
				...state,
				showEditorLoader: !!action.payload,
			};
		}

		case UPDATE_REGISTRATION_LIMIT: {
			if (state.workingEvent?.registration_settings) {
				return ({
					...state,
					workingEvent: {
						...state.workingEvent as BrandliveEvent,
						registration_settings: {
							...state.workingEvent?.registration_settings,
							registration_limit: action.payload.limit || null,
							registration_limit_is_on: action.payload.isOn
						}
					}
				});
			} else {
				return state;
			}
		}

		case SET_TRANASLATION_LOCK: {
			return {
				...state,
				translationLocked: action.payload
			};
		}

		case SET_SESSION_PLACEHOLDER_VIDEO: {
			if (!state.workingEvent) return state;
			const payload = {
				...action.payload,
				playback_url: action.payload.playback_url?.replace('stream.mux.com', process.env.REACT_APP_MUX_STREAMING_CUSTOM_DOMAIN || '')
			};
			const placeholderEndpoints = { ...(state.workingEvent.placeholderEndpoints ?? {}) };
			const sessionVideos = sessionVideosWithCleanPlayback(state.workingEvent.sessionVideos ?? []);
			const workingEvent = { ...state.workingEvent };
			const placeholderExists = sessionVideos.some(video => {
				return (
					video.session_uuid === payload.session_uuid &&
					video.language === payload.language &&
					video.type === 'placeholder_video'
				);
			});

			if (placeholderExists) {
				workingEvent.sessionVideos = sessionVideos.map(video => {
					if (
						video.session_uuid === payload.session_uuid &&
						video.language === payload.language &&
						video.type === 'placeholder_video'
					) {
						return payload;
					}
					return video;
				});
			} else {
				workingEvent.sessionVideos = [...sessionVideos, payload];
			}

			placeholderEndpoints[`${payload.session_uuid}_${payload.language}`] = payload.playback_url;

			return {
				...state,
				workingEvent
			};
		}

		case CLEAR_PLACEHOLDER_VIDEO: {
			const sessionUuid = action.payload.sessionUuid;
			const language = action.payload.language;
			if (!state?.workingEvent) return state;

			const filteredSessionVideos = sessionVideosWithCleanPlayback(state.workingEvent.sessionVideos ?? []).filter(video => {
				return !(
					video.session_uuid === sessionUuid
					&& [language, 'all'].includes(video.language)
					&& video.type === 'placeholder_video'
				);
			});

			return {
				...state,
				workingEvent: {
					...state.workingEvent,
					sessionVideos: filteredSessionVideos,
				},
			};
		}

		case ADMIN_ADD_SESSION_VIDEOS_REPLAY: {
			if (!state.workingEvent) return state;
			const payload = {
				...action.payload,
				playback_url: action.payload.playback_url?.replace('stream.mux.com', process.env.REACT_APP_MUX_STREAMING_CUSTOM_DOMAIN || '')
			};
			const replayEndpoints = { ...(state.workingEvent.secondaryVideos ?? {}) };
			const sessionVideos = sessionVideosWithCleanPlayback(state.workingEvent.sessionVideos ?? []);
			const workingEvent = { ...state.workingEvent };
			const replayExists = sessionVideos.some(video => {
				return (
					video.session_uuid === payload.session_uuid &&
					video.language === payload.language &&
					video.type === 'replay'
				);
			});

			if (replayExists) {
				workingEvent.sessionVideos = sessionVideos.map(video => {
					if (
						video.session_uuid === payload.session_uuid &&
						video.language === payload.language &&
						video.type === 'replay'
					) {
						return payload;
					}
					return video;
				});
			} else {
				workingEvent.sessionVideos = [...sessionVideos, payload];
			}

			replayEndpoints[`${payload.session_uuid}_${payload.language}`] = payload.playback_url;
			workingEvent.secondaryVideos = replayEndpoints;

			return {
				...state,
				workingEvent
			};
		}

		case ADMIN_ADD_SESSION_VIDEOS_LIVE: {
			if (!state.workingEvent) return state;
			const payload = {
				...action.payload,
				playback_url: action.payload.playback_url?.replace('stream.mux.com', process.env.REACT_APP_MUX_STREAMING_CUSTOM_DOMAIN || '')
			};
			const playbackUrls = { ...(state.workingEvent.playbackUrls ?? {}) };
			const sessionVideos = sessionVideosWithCleanPlayback(state.workingEvent.sessionVideos ?? []);
			const workingEvent = { ...state.workingEvent };
			const liveVideoExists = sessionVideos.some(video => {
				return (
					video.session_uuid === payload.session_uuid &&
					video.language === payload.language &&
					video.type === 'live_stream'
				);
			});

			if (liveVideoExists) {
				workingEvent.sessionVideos = sessionVideos.map(video => {
					if (
						video.session_uuid === payload.session_uuid &&
						video.language === payload.language &&
						video.type === 'live_stream'
					) {
						return payload;
					}
					return video;
				});
			} else {
				workingEvent.sessionVideos = [...sessionVideos, payload];
			}

			playbackUrls[`${payload.session_uuid}_${payload.language}`] = payload.playback_url;
			workingEvent.playbackUrls = playbackUrls;
			return {
				...state,
				workingEvent
			};
		}

		case ADMIN_DELETE_SESSION_VIDEOS_LIVE: {
			if (!state.workingEvent) return state;

			const playbackUrls = { ...(state.workingEvent.playbackUrls ?? {}) };
			const sessionVideos = sessionVideosWithCleanPlayback(state.workingEvent.sessionVideos ?? []).filter(video => {
				if (
					video.session_uuid === action.payload.sessionUuid &&
					video.language === action.payload.language &&
					video.type === 'live_stream') {
					return false;
				}

				return true;
			});

			delete playbackUrls[`${action.payload.sessionUuid}_${action.payload.language}`];

			const workingEvent = {
				...state.workingEvent,
				sessionVideos,
				playbackUrls
			};

			return {
				...state,
				workingEvent
			};
		}

		case SHOULD_CREATE_CHANNEL_PRODUCT_TOGGLE: {
			return {
				...state,
				shouldCreateChannelProduct: action.payload
			};
		}

		default: return state;
	}
}

const SAVING_ACTION = [
	ADD_REGISTRATION_REQUIREMENT,
	ADD_NEW_SESSION,
	UPDATE_SESSION_INFO,
	UPDATE_SESSION_CARD,
	SET_REGISTRATION_HEADER,
	SET_REGISTRATION_DESCRIPTION,
	SET_REGISTRATION_IMAGE,
	SET_REGISTRATION_LOGO,
	SET_REGISTRATION_ON,
	SET_REGISTRATION_TYPE,
	SET_REGISTRATION_FEATURE,
	SET_REGISTRATION_SINGLE_SIGN_ON,
	SET_REGISTRATION_SINGLE_SIGN_ON_TYPES,
	SET_REGISTRATION_SSO_DISPLAY_TOP,
	SET_REGISTRATION_QUESTIONS,
	UPDATE_REGISTRATION_STEPS,
	SET_SELECTED_FONT,
	SET_SELECTED_COLOR,
	SET_DESIGN_SHAPE,
	SET_DESIGN_STYLE,
	SET_CUSTOM_CSS,
	SET_DESIGN_COLORS,
	UPDATE_HOMEPAGE_MODULE,
	UPDATE_HOMEPAGE_FOOTER,
	UPDATE_HOMEPAGE_HEADER,
	UPDATE_HOMEPAGE_MAIN_NAV,
	UPDATE_HOMEPAGE_PROFILE_NAV,
	UPDATE_NAVBAR_TYPE,
	SET_HOMEPAGE_MODULES,
	ADD_PAGE_MODULE,
	TOGGLE_AUTOMATIC_SESSION_FILTER_BY_USER_LANGUAGE,
	TOGGLE_POST_REGISTER_HOME_MODULE,
	SET_BLOCKED_DOMAIN_EMAILS,
	REMOVE_REGISTRATION_REQUIREMENT,
	UPDATE_REGISTRATION_REQUIREMENT,
	UPDATE_TICKETING_SET,
	ADD_TICKETING_SET,
	REMOVE_TICKETING_SET,
	SET_EVENT_CUSTOM_CSS,
	SET_EVENT_CUSTOM_COMPILED_CSS,
	UPDATE_REQUIRED_REGISTRATION_QUESTIONS,
	SET_SOCIAL_SHARING,
	UPDATE_EMAIL_COLOR,
	UPDATE_EMAIL_FONT,
	TOGGLE_EMAIL_SETTINGS_HIDE_BRANDING,
	SAVE_COLOR_PACK,
	UPDATE_EVENT_NAVBAR,
	TOGGLE_DISPLAY_SESSION_FOOTER,
	TOGGLE_DISPLAY_SESSIONS,
	TOGGLE_DISPLAY_LANDING_PAGE,
	ADD_CUSTOM_PAGE,
	UPDATE_CUSTOM_PAGES,
	UPDATE_CUSTOM_PAGE,
	DELETE_CUSTOM_PAGE,
	TOGGLE_DISPLAY_DIRECTORY,
	TOGGLE_DISPLAY_PROFILES,
	TOGGLE_MESSAGING,
	TOGGLE_BOOKMARK_ATTENDEES,
	TOGGLE_PROFILE_APPEARANCE,
	TOGGLE_FILTERS,
	TOGGLE_TOPIC_GROUP_CHATS,
	SET_FILTERS,
	SET_CUSTOM_PASSCODE_LABEL,
	SET_REJECTED_PASSCODE_MESSAGE,
	CREATE_EVENT_CHAT_CHANNEL,
	TOGGLE_RECAPTCHA,
	TOGGLE_SPEAKERS,
	TOGGLE_OVERVIEW_CONTENT,
	TOGGLE_PERSONALIZED_ATTENDEE_LIST,
	TOGGLE_SHOW_ADD_TO_CAL_BTN,
	TOGGLE_TRACK_MODAL,
	SET_VOD_ORDER,
	TOGGLE_CLOSE_REGISTRATION,
	ADMIN_TOGGLE_IN_PERSON_ATTENDEE_MODE,
	TOGGLE_TRANSLATIONS_V2,
	TOGGLE_TRANSLATIONS_V3,
	SET_EVENT_TAGS,
	TOGGLE_DISPLAY_SESSION_LANGUAGE_DROPDOWN,
	SET_EMAIL_LINK_LIMIT,
	UPDATE_SESSION_CUSTOM_URL,
	SET_MARKETING_OPT_IN_TYPE,
	UPDATE_NATIVE_REGISTRATION,
	TOGGLE_DISPLAY_HOMEPAGE,
	EDIT_CHANNEL_TRACKS,
	UPDATE_REGISTRATION_LIMIT,
	TOGGLE_PASSWORD_GATING
];

const REFETCHING_ACTION = [
	ADD_NEW_SESSION,
	UPDATE_SESSION_INFO
];

export default function CreateEventReducer(state: CreateEventState = initialState, action: CreateEventActions): CreateEventState {
	const newState = getState(state, action);

	if (SAVING_ACTION.includes(action.type)) {
		// clearTimeout must go inside the if statement, otherwise it can be accidentally cancelled
		clearTimeout(timeout);
		timeout = setTimeout(async () => {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const store = require('../../main');

			if (store.default) {
				const {
					CreateEventReducer: {
						workingEvent,
						loadingEvent,
						saveLocked
					},
					AuthReducer: {
						token
					}
				} = store.default.getState();

				if (saveLocked) {
					console.warn(`Cannot save event while it is locked. Action: ${action.type}`);
					return;
				}

				// if a working event exists but we aren't currently reloading it
				if (workingEvent && !loadingEvent) {
					store.default.dispatch(isSavingEvent(true));

					const _workingEvent: BrandliveEvent = workingEvent;
					_workingEvent.status = 2;
					const result = await SaveEvent(_workingEvent, token);

					// this fixes issue where homepage translations do not update in redux when a new item is added into a modules
					if (result?.homepage?.modules && _workingEvent?.homepage?.modules) {
						_workingEvent.homepage.modules = result.homepage.modules;
						store.default.dispatch(resetHomepageModules(result.homepage.modules));
					}

					// update events reducer so published status gets updated
					store.default.dispatch(updateAdminEvents(_workingEvent));

					if (REFETCHING_ACTION.includes(action.type)) {
						store.default.dispatch(loadWorkingEvent(_workingEvent.uuid, token));
					}
					store.default.dispatch(isSavingEvent(false));
				}
			}
		}, 1000);
	}

	return newState;
}
