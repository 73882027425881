import { TranslateString } from "./working-model";

type ISODateString = `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`;

export enum CourseStatus {
	active = 'active',
	inactive = 'inactive',
	deleted = 'deleted'
}

export enum CourseRequirementType {
	watched_minute = 'watched_minute',
	pulse_check = 'pulse_check'
}

export interface ICoursePrototype {
	id: number;
	uuid: string;
	channel: number;
	settings: Record<string, unknown>;
	status: CourseStatus;
	created_by: number;
	created_at: Date;
	modified_by?: number | null; // null in the DB but can be treated as undefined
	modified_at?: Date | null;
}

export interface ICreateCoursePrototype {
	channel: number;
	created_by: number;
	status?: CourseStatus;
	settings?: Record<string, unknown>;
}

export interface ICreateCourse extends ICreateCoursePrototype {
	event_id: number;
	credits: number;
	name: TranslateString;
	categories?: string[];
	description?: string;
}

export interface ICourse extends ICoursePrototype {
	event_id: number;
	credits: number;
	name: TranslateString;
	categories: string[];
	description?: string;
	requirements?: ICourseRequirement[];
	certificate?: ICourseCertificate;
}

export interface ICreateCourseRequirement extends ICreateCoursePrototype {
	course_id: number;
	session_id: number;
	points: number;
	type: string;
	unit_type: string;
	required_amount: number;
}

export type PulseCheckSettings = {
	pulseCheckDisplayDuration: number;
	pulseCheckButtonText: TranslateString;
	pulseCheckText: TranslateString;
	audibleNotification: boolean;
}

export interface ICourseRequirement extends ICoursePrototype {
	course_id: number;
	session_id: number;
	points: number;
	type: CourseRequirementType;
	unit_type: string;
	required_amount: number;
	settings: PulseCheckSettings | Record<string, never>;
}

export interface ICreateCourseCertificate extends Omit<ICreateCoursePrototype, 'settings'> {
	course_id: number;
	name: TranslateString;
	email_subject: TranslateString;
	email_body: TranslateString;
	design_id: number;
	settings: {
		backgroundImage?: string;
		icon?: string;
	}
}

export type CourseCertificateSettings = {
	backgroundImage?: string;
	icon?: string;
	byline?: string;
};

export interface ICourseCertificate extends Omit<ICoursePrototype, 'settings'> {
	name: TranslateString;
	email_subject: TranslateString;
	email_body: TranslateString;
	course_id: number;
	design_id: number;
	design?: ICourseCertificateDesign;
	settings: CourseCertificateSettings;
}

export type CertificateFont = {
	font: string;
	size: string;
	url?: string;
	letterSpacing?: string;
	style?: "normal" | "italic";
	weight?: "bold" | "lighter";
	caps?: boolean;
}

export type CertificatePosition = {
	x: number;
	y: number;
	align?: 'left' | 'center' | 'right';
}

export type CourseCertificateDesignSettings = {
	id: string;
	backgroundImage: string;
	icon?: string;
	fonts: {
		default: string;
		default_url?: string;
		userName: CertificateFont;
		certificateName: CertificateFont;
		dateString: CertificateFont;
		credits?: CertificateFont;
		categories?: CertificateFont;
		byline?: CertificateFont;
	},
	positions: {
		userName: CertificatePosition;
		certificateName: CertificatePosition;
		dateString: CertificatePosition;
		credits?: CertificatePosition;
		categories?: CertificatePosition;
		byline?: CertificatePosition;
		icon?: {
			x: number;
			y: number;
			width: number;
			height: number;
		};
	}
	colors: {
		background: string;
		text: string;
		accent1: string;
		accent2: string;
		accent3: string;
		userNameColor?: 'background' | 'text' | 'accent1' | 'accent2' | 'accent3';
		titleColor?: 'background' | 'text' | 'accent1' | 'accent2' | 'accent3';
		dateColor?: 'background' | 'text' | 'accent1' | 'accent2' | 'accent3';
		certificateNameColor?: 'background' | 'text' | 'accent1' | 'accent2' | 'accent3';
		creditColor?: 'background' | 'text' | 'accent1' | 'accent2' | 'accent3';
		categoriesColor?: 'background' | 'text' | 'accent1' | 'accent2' | 'accent3';
	}
}

export interface ICourseCertificateDesign {
	id: number;
	uuid: string;
	settings: CourseCertificateDesignSettings;
	channel: number;
	status: CourseStatus;
	thumbnail: string | null;
}

export interface ICourseCompletions {
	id: number;
	uuid: string;
	channel: number;
	course_id: number;
	bl_profile: number;
	timestamp: Date;
	certificate_id: number;
}

export interface ICreateScheduledPulseCheck {
	channel: number;
	requirement_id?: number | null;
	session_id: number;
	scheduled_time: Date | ISODateString;
}

export interface IScheduledPulseCheck {
	id: number;
	uuid: string;
	channel: number;
	requirement_id: number | null;
	session_id: number;
	scheduled_time: Date | ISODateString;
	delivered_at: Date | null;
}

export interface IScheduledPulseCheckReceipt {
	id: number;
	uuid: string;
	scheduled_pulse_check_id: number;
	bl_profile: number;
	received_at: Date | ISODateString;
}

export interface IScheduledPulseCheckAcknowledgement {
	id: number;
	uuid: string;
	scheduled_pulse_check_id: number;
	bl_profile: number;
	acknowledged_at: Date | ISODateString;
}

export type LoadOptions = {
	full?: boolean;
}

export type RequirementKey = `req-${number}`;

export type UserCourseCompletionStats = {
	state: UserCourseCompletionState;
	date?: string;
} & Record<RequirementKey, string>;

export enum UserCourseCompletionState {
	InProgress = 'in-progress',
	Passed = 'passed',
	Cooldown = 'cooldown',
	Wait = 'waiting',
	Failed = 'failed'
}

export type ProgressItem = {
	watched_seconds: number;
	pulse_checks_acknowledged: number;
}

export enum EPassable {
	yes = 'yes',
	no = 'no',
	undetermined = 'undetermined'
}
