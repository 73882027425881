import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, batch } from "react-redux";

import Switch from "../../../../../../../general-ui/switch/switch";
import images from '../../../../../../../../images';
import {
	checkRegistrationSingleSignOn,
	setMarketingOptInType,
	setRegistrationSingleSignOn,
	setRegistrationSingleSignOnTypes,
	setRegistrationSSODisplayTop,
	setRegistrationType,
	toggleInPersonAttendeeMode,
	updateRegistrationSteps,
} from "../../../../../../../../store/actions/admin/create-event";
import {
	ISSOIntegration,
	MarketingOptInTypes,
	RegistrationStep,
	RegistrationStepType,
	RegistrationTypes,
	SingleSignOnTypes,
	GateTypes
} from "../../../../../../../../types/working-model";
import { GetDefaultRegistrationQuestions } from "../../../../../../../../store/utils/create-event";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { getPublicOauth2Integration, getSSOIntegrations } from "../../../../../../../../store/actions/admin/integrations";
import { isCustomSignOnType, isUsingCustomSSO } from "../../../../../../../../utils/sso-utils";
import { ALERT_TYPES, showAlert } from "../../../../../../../general-ui/alert/alert-service";

interface SignInOption {
	type: SingleSignOnTypes;
	label: string;
	icon: string;
	uuid?: string;
}

function SingleSignOn(): JSX.Element {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const customSSOs = useTypedSelector(state => state.IntegrationsReducer.customSSOs);
	const oauthIntegration = useTypedSelector(state => state.IntegrationsReducer.publicOauthIntegration);
	const courseOn = workingEvent?.settings?.course_enabled;

	const isSingleSignOn = !!workingEvent?.registration_settings?.singleSignOn?.isOn;
	const displayBeforeGeneralInfoQuestions = !!workingEvent?.registration_settings?.singleSignOn?.displayTop;
	const marketingOptInType = workingEvent?.registration_settings?.marketing_email_options;
	const currentSignOns = useMemo(() => { // need to wrap in useMemo to prevent `handleUpdateSignOns` from being called on every render
		return workingEvent?.registration_settings?.singleSignOn?.singleSignOns || [];
	}, [workingEvent?.registration_settings?.singleSignOn?.singleSignOns]);

	const [singleSignOns, setSingleSignOns] = useState<Set<string>>(new Set(currentSignOns));

	const passwordGatingEnabled = useMemo(() => !!workingEvent?.registration_settings?.password_gating_enabled, [workingEvent?.registration_settings?.password_gating_enabled]);
	const registrationSteps = useMemo(() => workingEvent?.registration_steps || [], [workingEvent]);

	const dispatch = useDispatch();

	const SignInOptions: SignInOption[] = [
		{ type: SingleSignOnTypes.Google, label: 'Google', icon: images.Google },
	];

	useEffect(() => {
		if (!token || !workingEvent?.channel) return;
		dispatch(getSSOIntegrations(token, workingEvent.channel));
		dispatch(getPublicOauth2Integration(token, workingEvent.channel));
	}, [dispatch, token, workingEvent?.channel]);

	const customSignInOptions: SignInOption[] = customSSOs?.filter((sso: ISSOIntegration) => sso.enabled).map((sso: ISSOIntegration) => {
		return { type: sso.type as SingleSignOnTypes, label: sso.login_text, icon: sso.icon, uuid: sso.uuid };
	}) ?? [];

	const oauthSignInOption: SignInOption | null = oauthIntegration ? { type: SingleSignOnTypes.oAuth, label: oauthIntegration.login_text || '', icon: oauthIntegration.icon || '' } : null;

	const updatedSteps = useCallback(() => {
		// custom SSO excludes registration gating and all registration steps except general (which includes only first name, last name, and email)
		// first and last will become optional which are set inside the updateRegistrationSteps action using the makeNameOptional flag
		// note: if breakout session or leaderboard is on, then the first and last name will remain required; see registration-required-fields.ts -> useSetRequiredField
		const _updatedSteps: RegistrationStep[] = registrationSteps?.map((step: RegistrationStep) => {
			if (step.type === RegistrationStepType.general) {
				return {
					...step,
					questions: GetDefaultRegistrationQuestions(),
				};
			}
			return step;
		});

		return _updatedSteps;
	}, [registrationSteps]);

	const handleUpdateSignOns = useCallback((updatedSignOns?: Set<string>) => {
		const _singleSignOns = updatedSignOns || new Set(currentSignOns);
		const usingSSO = isUsingCustomSSO(workingEvent);

		const singleSignOnsArray = Array.from(_singleSignOns);

		if (singleSignOnsArray.some(isCustomSignOnType) && usingSSO) {


			batch(() => {
				dispatch(setRegistrationSingleSignOnTypes(Array.from(_singleSignOns)));
				dispatch(setRegistrationType(RegistrationTypes.open));
				dispatch(updateRegistrationSteps(updatedSteps(), { makeNameOptional: true }));
				dispatch(toggleInPersonAttendeeMode(false));
			});
		} else {
			dispatch(setRegistrationSingleSignOnTypes(Array.from(_singleSignOns)));
		}
	}, [currentSignOns, dispatch, updatedSteps, workingEvent]);

	useEffect(() => {
		return function unmountSingleSignOn() {
			dispatch(checkRegistrationSingleSignOn());
		};
	}, [dispatch]);

	const setIsSingleSignOn = (isOn: boolean) => {
		if (marketingOptInType !== MarketingOptInTypes.noOptIn) {
			showAlert({
				message: 'Marketing email opt-in has been disabled',
				description: 'In order to enable the market email option, you must disable the SSO option',
				duration: 7000,
				type: ALERT_TYPES.NEUTRAL
			});
		}
		dispatch(setMarketingOptInType(MarketingOptInTypes.noOptIn));
		dispatch(setRegistrationSingleSignOn(isOn));
	};

	const handleDisplayFirst = (isOn: boolean) => {
		dispatch(setRegistrationSSODisplayTop(isOn));
	};

	const handleSignOns = (loginType: SingleSignOnTypes, isOn: boolean) => {
		const newSet = new Set(singleSignOns);
		// custom SSO is mutually exclusive of all other SSOs
		if (isOn) {
			if (isCustomSignOnType(loginType)) {
				newSet.clear();
			} else {
				newSet.forEach(sso => {
					if (isCustomSignOnType(sso)) {
						newSet.delete(sso);
					}
				});
			}
		}

		isOn ? newSet.add(loginType) : newSet.delete(loginType);
		setSingleSignOns(newSet);

		handleUpdateSignOns(newSet);
	};

	function renderSignInOption(option: SignInOption) {
		const value = option.uuid ?? option.type;
		return (
			<div className="settings-card sub-list" key={value}>
				<label>
					{!!option.icon && <img className="sign-on-logo" src={option.icon} alt="" />}
					Sign in with {option.label}
				</label>
				<Switch
					value={value}
					on={singleSignOns.has(value)}
					onClick={handleSignOns}
				/>
			</div>
		);
	}

	const handleToggleSSO = (_: unknown, isOn: boolean) => {
		if (isOn) {
			dispatch(updateRegistrationSteps(updatedSteps(), { makeNameOptional: true }));
		}
		setIsSingleSignOn(isOn);

		handleUpdateSignOns();
	};

	return (
		<>
			<div className="settings-card">
				<label>
					Single sign on
				</label>
				<Switch
					disabled={passwordGatingEnabled || courseOn}
					value={"Single sign on"}
					on={isSingleSignOn}
					onClick={handleToggleSSO}
				/>
			</div>
			{isSingleSignOn && (
				<>
					<div className="settings-card sub-list" key="display-top">
						<label>Display before general info questions</label>
						<Switch
							disabled={passwordGatingEnabled || courseOn}
							value={"Display on top"}
							on={displayBeforeGeneralInfoQuestions}
							onClick={(_, isOn) => handleDisplayFirst(isOn)}
						/>
					</div>
					{SignInOptions.map(renderSignInOption)}
					{customSignInOptions.map(renderSignInOption)}
					{oauthSignInOption && renderSignInOption(oauthSignInOption)}
				</>
			)}
		</>
	);
}

export default SingleSignOn;