
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';

import { QuestionType, RegistrationQuestion, CreateRegistrationQuestion, RegistrationStepType, GateTypes, RegFieldsEnum } from 'types/working-model';

import './sortable-registration-question-v2.scss';
import { OptionalComponent } from 'utils/optional-component';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import EditRegistrationQuestionV2 from './edit-registration-question-v2';
import Icon, { COLORS, ICONS } from '@general-ui/icon';
import { useFieldRequired } from 'utils/registration-required-fields';
import { showAlert } from '@general-ui/alert/alert-service';
import { useAppDispatch, useTypedSelector } from 'store/reducers/use-typed-selector';
import { updateRegistrationSteps, updateRequiredQuestions } from 'store/actions/admin/create-event';
import { batch } from 'react-redux';
import { UpdateRegistrationSteps } from 'connection/create-event';
import { queries } from '@testing-library/react';

export const QuestionTypeMap = {
	[QuestionType.text]: "Text field",
	[QuestionType.textarea]: "Text area",
	[QuestionType.select]: "Dropdown list",
	[QuestionType.checkbox]: "Checkbox",
	[QuestionType.numeric]: "Numeric Field",
	// [QuestionType.date]: "Date Field",
	[QuestionType.email]: "Email Field",
	[QuestionType.multiselect]: "Multi-Select",
	[QuestionType.customtext]: "Custom Text"
};

interface ISortableRegistrationQuestionV2Props {
	question: RegistrationQuestion;
	requiredQuestions: number[];
	selectedIds: number[];
	selectedQuestions: RegistrationQuestion[];
	setQuestionRequired: (question: number, value: number) => void;
	setSelectedQuestions: React.Dispatch<React.SetStateAction<RegistrationQuestion[]>>;
	expandedRegCard: number;
	setExpandedRegCard: React.Dispatch<React.SetStateAction<number>>;
	setRequiredQuestions: React.Dispatch<React.SetStateAction<number[]>>;
}

const SortableRegistrationQuestionV2: React.FC<ISortableRegistrationQuestionV2Props> = (props) => {
	const {
		question,
		requiredQuestions,
		selectedIds,
		selectedQuestions,
		setQuestionRequired,
		setSelectedQuestions,
		expandedRegCard,
		setExpandedRegCard,
		setRequiredQuestions
	} = props;

	const dispatch = useAppDispatch();

	const id = question.registration_question.toString();

	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
	} = useSortable({ id });
	const isFieldRequired = useFieldRequired();

	const [editingQuestion, setEditingQuestion] = useState<RegistrationQuestion | CreateRegistrationQuestion | null>({ ...question });
	const [childClicked, setChildClicked] = useState(false);

	const token = useTypedSelector(state => state.AuthReducer.token);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const registrationStep = useTypedSelector(state => state.CreateEventReducer.registrationStep);
	const registrationQuestions = useTypedSelector(state => state.RegistrationQuestionsReducer.registrationQuestions);

	const passwordGatingEnabled = useMemo(() => !!workingEvent?.registration_settings?.password_gating_enabled, [workingEvent?.registration_settings?.password_gating_enabled]);

	const handleToggleQuestion = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();

		if (!token || !workingEvent || !workingEvent.registration_steps) return;

		const requiredOn = isFieldRequired.on.includes(question.registration_question);
		const requiredOnAndOptional = isFieldRequired.onAndOptional.includes(question.registration_question);
		const requiredOnAndOptionalOrRequired = isFieldRequired.onAndOptionalOrRequired.includes(question.registration_question);

		const updatedSelectedQuestions = selectedQuestions.filter((q) => q.registration_question !== question.registration_question);
		const _selectedQuestions = updatedSelectedQuestions.map(q => q.registration_question);
		const updatedSteps = workingEvent.registration_steps.map(step => {
			if (step.type !== registrationStep) return step;
			const selectedQuestionObjects = registrationQuestions.filter(q => _selectedQuestions.includes(q.registration_question));
			return { ...step, questions: selectedQuestionObjects };
		});

		const _requiredQuestions = requiredQuestions.filter(requiredQn => requiredQn !== question.registration_question);

		if (_requiredQuestions.length === 0) {
			showAlert({
				message: "Cannot save registration form",
				description: "Your registration form must contain at least one required field",
				duration: 3000,
				type: 'error',
			});
			return;
		}

		// only prevent deleting email on general step, regardless of required status
		const allowDeleteEmail = question.type === QuestionType.email && registrationStep !== RegistrationStepType.general;

		if (!allowDeleteEmail && (requiredOn || requiredOnAndOptional || requiredOnAndOptionalOrRequired)) {
			showAlert({
				message: `This field ${requiredOn ? 'is required' : 'must be on'} for event features currently in use.`,
				duration: 3000,
				type: "error",
			});
			return;
		}

		if (requiredQuestions.includes(question.registration_question)) {
			setRequiredQuestions(_requiredQuestions);
		}

		setSelectedQuestions(updatedSelectedQuestions);

		dispatch(updateRegistrationSteps(updatedSteps, { requiredQuestions: _requiredQuestions }));

		await UpdateRegistrationSteps(token, workingEvent.uuid, updatedSteps);
		setChildClicked(true);

	};

	const style = {
		transform: CSS.Transform.toString(transform),
	};

	return (
		<>
			<div
				key={question.registration_question}
				ref={setNodeRef}
				style={style}
				className={classNames("registration-option-v2-container", {
					'on': selectedIds?.includes(question.registration_question) ?? false,
					dragging: attributes["aria-pressed"],
					'expanded': question.registration_question === expandedRegCard
				})}

				onClick={() => {
					if (question.registration_question !== expandedRegCard && !childClicked) {
						setExpandedRegCard(question.registration_question);
						setEditingQuestion({ ...question });
					}
				}}

				{...attributes}
				{...(expandedRegCard ? {} : listeners)} // only allow drag when not expanded
			>
				<div
					className="drag-handle"
					// drag-handle is automatically listened to when the parent element has listeners
					// but because we de-activate listeners on the parent when the card is expanded,
					// we need to re-activate listeners on the drag handle
					{...(expandedRegCard ? listeners : {})}
				>
					<Icon name={ICONS.DRAG_HANDLE} size={18} color={COLORS.DEFAULT_GRAY} />
				</div>
				<div className="registration-option-v2">
					<OptionalComponent display={question.registration_question !== expandedRegCard}>
						<div className="registration-preview">
							<div>
								<p className="registration-question-label">{question.name}</p>

								<div className="question-description">
									<div className="registration-question-type">{QuestionTypeMap[question.type]}</div>
									{requiredQuestions.includes(question.registration_question) ? <p>·</p> : null}
									{requiredQuestions.includes(question.registration_question) ? <div className="required"> Required</div> : null}
								</div>
							</div>

							{/* 
							Shows only on hover
							Display edit pencil / x to remove per card on hover
							Edit functions with current flow
							X removes question from list */}
							<div className="registration-option-hover">
								<div className="registration-option-hover-icon">
									<Icon name={ICONS.EDIT} size={16} color={COLORS.LIGHTER_GRAY} />
								</div>
								<div
									className={
										classNames(
											"registration-option-hover-icon delete",
											{
												disabled: isFieldRequired.on.includes(question.registration_question)
											}
										)}
									onClick={handleToggleQuestion}
								>
									<Icon name={ICONS.CLOSE} size={16} color={COLORS.LIGHTER_GRAY} />
								</div>
							</div>
						</div>
					</OptionalComponent>

					<OptionalComponent display={question.registration_question === expandedRegCard}>
						<EditRegistrationQuestionV2
							{...{
								requiredQuestions,
								setQuestionRequired,
								setExpandedRegCard,
								editingQuestion,
								setEditingQuestion
							}}
						/>
					</OptionalComponent>
				</div>
			</div>
			<OptionalComponent display={passwordGatingEnabled && question.registration_question === RegFieldsEnum.email}>
				<>
					<div
						key="password-question"
						ref={setNodeRef}
						style={style}
						className={classNames("registration-option-v2-container on", {
							dragging: attributes["aria-pressed"],
						})}
						{...attributes}
						{...(expandedRegCard ? {} : listeners)} // only allow drag when not expanded
					>
						<div
							className="drag-handle"
							{...(expandedRegCard ? listeners : {})}
						>
							<Icon name={ICONS.DRAG_HANDLE} size={18} color={COLORS.DEFAULT_GRAY} />
						</div>
						<div className="registration-option-v2">
							<div className="registration-preview">
								<div>
									<p className="registration-question-label">Password</p>
									<div className="question-description">
										<div className="registration-question-type">Password Field</div>
										<p>·</p>
										<div className="required">Required</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						key="password-confirmation-question"
						ref={setNodeRef}
						style={style}
						className={classNames("registration-option-v2-container on", {
							dragging: attributes["aria-pressed"],
						})}
						{...attributes}
						{...(expandedRegCard ? {} : listeners)} // only allow drag when not expanded
					>
						<div
							className="drag-handle"
							{...(expandedRegCard ? listeners : {})}
						>
							<Icon name={ICONS.DRAG_HANDLE} size={18} color={COLORS.DEFAULT_GRAY} />
						</div>
						<div className="registration-option-v2">
							<div className="registration-preview">
								<div>
									<p className="registration-question-label">Password Confirmation</p>
									<div className="question-description">
										<div className="registration-question-type">Password Field</div>
										<p>·</p>
										<div className="required">Required</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			</OptionalComponent>
		</>
	);
};

export default SortableRegistrationQuestionV2;
