import { useEffect } from "react";
import { matchPath, useHistory } from "react-router";

import { PageModuleGroupModules, PageModuleType, Session, TranslateString } from "../../../../types/working-model";
import { sessionPanelCustomPathPattern } from "../../../../utils/admin-routing-utils";
import { getSessionPanelRouteState, getExtrasPanelTabRouteState } from "../../../../utils/path-utils";
import { SlidingMenuOptionTab } from "./above-the-fold-content";

const findActiveModule = (type: string) => (module: PageModuleGroupModules) => {
	return module.type === type && module.is_on;
};

type UseWatchAdminNavType = {
	session: Session | undefined;
	setChatIsActive: (isActive: boolean) => void;
	setSelectedModuleTabId: (tabId: number) => void;
	setSelectedTabUuid: (uuid: string | undefined) => void;
	adminInitialSelectedRouteRef: React.MutableRefObject<string | undefined>;
	adminInitialSelectedTabRef: React.MutableRefObject<number | undefined>;
	tabOptions: SlidingMenuOptionTab[]
}
export const useWatchAdminNav = ({
	session,
	setChatIsActive,
	setSelectedModuleTabId,
	setSelectedTabUuid,
	adminInitialSelectedRouteRef,
	adminInitialSelectedTabRef,
	tabOptions
}: UseWatchAdminNavType) => {
	const history = useHistory();
	// fires on navigate in admin console
	useEffect(() => {
		// when loading the page OR changing routes in the panel,
		// we want to update what the active tab is here so users can see
		// their updates come through in real time, so this is checked on every
		// navigate AND on the initial page load
		const checkAdminPathForTab = (location: Pick<Location, 'pathname'>) => {
			setTimeout(() => {
				if (!session?.module_grouping) return;

				const {
					isEngage,
					isDetails,
					isEducation,
					isExtras,
					isCourses
				} = getSessionPanelRouteState(location.pathname);

				const match = matchPath<{ customPath?: string }>(window.location.pathname, { path: sessionPanelCustomPathPattern });
				const customPath = match?.params?.customPath;
				const isCustom = !!customPath;

				if (tabOptions.length > 1 && (isCustom || isExtras || isEngage || isDetails || isEducation)) {
					setChatIsActive(false);
				}

				// fall through an else-if chain because react router COULD determine that two
				// routes are matches, but we only want to act on one of them
				if (isExtras) {
					setTimeout(() => {
						const {
							isProducts,
							isResources,
							isSuggested,
							isSpeaker,
							isCustomEmbed
						} = getExtrasPanelTabRouteState(location.pathname);

						const extrasModule = session.module_grouping?.find(findActiveModule('extras'));

						if (extrasModule?.uuid) {
							// set the state AND the ref so that the active bar is in the right place
							// the active bar is set async after load so that the DOM measurement is correct
							// we want to preselect the correct tab
							setSelectedTabUuid(extrasModule.uuid);
							adminInitialSelectedRouteRef.current = extrasModule.uuid;

							// these are numbers and I am not certain that the order is going to remain static forever
							// if the sub-tab ordering becomes sortable, we will need to make a change to this
							const [product, resource, suggested, ...customTabs] = extrasModule.modules;

							if (isProducts) {
								adminInitialSelectedTabRef.current = product;
								setSelectedModuleTabId(product);
							} else if (isResources) {
								adminInitialSelectedTabRef.current = resource;
								setSelectedModuleTabId(resource);
							} else if (isSuggested) {
								adminInitialSelectedTabRef.current = suggested;
								setSelectedModuleTabId(suggested);
							} else if (isSpeaker) {
								const speakerTab = customTabs.find(tab => session.modules?.find(module => module.id === tab)?.type === PageModuleType.speakers);

								if (speakerTab) {
									adminInitialSelectedTabRef.current = speakerTab;
									setSelectedModuleTabId(speakerTab);
								}
							} else if (isCustomEmbed) {
								const embedTab = customTabs.find(tab => session.modules?.find(module => module.id === tab)?.type === PageModuleType.blank);

								if (embedTab) {
									adminInitialSelectedTabRef.current = embedTab;
									setSelectedModuleTabId(embedTab);
								}
							}
						}
					});
				} else if (isEngage) {
					setTimeout(() => {
						const engageModule = session.module_grouping?.find(findActiveModule('engage'));
						if (engageModule?.uuid) {
							setSelectedTabUuid(engageModule.uuid);
							adminInitialSelectedRouteRef.current = engageModule.uuid;
						}
					});
				} else if (isCourses) {
					setTimeout(() => {
						setSelectedTabUuid('courses');
						adminInitialSelectedRouteRef.current = 'courses';
					});
				} else if (isCustom) {
					// custom modules embed their path name in this new translateString field "transliterated" 
					// because we don't want to await a transliteration on the attendee side (which we would have to do if we import it)
					setTimeout(() => {
						const customModule = session.module_grouping?.find(module => (module.name as TranslateString).transliterated === customPath);
						if (customModule?.uuid) {
							setSelectedTabUuid(customModule.uuid);
							adminInitialSelectedRouteRef.current = customModule.uuid;
						}
					});
				}
			});
		};

		// this could be cleaner and more succinct but I am not certain 
		// whether this is going to need to be changed before v2 is out
		// history.listen returns its own removeEventListener function - call it to stop listening
		// TODO: do not call this when this component is not in the editor
		const off = history.listen(checkAdminPathForTab);

		// call once on mount
		checkAdminPathForTab(window.location);

		return () => {
			off();
		};
	}, [adminInitialSelectedRouteRef, adminInitialSelectedTabRef, history, session?.module_grouping, setChatIsActive, setSelectedTabUuid]);
};