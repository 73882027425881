import { handle } from "redux-pack";
import {
	CourseActions,
	CHECK_CANONICAL_PASS_STATE,
	LOAD_COURSE,
	LOAD_COURSE_ADMIN,
	WATCHED_SECONDS,
	PULSE_CHECK,
	RESET_CANONICAL_PASS_STATE,
	SET_PASS_CHECK_LOADING,
	ESTIMATE_PASSABILITY
} from "store/actions/event/courses";
import {
	checkPassability,
	estimatePassState,
	userStatsToPassState
} from "store/utils/course-utils";
import {
	EPassable,
	ICourse,
	ProgressItem,
	UserCourseCompletionState
} from "types/courses";

export type CourseState = {
	course: ICourse | undefined;
	loadingCourse: boolean;
	errorLoadingCourse: string | undefined;
	passStateInitiallyChecked: boolean;
	estimatedPassState?: boolean;
	estimatedPassable: EPassable;
	estimatedProgress: Record<number, ProgressItem> | undefined;
	canonicalPassState?: undefined | UserCourseCompletionState;
	loadingCanonicalPassState?: boolean;
	errorLoadingCanonicalPassState?: string | undefined;
	passDate?: Date;
}

const initialState: CourseState = {
	course: undefined,
	loadingCourse: false,
	passStateInitiallyChecked: false,
	errorLoadingCourse: undefined,
	estimatedPassState: undefined,
	estimatedPassable: EPassable.undetermined,
	estimatedProgress: undefined,
	canonicalPassState: undefined,
	loadingCanonicalPassState: false,
	errorLoadingCanonicalPassState: undefined,
};

const courseReducer = (state = initialState, action: CourseActions): CourseState => {
	switch (action.type) {
		case LOAD_COURSE:
		case LOAD_COURSE_ADMIN: {
			return handle(state, action, {
				start: (state: CourseState) => {
					return {
						...state,
						loadingCourse: true
					};
				},
				finish: (state: CourseState) => {
					return {
						...state,
						loadingCourse: false
					};
				},
				failure: (state: CourseState) => {
					return {
						...state,
						errorLoadingCourse: 'Error loading course'
					};
				},
				success: (state: CourseState) => {
					return {
						// course load should reset state
						...initialState,
						course: action.payload,
					};
				}
			});
		}
		case WATCHED_SECONDS: {
			if (!state.course || !state.estimatedProgress) {
				return state;
			}

			const { session_id, seconds } = action.payload;

			const progress = {
				...state.estimatedProgress,
				[session_id]: {
					...state.estimatedProgress[session_id],
					watched_seconds: state.estimatedProgress[session_id].watched_seconds + seconds
				}
			};

			return {
				...state,
				estimatedProgress: progress,
				estimatedPassState: estimatePassState(state.course, progress)
			};
		}
		case PULSE_CHECK: {
			if (!state.course || !state.estimatedProgress) {
				return state;
			}

			const { session_id } = action.payload;

			const progress = {
				...state.estimatedProgress,
				[session_id]: {
					...state.estimatedProgress[session_id],
					pulse_checks_acknowledged: state.estimatedProgress[session_id].pulse_checks_acknowledged + 1
				}
			};

			return {
				...state,
				estimatedProgress: progress,
				estimatedPassState: estimatePassState(state.course, progress)
			};
		}
		case CHECK_CANONICAL_PASS_STATE: {
			return handle(state, action, {
				start: (state: CourseState) => {
					return {
						...state,
						loadingCanonicalPassState: true,
					};
				},
				finish: (state: CourseState) => {
					return {
						...state,
						loadingCanonicalPassState: false,
						passStateInitiallyChecked: true
					};
				},
				failure: (state: CourseState) => {
					return {
						...state,
						errorLoadingCanonicalPassState: 'Error loading canonical pass state'
					};
				},
				success: (state: CourseState) => {
					if (!action.payload || !state.course) return state;

					const {
						passState,
						progress,
						passDate
					} = userStatsToPassState(state.course, action.payload);

					// user has locally estimated pass state, do not replace
					// in case the sqs queues are slightly behind
					if (!action.meta?.shouldUpdate) {
						return {
							...state,
							canonicalPassState: action.payload.state,
							passDate: passDate ? new Date(passDate) : undefined
						};
					}

					return {
						...state,
						canonicalPassState: passState,
						estimatedProgress: progress,
						estimatedPassState: estimatePassState(state.course, progress),
						passDate: passDate ? new Date(passDate) : undefined
					};
				}
			});
		}

		case ESTIMATE_PASSABILITY: {
			if (!state.course || !state.estimatedProgress) {
				return {
					...state,
					estimatedPassable: EPassable.undetermined
				};
			}

			return {
				...state,
				estimatedPassable: checkPassability(action.payload, state.course, state.estimatedProgress)
			};
		}

		case RESET_CANONICAL_PASS_STATE: {
			return {
				...state,
				canonicalPassState: undefined,
				loadingCanonicalPassState: false,
				errorLoadingCanonicalPassState: undefined
			};
		}

		case SET_PASS_CHECK_LOADING: {
			return {
				...state,
				loadingCanonicalPassState: action.payload
			};
		}
		default:
			return state;
	}
};

export default courseReducer;