import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// REDUX IMPORTS
import { setEmailLinkLimit } from "../../../../../../../store/actions/admin/create-event";
import { EventsState } from "../../../../../../../store/types";

// GENERAL UI IMPORTS
import Switch from "../../../../../../general-ui/switch/switch";
import Icon, { ICONS } from "../../../../../../general-ui/icon";
import { Tooltip } from "../../../../../../general-ui/tooltip/tooltip";
import { COLORS } from "../../../../../../general-ui/icon";

const LimitRegistrationEmails: React.FC = () => {
	const workingEvent = useSelector((state: EventsState) => state.CreateEventReducer.workingEvent);
	const courseOn = workingEvent?.settings?.course_enabled;
	const registration_settings = workingEvent?.registration_settings;
	const emailRequired = registration_settings?.registrationConfirmation;

	const [linkLimit, setLinkLimit] = useState(workingEvent?.registration_settings?.emailLinkLimit);

	const dispatch = useDispatch();

	const toggleEmailLink = useCallback((value: string, on: boolean) => {
		// for now this is a toggle between on = 1 use and off = null = unlimited uses 
		// eventually the desire is to limit to a specified number of uses so the property is a number rather than a boolean
		const limit = on ? 1 : null;
		setLinkLimit(limit);
		dispatch(setEmailLinkLimit(limit));
	}, [dispatch]);

	useEffect(() => {
		if (!!linkLimit && !emailRequired) {
			toggleEmailLink('', false);
		}
	}, [emailRequired, linkLimit, toggleEmailLink]);

	return (
		<div className="settings-card">
			<label>
				Single use registrations
				<Tooltip
					maxWidth={300}
					tooltip="This setting applies to all registered emails, including those that have already have been sent. To enable, require confirmation must be enabled.">
					<Icon name={ICONS.PRIMARY_TOOLTIP} color={COLORS.CYAN} size={12} />
				</Tooltip>
			</label>
			<Switch
				value={"limitEmailLink"}
				on={courseOn ? false : !!linkLimit}
				onClick={toggleEmailLink}
				disabled={courseOn || !emailRequired}
			/>
		</div>
	);
};

export default LimitRegistrationEmails;