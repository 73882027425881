import React, { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from 'react-router';
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { throttle } from "underscore";

import { useTranslate } from '../../../../i18n/useTranslationModules';
import { EditorSizes, TemplateClassNames, TemplateNames } from "../../../../types/template-layouts";
import {
	ActionEvent,
	ActionTargetType,
	ActionType,
	BlProfile,
	BrandliveEvent,
	DeprecatedOnDemandSession,
	Dictionary,
	EBroadcastTypes,
	HomepageVideo,
	LanguagesAbbr,
	MiscellaneousActionData,
	Session,
	SessionTypesEnum,
	SessionTypesFeatures
} from "../../../../types/working-model";
import {
	displayAddToCalendarButton,
	getTemplateClassName,
	isiOSMobile,
	isMobile,
	niceDateTimeTz
} from "../../../../utils/utils";
import { Button } from "../../../general-ui/button/button";
import { getAsset } from "../../modules/assets/asset-map";
import { useSocket } from "../../../../connection/socket";
import useLive from "../../../../utils/use-live";
import { useTypedSelector } from '../../../../store/reducers/use-typed-selector';
import { useScreenMediaQuery } from '../../../../utils/use-screen-media-query';
import Icon, { COLORS, ICONS } from '../../../general-ui/icon';
import SessionCountdown from './session-countdown';
import { useTracking } from '../../../../utils/tracking';
import useTimestampStatus, { ETimestampStatus } from '../../../../utils/use-timestamp-status';
import { OptionalComponent } from '../../../../utils/optional-component';
import { Tag } from '../../../general-ui/tag/tag';
import { setFiresideLive } from '../../../../store/actions/event/firesides-actions';
import ErrorBoundary from "../../../../utils/error-boundary";
import { toggleChatPanel } from "../../../../store/actions/event/chat-actions";
import store from "../../../../store/main";
import usePrevious from "../../../../utils/use-previous";
import { ParamsProps } from "../../live-event";
import { setLiveSessionIds } from "../../../../store/actions/admin/create-event";
import { useIsAboveTheFold, useIsNewModuleGrouping, useIsSingleSession } from "../../../../hooks/session.hooks";
import { getDefaultLanguage } from "../../utils";
import { useIsNewNavigation } from "../../../../hooks/navigation.hooks";
import AboveTheFoldContent from "../above-the-fold/above-the-fold-content";
import SessionThumbnail from "./session-thumbnail";
import { ThemeContext } from "components/live-event/theme-context";
import { EPaletteModes } from "types/theme-packs";

const EditBannerColor = React.lazy(() => import("../../../admin/create/session/editor/edit-banner-color"));
const LiveChat = React.lazy(() => import('../live-chat/live-chat'));
const Video = React.lazy(() => import("../../modules/video/video"));
const AddToCalendarModal = React.lazy(() => import('../../modules/agenda/add-to-calendar-modal/add-to-calendar-modal'));

import '../../../../scss/live-event/base/session/session-stream.scss';

interface SessionStreamProps {
	session: Session;
	eventBundle: BrandliveEvent;
	isEditor?: boolean;
	playback?: string;
	languageProp?: LanguagesAbbr;
	editorSize?: string;
	isAdmin?: boolean;
	secondaryVideos?: Dictionary;
	interacted?: boolean;
	moderatorView?: boolean;
	sessionHeaderRef?: React.MutableRefObject<HTMLDivElement | null>;
	googleMeetBreakoutsRef?: React.MutableRefObject<HTMLDivElement | null>;
}

declare global {
	interface Document {
		mozCancelFullScreen?: () => Promise<void>;
		msExitFullscreen?: () => Promise<void>;
		webkitExitFullscreen?: () => Promise<void>;
		mozFullScreenElement?: Element;
		msFullscreenElement?: Element;
		webkitFullscreenElement?: Element;
	}

	interface HTMLElement {
		msRequestFullscreen?: () => Promise<void>;
		mozRequestFullscreen?: () => Promise<void>;
		webkitRequestFullscreen?: () => Promise<void>;
	}
}

const getHomepageVideo = (session: Session, language: LanguagesAbbr) => {
	let source;
	let homepageVideo;

	if (session.streaming_options?.single_stream) {
		if (Array.isArray(session.on_demand_video)) {
			source = session.on_demand_video[0]?.source;
			homepageVideo = session.on_demand_video[0];
		} else {
			const backwardsCompatibileSession = session as unknown as DeprecatedOnDemandSession;
			source = backwardsCompatibileSession.on_demand_video?.source;
			homepageVideo = backwardsCompatibileSession.on_demand_video;
		}
	} else {
		if (session.on_demand_video !== null && typeof session.on_demand_video === 'object' && !Array.isArray(session.on_demand_video)) {
			const backwardsCompatibileSession = session as unknown as DeprecatedOnDemandSession;
			source = backwardsCompatibileSession.on_demand_video?.source;
			homepageVideo = backwardsCompatibileSession.on_demand_video;
		} else {
			source = session.on_demand_video?.find(video => video?.session_language === language)?.source ?? session.on_demand_video?.[0]?.source;
			homepageVideo = session.on_demand_video?.find(video => video?.session_language === language) as HomepageVideo ??
				session.on_demand_video?.[0] as HomepageVideo;
		}
	}

	return [source, homepageVideo];
};

const isIphone = isiOSMobile();

const SessionStream: React.FC<SessionStreamProps> = ({
	session,
	eventBundle,
	isEditor,
	playback = null,
	languageProp,
	editorSize = '',
	isAdmin = false,
	secondaryVideos,
	moderatorView,
	sessionHeaderRef,
	googleMeetBreakoutsRef
}) => {
	const { t } = useTranslate(["homepage", "landing"]);
	const dispatch = useDispatch();
	const { isLessThan1024, isLessThan640 } = useScreenMediaQuery();
	const blProfileUser = useTypedSelector(state => state.LiveEventReducer.blProfileUser);
	const loadingEventBundle = useTypedSelector(state => state.LiveEventReducer.loadingEventBundle);
	const secondaryVideo = useTypedSelector(state => state.CreateSessionReducer.secondarySessionVideo);
	const firesidesHostSessions: number[] = useTypedSelector(state => state.LiveEventReducer.firesidesHostSessions);
	const featureFlags = useTypedSelector(state => state.FeatureFlagsReducer.featureFlags);
	const [playbackUrl, setPlaybackUrl] = useState<string | null>(playback);
	const [currentlyWatching, setCurrentlyWatching] = useState<string | null>(null);
	const [hasScrolledPast, setHasScrolledPast] = useState(false);
	const [openAddToCalendar, setOpenAddToCalendar] = useState(false);
	const [isFullscreen, setIsFullscreen] = useState(false);
	// isMobile or isLessThan1024 (because "tablet" is considered less than 1024 in our chat layout)
	const isMobileEditor = editorSize === EditorSizes.mobile;
	const isTabletEditor = editorSize === EditorSizes.tablet;
	const isFireside = session.session_type === SessionTypesEnum.fireside;
	const isFiresideTablet = isFireside && isLessThan1024;
	const [chatClosed, setChatClosed] = useState(isMobile || isLessThan640 || isFiresideTablet);

	const [theme] = useContext(ThemeContext);
	const isDarkMode = theme === EPaletteModes.Dark;

	const language = languageProp ?? useParams<ParamsProps>()?.language ?? getDefaultLanguage(eventBundle);
	const prevLanguage = usePrevious(language);
	const isSessionDetailsAboveTheFoldV2 = useIsAboveTheFold(session);
	const isNewModuleGrouping = useIsNewModuleGrouping();

	const template = getTemplateClassName(eventBundle.template.name);
	const startDate = SessionTypesFeatures[session.session_type].has_start_time ? niceDateTimeTz(session.timestamp ?? 0, language) : null;
	const isOnDemand = session.session_type === SessionTypesEnum.onDemand;
	const isBroadcast = session.session_type === SessionTypesEnum.broadcast;
	const isBreakoutRooms = session.session_type === SessionTypesEnum.breakoutRooms;
	const isFiresideHost = isFireside && firesidesHostSessions.includes(session.session);
	const isIFrameBroadcast = session.session_type === SessionTypesEnum.broadcast && session.broadcast_type === EBroadcastTypes.embed;

	// orientation can either be 0, -90, 90, or 180 https://developer.apple.com/documentation/webkitjs/domwindow/1632568-orientation.
	const isLandscapeIphone = isIphone && (window.orientation === -90 || window.orientation === 90);

	// secondary videos that are chosen in advance are set under "global" and should override the stream recordings
	// if in editor use the redux state secondary video that comes from sql, if in published event use the redis cache that gets updated upon publish
	const uploadedSecondaryVideo: string | undefined = isEditor ? secondaryVideo?.playback_url : secondaryVideos?.[`${session.uuid}-global`];
	const recordedSecondaryVideo: string | undefined = secondaryVideos?.[`${session.uuid}-${language}`];
	const [secondaryUrl, setSecondaryUrl] = useState<string | undefined>(uploadedSecondaryVideo || recordedSecondaryVideo);
	const sessionName = session.title.base;
	const sessionChatEnabled = moderatorView ? !moderatorView : session.session_chat_enabled;

	const socket = useSocket(`session-${session.uuid}-${language}`);

	const [isLive] = useLive(playbackUrl, session, featureFlags);

	const scrollContainer = useRef<HTMLDivElement | null>(null);
	const container = useRef<HTMLDivElement | null>(null);

	const session_type = session?.session_type;
	const displayCountdown = true;
	// update recorded urls when changing languages
	useEffect(() => {
		setSecondaryUrl(uploadedSecondaryVideo || recordedSecondaryVideo);
	}, [uploadedSecondaryVideo, recordedSecondaryVideo]);

	const sessionUUID = session.uuid;
	useEffect(() => {
		dispatch(setLiveSessionIds({ sessionUUID, isLive }));
		if (isLive) {
			//clear the secondaryUrl so a new recording can replace it (new url will be set with a socket message)
			setSecondaryUrl(undefined);
			if (session_type === SessionTypesEnum.fireside) {
				dispatch(setFiresideLive(Date.now()));
			}
		} else {
			//if the uploaded secondary video exists set that right away and don't wait for the recorded stream to come in
			//if in the editor and the secondary video has been cleared we want to reset the last recorded stream automatically.
			if (uploadedSecondaryVideo || isEditor) {
				setSecondaryUrl(uploadedSecondaryVideo || recordedSecondaryVideo);
			}
			if (session_type === SessionTypesEnum.fireside) {
				dispatch(setFiresideLive(null));
			}
		}
	}, [isLive, uploadedSecondaryVideo, recordedSecondaryVideo, isEditor, session_type, dispatch, language, sessionUUID]);

	useEffect(() => {
		const playerUrlListener = (data: unknown) => {
			if (typeof data === 'string' || data === null || data === undefined) {
				setPlaybackUrl(data || null);
			}
		};

		const currentlyOnPage = (data: { viewers: string | null; }) => {
			setCurrentlyWatching(data.viewers);
		};

		const setStreamReplay = ({ data }: { data: string; }) => {
			//Set secondary videos should override the recorded stream
			//if the uploadedSecondaryVideo exists its being placed immediately in a useEffect above when isLive is false.
			//otherwise, set the recorded stream when it's done processing
			if (!uploadedSecondaryVideo) {
				setSecondaryUrl(data);
			}
		};

		socket.addListener('playbackUrl', playerUrlListener);
		socket.addListener('on-page', currentlyOnPage);
		socket.addListener('replayVideoUrl', setStreamReplay);

		//get currently watching on load
		socket.getCurrentlyWatching(({ viewers }) => {
			setCurrentlyWatching(viewers);
		});

		return () => {
			socket.removeListener('playbackUrl', playerUrlListener);
			socket.removeListener('on-page', currentlyOnPage);
			socket.removeListener('replayVideoUrl', setStreamReplay);
		};
	}, [socket, session.uuid, secondaryVideos, uploadedSecondaryVideo]);

	useEffect(() => {
		if (playback) setPlaybackUrl(playback);
	}, [playback]);

	const timestampStatus = useTimestampStatus(session);

	const styleOverrides = useMemo(() => {

		if (session.video?.customizations?.backgroundColor) {
			return { backgroundColor: `var(--${session.video?.customizations?.backgroundColor})`, padding: isEditor ? 0 : undefined };
		}

		if (session.video?.customizations?.backgroundImage) {
			return { backgroundImage: `url(${session.video?.customizations?.backgroundImage})` };
		}

		return {};
	}, [session, isEditor]);

	const disableSessionThumbnails = eventBundle?.settings?.disable_session_thumbnails === true;
	const streamPlaceholderBGColor = session?.layout?.styling_overrides?.content?.stream_placeholder_background_color;

	const placeholderStyle = useMemo(() => {
		// if thumbnails are toggled off
		if (disableSessionThumbnails) {
			// use custom placeholder bg color if available
			if (streamPlaceholderBGColor?.length) {
				return { background: streamPlaceholderBGColor[0] };
			}
			// else just return empty because bg color will default to secondaryBackgroundColor via css
			return {};
		}

		const isFuture = session.timestamp && session.timestamp > Date.now();

		if (session.post_broadcast_thumbnail && !isFuture) {
			return { backgroundImage: `url(${session.post_broadcast_thumbnail})` };
		} else if (session.pre_broadcast_thumbnail) {
			return { backgroundImage: `url(${session.pre_broadcast_thumbnail})` };
		} else {
			const isCustomTemplate = !TemplateClassNames[eventBundle?.template.name];
			const defaultTemplateAsset = isCustomTemplate ? TemplateNames.Apollo : eventBundle?.template.name;

			return { backgroundImage: `url(${getAsset(defaultTemplateAsset, 'video_background')})` };
		}
	}, [
		streamPlaceholderBGColor,
		disableSessionThumbnails,
		session.timestamp,
		session.post_broadcast_thumbnail,
		session.pre_broadcast_thumbnail,
		eventBundle?.template.name
	]);

	useEffect(() => {
		const handleScroll = throttle(() => {
			const rect = scrollContainer.current?.getBoundingClientRect();

			if (rect) {
				// if screen is less than 640px we immediately fix the video to the top as soon as we scroll,
				// so we only have to cheeck if we have scrolled any amount
				if (window.innerWidth < 640) {
					const scrolledPast = window.scrollY > 0;
					setHasScrolledPast(scrolledPast);
				} else {
					// else if greater than 640
					// we need to see if we've scrolled past the video height, because that's when we fix the video to the screen
					const scrolledPast = Math.abs(rect.y) > rect.height;
					if (scrolledPast !== hasScrolledPast) {
						setHasScrolledPast(scrolledPast);
					}
				}
			}
		}, 500);

		if (scrollContainer.current) {
			window.addEventListener('scroll', handleScroll);
			window.addEventListener('resize', handleScroll);
		}

		return () => {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleScroll);
		};
	}, [scrollContainer, hasScrolledPast,]);

	const closeAddToCalendar = useCallback(() => {
		setOpenAddToCalendar(false);
	}, []);

	const openAddToCalendarModal = useCallback(() => {
		setOpenAddToCalendar(true);
	}, []);

	// fireside and iframe broadcasts never uses chat overlay
	const forceOverlay = useMemo(() => (
		!isFireside
		&& !isIFrameBroadcast
		&& (editorSize === EditorSizes.tablet || (isLessThan1024 && !isLessThan640))
	), [isFireside, isIFrameBroadcast, editorSize, isLessThan1024, isLessThan640]);

	// so for tablet, fireside and iframe broadcasts acts like mobile
	const mobileOnly =
		isLessThan640
		|| editorSize === EditorSizes.mobile
		|| isFiresideTablet
		|| (isFireside && editorSize && editorSize !== EditorSizes.desktop)
		|| (isIFrameBroadcast && isLessThan1024)
		|| (isIFrameBroadcast && editorSize && editorSize !== EditorSizes.desktop)
		// only need to check landscape for iphone cause landscape on Android will force a fullscreen of the video
		|| isLandscapeIphone;

	const didEnterFullscreen = useCallback(() => {
		setIsFullscreen(true);
	}, []);

	const errorEnterFullscreen = useCallback((e: unknown) => {
		setIsFullscreen(false);
		console.error(e);
	}, []);

	const didExitFullscreen = useCallback(() => {
		setIsFullscreen(false);
		setHasScrolledPast(false);
	}, []);

	useEffect(() => {
		const _container = container.current;
		const handleFullscreenChange = () => {
			if (document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement) {
				setIsFullscreen(true);
			} else {
				setIsFullscreen(false);
			}
		};

		_container?.addEventListener('fullscreenchange', handleFullscreenChange);
		_container?.addEventListener('webkitfullscreenchange', handleFullscreenChange);
		_container?.addEventListener('webkitendfullscreen', handleFullscreenChange);
		_container?.addEventListener('fullscreentrigger', handleFullscreenChange);

		return () => {
			_container?.removeEventListener('fullscreenchange', handleFullscreenChange);
			_container?.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
			_container?.removeEventListener('webkitendfullscreen', handleFullscreenChange);
			_container?.removeEventListener('fullscreentrigger', handleFullscreenChange);
		};
	}, [isFullscreen]);

	const { trackEvent } = useTracking();

	useEffect(() => {
		if (isEditor) return;

		const validPasscodeLists = store.getState().LiveEventReducer.validPasscodeLists;
		const timer = setTimeout(() => {
			const { end_timestamp: session_end, timestamp: session_start } = session;
			const session_type = isLive ? SessionTypesEnum.broadcast : SessionTypesEnum.onDemand;
			const miscPayload: Pick<MiscellaneousActionData, 'attendance' | 'valid_passcode_lists'> = {
				attendance: {
					session_end,
					session_start,
					session_type,
					user_session_start: Date.now(),
					event_name: eventBundle.name,
					session_name: sessionName,
					email: blProfileUser?.email ?? null
				},
				valid_passcode_lists: validPasscodeLists
			};

			trackEvent({
				action: ActionEvent.View,
				action_type: ActionType.Passive,
				target_type: ActionTargetType.Attendance,
				target_id: session.session,
				miscellaneous: JSON.stringify(miscPayload)
			});
		}, 90 * 1000); // fire after 90 seconds

		return () => clearTimeout(timer);
		// Fire only if the streaming status changes.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditor, isLive]);

	/**
	 * Browsers haven't fully adopted the requestFullscreen API.
	 *
	 * Need to polyfill a bunch here.
	 */
	const handleFullscreen = useCallback((fullscreen: boolean) => {
		if (isIphone) {
			// for iOS mobile we are using the native video player when going fullscreen, so we don't need to run the below polyfill (which iOS mobile doesn't support anyway).
			setIsFullscreen(fullscreen);
			return;
		}

		if (fullscreen) {
			if (container.current?.requestFullscreen) {
				container.current?.requestFullscreen()
					.then(didEnterFullscreen)
					.catch(errorEnterFullscreen);
			} else if (container.current?.webkitRequestFullscreen) {
				container.current?.webkitRequestFullscreen();
				didEnterFullscreen();
			} else if (container.current?.msRequestFullscreen) {
				container.current?.msRequestFullscreen()
					.then(didEnterFullscreen)
					.catch(errorEnterFullscreen);
			} else if (container.current?.mozRequestFullscreen) {
				container.current?.mozRequestFullscreen()
					.then(didEnterFullscreen)
					.catch(errorEnterFullscreen);
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen()
					.then(didExitFullscreen)
					.catch(errorEnterFullscreen);
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
				didExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
					.then(didExitFullscreen)
					.catch(errorEnterFullscreen);
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen()
					.then(didExitFullscreen)
					.catch(errorEnterFullscreen);
			}
		}
	}, [didEnterFullscreen, didExitFullscreen, errorEnterFullscreen]);


	const handleChatClose = useCallback((chatClosed: boolean) => {
		dispatch(toggleChatPanel(false));
		setChatClosed(chatClosed);
	}, [dispatch]);

	useEffect(() => {
		// if it is a fireside, chat opens automatically in the initial loading
		// unless screen size is tablet or smaller
		if (
			isFireside
			&& timestampStatus === ETimestampStatus.preLive
			&& !isLessThan1024
			&& !isMobile
		) {
			setChatClosed(false);
		}
	}, [timestampStatus, isFireside, isLessThan1024]);

	const toggleChat = useCallback(() => {
		dispatch(toggleChatPanel(chatClosed));
		setChatClosed(!chatClosed);
	}, [chatClosed, dispatch]);

	const isFutureSession = session?.timestamp && session.timestamp > Date.now().valueOf();

	const showAddToCalBtn = eventBundle?.settings?.show_add_to_cal_btn;
	const showAddToCalendarButton = useMemo(() => {
		if (!session || moderatorView) return false;
		return displayAddToCalendarButton(session, showAddToCalBtn);
	}, [moderatorView, session, showAddToCalBtn]);

	const displayCurrentlyWatching = session?.layout?.display_viewer_count;

	const broadcastUrl = ((isLive || !secondaryUrl) && playbackUrl) ? playbackUrl : secondaryUrl;

	const tagText = () => {
		if (isOnDemand) {
			return t("On Demand");
		} else if (isBroadcast) {
			return t("Broadcast");
		} else if (isBreakoutRooms) {
			return t("Breakout Rooms");
		} else if (isFireside) {
			return t("Fireside");
		} else {
			return "";
		}
	};


	const isTestStream = useMemo(() => {
		return !isEditor && session?.streaming_options?.test_stream && session.session_type === SessionTypesEnum.broadcast;
	}, [isEditor, session.streaming_options, session.session_type]);

	// because the session header position is absolute, we cannot properly set the width using css
	// so we dynamically set the width of the header to be the width of the session stream placeholder
	const refToContainHeader = useRef<HTMLDivElement | null>(null);
	const [streamPlaceholderWidth, setStreamPlaceholderWidth] = useState('auto');

	useEffect(() => {
		const el = refToContainHeader?.current;
		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				if (entry?.contentRect?.width) {
					setStreamPlaceholderWidth(entry.contentRect.width + 'px');
				}
			}
		});
		if (el) {
			resizeObserver.observe(el);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, [refToContainHeader]);

	const sessionHeaderStyles = sessionHeaderRef?.current?.style;

	useEffect(() => {
		sessionHeaderStyles?.setProperty('--session-header-width', streamPlaceholderWidth);
	}, [streamPlaceholderWidth, sessionHeaderStyles]);

	const renderSessionStream = () => {
		const [source, homepageVideo] = getHomepageVideo(session, language);

		const isOnDemandAndVideoExists = session.session_type === SessionTypesEnum.onDemand && source;
		const isBroadcastEventAndNotTesting = !isTestStream && session.session_type === SessionTypesEnum.broadcast;
		const isFiresideBroadcast = session.session_type === SessionTypesEnum.fireside;
		const isEmbedTypeAndEmbedExists = session.broadcast_type === EBroadcastTypes.embed && source;
		const replayOnAndExists = session.replay_on && secondaryUrl;
		const isLiveAndNotTesting = !isTestStream && isLive;

		// overlay chat is off AND we are on a smaller than desktop view
		// OR we are in landscape on iPhone
		// OR we are a moderator
		// OR session chat is turned off 
		// OR this is a fireside on tablet
		const hideChatButton = (!session.layout?.overlayChat && (isFullscreen && !isLessThan1024))
			|| isLandscapeIphone
			|| moderatorView
			|| (!session?.session_chat_enabled && isSessionDetailsAboveTheFoldV2)
			|| isFiresideTablet;

		if (
			isOnDemandAndVideoExists
			|| ((isBroadcastEventAndNotTesting || isFiresideBroadcast) && isEmbedTypeAndEmbedExists)
		) {
			// ondemand/embed player
			return (
				<div className={classNames("session-video-player-container", { 'is-fullscreen': isFullscreen })}>
					<Suspense fallback="">
						<ErrorBoundary uniqueLabel="On-demand or embed player">
							<OptionalComponent display={language === prevLanguage}>
								<Video
									video={homepageVideo as HomepageVideo}
									template={template}
									main={true}
									session={session}
									onRequestFullscreen={handleFullscreen}
									isFullscreen={isFullscreen}
									onChatClose={handleChatClose}
									chatClosed={chatClosed}
									hideChatButton={hideChatButton}
									isOnDemand={session.session_type === SessionTypesEnum.onDemand}
									currentlyWatching={currentlyWatching}
									chatIsOverlaid={(session.layout?.overlayChat || forceOverlay) && !isLessThan640}
									displayCurrentlyWatching={displayCurrentlyWatching}
									colorTheme={session.video_player_controls_settings?.color_theme ?? 'default'}
									moderatorView={moderatorView}
									url={(moderatorView && playbackUrl) || undefined}
								/>
							</OptionalComponent>
						</ErrorBoundary>
					</Suspense>
				</div>
			);
		} else if (isLiveAndNotTesting || replayOnAndExists) {
			// live player
			return (
				<div className={classNames("session-video-player-container")}>
					<Suspense fallback="">
						<ErrorBoundary uniqueLabel="Live player">
							{/*
								Why this optional component based on language? Because of tracking.
								When switching languages we do not want to trigger previous video seconds played with the new language.
								This forces the component to unmount, which triggers the unload "end" tracking that tracks seconds for the current language
								and then starts fresh on the new language.
							*/}
							<OptionalComponent display={language === prevLanguage}>
								<Video
									isAdmin={isAdmin}
									isFiresidesHost={isFiresideHost}
									session={session}
									loop={!!uploadedSecondaryVideo}
									url={broadcastUrl}
									template={template}
									main={true}
									onRequestFullscreen={handleFullscreen}
									isFullscreen={isFullscreen}
									onChatClose={handleChatClose}
									chatClosed={chatClosed}
									hideChatButton={hideChatButton}
									currentlyWatching={currentlyWatching}
									isOnDemand={!isLive}
									chatIsOverlaid={(session.layout?.overlayChat || forceOverlay) && !isLessThan640}
									displayCurrentlyWatching={displayCurrentlyWatching}
									colorTheme={session.video_player_controls_settings?.color_theme ?? 'default'}
									moderatorView={moderatorView}
								/>
							</OptionalComponent>
						</ErrorBoundary>
					</Suspense>
				</div>
			);
		}

		return (
			<div
				className={
					classNames(
						"session-video-placeholder",
						{
							[timestampStatus]: timestampStatus,
							'no-image': disableSessionThumbnails,
						}
					)
				}
				style={placeholderStyle}
				ref={isAdmin ? null : refToContainHeader}
			>
				<OptionalComponent display={isAdmin && disableSessionThumbnails}>
					<EditBannerColor />
				</OptionalComponent>
				{(session.session_type !== 'on-demand') && (
					<div
						className="session-live-card-container"
						ref={isAdmin ? refToContainHeader : null}
					>
						<Tag
							classNameTag={classNames('session-live-card-container-tag')}
							text={tagText()}
						/>
						<h1 className="session-live-card-title">{t(`landing:agenda.session.${session.session}.title`, session?.title[language] as string)}</h1>
						<div className={classNames("live-in-card", { "past-session": !isFutureSession })}>
							<div className="live-in-card-text">
								{displayCountdown &&
									<SessionCountdown
										timestamp={session.timestamp}
										end_timestamp={session.end_timestamp}
										replay_on={session.replay_on}
									/>}
								<p>{startDate}</p>
							</div>
							{showAddToCalendarButton && (
								<Button
									onClick={openAddToCalendarModal}
									classButton="primary"
									template={template}
								>
									<span style={{ marginRight: '8px' }}>{t("ADD")}</span>
									<Icon name={ICONS.CALENDAR_ADD} color="" size={22} />
								</Button>
							)}
						</div>
					</div>
				)}
				<OptionalComponent display={sessionChatEnabled}>
					<Button
						onClick={toggleChat}
						classButton={classNames('player-button chat-close waiting')}
						template={template}
					>
						{
							chatClosed ? (
								<Icon name={ICONS.CHAT_COMMENT} size={22} color={COLORS.WHITE} />
							) : (
								<Icon name={ICONS.CHAT_HIDE} size={22} color={COLORS.WHITE} />
							)
						}
					</Button>
				</OptionalComponent>
			</div>
		);
	};

	const showNewNav = useIsNewNavigation();
	const isSingleSession = useIsSingleSession();

	if (session.session_type === SessionTypesEnum.breakoutRooms) {
		return null;
	}

	return (
		<div
			className={classNames("session-stream-wrapper", template, {
				'chat-closed': chatClosed || isLessThan640,
				'overlay-chat': (session.layout?.overlayChat || forceOverlay),
				'fireside-session': isFireside,
				[timestampStatus]: isLive ? ETimestampStatus.live : timestampStatus,
				['supports-fullscreen']: isIphone,
				'moderator-view': moderatorView,
				'dark-mode': isDarkMode,
				'new-navigation': showNewNav && isSingleSession,
			})}
			style={{ ...styleOverrides, ...(moderatorView ? { height: '100%' } : {}) }}
			ref={scrollContainer}
		>
			<div
				className='session-stream-container'>
				<div
					className={
						classNames(
							"session-stream-container-inner",
							{
								center: (session.layout?.overlayChat || forceOverlay),
								"greater-than-1024": !isLessThan1024 && !isMobileEditor && !isTabletEditor,
							}
						)
					}
					ref={refToContainHeader}
				>
					<SessionThumbnail
						session={session}
						isEditor={isEditor}
					/>
					<div
						className={
							classNames(
								"session-stream",
								{
									"scrolled-past": hasScrolledPast && !isEditor && !isMobile && !isFullscreen,
									"is-iphone": isIphone,
									"less-than-1024": isLessThan1024 || isMobileEditor || isTabletEditor,
									"greater-than-640": !isLessThan640 && !isMobileEditor,
									"is-editor": isEditor
								},
							)
						}
						ref={container}>
						{renderSessionStream()}
						{//for tablet, we force the overlay regardless of what the admin has selected (except for Firesides and iFrame broadcasts)
							(!mobileOnly && (session.layout?.overlayChat || forceOverlay)) && sessionChatEnabled && (
								<Suspense fallback="">
									<ErrorBoundary uniqueLabel="Live chat overlay">
										<LiveChat
											session={session}
											loading={!!loadingEventBundle}
											language={language}
											registrationOn={!!eventBundle?.registration_on}
											blProfileUser={blProfileUser as BlProfile}
											template={template}
											forceOverlay={true}
											mobileOnly={false}
											onChatClose={handleChatClose}
											chatClosed={chatClosed}
											eventBundle={eventBundle}
											isAdmin={isAdmin}
											canGoLandscape={false}
										/>
									</ErrorBoundary>
								</Suspense>
							)
						}
					</div>
				</div>
				{isNewModuleGrouping && !isLessThan1024 && !isMobileEditor ? // && isChatPanelOpen
					<Suspense fallback="">
						<AboveTheFoldContent
							session={session}
							language={language}
							googleMeetBreakoutsRef={googleMeetBreakoutsRef}
							chatClosed={chatClosed}
							isEditor={!!isEditor}
						/>
					</Suspense> :
					//necessary to break these up to get the layouts correct
					!session.layout?.overlayChat
					&& !forceOverlay
					&& !isFullscreen
					&& !mobileOnly
					&& sessionChatEnabled
					&& (
						<Suspense fallback="">
							<ErrorBoundary uniqueLabel="Live chat not overlay">
								<LiveChat
									session={session}
									loading={!!loadingEventBundle}
									language={language}
									registrationOn={!!eventBundle?.registration_on}
									blProfileUser={blProfileUser as BlProfile}
									template={template}
									forceOverlay={false}
									mobileOnly={false}
									onChatClose={handleChatClose}
									chatClosed={chatClosed}
									eventBundle={eventBundle}
									isAdmin={isAdmin}
								/>
							</ErrorBoundary>
						</Suspense>
					)
				}


			</div >

			{!moderatorView &&
				<Suspense fallback="">
					<AddToCalendarModal
						session={session}
						eventName={eventBundle?.name || ""}
						open={openAddToCalendar}
						close={closeAddToCalendar}
						language={language}
						isSingleSessionNoHomepage={eventBundle?.sessions?.length === 1 && !eventBundle?.homepage}
					/>
				</Suspense>
			}
		</div >
	);
};

export default SessionStream;
