import { LiveState, LiveStateEntry, StreamState } from "types/live-state";
import { LeaderboardActions, LeaderboardScoreForProfile } from "../../types/leaderboard";
import { CreateVideo, HomepageVideo } from "../../types/working-model";

const processLeaderboardActions = (
	collated: Map<string | LeaderboardActions, LeaderboardScoreForProfile>,
	action: LeaderboardScoreForProfile
) => {
	const existing = collated.get(action.action ?? action.custom_action);
	if (existing) {
		existing.score += action.score;
	} else {
		collated.set(action.action ?? action.custom_action, action);
	}
	return collated;
};

export const getLeaderboardActionsAggregatedByAction = (payload: LeaderboardScoreForProfile[]): LeaderboardScoreForProfile[] => {
	const mapped = payload.reduce(
		processLeaderboardActions,
		new Map<string | LeaderboardActions, LeaderboardScoreForProfile>()
	);

	return Array.from(mapped.values());
};

export const homepageVideoToCreateVideo = (video: CreateVideo | HomepageVideo): CreateVideo => {
	return {
		...video,
		source: video.source ?? null,
		duration: video.duration ?? undefined
	};
};

export const playbackUrlToPathname = (playbackUrl: string | null | undefined): string => {
	try {
		if (!playbackUrl) {
			console.warn("playbackUrl is null or undefined");
			return "";
		}

		if (!playbackUrl.startsWith('http')) {
			return playbackUrl;
		}

		const url = new URL(playbackUrl ?? "");
		return url.pathname;
	} catch (e) {
		console.error(`Error parsing playbackUrl: ${e}`);
		return playbackUrl ?? "";
	}
};

export const getPlaybackUrlLiveState = (liveStates: LiveState, playbackUrl: string | null | undefined): LiveStateEntry | undefined => {
	if (!playbackUrl) {
		return {
			state: StreamState.offline,
			liveAt: 0,
		};
	}
	return liveStates[playbackUrlToPathname(playbackUrl)];
};