
import React from "react";
import { Link } from 'react-router-dom';

import { RegistrationPanelLayoutsTypes } from "../../../../../../../types/working-model";
import Icon, { COLORS, ICONS } from "../../../../../../general-ui/icon";
import { useGetAdminUrl } from "../../../../../../../utils/admin-routing-utils";
import { useTypedSelector } from "../../../../../../../store/reducers/use-typed-selector";
import MarketingEmailOptInSwitches from "./marketing-email-opt-in-switches";
import { OptionalComponent } from "../../../../../../../utils/optional-component";
import { RegistrationPanelMap } from "@admin/navigation/registration-panel-route-map";

import "./registration-panel.scss";

const PanelSettings: React.FC = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const adminPath = useGetAdminUrl();

	const registrationOn = workingEvent?.registration_on || false;

	return (
		<div className="registration-options-container-v2">
			<div className="registration-panel-container">

				<OptionalComponent display={registrationOn}>
					<Link role="button" to={adminPath({ path: RegistrationPanelMap[RegistrationPanelLayoutsTypes.SettingsGating] })} className="clear no-style">
						<div className="settings-card no-style">
							<div>
								<label>Gating</label>
								<p>Registration settings</p>
							</div>
							<Icon name={ICONS.ARROW_RIGHT} size={14} color={COLORS.WHITE} />
						</div>
					</Link>

					<Link role="button" to={adminPath({ path: RegistrationPanelMap[RegistrationPanelLayoutsTypes.SettingsSSO] })} className="clear no-style">
						<div className="settings-card no-style">
							<div>
								<label>SSO</label>
								<p>Configure SSO</p>
							</div>
							<Icon name={ICONS.ARROW_RIGHT} size={14} color={COLORS.WHITE} />
						</div>
					</Link>

					<MarketingEmailOptInSwitches />

					<Link role="button" to={adminPath({ path: RegistrationPanelMap[RegistrationPanelLayoutsTypes.SettingsEmails] })} className="clear no-style">
						<div className="settings-card no-style">
							<div>
								<label>Emails</label>
								<p>Registration settings</p>
							</div>
							<Icon name={ICONS.ARROW_RIGHT} size={14} color={COLORS.WHITE} />
						</div>
					</Link>

					<Link role="button" to={adminPath({ path: RegistrationPanelMap[RegistrationPanelLayoutsTypes.SettingsAdditionalSettings] })} className="clear no-style">
						<div className="settings-card no-style">
							<div>
								<label>Additional Settings</label>
							</div>
							<Icon name={ICONS.ARROW_RIGHT} size={14} color={COLORS.WHITE} />
						</div>
					</Link>
				</OptionalComponent>
			</div>
		</div>
	);
};

export default PanelSettings;
