import { RegistrationPanelMap } from "@admin/navigation/registration-panel-route-map";
import { matchPath } from "react-router-dom";
import { SessionPanelMap } from "../components/admin/create/session/navigation/panel/session-panel-route-map";
import { ModuleGroupingTypes, PageModuleGroupModules, RegistrationPanelLayoutsTypes, SessionPanelLayoutsTypes } from "../types/working-model";
import { GetAdminUrlProps } from "./admin-routing-utils";

export const isOnPanel = (pathname: string, panels: string[], exact = false) => {
	return !!matchPath(pathname, {
		path: panels,
		exact
	});
};

export const getSessionPanelRouteState = (pathname: string, exact = false) => {
	const isEngage = isOnPanel(
		pathname,
		[
			SessionPanelMap[SessionPanelLayoutsTypes.Engage]
		],
		exact
	);

	const isDetails = isOnPanel(
		pathname,
		[
			SessionPanelMap[SessionPanelLayoutsTypes.Engage]
		],
		exact
	);

	const isEducation = isOnPanel(
		pathname,
		[
			SessionPanelMap[SessionPanelLayoutsTypes.Education]
		],
		exact
	);

	const isExtras = isOnPanel(
		pathname,
		[
			SessionPanelMap[SessionPanelLayoutsTypes.Extras],
			// SessionPanelMap[SessionPanelLayoutsTypes.ExtraProducts],
			SessionPanelMap[SessionPanelLayoutsTypes.ExtraSuggested],
			SessionPanelMap[SessionPanelLayoutsTypes.ExtraResources]
		],
		exact
	);

	const isExtrasCustom = isOnPanel(
		pathname,
		[
			SessionPanelMap[SessionPanelLayoutsTypes.Custom]
		],
		exact
	);

	const isCourses = isOnPanel(
		pathname,
		[
			SessionPanelMap[SessionPanelLayoutsTypes.Courses]
		],
		exact
	);

	return {
		isEngage,
		isDetails,
		isEducation,
		isExtras,
		isExtrasCustom,
		isCourses
	};
};

export const getExtrasPanelTabRouteState = (pathname: string, exact = false) => {
	const isProducts = isOnPanel(
		pathname,
		[
			SessionPanelMap[SessionPanelLayoutsTypes.ExtraProducts]
		],
		exact
	);

	const isResources = isOnPanel(
		pathname,
		[
			SessionPanelMap[SessionPanelLayoutsTypes.ExtraResources]
		],
		exact
	);

	const isSuggested = isOnPanel(
		pathname,
		[
			SessionPanelMap[SessionPanelLayoutsTypes.ExtraSuggested]
		],
		exact
	);

	const isSpeaker = isOnPanel(
		pathname,
		[
			SessionPanelMap[SessionPanelLayoutsTypes.ExtraSpeaker]
		],
		exact
	);

	const isCustomEmbed = isOnPanel(
		pathname,
		[
			SessionPanelMap[SessionPanelLayoutsTypes.ExtraEmbed]
		],
		exact
	);

	return {
		isProducts,
		isResources,
		isSuggested,
		isSpeaker,
		isCustomEmbed
	};
};

export const getRegistrationPanelRouteState = (pathname: string, exact = false) => {
	const isContent = isOnPanel(
		pathname,
		[
			RegistrationPanelMap[RegistrationPanelLayoutsTypes.Content],
		],
		exact
	);
	const isLayout = isOnPanel(
		pathname,
		[
			RegistrationPanelMap[RegistrationPanelLayoutsTypes.Layout],
		],
		exact
	);
	const isSettings = isOnPanel(
		pathname,
		[
			RegistrationPanelMap[RegistrationPanelLayoutsTypes.Settings],
		],
		exact
	);

	return {
		isContent,
		isLayout,
		isSettings,
	};
};

export const navigateOnClickTo = (
	adminPath: (props: GetAdminUrlProps) => string,
	moduleGroup: PageModuleGroupModules,
	session?: string
) => {
	if (moduleGroup?.type === ModuleGroupingTypes.Engage) {
		return adminPath({
			path: SessionPanelMap[SessionPanelLayoutsTypes.Engage],
			session
		});
	}
	if (moduleGroup?.type === ModuleGroupingTypes.Extras) {
		return adminPath({
			path: SessionPanelMap[SessionPanelLayoutsTypes.Extras],
			session
		});
	}
};

export const navigateOnClickState = (
	moduleGroup: PageModuleGroupModules
) => {
	if (moduleGroup?.type === ModuleGroupingTypes.Engage) {
		return {
			panelTitle: SessionPanelLayoutsTypes.Engage
		};
	}

	if (moduleGroup?.type === ModuleGroupingTypes.Extras) {
		return {
			panelTitle: SessionPanelLayoutsTypes.Extras
		};
	}
};