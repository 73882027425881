import { ConnectedAdmin, IBreakoutRoomProfile } from "../../types/working-model";
import { SocketConnection } from "./socket-connection";
import * as EventEmitter from '../../utils/event-emitter';
import { getLogger } from "../../utils/debug-logger";
import { GetSocketOptions } from "./types";

const log = getLogger('bl-socket:manager');
const silly = getLogger('bl-socket-silly:manager');

const improperUsageWarning = `Error: using the same socket namespace manually and from a hook is deprecated and can lead to accidental socket disconnects.`;

class SocketManager {
	private sockets: Map<string, SocketConnection> = new Map();
	private chatSockets: Map<string, SocketConnection> = new Map();
	private socketsFromHook: Set<string> = new Set();
	private chatSocketsFromHook: Set<string> = new Set();
	private toLeave: Set<string> = new Set();
	private toHave: Set<string> = new Set();
	private chatToHave: Set<string> = new Set();
	private changed = false;

	constructor() {
		setInterval(() => this.tick(), 1000);
		EventEmitter.on('socket-disconnect', this.disconnectAll);
		EventEmitter.on('socket-reconnect', this.reconnectAll);
	}

	private disconnectAll = () => {
		for (const socket of this.sockets.values()) {
			socket.end();
		}

		for (const socket of this.chatSockets.values()) {
			socket.end();
		}

		EventEmitter.off('socket-disconnect');
	};

	private reconnectAll = () => {
		for (const socket of this.sockets.values()) {
			socket.setupConnection();
		}

		for (const socket of this.chatSockets.values()) {
			socket.setupConnection();
		}

		EventEmitter.on('socket-disconnect', this.disconnectAll);
	};

	public get = (ns: string, options?: GetSocketOptions): SocketConnection => {
		const socketsFromHook = options?.chat ? this.chatSocketsFromHook : this.socketsFromHook;
		if (socketsFromHook.has(ns)) {
			if (process.env.REACT_APP_STAGE === 'local') {
				throw new Error(`${improperUsageWarning} ${ns} ${JSON.stringify(options)}`);
			}

			console.warn(improperUsageWarning, ns, options);
		}

		silly(`Mounted ${ns}`);
		const sockets = options?.chat ? this.chatSockets : this.sockets;
		const toHave = options?.chat ? this.chatToHave : this.toHave;
		toHave.add(ns);
		if (sockets.has(ns)) {
			silly(`Reusing ${ns}`);
			return sockets.get(ns) as SocketConnection;
		} else {
			silly(`Creating ${ns}`);
			const socket = new SocketConnection(ns, options);
			sockets.set(ns, socket);
			this.changed = true;
			return socket;
		}
	};

	public getFromHook = (ns: string, options?: GetSocketOptions): SocketConnection => {
		const socketsFromHook = options?.chat ? this.chatSocketsFromHook : this.socketsFromHook;
		const toHave = options?.chat ? this.chatToHave : this.toHave;
		const sockets = options?.chat ? this.chatSockets : this.sockets;

		silly(`Mounted ${ns}`);
		toHave.add(ns);
		socketsFromHook.add(ns);
		if (sockets.has(ns)) {
			silly(`Reusing ${ns}`);
			return sockets.get(ns) as SocketConnection;
		} else {
			silly(`Creating ${ns}`);
			const socket = new SocketConnection(ns, options);
			sockets.set(ns, socket);
			this.changed = true;
			return socket;
		}
	};

	public leave = (ns: string) => {
		this.toLeave.add(ns);
		silly(`Dismounted ${ns}`);
	};

	private iterateLeave = (ns: string, chat?: boolean) => {
		const sockets = chat ? this.chatSockets : this.sockets;
		const toHave = chat ? this.chatToHave : this.toHave;

		if (!toHave.has(ns)) {
			this.changed = true;
			silly(`Leaving ${ns}`);
			const socket = sockets.get(ns);
			const listeners = socket?.getListeners();
			if (socket && (!listeners || listeners?.length === 0)) {
				sockets.get(ns)?.disconnect();
				sockets.delete(ns);
				silly(`Left ${ns}`);
			} else if (socket) {
				silly(`Cannot leave ${ns}, ${listeners} remain.`);
			}
		}
	};

	private tick = () => {
		for (const ns of this.toLeave) {
			this.iterateLeave(ns);
			this.iterateLeave(ns, true);
		}

		if (this.changed) {
			log(`Should have channels`, Array.from(this.sockets.keys()));
		}

		this.toHave = new Set();
		this.chatToHave = new Set();
		this.toLeave = new Set();
		this.changed = false;
	};
}

export default new SocketManager();