import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { union, difference, isEqual, uniq } from 'underscore';

import { useTypedSelector } from '../store/reducers/use-typed-selector';
import { getPasscodeLists } from '../store/actions/admin/passcode-lists';
import { updateRegistrationSteps, updateRequiredQuestions } from '../store/actions/admin/create-event';
import { GateTypes, PASSCODE_LIST_TYPE, RegistrationTypes, RegFieldsEnum, RegistrationQuestion, RegistrationStepType, SessionTypesEnum, PageGate, PasscodeList, FeatureFlagsEnum, MarketingOptInTypes } from '../types/working-model';
import { isPartiallyGatedEvent } from './utils';


interface IRequiredFields {
	on: RegFieldsEnum[];
	off: RegFieldsEnum[];
	onAndOptional: RegFieldsEnum[];
	onAndOptionalOrRequired: RegFieldsEnum[];
}

export const useFieldRequired = (): IRequiredFields => {
	const token = useTypedSelector(state => state.AuthReducer.token);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const passcodeLists = useTypedSelector(state => state.PasscodeListReducer.passcodeLists);
	const loadingPasscodeLists = useTypedSelector(state => state.PasscodeListReducer.loadingPasscodeLists);
	const loadedPasscodeLists = useTypedSelector(state => state.PasscodeListReducer.loadedPasscodeLists);
	const courseOn = workingEvent?.settings?.course_enabled;

	const dispatch = useDispatch();

	useEffect(() => {
		if (user && token && !loadedPasscodeLists && !loadingPasscodeLists) {
			dispatch(getPasscodeLists(user.active_channel, token));
		}
	}, [dispatch, token, user, loadedPasscodeLists, loadingPasscodeLists]);

	// registration questions that must be on and required for other features to function
	let requiredOn: RegFieldsEnum[] = [];
	// registration questions that must be on and but NOT required for other features to function
	const requiredOnAndOptional: RegFieldsEnum[] = [];
	// registration questions that should be off and not in the required array
	const requiredOff: RegFieldsEnum[] = [];
	// registration questions that should be on and either optional or required
	const requiredOnAndOptionalOrRequired: RegFieldsEnum[] = [];

	if (!workingEvent || loadingPasscodeLists) {
		return { on: [], off: [], onAndOptional: [], onAndOptionalOrRequired: [] };
	}
	const gating = workingEvent.registration_settings?.type === RegistrationTypes.gated;
	const passcodeListRequirements = workingEvent.registration_settings?.requirements[GateTypes.passcode_list];
	const sessionIDs = workingEvent.sessions.map(s => s.session);
	const isPartiallyGated = isPartiallyGatedEvent(passcodeListRequirements, sessionIDs);

	// finds passcode lists for registration (not page gating)
	const findPasscodeLists = (listTypesArray: PASSCODE_LIST_TYPE[]): boolean => {
		const passcodeListObjects = workingEvent.registration_settings?.requirements?.[GateTypes.passcode_list];
		const passcodeListMatch = passcodeListObjects?.find(listObject => listObject?.passcodeList?.find(list => {
			if (passcodeLists) {
				const listType = passcodeLists.find(passcodeList => passcodeList.passcode_list === list)?.type;
				if (listType !== undefined && listTypesArray.includes(listType)) return true;
			}
		}));
		return passcodeListMatch ? true : false;
	};

	// EMAIL: must be turned ON and REQUIRED if ANY of the following registration features are being used:
	// email confirmation, profile, ticketing, avatar, passcode list using email, domains specifically allowed or blocked
	const emailConfirm = workingEvent.registration_settings?.registrationConfirmation || workingEvent.registration_settings?.sendEmailConfirmation;
	const profile = workingEvent.registration_steps && workingEvent.registration_steps.find(step => step.type === RegistrationStepType.profile)?.isOn;
	const ticketing = workingEvent.registration_steps && workingEvent.registration_steps.find(step => step.type === RegistrationStepType.ticketing)?.isOn;
	const avatar = workingEvent.registration_steps && workingEvent.registration_steps.find(step => step.type === RegistrationStepType.avatar)?.isOn;
	const blockedDomains = workingEvent.registration_settings?.useBlockedDomainEmails;

	// but only check gating requirements if registration gating is turned on
	const emailPasscodeList = gating && findPasscodeLists([PASSCODE_LIST_TYPE.EMAILS_ONLY, PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES]);
	const allowedDomains = gating && workingEvent.registration_settings?.requirements?.[GateTypes.domain_email];

	if (emailConfirm || profile || ticketing || avatar || emailPasscodeList || allowedDomains || blockedDomains) {
		requiredOn.push(RegFieldsEnum.email);
	}

	// EMAIL: must be turned ON and REQUIRED if ANY of the following social features are being used:
	// directory, profiles, messaging
	const directoryOn = workingEvent.social_settings?.directory.isOn;
	const profilesOn = workingEvent.social_settings?.profiles.isOn;
	const messagingOn = workingEvent.social_settings?.messaging;

	if (directoryOn || profilesOn || messagingOn) {
		requiredOn.push(RegFieldsEnum.email);
	}

	if (courseOn) {
		requiredOn.push(RegFieldsEnum.email, RegFieldsEnum.first_name, RegFieldsEnum.last_name);
	}

	// PASSCODE: must be turned ON and REQUIRED if ANY of the following registration features are being used:
	// shared passcode, passcode lists other than email only (but only if registration gating is currently on and not a partially gated event)
	const passcode = gating && workingEvent.registration_settings?.requirements[GateTypes.shared_passcode];
	const passcodeList = gating && findPasscodeLists([PASSCODE_LIST_TYPE.PASSCODES_ONLY, PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES]);

	if (passcode) {
		requiredOn.push(RegFieldsEnum.passcode);
	}

	if (passcodeList) {
		if (isPartiallyGated) {
			requiredOnAndOptional.push(RegFieldsEnum.passcode);
		} else {
			requiredOn.push(RegFieldsEnum.passcode);
		}
	}

	// if SSO enabled, remove first and last name from required fields,
	// however, do this BEFORE breakout sessions and leaderboard because those features require first and last name
	const ssoEnabled = workingEvent.registration_settings?.singleSignOn?.isOn;
	if (ssoEnabled) {
		requiredOn = requiredOn.filter(field => {
			return !([RegFieldsEnum.first_name, RegFieldsEnum.last_name].includes(field));
		});
	}

	// BREAKOUT SESSIONS
	// Email, first name, and last name are required for breakout sessions (chat/attendee info)
	const breakoutSession = workingEvent?.sessions.some(session => session.session_type === SessionTypesEnum.breakoutRooms);
	if (breakoutSession) {
		requiredOn.push(RegFieldsEnum.email);
		requiredOn.push(RegFieldsEnum.first_name);
		requiredOn.push(RegFieldsEnum.last_name);
	}

	// CUSTOM PAGE GATING
	// Email and/or passcode required for passcode lists
	const pageGating = workingEvent?.settings.pageGating;
	if (pageGating?.isOn) {
		pageGating.pageGates.forEach((gate: PageGate) => {
			gate.passcodeLists.forEach((list: PasscodeList) => {
				if ([PASSCODE_LIST_TYPE.EMAILS_ONLY, PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES].includes(list.type)) {
					requiredOn.push(RegFieldsEnum.email);
				}
				if ([PASSCODE_LIST_TYPE.PASSCODES_ONLY, PASSCODE_LIST_TYPE.EMAILS_AND_PASSCODES].includes(list.type)) {
					requiredOn.push(RegFieldsEnum.passcode);
				}
			});
		});
	}

	// PASSCODE OFF: if passcode is not required for anything above, it should be off and removed from required array
	if (!requiredOn.includes(RegFieldsEnum.passcode) && !requiredOnAndOptional.includes(RegFieldsEnum.passcode)) {
		requiredOff.push(RegFieldsEnum.passcode);
	}

	// LEADERBOARD FEATURE
	// Email, first and last are reuired for leaderboard feature if flag is enabled
	const leaderboardOn = !!workingEvent.settings?.leaderboard_settings?.enabled;
	if (leaderboardOn) {
		requiredOn.push(RegFieldsEnum.email);
		requiredOn.push(RegFieldsEnum.first_name);
		requiredOn.push(RegFieldsEnum.last_name);
	}

	// MARKETING EMAIL OPT-IN
	// Email is required if marketing email opt-in is turned on
	const marketingEmailOptions = workingEvent?.registration_settings?.marketing_email_options;
	const isMarketingOptInEnabled = marketingEmailOptions === MarketingOptInTypes.singleOptIn || marketingEmailOptions === MarketingOptInTypes.doubleOptIn;
	if (isMarketingOptInEnabled) {
		requiredOnAndOptionalOrRequired.push(RegFieldsEnum.email);
	}

	// compiled array of registration fields required for other registration steps currently in use
	return {
		on: uniq(requiredOn),
		off: uniq(requiredOff),
		onAndOptional: uniq(requiredOnAndOptional),
		onAndOptionalOrRequired: uniq(requiredOnAndOptionalOrRequired),
	};
};

export const useSetRequiredField = async (required: IRequiredFields): Promise<void> => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const registrationQuestions = useTypedSelector(state => state.RegistrationQuestionsReducer.registrationQuestions);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!workingEvent?.registration_on) return;
		if (!required.on.length && !required.off.length && !required.onAndOptional) return;
		if (!workingEvent?.registration_steps || !registrationQuestions.length) return;

		const steps = workingEvent.registration_steps;
		const generalStepQuestions = steps?.[0]?.questions || [];
		const generalStepQuestionIDs = generalStepQuestions.map(question => question.registration_question);
		const reqQuestions = workingEvent.required_registration_questions || [];

		// make sure required ON fields are added to general registration steps and to required array, 
		// and onAndOptional is not added to required array
		let updatedGeneralStepQuestionIDs: number[] = union(generalStepQuestionIDs, [...required.on, ...required.onAndOptional]);
		let updatedReqQuestions: number[] = union(reqQuestions, required.on);

		// make sure required OFF fields are removed from general registration steps and from required array,
		// and onAndOptional is not added to required array
		updatedGeneralStepQuestionIDs = difference(updatedGeneralStepQuestionIDs, required.off);
		updatedReqQuestions = difference(updatedReqQuestions, [...required.off, ...required.onAndOptional]);

		// if changes have been made, dispatch updates
		if (!isEqual(generalStepQuestionIDs, updatedGeneralStepQuestionIDs)) {
			const updatedGeneralStepQuestions = updatedGeneralStepQuestionIDs.map(questionID =>
				registrationQuestions.find(question => question.registration_question === questionID))
				.filter(question => question) as RegistrationQuestion[]; // filter out possible undefined
			const updateSteps = [...steps];
			const updateGeneralStep = { ...steps[0], questions: updatedGeneralStepQuestions };
			updateSteps.splice(0, 1, updateGeneralStep);
			dispatch(updateRegistrationSteps(updateSteps));
		}
		if (!isEqual(reqQuestions, updatedReqQuestions)) {
			dispatch(updateRequiredQuestions(updatedReqQuestions));
		}
	}, [
		required,
		dispatch, workingEvent?.registration_on,
		workingEvent?.registration_steps,
		workingEvent?.required_registration_questions,
		registrationQuestions,
		workingEvent?.registration_settings?.singleSignOn?.isOn
	]);
};
