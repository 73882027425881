import React, { lazy, Suspense, useCallback, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { BrandliveEvent, CreateVideo, Session, SessionTypesEnum } from '../../../../../types/working-model';
import { VideoStateContext } from '../session-stream-provider';
import { VideoPlayerType } from './types';
import Tracking from './components/tracking';
import PrePostLivePlaceholder from './pre-post-live';
import { numClamp } from '../../../../../utils/utils';
import PausedCover from './components/paused-cover';
import { useIsNewNavigation } from '../../../../../hooks/navigation.hooks';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useTypedSelector } from 'store/reducers/use-typed-selector';
import { ThemeContext } from 'components/live-event/theme-context';
import { UseFiresideMeetData } from '../../hooks/fireside-meet';
import { EPaletteModes } from 'types/theme-packs';

import './video.scss';
import { useGetRecordingUrl } from 'hooks/recording-jwt.hooks';

const Controls = lazy(() => import('./controls'));
const HlsPlayer = lazy(() => import('./players/hls'));
const IvsPlayer = lazy(() => import('./players/ivs'));
const EmbedPlayer = lazy(() => import('./players/embed'));
const LinkPlayer = lazy(() => import('./players/link'));
const MeetPlayer = lazy(() => import('./players/google-meet'));
const Mp4Player = lazy(() => import('./players/mp4'));

type VideoProps = {
	onDemandVideo?: CreateVideo;
	session: Session;
	onRequestFullscreen?: (fullscreen: boolean) => void;
	isFullscreen: boolean;
	onChatClose?: (closed: boolean) => void;
	chatClosed: boolean;
	hideChatButton: boolean;
	chatIsOverlaid: boolean;
	isMobile: boolean;
	pip: boolean;
	isAdmin: boolean;
	event?: BrandliveEvent;
	moderatorView?: boolean;
	tryNativeFullscreen: boolean;
	onEndNativeFullscreen: () => void;
	hostToken?: string | undefined;
	firesideHostIdToken?: string | undefined;
	firesideMeetData?: UseFiresideMeetData
}

const googleOAuthClientId = process.env.REACT_APP_MEET_INTEGRATION_OAUTH_CLIENT_ID || '';

const Video: React.FC<VideoProps> = ({
	session,
	isFullscreen,
	onChatClose,
	chatClosed,
	hideChatButton,
	chatIsOverlaid,
	pip,
	isAdmin,
	event,
	moderatorView,
	tryNativeFullscreen,
	onEndNativeFullscreen,
	hostToken,
	firesideMeetData,
}) => {
	const { state: {
		playerType,
		cancelPip,
		showPlaceholder,
		useNativePip,
		isLive,
		onDemandVideo,
		currentDynamicVideo
	} } = useContext(VideoStateContext);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const lastLeftRef = useRef<number | null>(null);
	const lastTopRef = useRef<number | null>(null);
	const showNewNav = useIsNewNavigation();
	const fsJoined = useTypedSelector(state => state.FiresidesReducer.fsJoined);
	const {
		isFiresideMeet,
		isFiresideLive,
		displayFiresideMeetPlaceholder
	} = firesideMeetData || {};
	const useIvsPlayerForFiresideMeet = !isFiresideMeet && playerType === VideoPlayerType.meet;
	const [theme] = useContext(ThemeContext);
	const isMeetEmbed = playerType === VideoPlayerType.meet && ((!isFiresideLive && !currentDynamicVideo) || fsJoined);
	const forceLightTheme = isMeetEmbed && theme === EPaletteModes.Light;
	const showMeetRecording = !isFiresideLive && !useIvsPlayerForFiresideMeet && !!currentDynamicVideo && !fsJoined;
	const [playButtonClicked, setPlayButtonClicked] = useState(false);
	const [forcingRefresh, setForcingRefresh] = useState(false);
	const [playableUrl, setPlayableUrl] = useState<string | null | undefined>(null);
	const getRecordingUrl = useGetRecordingUrl();

	useEffect(() => {
		if (session.session_type === SessionTypesEnum.onDemand) {
			setPlayableUrl(getRecordingUrl(onDemandVideo?.hls_link ?? onDemandVideo?.source ?? undefined));
		} else {
			setPlayableUrl(getRecordingUrl(currentDynamicVideo?.playback_url));
		}
	}, [currentDynamicVideo?.playback_url, getRecordingUrl, onDemandVideo?.hls_link, onDemandVideo?.source, session.session_type]);

	useEffect(() => {
		setForcingRefresh(true);
		setTimeout(() => {
			setForcingRefresh(false);
		}, 16); // one frame
	}, [playableUrl]);

	useEffect(() => {
		const notMobile = window.innerWidth > 639;
		const container = containerRef.current;

		const getMaxY = (box: DOMRect, minY = 20) => window.innerHeight - (box.height + minY);
		const getMaxX = (box: DOMRect, minX = 28) => window.innerWidth - (box.width + minX);

		if (!displayFiresideMeetPlaceholder && !showPlaceholder && !useNativePip && notMobile && pip && !cancelPip && container) {
			let deltaX = 0;
			let deltaY = 0;
			let startX = 0;
			let startY = 0;
			let initialX = 0;
			let initialY = 0;
			let isDragging = false;
			const minX = !showNewNav ? 95 + 28 : 28;
			const minY = 20;
			const box = container.getBoundingClientRect();

			let maxY = getMaxY(box);
			let maxX = getMaxX(box);

			let currentX = box.left;
			let currentY = box.top;

			if (lastTopRef.current === null) {
				lastTopRef.current = maxY;
			}

			if (lastLeftRef.current === null) {
				lastLeftRef.current = maxX;
			}

			// Adam's original popup code to return to last location
			// container.style.setProperty('top', `${numClamp(lastTopRef.current, minY, maxY)}px`);
			// container.style.setProperty('left', `${numClamp(lastLeftRef.current, minX, maxX)}px`);

			const handleRelease = (e: PointerEvent) => {
				isDragging = false;
				container.removeEventListener('pointermove', handleMove);
				container.removeEventListener('pointerup', handleRelease);

				// user is returning the pip frame to the top, don't set anything
				if ((e.target as HTMLElement)?.classList.contains('pip-toggle')) {
					return;
				}

				lastTopRef.current = currentY;
				lastLeftRef.current = currentX;
				document.body.style.userSelect = '';
			};

			const handleMove = (e: PointerEvent) => {
				const clientX = e.clientX;
				const clientY = e.clientY;

				deltaX = clientX - startX;
				deltaY = clientY - startY;

				currentX = numClamp(initialX + deltaX, minX, maxX);
				currentY = numClamp(initialY + deltaY, minY, maxY);

				if (isDragging) {
					container.style.setProperty('top', `${currentY}px`);
					container.style.setProperty('left', `${currentX}px`);
				}
			};

			const handleGrab = (e: PointerEvent) => {
				const box = container.getBoundingClientRect();
				initialX = box.left;
				initialY = box.top;

				maxX = getMaxX(box, minX);
				maxY = getMaxY(box, minY);

				document.body.style.userSelect = 'none';

				isDragging = true;
				startX = e.clientX;
				startY = e.clientY;

				container.addEventListener('pointermove', handleMove);
				document.addEventListener('pointerup', handleRelease);
			};

			const handleResize = () => {
				const box = container.getBoundingClientRect();
				// const left = box.left;
				const top = box.top;
				// const maxX = getMaxX(box);
				const maxY = getMaxY(box);

				// Adam's original popup code to return to last location. Now favoring the video to sit in the gutter
				// if (left > maxX) {
				// container.style.setProperty('left', `${maxX}px`);
				// }

				if (top > maxY) {
					container.style.setProperty('top', `${maxY}px`);
				}
			};

			container.addEventListener('pointerdown', handleGrab);
			window.addEventListener('resize', handleResize);

			return () => {
				container.removeEventListener('pointerdown', handleGrab);
				container.removeEventListener('pointermove', handleMove);
				document.addEventListener('pointerup', handleRelease);
				window.removeEventListener('resize', handleResize);
			};
		} else {
			container?.style.removeProperty('top');
			container?.style.removeProperty('left');
		}
	}, [pip, cancelPip, showNewNav, showPlaceholder, displayFiresideMeetPlaceholder, useNativePip]);

	const handleLinkPlayButtonClick = useCallback(() => {
		setPlayButtonClicked(true);
	}, []);

	if (!session || !event || forcingRefresh) {
		return <></>;
	}

	if (displayFiresideMeetPlaceholder || showPlaceholder || playerType === VideoPlayerType.placeholder) {
		return (
			<PrePostLivePlaceholder
				isAdmin={isAdmin}
				session={session}
				event={event}
				moderatorView={moderatorView}
				chatClosed={chatClosed}
				onChatClose={onChatClose}
				isFullscreen={isFullscreen}
				hideChatButton={moderatorView}
			/>
		);
	}

	return (
		<div
			id={`video-player-v2-${session.uuid}`}
			ref={containerRef}
			className={
				classNames(
					"video-player-v2-wrapper",
					{
						'is-fullscreen': isFullscreen,
						'is-on-demand': playerType === VideoPlayerType.hls,
						'chat-is-overlaid': chatIsOverlaid,
						'chat-closed': chatClosed,
						'hide-chat-button': hideChatButton,
						'pip': pip && !cancelPip,
						embed: playerType === VideoPlayerType.embed,
						'force-light-theme': forceLightTheme
					}
				)
			}
		>
			<Tracking isLive={isLive} />
			<div className="video-player-layer">
				{
					(playerType === VideoPlayerType.meet ? (
						<GoogleOAuthProvider clientId={googleOAuthClientId}>
							<Suspense fallback={<div className="loading-placeholder" />}>
								<MeetPlayer
									isAdmin={isAdmin}
									event={event}
									moderatorView={moderatorView}
									chatClosed={chatClosed}
									onChatClose={onChatClose}
									isFullscreen={isFullscreen}
									hostToken={hostToken}
									firesideMeetData={firesideMeetData as UseFiresideMeetData}
								/>
							</Suspense>
						</GoogleOAuthProvider>
					) : null
					)}

				{
					(playerType === VideoPlayerType.hls && playableUrl) ? (
						<Suspense fallback={<div className="loading-placeholder" />}>
							<HlsPlayer
								tryNativeFullscreen={tryNativeFullscreen}
								onEndNativeFullscreen={onEndNativeFullscreen}
								playableUrl={playableUrl}
							/>
						</Suspense>
					) : null
				}

				{
					(playerType === VideoPlayerType.ivs || useIvsPlayerForFiresideMeet) ? (
						<Suspense fallback={<div className="loading-placeholder" />}>
							<IvsPlayer
								tryNativeFullscreen={tryNativeFullscreen}
								onEndNativeFullscreen={onEndNativeFullscreen}
							/>
						</Suspense>
					) : null
				}

				{
					playerType === VideoPlayerType.embed ? (
						<Suspense fallback={<div className="loading-placeholder" />}>
							<EmbedPlayer />
						</Suspense>
					) : null
				}

				{
					playerType === VideoPlayerType.link ? (
						<Suspense fallback={<div className="loading-placeholder" />}>
							<LinkPlayer playButtonClicked={playButtonClicked} onPlayButtonClick={handleLinkPlayButtonClick} />
						</Suspense>
					) : null
				}

				{
					(playerType === VideoPlayerType.mp4) ? (
						<Suspense fallback={<div className="loading-placeholder" />}>
							<Mp4Player
								tryNativeFullscreen={tryNativeFullscreen}
								onEndNativeFullscreen={onEndNativeFullscreen}
							/>
						</Suspense>
					) : null
				}
			</div>

			<div className={"video-controls-layer"}>
				<Suspense fallback={<div />}>
					<Controls
						onChatClose={onChatClose}
						isFullscreen={isFullscreen}
						chatClosed={chatClosed}
						isPip={pip}
						moderatorView={moderatorView}
						event={event}
						isAdmin={isAdmin}
						onPlayButtonClick={handleLinkPlayButtonClick}
						playButtonClicked={playButtonClicked}
						showMeetRecording={showMeetRecording}
					/>
				</Suspense>
			</div>

			<PausedCover event={event} isFullscreen={isFullscreen} />
		</div>
	);
};

// Video.whyDidYouRender = true;
export default Video;
