import classNames from "classnames";
import { useParams } from "react-router-dom";

import { Document, TranslateString } from "../../../../../../../types/working-model";
import NavigationDropdown from "../../../../../../general-ui/dropdown/navigation-dropdown";
import Icon, { ICONS, COLORS } from "../../../../../../general-ui/icon";

const tString = (str: TranslateString, language: string) => {
	if(!str) return '';
	return str[language] || str.base;
};

type Props = {
	document: Document;
	selected?: boolean;
	handleSelectedDocument?: (id: number, selected: boolean) => void;
	handleEdit?: (document: Document) => void;
	handleDelete?: (document: Document) => void;
};
const DocumentCard = ({ document, selected, handleSelectedDocument, handleEdit, handleDelete }: Props) => {
	const { document: id } = document;
	const { language } = useParams<{ language: string }>();

	const extensionMatch = (document.location || '').match(/\.([a-zA-Z0-9]+)$/);
	const extension = extensionMatch ? extensionMatch[1] : '';

	return (
		<div
			className={classNames("panel-card document-card", { selected })}
			key={document.document}
			onClick={() => handleSelectedDocument?.(id as number, !selected)}
		>
			<div
				className="document-image"
				style={{ backgroundImage: `url(${document.thumbnail ?? document.generated_thumbnail})` }}
			>
				<div className={classNames("type-bubble", extension)}>{extension}</div>
			</div>
			<div className="document-data">
				<label>{tString(document.display_name, language)}</label>
			</div>
			<div className="document-actions">
				<NavigationDropdown
					title={<Icon name={ICONS.THREE_DOTS_VERTICAL} size={20} color={COLORS.WHITE} />}
					className="added-card-dropdown"
					buttonClassName="question-card-options round no-style"
					edge="right"
					isArrow={false}
				>
					<>
						<a download={true} href={document.location} rel="noreferrer" target="_blank">
							Download
						</a>

						{handleEdit && <button onClick={() => handleEdit(document)}>Edit</button>}
						{handleDelete && <button onClick={() => handleDelete(document)}>Delete</button>}
					</>
				</NavigationDropdown>
			</div>
		</div>
	);
};

export default DocumentCard;