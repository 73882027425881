import { ChannelFeaturesEnum, ConnectedAdmin, IBreakoutRoomProfile } from '../types/working-model';
import { useEffect, useMemo, useState } from 'react';
import manager from './socket-main-thread/socket-manager';
import { SocketConnection } from './socket-main-thread/socket-connection';
import { GetSocketOptions, SocketMessage } from './socket-main-thread/types';
import { useTypedSelector } from 'store/reducers/use-typed-selector';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export default interface Socket extends SocketConnection {
	// Must use SocketManager to get a new socket connection. 
	// using new Socket() is deprecated in components
}

const baseConnection = new SocketConnection('');

export function useSocket(channel: string, options?: GetSocketOptions): Socket {
	const [socket, setSocket] = useState<Socket>(baseConnection);

	useEffect(() => {
		if (channel.includes('undefined')) {
			console.warn('Skipping ws channel', channel, 'because it includes "undefined"');
			return;
		}

		setSocket(manager.getFromHook(channel, options));

		return () => {
			manager.leave(channel);
		};
	}, [channel, options]);

	return socket;
}

const forceChatSocket = localStorage.getItem('CHAT_SOCKET') === 'true';
const rateLimitMs = Number(localStorage.getItem('RATE_LIMIT_MS') || 200);

const useHighRate = () => {
	const eventBundle = useTypedSelector(state => state.LiveEventReducer.eventBundle);
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const event = eventBundle || workingEvent;
	return useMemo(() => {
		return forceChatSocket || event?.channel_features?.includes(ChannelFeaturesEnum.high_volume_chat);

	}, [event?.channel_features]);
};

export function useChatSocket(channel: string, options?: GetSocketOptions): Socket {
	const [socket, setSocket] = useState<Socket>(baseConnection);
	const highRate = useHighRate();

	useEffect(() => {
		if (channel.includes('undefined')) {
			console.warn('Skipping ws channel', channel, 'because it includes "undefined"');
			return;
		}

		const opts = { ...options, chat: true, rateLimitMs: highRate ? rateLimitMs : 1000 };

		setSocket(manager.getFromHook(channel, opts));

		return () => {
			manager.leave(channel);
		};
	}, [channel, highRate, options]);

	return socket;
}

export type { SocketMessage };