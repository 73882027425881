import { ConnectedAdmin, FiresideSocketMessages, IBreakoutRoomProfile, SqsSocketTypes } from "../../types/working-model";

export const SIGNATURE_REQUIRED_TYPES = [
	'announcement',
	'approve_question_comment',
	'approve_question',
	'broadcast-killed',
	'broadcast-main-show',
	'broadcast-server-prewarming-fail',
	'broadcast-server-ready',
	'bundle_update_sec',
	'bundle_update_url_sec',
	'bundle_update_url',
	'bundle_update',
	'channel_removed',
	'csv-complete',
	'delete_question_comment',
	'delete_question',
	'download-error',
	'download-progress',
	'download-ready',
	'ended_breakouts',
	'invalidate_sessions',
	'leaderboard-isOn',
	'leaderboard.complete',
	'leaderboard.error.internal',
	'leaderboard.error.invalid',
	'live-state-change',
	'malicious-file-removed',
	'question_banned',
	'replayVideoUrl',
	'reset_registrations',
	'scheduled-email-sent',
	'session-stream-update',
	'session-video-replay-available',
	'translation-complete',
	'unapprove_question_comment',
	'unapprove_question',
	'undelete_question_comment',
	'undelete_question',
	'update_chat_message',
	'update_question_comment_message',
	'update_question_message',
	'video-conversion-complete',
	'video-conversion-error',
	'video-conversion-progress',
	FiresideSocketMessages.JOIN_ACCEPT,
	FiresideSocketMessages.JOIN_DENY,
	FiresideSocketMessages.JOIN_REQ,
	SqsSocketTypes.ANNOUNCEMENT,
	SqsSocketTypes.SURVEY,
];

declare global {
	interface Document {
		msHidden: any;
		webkitHidden: any;
	}
}

export interface Listener {
	[key: string]: Array<((...args: any[]) => any)>;
}

export interface SocketMessage {
	type: string;
	data: any;
	message?: any;
	signature?: string;
	moderator_reply?: boolean;
	reactions?: Record<string, number>;
}

export enum MessageTypes {
	getConnection = 'getConnection',
	sendMessage = 'sendMessage',
	getCurrentlyWatching = 'getCurrentlyWatching',
	sendBreakoutData = 'sendBreakoutData',
	updateWorkingEvent = 'updateWorkingEvent',
	disconnect = 'disconnect',
	end = 'end',
}

export enum IncomingTypes {
	message = 'message',
	handleUpdatedUser = 'update-admin',
	handleConnectedAdmin = 'connected-single-admin',
	handleDisconnectedAdmin = 'disconnected-admin',
	handleAdminsConnected = 'connected-admins-list',
	handleUpdatedBRProfile = 'update-breakout-room-profiles',
	handleConnectedBRProfile = 'connected-single-breakout-room-profile',
	handleDisconnectedBRProfile = 'disconnect-profile-from-breakout-room',
	handleBRProfilesConnected = 'connected-breakout-room-profiles-list',
	currentlyWatching = 'currently-watching'
}

export interface IncomingMessage {
	type: IncomingTypes;
	_ns: string;
	admin?: ConnectedAdmin;
	admins?: ConnectedAdmin[];
	blProfile?: IBreakoutRoomProfile;
	blProfiles?: IBreakoutRoomProfile[];
	viewers?: { viewers: string };
	message?: SocketMessage;
}

export type GetSocketOptions = {
	admin?: ConnectedAdmin,
	profile?: IBreakoutRoomProfile,
	chat?: boolean,
	rateLimitMs?: number
}

export type OutgoingMessage = {
	type: MessageTypes;
	channel: string;
	options?: GetSocketOptions;
	message?: MessageData;
}

export type BreakoutMessage = {
	sessionUuid: string;
	breakoutUuid: string;
	opentokId: string;
	host: string;
}

export type AdminMessage = {
	admin: ConnectedAdmin;
}

export type MessageData = SocketMessage | BreakoutMessage | AdminMessage;