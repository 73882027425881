import { createQueryString } from 'utils/utils';
import { MarkedAttendedRegistration } from '../types/working-model';
import { Get, PutJson } from './helpers';

export const GetPeople = async (token: string, channel: number, offset: number, sortBy: number): Promise<any> => {
	return Get(`/v3/admin/events/registrations/${channel}/${offset}/${sortBy}`, token, {}, true)
		.then(async res => {
			if (res.ok) {
				return await res.json();
			}

			return { error: res.status };
		});
};

export const GetPeopleCount = async (token: string, channel: number): Promise<any> => {
	return Get(`/v3/admin/events/count-registrations/count/${channel}`, token);
};

export function GetPeopleSearchCount(
	token: string,
	channel: number,
	value: string
): Promise<any> {
	if (value === '') {
		return Get(
			`/v3/admin/events/count-registrations/count/${channel}`,
			token
		);
	}
	else {
		return Get(
			`/v3/admin/events/count-registrations/count/${channel}/${encodeURIComponent(value)}`,
			token
		);
	}
}

export function SearchPeople(
	token: string,
	channel: number,
	value: string,
	offset: number,
	order: number
): Promise<any> {
	if (value === '') {
		return Get(
			`/v3/admin/events/registrations/${channel}/${offset}/${order}`,
			token
		);
	} else {
		return Get(
			`/v3/admin/events/registrations/${channel}/${offset}/${order}/${encodeURIComponent(value)}`,
			token
		);
	}
}


export function GetEventRegistrations(
	token: string,
	channel: number,
	event: number,
	offset: number,
	order: number,
	getDeleted?: boolean,
	coursesEnabled?: boolean
): Promise<any> {
	const query = createQueryString({ 
		...(getDeleted ? { deleted: true } : {}), 
		...(coursesEnabled ? { courses: true } : {})
	});

	return Get(
		`/v3/admin/events/event-registrations/${channel}/${event}/${offset}/${order}?${query}`,
		token
	);
}

export function GetEventRegistrationsCount(
	token: string,
	channel: number,
	value: string,
	event: number,
	getDeleted: boolean,
	coursesEnabled?: boolean
): Promise<any> {
	const query = createQueryString({ 
		...(getDeleted ? { deleted: true } : {}), 
		...(coursesEnabled ? { courses: true } : {})
	});

	if (value === '') {
		return Get(
			`/v3/admin/events/count-event-registrations/count/${channel}/${event}?${query}`,
			token
		);
	} else {
		return Get(
			`/v3/admin/events/count-event-registrations/count/${channel}/${event}/${encodeURIComponent(value)}?${query}`,
			token
		);
	}
}

export function SearchEventRegistrations(
	token: string,
	channel: number,
	value: string,
	offset: number,
	order: number,
	event: number,
	getDeleted?: boolean,
	coursesEnabled?: boolean
): Promise<any> {
	const query = createQueryString({ 
		...(getDeleted ? { deleted: true } : {}), 
		...(coursesEnabled ? { courses: true } : {})
	});

	if (value === '') {
		return Get(
			`/v3/admin/events/event-registrations/${channel}/${event}/${offset}/${order}?${query}`,
			token
		);
	} else {
		return Get(
			`/v3/admin/events/event-registrations/${channel}/${event}/${offset}/${order}/${encodeURIComponent(value)}?${query}`,
			token
		);
	}
}

export function GetEventRegisteredUsers(
	token: string,
	eventUuid: string,
	offset: number,
): Promise<any> {
	return Get(`/v3/admin/events/event-registration/${eventUuid}/${offset}`, token);
}

export function UpdateRegistrationsAttendedStatus(
	token: string,
	registrationUUIDS: string[],
	markedAttendedByAdmin: MarkedAttendedRegistration,
): Promise<{ registrationUUIDS: string[]; markedAttemdedByAdmin: MarkedAttendedRegistration; }> {
	return PutJson({
		path: `/v3/admin/events/registrations/attended`,
		token,
		data: { markedAttendedByAdmin, registrationUUIDS },
	});
}