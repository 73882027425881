import { useMemo } from 'react';
import './waiting-indicator.scss';

const width = 30;
const height = 15;
const centerX = width / 2;
const centerY = height / 2;
const radius = 2.5;
const difX = 9;

export default function WaitingIndicator({
	fill = '#B2B2BF',
	fillSpace = false,
	minHeight,
	transparentFill = false,
	darkLoader = false,
	fillOverride
}: {
	fill?: string,
	fillSpace?: boolean,
	minHeight?: number | string,
	transparentFill?: boolean,
	darkLoader?: boolean,
	fillOverride?: string // use custom css prop for fill override as this will override the animation colors as well
}): JSX.Element {
	const svg = useMemo(() => {
		return (
			<svg
				className={`waiting-indicator ${darkLoader ? 'dark-loader' : ''}`}
				width={width}
				height={height}
				// @ts-expect-error custom css prop for fill override
				style={fillOverride ? { '--waiting-indicator-fill': fillOverride } : undefined}
			>
				<circle
					className="circle-1"
					cx={centerX - difX}
					cy={centerY}
					r={radius}
					fill={fill}
				/>
				<circle
					className="circle-2"
					cx={centerX}
					cy={centerY}
					r={radius}
					fill={fill}
				/>
				<circle
					className="circle-3"
					cx={centerX + difX}
					cy={centerY}
					r={radius}
					fill={fill}
				/>
			</svg>
		);
	}, [darkLoader, fill, fillOverride]);

	return fillSpace ? (
		<div className="waiting-indicator-fill-space" style={{ minHeight, backgroundColor: transparentFill ? 'transparent' : undefined }}>
			{svg}
		</div>
	) : svg;
}
