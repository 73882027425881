import { useMemo } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";

import { useTranslate } from "../../../../../../i18n/useTranslationModules";
import { Session, LanguagesAbbr, SessionTypesFeatures } from "../../../../../../types/working-model";
import useTranslationRoute from "../../../../hooks/use-translation-route";
import * as Signals from "../../../../../../utils/event-emitter";
import { getSessionDescription } from "../utils";
import RevealableText from "./revealable-text";
import { niceDateTimeWithoutTimeZone } from "../../../../../../utils/utils";
import { useGetSessionTracks } from "../../../../../../hooks/session.hooks";
import { OptionalComponent } from "../../../../../../utils/optional-component";
import { useTypedSelector } from "store/reducers/use-typed-selector";
import { getSessionDuration } from "hooks/agenda.hooks";

export type VideoTitleProps = {
	session: Session;
}

export const VideoTitle = ({ session }: VideoTitleProps) => {
	const attendeeInPersonEnabled = useTypedSelector(state => state.LiveEventReducer.eventBundle?.settings?.attendeeInPersonEnabled);

	const namespace = useTranslationRoute();
	const { t } = useTranslate([namespace, "homepage", "landing", "session"]);
	const { t: tSession } = useTranslate('session');

	const { language } = useParams<{ language: string }>();
	const description = getSessionDescription(session, language as LanguagesAbbr, t, namespace);

	const startDate = SessionTypesFeatures[session.session_type].has_start_time ? niceDateTimeWithoutTimeZone({
		timestamp: session.timestamp as number,
		language: language as LanguagesAbbr,
		short: false,
		dateOnly: false,
		timeOnly: false,
		useDot: true
	}) : null;

	const duration = getSessionDuration(session);

	const isPastStartTime = session.timestamp && session.timestamp.valueOf() < Date.now();
	const tracksT = useGetSessionTracks(session);

	const tagText = useMemo(() => {
		const tracks = tracksT?.map(track => t(`${namespace}:tracks.${track.label}`, track.label));

		{/* If start data doesn't exists and we're on the first dot */ }
		{/* If the event has ended and wer're on the first dod */ }
		{/* If the start data exists and session hasn't ended */ }

		const displayStartDate = !!startDate && !isPastStartTime;

		const tracksComponent = tracks.map((track, i) =>
			<div className="track" key={track}>
				{((i === 0 && displayStartDate)) && <div className="space-dot">&nbsp;·&nbsp;</div>}
				{((i !== 0)) && <div className="space-dot">·</div>}
				<label className="track-name">{track}</label>
			</div>);

		return (
			<div className="live-in-card-text">
				<OptionalComponent display={displayStartDate}>
					<p>{startDate}  ·  {tSession('Runtime')}: {duration}</p>
				</OptionalComponent>
				<OptionalComponent display={!!tracksComponent}>
					<div className="tracks">
						<div className="session-live-card-metadata">{tracksComponent}</div>
					</div>
				</OptionalComponent>
			</div>
		);
	}, [duration, isPastStartTime, namespace, startDate, t, tSession, tracksT]);

	return (
		<div className={classNames("video-titles-container", { 'in-person-attendee-mode': attendeeInPersonEnabled })}>
			{tagText}
			<h1 className="session-live-card-title">{t(`landing:agenda.session.${session.session}.title`, session?.title[language] as string)}</h1>
			{description && (
				<RevealableText
					text={description}
					revealed={true}
					className="session-live-card-description"
					onExpand={() => Signals.broadcastSignal('session-details-modal:open')}
				/>
			)}
		</div>
	);
};
