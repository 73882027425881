import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Icon, { ICONS } from '../components/general-ui/icon';

import { addErrorBoundaryComponent } from '../store/actions/event/event-actions';
import { useTypedSelector } from '../store/reducers/use-typed-selector';
import { OptionalComponent } from './optional-component';
import '../scss/base/_error-boundary.scss';
import { useTranslate } from '../i18n/useTranslationModules';
import PageNotFound from '../components/404/404';

export const TEST_ERROR_BOUNDARIES = localStorage.getItem('TEST_ERROR_BOUNDARIES') && process.env.REACT_APP_STAGE !== 'prod';

function QATestErrorBoundary() {
	throw new Error('Not a real error, testing error boundary');
	return (
		<></>
	);
}

function FallbackRender(props: { className?: string }) {
	const { t } = useTranslate("homepage");

	return (
		<div className={classNames("error-boundary-fallback", props.className)}>
			<div className="error-message-container">
				<div className="icon-container"><Icon name={ICONS.CAUTION} size={48} color="" /></div>
				<div className="text-container">
					<h5>{t("Something went wrong", "")}</h5>
					<span>{t("Please try again in a few minutes", "")}</span>
				</div>
			</div>
		</div>
	);
}

interface IErrorHandlerProps {
	children: JSX.Element;
	fallbackClassName?: string;
	onError?: () => void;
	pageNotFound?: boolean;
}

interface IErrorHandlerState {
	hasError: boolean;
}

class ErrorHandler extends React.Component<IErrorHandlerProps, IErrorHandlerState> {
	constructor(props: IErrorHandlerProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		console.error('ErrorBoundary caught an error', this.props.fallbackClassName, error, errorInfo);
		this.props?.onError?.();
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			if (this.props.pageNotFound) {
				return (<PageNotFound event />);
			}
			return <FallbackRender className={this.props.fallbackClassName} />;
		}

		return this.props.children;
	}
}

interface IErrorBoundaryProps {
	children: JSX.Element | null;
	/** used for the fallback styling and testing each the ErrorBoundary*/
	uniqueLabel: string;
	onError?: () => void;
	pageNotFound?: boolean;
}

// In order to properly test these error boundaries (and the fallback UI) we needed a way to throw errors at the same level as the the child component to simulate the component itself erroring when rendering.
// That is what <QATestErrorBoundary /> in each <ErrorBoundary /> is used for.

// SETUP:
// When using <ErrorBoundary />, include a uniqueLabel that will be used as the id for the error boundary and the error boundary classname.
// Clean up the errorBoundary redux value (using the clearErrorBoundaryComponents action) when changing views. Make sure it only runs when TEST_ERROR_BOUNDARIES is true.

// To test the error boundary, add the TEST_ERROR_BOUNDARIES value to localStorage, refresh, and go to a view where that component is rendered.
// At the top right of the view you should see a drop down with the uniqueLabel that you used. Click on that and it'll throw an error inside the error boundary.

// More info on error boundaries: https://reactjs.org/docs/error-boundaries.html

/**
 * Creates an error boundary around children so that if they have an error while rendering, it doesn't cause the app to crash.
 */
function ErrorBoundary(props: IErrorBoundaryProps) {
	const {
		children,
		uniqueLabel = '',
		onError,
		pageNotFound,
	} = props;

	const componentsWithErrors = useTypedSelector(state => state.LiveEventReducer.errorBoundaries.componentsWithErrors);

	const dispatch = useDispatch();

	const errorBoundaryId = uniqueLabel.replace(/ /g, '-');

	useEffect(() => {
		if (uniqueLabel && TEST_ERROR_BOUNDARIES) {
			dispatch(addErrorBoundaryComponent({ label: uniqueLabel, value: errorBoundaryId }));
		}
		// disabled here cause we only want this to run on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ErrorHandler onError={onError} fallbackClassName={errorBoundaryId} pageNotFound={pageNotFound}>
			<>
				{children}
				<OptionalComponent display={componentsWithErrors[errorBoundaryId]}>
					<QATestErrorBoundary />
				</OptionalComponent>
			</>
		</ErrorHandler>
	);
}


export default ErrorBoundary;
