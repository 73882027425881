import classNames from "classnames";
import { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { GetAllProductAttributesForChannel } from "../../../../../../../../connection/products";
import { UploadFile } from "../../../../../../../../connection/uploads";
import { updatePageModuleAndSave } from "../../../../../../../../store/actions/admin/create-event/session";
import { useTypedSelector } from "../../../../../../../../store/reducers/use-typed-selector";
import { GetDefaultProduct } from "../../../../../../../../store/utils/create-event";
import { Attribute, BLAdmin, BrandliveEvent, Channel, LanguagesAbbr, PageModuleType, Product, Session, SessionPanelLayoutsTypes } from "../../../../../../../../types/working-model";
import { useGetAdminUrl } from "../../../../../../../../utils/admin-routing-utils";
import FileInput from "../../../../../../../../utils/file-input";
import { showAlert } from "../../../../../../../general-ui/alert/alert-service";
import { OptionalComponent } from "../../../../../../../general-ui/animated/optional-component";
import StaggerChildren from "../../../../../../../general-ui/animated/stagger-children";
import Icon, { COLORS, ICONS } from "../../../../../../../general-ui/icon";
import EnterSelectInput from "../../../../../../../general-ui/select/enter-select";
import Switch from "../../../../../../../general-ui/switch/switch";
import TagSelectInput from "../../../../../../../general-ui/tag-select/tag-select";
import TextInput, { Validation } from "../../../../../../../general-ui/text-input/text";
import { Tooltip } from "../../../../../../../general-ui/tooltip/tooltip";
import WaitingIndicator from "../../../../../../../general-ui/waiting-indicator/waiting-indicator";
import { Actions, editProductReducer, getInitialState } from "../../../../../../content-creation/edit-product-reducer";
import { saveProduct } from "../../../../../../content-creation/save-product-utils";
import { useComponentIsMounted, useFinishNavigate, usePageModule, usePageModuleGroup, useSelectedChannel } from "../../hooks/panel.hooks";
import { SessionPanelMap } from "../../session-panel-route-map";

import '../extras.scss';
import { shouldCreateChannelProductToggle } from "store/actions/admin/create-event";

const imgGridSizes: Record<number, string> = {
	0: 'zero',
	1: 'one',
	2: 'two',
	3: 'two'
};

const initialRequiredFields: { [key: string]: Validation } = {
	title: Validation.normal,
	about: Validation.normal,
	image: Validation.normal
};

type Props = {
	workingEvent: BrandliveEvent;
	workingSession: Session;
	user: BLAdmin;
	token: string;
	channel: Channel;
}

class InvalidUrlError extends Error {
	constructor(message: string) {
		super(message);
	}
}

function attemptConvertToValidUri(uri: string): string {
	try {
		// if it includes a protocol, return it as a fully-valid URL
		if (/http(|s):\/\//.test(uri)) {
			return new URL(uri.trim()).href;
		} else {
			// if it is missing a protocol - add one and return a fully-valid URL
			return new URL(`https://${uri.trim()}`).href;
		}
	} catch (e) {
		// the URL constructor will throw on an invalid URL - return a typed error
		throw new InvalidUrlError('Please enter a valid URL');
	}
}

const CreateNewProductWithRequired: React.FC<Props> = ({
	workingEvent,
	workingSession,
	user,
	token,
	channel
}) => {
	const { language } = useParams<{ language: LanguagesAbbr }>();
	const baseLanguage = workingEvent.settings.i18n?.base ?? 'en';
	const history = useHistory<{ productToEdit?: Product }>();
	const finish = useFinishNavigate();

	const [workingProduct, dispatchProduct] = useReducer(editProductReducer, getInitialState(baseLanguage, language));
	const [requiredFields, setRequiredFields] = useState(initialRequiredFields);
	const [uploading, setUploading] = useState(false);
	const [channelAttributes, setChannelAttributes] = useState<string[]>([]);
	const [saving, setSaving] = useState(false);
	const [gettingAttributes, setGettingAttributes] = useState(false);
	const [validUrl, setValidUrl] = useState(Validation.normal);

	const imageRef = useRef<HTMLInputElement | null>(null);
	const mounted = useComponentIsMounted();
	const pageModule = usePageModule();
	const pageModuleGroup = usePageModuleGroup();
	const dispatch = useDispatch();
	const adminPath = useGetAdminUrl();
	const { customPath } = useParams<{ customPath?: string; }>();

	const shouldCreateChannelProduct = useTypedSelector(state => state.CreateEventReducer.shouldCreateChannelProduct);

	useEffect(() => {
		if (history.location?.state?.productToEdit) {
			dispatchProduct({
				type: Actions.SetProduct,
				payload: {
					...history.location.state.productToEdit,
					language,
					baseLanguage
				}
			});
		}
	}, [baseLanguage, history.location.state, language]);

	useEffect(() => {
		setGettingAttributes(true);
		GetAllProductAttributesForChannel(token, channel.uuid).then(res => {
			if (mounted.current) {
				setChannelAttributes(res);
			}
		}).catch(e => {
			console.error(e);
		}).finally(() => {
			if (mounted.current) {
				setGettingAttributes(false);
			}
		});
	}, [channel, mounted, token]);

	useEffect(() => {
		return () => {
			dispatch(shouldCreateChannelProductToggle(false)); //flip the toggle off for create channel product on component unmount to reset the form
		};
	}, [dispatch]);

	const hasCompletedRequiredFields = () => {
		const _requiredFields = { ...requiredFields };
		if (!workingProduct?.product_title?.base) _requiredFields.title = Validation.error;
		if (!workingProduct?.description?.base) _requiredFields.about = Validation.error;
		if (!workingProduct?.images.length) _requiredFields.image = Validation.error;

		setRequiredFields(_requiredFields);
		for (const field in _requiredFields) if (_requiredFields[field] === Validation.error) return false;
		return true;
	};

	const finishedAddingProduct = async () => {
		if (
			!pageModule ||
			!pageModuleGroup ||
			!workingSession.module_grouping ||
			Object.values(requiredFields).includes(Validation.error)
		) {
			return;
		}

		setSaving(true);

		const savedProduct = await saveProduct({
			close,
			hasCompletedRequiredFields,
			initialSelected: [],
			onComplete: () => null,
			productToEdit: null,
			selectedProducts: [],
			workingProduct,
			setSaving,
			dispatchProduct,
			shouldCreateChannelProduct
		});

		if (savedProduct) {
			if (history.location?.state?.productToEdit) {
				dispatch(updatePageModuleAndSave({
					...pageModule,
					modules: pageModule.modules?.map((module: Product) => {
						if (module.product === savedProduct.product) {
							return savedProduct;
						} else {
							return module;
						}
					})
				}));
			} else {
				dispatch(updatePageModuleAndSave({
					...pageModule,
					content_modules: [...(pageModule.content_modules ?? []), savedProduct.product],
					modules: [...(pageModule.modules ?? []), savedProduct],
					type: PageModuleType.products
				}));
			}

			const path = (() => {
				if (customPath) {
					return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.CustomProducts], customPath, page_module: pageModule.id });
				}

				if (pageModule.content.custom_tab) {
					return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.ExtraCustom], page_module: pageModule.id });
				} else {
					return adminPath({ path: SessionPanelMap[SessionPanelLayoutsTypes.ExtraProducts], page_module: pageModule.id });
				}
			})();

			finish(path);
		}

		dispatch(shouldCreateChannelProductToggle(false)); //flip the toggle off for create channel product since we have complted it's action

		setSaving(false);
	};

	function resetRequiredFields() {
		const _requiredFields = { ...requiredFields };
		for (const field in _requiredFields) _requiredFields[field] = Validation.normal;
		setRequiredFields(_requiredFields);
	}

	function cancel() {
		if (workingProduct) {
			resetRequiredFields();
			dispatchProduct({ type: Actions.ClearProduct, payload: true });
			dispatch(shouldCreateChannelProductToggle(false)); //flip the toggle off for create channel product to reset the form
		}

		history.goBack();
	}

	async function handleImageUpload(e: React.ChangeEvent<HTMLInputElement>) {
		const file = e.target.files?.[0];

		if (file && user && token) {
			try {
				setUploading(true);
				const uploaded = await UploadFile(user, token, file);
				dispatchProduct({ type: Actions.AddImage, payload: uploaded });
				setRequiredFields({ ...requiredFields, image: uploaded ? Validation.normal : Validation.error });
			} catch (e) {
				console.error(e);
				showAlert({
					message: "Problem Uploading",
					description: "We ran into an issue uploading that image. Please check your inputs and try again.",
					type: "error"
				});
			} finally {
				setUploading(false);
			}
		}
	}

	function removeImage(index: number) {
		return () => {
			if (imageRef.current?.files) {
				imageRef.current.value = '';
			}

			dispatchProduct({ type: Actions.RemoveImage, payload: index });
		};
	}

	function setProductTitle({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
		dispatchProduct({ type: Actions.SetTitle, payload: value });
		setRequiredFields({ ...requiredFields, title: value ? Validation.normal : Validation.error });
	}

	function setBuyNowTitle({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
		dispatchProduct({ type: Actions.SetBuyNowTitle, payload: value });

	}

	function setDescription({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
		dispatchProduct({ type: Actions.SetDescription, payload: value });
		setRequiredFields({ ...requiredFields, about: value ? Validation.normal : Validation.error });
	}

	// we are not doing price right now but leaving these in for a potential future return

	// function setPrice({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
	// 	dispatchProduct({ type: Actions.SetPrice, payload: value });
	// }

	// function setSalePrice({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
	// 	dispatchProduct({ type: Actions.SetSalePrice, payload: value });
	// }

	// function formatCurrency(key: 'price' | 'sale_price', { target: { value } }: React.ChangeEvent<HTMLInputElement>) {
	// 	// toLocalString to handle rounding/formatting
	// 	dispatchProduct({ type: Actions.FormatCurrency, payload: { field: key, value: value } });
	// }

	function setUseAttributes(_: unknown, isOn: boolean) {
		dispatchProduct({ type: Actions.SetUseAttributes, payload: isOn });
	}

	function setCTANewTab(_: unknown, isOn: boolean) {
		dispatchProduct({ type: Actions.SetCtaNewTab, payload: isOn });
	}

	function setBypassItemModal(_: unknown, isOn: boolean) {
		dispatchProduct({ type: Actions.SetBypassModal, payload: isOn });
	}

	function setIncludeUtms(_: unknown, isOn: boolean) {
		dispatchProduct({ type: Actions.SetIncludeUtms, payload: isOn });
	}

	function setCategories(categories: string[]) {
		dispatchProduct({ type: Actions.SetCategories, payload: categories });
	}

	function setAttributeName(index: number) {
		return (attribute: string) => {
			dispatchProduct({ type: Actions.SetAttributeName, payload: [index, attribute] });
		};
	}

	function setAttributeValue(index: number) {
		return ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			dispatchProduct({ type: Actions.SetAttributeValue, payload: [index, value] });
		};
	}

	function addNewAttribute() {
		dispatchProduct({ type: Actions.AddAttribute, payload: true });
	}

	function createNewProduct() {
		dispatchProduct({ type: Actions.NewProduct, payload: { ...GetDefaultProduct(), baseLanguage, language } });
	}

	function uploadNewImage() {
		imageRef.current?.click();
	}

	function setBuyNowLink({ target: { value } }: React.ChangeEvent<HTMLInputElement>) {
		dispatchProduct({ type: Actions.SetBuyNowLink, payload: value });
	}

	function cleanupLink() {
		try {
			const url = workingProduct?.buy_now_link;

			if (url?.trim()) {
				const cleanUrl = attemptConvertToValidUri(url);
				dispatchProduct({ type: Actions.SetBuyNowLink, payload: cleanUrl });
				setValidUrl(Validation.ok);
			} else {
				setValidUrl(Validation.normal);
			}
		} catch (e) {
			console.error(e);
			if (e instanceof InvalidUrlError) {
				setValidUrl(Validation.error);
			}
		}
	}

	const toggleShouldCreateChannelProduct = () => {
		dispatch(shouldCreateChannelProductToggle(!shouldCreateChannelProduct));
	};

	return workingProduct ? (
		<div className="session-panel upload-product">
			<StaggerChildren className="session-panel-content">
				<TextInput
					key={'enter-title'}
					placeholder={"Enter title"}
					label={"Title*"}
					valid={requiredFields.title}
					onBlur={() => {
						setRequiredFields({
							...requiredFields,
							title: (
								workingProduct?.product_title?.[language] ||
								workingProduct?.product_title?.base
							) ? Validation.normal : Validation.error
						});
					}}
					onChange={setProductTitle}
					defaultValue={workingProduct.product_title?.[language] as string ?? workingProduct.product_title?.base}
					value={workingProduct.product_title?.[language] as string ?? workingProduct.product_title?.base}
				/>
				<TagSelectInput
					key={'enter-cat'}
					onChange={setCategories}
					tags={workingProduct.categories ?? []}
					label={"Categories"}
					placeholder="Enter Categories"
					defaultTags={workingProduct.categories ?? []}
				/>
				<TextInput
					key={'enter-desc'}
					placeholder="Enter description"
					label="Description*"
					// Keeping here incase we want to add back in later.
					// make sure to also update the edit-products.tsc modal as well
					onBlur={() => {
						setRequiredFields({
							...requiredFields,
							about: (
								workingProduct?.description?.[language] ||
								workingProduct?.description?.base
							) ? Validation.normal : Validation.error
						});
					}}
					valid={requiredFields.about}
					value={workingProduct?.description?.[language] as string ?? workingProduct?.description?.base}
					onChange={setDescription}
				/>
				<label className="product-image-label">Image*</label>
				<div
					key={'product-images'}
					className={classNames(
						'product-images',
						imgGridSizes[workingProduct?.images.length],
						{
							error: requiredFields.image === Validation.error,
							uploading
						}
					)}>
					{workingProduct.images.map((image: string, index: number) => (
						<div
							className="product-image"
							key={index}
							style={{ backgroundImage: `url(${image.replace(/ /g, '%20')})` }}
						>
							<div className="product-image-actions">
								<button className="no-style" onClick={removeImage(index)}>
									<Icon name={ICONS.TRASH_OUTLINE} size={16} color={COLORS.WHITE} />
								</button>
							</div>
						</div>
					))}
					{uploading && (
						<div className="product-image">
							<WaitingIndicator />
						</div>
					)}
					{workingProduct.images.length < 3 && !uploading && (
						<div onClick={uploadNewImage} className={classNames("upload-container", { 'upload-container-empty': !workingProduct.images.length })}>
							<span className="add-image">
								<Icon name={ICONS.ADD} size={12} color={COLORS.WHITE} />
								{!workingProduct.images.length ? (
									<span>Upload Image</span>
								) : (
									<span>Upload</span>
								)}
							</span>
							<p>Up to 50MB file size (3 images max)</p>
						</div>
					)}
					<FileInput
						type="file"
						style={{ display: 'none' }}
						onChange={handleImageUpload}
						accept={["image/png", "image/jpeg", "image/jpg", "image/gif"]}
						ref={imageRef}
					/>
				</div>
				<hr key={'rule-1'} />
				{/* 

				Price marked as deprecated in ATF/BTF but leaving in until confirmed
				
				<div className="row">
					<TextInput
						placeholder={"Enter Product Price"}
						label={"Price"}
						onChange={setPrice}
						onBlur={(e) => formatCurrency('price', e)}
						defaultValue={workingProduct.price?.base ?? ''}
						value={workingProduct.price?.base ?? ''}
						numeric
					/>
					<TextInput
						placeholder={"Enter Product Sale Price"}
						label={"Sale Price"}
						onChange={setSalePrice}
						onBlur={(e) => formatCurrency('sale_price', e)}
						defaultValue={workingProduct.sale_price?.base ?? ''}
						value={workingProduct.sale_price?.base ?? ''}
						numeric
					/>
				</div> */}
				<TextInput
					key={'enter-url'}
					placeholder={"URL"}
					label={"Enter URL"}
					onChange={setBuyNowLink}
					onBlur={cleanupLink}
					valid={validUrl}
					value={workingProduct.buy_now_link}
				/>

				<OptionalComponent key={'url-error'} display={validUrl === Validation.error} usingHeight={50}>
					<span className="error">Please enter a valid URL</span>
				</OptionalComponent>

				<TextInput
					key={'enter-button-bame'}
					placeholder={"Button name"}
					label={"Enter name"}
					onChange={setBuyNowTitle}
					value={workingProduct.button_text?.[language] as string ?? workingProduct.button_text?.base}
				/>
				<div
					key={'Open URL in new tab'}
					className="field-group switch">
					<label>Open URL in new tab</label>
					<Switch
						value={'cta-new-tab'}
						on={workingProduct.cta_new_tab !== false}
						onClick={setCTANewTab}
					/>
				</div>
				<div className="field-group switch">
					<label>Bypass item modal</label>
					<Switch
						value={'bypass-item-modal'}
						on={!!workingProduct.settings?.bypass_modal}
						onClick={setBypassItemModal}
					/>
				</div>
				<div
					key={'Save current tracking parameters'}
					className="field-group switch">
					<label style={{ display: 'flex' }}>Save current tracking parameters &nbsp;
						<Tooltip
							maxWidth={250}
							asDangerouslySetInnerHTML={true}
							tooltip={(
								<>
									Enable to save existing tracking parameters stored in user’s browser settings when opening this URL.
									Click <a target="_blank" href='https://support.brandlive.com/hc/en-us/articles/31410577971099-How-to-Use-UTM-Parameters-with-Brandlive-Platform' rel="noreferrer">here</a>&nbsp;
									to learn more about Brandlive & tracking parameters
								</>
							)}
						>
							<Icon name={ICONS.PRIMARY_TOOLTIP} size={12} color={COLORS.CYAN} />
						</Tooltip>
					</label>

					<Switch
						value={'include-utm-params'}
						on={!!workingProduct.settings?.include_utm_params}
						onClick={setIncludeUtms}
					/>
				</div>
				<div className="field-group text switch right">
					<label>Create channel product</label>
					<Switch
						value={'shouldCreateChannelProduct'}
						on={!!shouldCreateChannelProduct}
						onClick={toggleShouldCreateChannelProduct}
					/>
				</div>

				<hr key="rule-two" />

				<div key="product-attributes" className="field-group switch top-border right">
					<label>Product Attributes</label>
					<Switch
						value={'use-attributes'}
						on={workingProduct.use_attributes}
						onClick={setUseAttributes}
					/>
				</div>
				<OptionalComponent key="use-attributes" display={workingProduct.use_attributes} usingHeight={1000}>
					<div className="attributes-list">
						{workingProduct.attributes && workingProduct.attributes.length > 0 && (
							<>
								{workingProduct.attributes.map((attribute: Attribute, index: number) => (
									<div key={index} className="attribute-row">
										<EnterSelectInput
											defaultOptions={channelAttributes.length ? channelAttributes : ["Color", "Size"]}
											label="Attribute"
											placeholder="Enter attribute"
											onChange={setAttributeName(index)}
											value={attribute.type}
										/>
										<TextInput
											placeholder={"Attribute value"}
											label={"Value"}
											onChange={setAttributeValue(index)}
											value={attribute.value}
										/>
									</div>
								))}
							</>
						)}

						<button onClick={addNewAttribute} className="small">
							<Icon name={ICONS.ADD} size={14} color={COLORS.WHITE} />
							<span>Add Attribute</span>
						</button>
					</div>
				</OptionalComponent>

			</StaggerChildren>

			<div className={classNames("session-panel-footer visible")}>
				<button
					onClick={cancel}
					disabled={saving}
				>
					Cancel
				</button>
				<button
					className="lemonade"
					disabled={saving}
					onClick={finishedAddingProduct}
				>
					{saving ? <WaitingIndicator /> : "Save"}
				</button>
			</div>
		</div>
	) : <></>;
};

const CreateNewProduct: React.FC<unknown> = () => {
	const workingEvent = useTypedSelector(state => state.CreateEventReducer.workingEvent);
	const workingSession = useTypedSelector(state => state.CreateSessionReducer.workingSession);
	const user = useTypedSelector(state => state.AuthReducer.user);
	const token = useTypedSelector(state => state.AuthReducer.token);
	const channel = useSelectedChannel();
	return (workingEvent && user && token && channel && workingSession) ? (
		<CreateNewProductWithRequired
			workingEvent={workingEvent}
			workingSession={workingSession}
			user={user}
			token={token}
			channel={channel}
		/>
	) : <></>;
};

export default CreateNewProduct;
