import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router";

import { useAppDispatch, useTypedSelector } from "../../../../../../store/reducers/use-typed-selector";
import { ActionTargetType, Session, SessionTypesEnum, ActionEvent, ActionType } from "../../../../../../types/working-model";
import { getStorageItem } from "../../../../../../utils/local-storage";
import { useTracking } from "../../../../../../utils/tracking";
import { hasReachedMilestone } from "../../../../../../utils/utils";
import db from '../../../../../../utils/datastore/indexed-db';
import { Actions, VideoStateContext } from "../../session-stream-provider";
import * as Signals from '../../../../../../utils/event-emitter';
import useTrackPlaying from "../../../../modules/video/use-track-playing";
import { getLogger } from "../../../../../../utils/debug-logger";
import { CorsWorker as Worker } from '../../../../../../connection/cors-worker';
import { HvHostMap } from '../../../../../../connection/helpers';
import { TimeUpdate } from "../types";
import { helperGetDBOndemandWatchtime } from "../../../../../../hooks/agenda.hooks";
import usePrevious from "../../../../../../utils/use-previous";
import { CourseRequirementType } from "types/courses";
import store from "store/main";
import { watchedSecondsLocalTrack } from "store/actions/event/courses";
import { isObject } from "underscore";
const worker = new Worker(new URL('./workers/video-tracking.worker.ts', import.meta.url)).getWorker();
const logger = getLogger('bl-video:tracking');

worker.addEventListener('message', (
	event: MessageEvent<{ session_id: number, watched_seconds: number }>
) => {
	logger(`Worker message:`, event.data);
	if (
		isObject(event.data)
		&& event.data.session_id
		&& event.data.watched_seconds
	) {
		store.dispatch(
			watchedSecondsLocalTrack(event.data.session_id, event.data.watched_seconds)
		);
	}
});

/*

Be advised:

jest does not like the `import.meta.url` use in the worker import above.

You must mock this component in order to test the component that uses it.

*/

interface ITrackingProps {
	isLive?: boolean;
}

export const Tracking: React.FC<ITrackingProps> = ({ isLive }) => {
	const {
		state: {
			playing,
			session,
			event,
			onDemandVideo,
			durationInSeconds,
			initialPlay,
			paused,
			isMobile,
			ended,
			playbackRate,
			currentDynamicVideo
		},
		dispatch
	} = useContext(VideoStateContext);
	const dispatchRedux = useAppDispatch();
	const { language } = useParams<{ language: string }>();
	const playedSecondsRef = useRef<number>(0);
	const playedPercentageRef = useRef<number>(0);
	const milestoneChecker = useRef(hasReachedMilestone());
	const isPlayingRef = useRef(playing);
	const startWatchtimeRef = useRef(0);
	const languageSelection = useRef(getStorageItem(`event.${event?.event}.language`));
	const sleepInterval = useRef<NodeJS.Timeout | null>(null);
	const componentWillUnmount = useRef(false);
	const unmountRef = useRef<{ timestamp: number | null, isPlaying: boolean }>();
	const lastActionType = useRef<ActionEvent>();
	const workerInitialized = useRef(false);
	const workerInitTimeout = useRef<NodeJS.Timeout | null>(null);
	const isOnDemand = (session as Session).session_type === SessionTypesEnum.onDemand;
	const isBroadcast = (session as Session).session_type === SessionTypesEnum.broadcast;
	const validPasscodeLists = useTypedSelector(event => event.LiveEventReducer.validPasscodeLists);
	const leaderboardIsOn = useTypedSelector(event => event.LiveEventReducer.leaderboardIsOn);
	const blProfileUser = useTypedSelector(event => event.LiveEventReducer.blProfileUser);
	const blProfileUserToken = useTypedSelector(event => event.LiveEventReducer.blProfileUserToken);
	const channel = useTypedSelector(state => state.LiveEventReducer.eventBundle?.channel);
	const eventId = useTypedSelector(event => event.LiveEventReducer.eventBundle?.event);
	const course = useTypedSelector(state => state.CourseReducer.course);
	const expectCourse = event?.settings?.course_enabled;
	const courseRequirements = course?.requirements;
	const courseUuid = course?.uuid;
	const sessionUuid = session?.uuid;
	const blProfileId = blProfileUser?.bl_profile;
	const sessionId = session?.session;
	const startTimestamp = session?.timestamp;
	const endTimestamp = session?.end_timestamp;

	const courseRequirement = useMemo(() => {
		return courseRequirements?.find(req => req.type === CourseRequirementType.watched_minute);
	}, [courseRequirements]);

	const courseTrack = !!courseRequirement;
	const requirementId = courseRequirement?.id;

	const isLiveVideo = currentDynamicVideo?.type === 'live_stream';

	useTrackPlaying({ isPlaying: playing, sessionUuid });

	const previousPlaybackRate = usePrevious(playbackRate);

	const { trackEvent } = useTracking({
		target_id_string: (session as Session).session_type,
		target_type: ActionTargetType.Video,
		current_language: language
	});

	// order of this effect matters - it must be before any other useEffects that reference it
	useEffect(() => {
		return () => {
			componentWillUnmount.current = true;
		};
	}, []);

	// store these values as refs so we don't have to re-trigger useEffects more than needed
	useEffect(() => {
		isPlayingRef.current = playing;
	}, [playing]);

	const getSecondsPlayed = useCallback((timeOfLastKnownStream?: number) => {
		if (startWatchtimeRef.current) {
			return Math.round(((timeOfLastKnownStream || Date.now()) - startWatchtimeRef.current) / 1000);
		}
		return undefined;
	}, []);

	const getVideoTimestamp = useCallback(() => {
		// if broadcast session, pull relative timestamp from the session start time
		// else pull from played seconds on video player
		if (isBroadcast) {
			if (session?.timestamp && session?.timestamp < Date.now()) {
				return (Date.now() - session?.timestamp) / 1000;
			}
			return null;
		}
		return playedSecondsRef.current;
	}, [isBroadcast, session?.timestamp]);

	useEffect(() => {
		unmountRef.current = { timestamp: getVideoTimestamp(), isPlaying: playing };
	}, [getVideoTimestamp, playing]);

	// if session is live, then target_id_string should be marked as broadcast
	// if session has a timestamp and it has passed, then target_id_string should be on-demand
	// if it's actually just an on-demand session, then target_id_string should be marked as on-demand
	// else just return undefined and let the default values handle it
	// Note: isLive uses the actual "has a live stream" status, not the session timestamp status
	const getSessionTargetIdString = useCallback(() => {
		if (!session) return;

		if (isLive) {
			return SessionTypesEnum.broadcast;
		}

		const sessionType = session.session_type;
		if (!session.timestamp || !session.end_timestamp || sessionType === SessionTypesEnum.onDemand) {
			return SessionTypesEnum.onDemand;
		}
		const hasEndSessionTimePassed = session.end_timestamp < Date.now();
		if (!isLive && hasEndSessionTimePassed) {
			return SessionTypesEnum.onDemand;
		}
	}, [isLive, session]);

	useEffect(() => {
		const _targetIdString = getSessionTargetIdString();
		const target_id_string = _targetIdString ? { target_id_string: _targetIdString } : {};
		if (paused) {
			logger(`Tracking pause action`, getVideoTimestamp(), getSecondsPlayed());
			trackEvent({
				action: ActionEvent.Pause,
				action_type: ActionType.Active,
				...target_id_string,
				miscellaneous: JSON.stringify({
					relative_timestamp: getVideoTimestamp(),
					seconds_played: getSecondsPlayed(),
					valid_passcode_lists: validPasscodeLists,
					playback_rate: playbackRate
				})
			});

			db.deleteItem('TIME_OF_LAST_STREAM', `${sessionUuid}-TIME_OF_LAST_STREAM`).catch(e => console.error(e));
			startWatchtimeRef.current = 0;

			if (lastActionType.current !== ActionEvent.Play) {
				worker.postMessage({ type: 'stopped' });
			}

			lastActionType.current = ActionEvent.Play;
		} else {
			logger(`Tracking play action`, getVideoTimestamp());
			trackEvent({
				action: ActionEvent.Play,
				action_type: ActionType.Active,
				...target_id_string,
				miscellaneous: JSON.stringify({
					relative_timestamp: getVideoTimestamp(),
					seconds_played: undefined,
					valid_passcode_lists: validPasscodeLists,
					playback_rate: playbackRate
				})
			});

			db.putItem({
				uuid: `${sessionUuid}-TIME_OF_LAST_STREAM`,
				type: 'TIME_OF_LAST_STREAM',
				data: Date.now(),
			}).catch(e => console.error(e));

			startWatchtimeRef.current = Date.now();

			if (lastActionType.current !== ActionEvent.Pause) {
				worker.postMessage({ type: 'playing' });
			}

			lastActionType.current = ActionEvent.Pause;
		}
	}, [paused, trackEvent, getVideoTimestamp, getSecondsPlayed, validPasscodeLists, sessionUuid, getSessionTargetIdString, playbackRate]);

	const trackProgress = useCallback(() => {
		if (isOnDemand && milestoneChecker.current(Math.round(playedPercentageRef.current * 100))) {
			const milestone = {
				...onDemandVideo,
				languageSelection,
				playedSeconds: playedSecondsRef.current,
				played: playedPercentageRef.current,
				playback_url: currentDynamicVideo?.playback_url
			};

			logger(`Tracking milestone`, Math.round(milestone.played * 100) + '%');

			const _targetIdString = getSessionTargetIdString();
			const target_id_string = _targetIdString ? { target_id_string: _targetIdString } : {};

			trackEvent({
				playedSeconds: playedSecondsRef.current,
				action: ActionEvent.Milestone,
				action_type: ActionType.Passive,
				...target_id_string,
				miscellaneous: JSON.stringify({
					milestone,
					valid_passcode_lists: validPasscodeLists,
					playback_rate: playbackRate
				})
			});
		}

		if (leaderboardIsOn) {
			logger(`Sending ${playedSecondsRef.current} to leaderboard tracking worker`);
			worker.postMessage({ type: 'progress', payload: { second: playedSecondsRef.current } });
		}

		if (courseTrack && isLiveVideo) {
			worker.postMessage({ type: 'watching' });
		}
	}, [
		isOnDemand,
		leaderboardIsOn,
		onDemandVideo,
		currentDynamicVideo?.playback_url,
		getSessionTargetIdString,
		trackEvent,
		validPasscodeLists,
		playbackRate,

		// as of right now only live streams are tracked for courses, not on-demand
		courseTrack,
		isLiveVideo,
	]);

	useEffect(() => {
		if (previousPlaybackRate !== playbackRate) {
			trackEvent({
				playedSeconds: playedSecondsRef.current,
				action: ActionEvent.PlaybackSpeedChange,
				action_type: ActionType.Active,
				miscellaneous: JSON.stringify({
					relative_timestamp: getVideoTimestamp(),
					seconds_played: getSecondsPlayed(),
					valid_passcode_lists: validPasscodeLists,
					playback_rate: playbackRate
				})
			});
		}
	}, [getSecondsPlayed, getVideoTimestamp, playbackRate, previousPlaybackRate, trackEvent, validPasscodeLists]);

	useEffect(() => {
		const updateRefs = (progress: TimeUpdate) => {
			playedSecondsRef.current = progress.playedSeconds;
			playedPercentageRef.current = progress.played;
			trackProgress();
		};

		Signals.addEventListener('video-player-progress', updateRefs);

		return () => {
			Signals.removeEventListener('video-player-progress', updateRefs);
		};
	}, [trackProgress]);

	useEffect(() => {
		if (initialPlay) {
			// Initializing this function inside the if statement to avoid redeclaring it on every render
			const getDBOndemandWatchtime = async (sessionId: number, source: string) => {
				const progressData = await helperGetDBOndemandWatchtime(sessionId, source);
				if (progressData?.watchtime) {
					dispatch({
						type: Actions.SeekTo,
						payload: progressData?.watchtime,
					});
				}
			};

			logger(`Tracking initial play action`, getVideoTimestamp());

			if (session?.session && onDemandVideo?.source) {
				getDBOndemandWatchtime(session.session, onDemandVideo?.source);
			}

			const targetIdString = getSessionTargetIdString();
			const target_id_string = targetIdString ? { target_id_string: targetIdString } : {};
			trackEvent({
				action: ActionEvent.Start,
				action_type: ActionType.Passive,
				...target_id_string,
				miscellaneous: JSON.stringify({
					relative_timestamp: getVideoTimestamp(),
					valid_passcode_lists: validPasscodeLists,
					playback_rate: playbackRate
				})
			});

			startWatchtimeRef.current = Date.now();

			db.putItem({
				uuid: `${sessionUuid}-TIME_OF_LAST_STREAM`,
				type: 'TIME_OF_LAST_STREAM',
				data: Date.now(),
			}).catch(e => console.error(e));
		}
	}, [dispatch, dispatchRedux, getVideoTimestamp, initialPlay, session?.session, sessionUuid, trackEvent, validPasscodeLists, onDemandVideo?.source, getSessionTargetIdString, playbackRate]);

	// when video is ended by user leaving page
	const leaveVideoPage = useCallback(async (userAction: string, timestamp?: number | null, isPlaying?: boolean) => {
		if (isPlaying ?? playing) {
			logger(`Tracking leave video page action`, userAction, timestamp ?? getVideoTimestamp(), getSecondsPlayed());

			const _targetIdString = getSessionTargetIdString();
			const target_id_string = _targetIdString ? { target_id_string: _targetIdString } : {};

			// track event is more consistent if it is sent first thing in this function
			trackEvent({
				action: ActionEvent.End,
				action_type: ActionType.Passive,
				...target_id_string,
				miscellaneous: JSON.stringify({
					relative_timestamp: timestamp ?? getVideoTimestamp(),
					userAction,
					seconds_played: getSecondsPlayed(),
					playback_rate: playbackRate
				})
			});

			startWatchtimeRef.current = 0;

			lastActionType.current = ActionEvent.End;
			if (sessionUuid) {
				try {
					await db.deleteItem('TIME_OF_LAST_STREAM', `${sessionUuid}-TIME_OF_LAST_STREAM`);
				} catch (e) {
					console.error(e);
				}
			}
		}
	}, [playing, getVideoTimestamp, getSecondsPlayed, getSessionTargetIdString, trackEvent, playbackRate, sessionUuid]);

	// captures navigation from app sidebar
	useEffect(() => {
		return () => {
			if (componentWillUnmount.current) {
				leaveVideoPage('component unmounted', unmountRef.current?.timestamp, unmountRef.current?.isPlaying);
			}
		};
	}, [getSecondsPlayed, leaveVideoPage]);

	// mobile - track start and end events based on visibility of page
	// not all mobile devices unmount or unload when app is hidden
	const handleVisibilityChange = useCallback(async () => {
		if (isMobile) {
			if (document.visibilityState === 'hidden') {
				leaveVideoPage('mobile - page visibility hidden');
			} else if (playing) {
				// if the video is playing when the user returns to the page, track a start event
				startWatchtimeRef.current = Date.now();

				lastActionType.current = ActionEvent.Start;
				if (sessionUuid) {
					try {
						await db.putItem({
							uuid: `${sessionUuid}-TIME_OF_LAST_STREAM`,
							type: 'TIME_OF_LAST_STREAM',
							data: Date.now(),
						});
					} catch (e) {
						console.error(e);
					}
				}

				const _targetIdString = getSessionTargetIdString();
				const target_id_string = _targetIdString ? { target_id_string: _targetIdString } : {};

				trackEvent({
					action: ActionEvent.Start,
					action_type: ActionType.Passive,
					miscellaneous: JSON.stringify({ relative_timestamp: getVideoTimestamp(), dev: 'mobile - returning to app/page' }),
					playback_rate: playbackRate,
					...target_id_string
				});
			}
		}
	}, [isMobile, playing, leaveVideoPage, sessionUuid, getSessionTargetIdString, trackEvent, getVideoTimestamp, playbackRate]);

	useEffect(() => {
		const leaveVideoPageHandler = () => leaveVideoPage('page unloaded via refresh, navigation, or closing');

		window.addEventListener('beforeunload', leaveVideoPageHandler);
		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			window.removeEventListener('beforeunload', leaveVideoPageHandler);
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, [handleVisibilityChange, leaveVideoPage]);

	useEffect(() => {
		if (ended) {
			logger(`Tracking end action`, getVideoTimestamp(), getSecondsPlayed());
			lastActionType.current = ActionEvent.End;
			db.deleteItem('TIME_OF_LAST_STREAM', `${sessionUuid}-TIME_OF_LAST_STREAM`).catch(e => console.error(e));

			const _targetIdString = getSessionTargetIdString();
			const target_id_string = _targetIdString ? { target_id_string: _targetIdString } : {};

			trackEvent({
				action: ActionEvent.End,
				action_type: ActionType.Passive,
				...target_id_string,
				miscellaneous: JSON.stringify({
					relative_timestamp: getVideoTimestamp(),
					seconds_played: getSecondsPlayed(),
					valid_passcode_lists: validPasscodeLists,
					playback_rate: playbackRate
				})
			});

			worker.postMessage({ type: 'ended' });
		}
	}, [ended, getSecondsPlayed, getVideoTimestamp, sessionUuid, trackEvent, validPasscodeLists, getSessionTargetIdString, playbackRate]);

	// This is componentWillUnmount
	// This must be before any useEffects that reference it
	useEffect(() => {
		sleepInterval.current = setInterval(() => {
			Signals.broadcastSignal('get-is-player-idle');
		}, 2000);

		Signals.on('video-player-is-idle', async (idle: boolean | undefined) => {
			if (idle) {
				if (isPlayingRef.current) {
					logger(`Player is idle but state thinks we should be playing - machine woke from sleep, fixing state`, getVideoTimestamp(), getSecondsPlayed());
					dispatch({ type: Actions.SetPlaying, payload: false });
					worker.postMessage({ type: 'idled' });

					const timeOfLastKnownStream = await db.getItem<number>('TIME_OF_LAST_STREAM', `${sessionUuid}-TIME_OF_LAST_STREAM`);
					if (
						(lastActionType.current === ActionEvent.Start || lastActionType.current === ActionEvent.Play)
						&& timeOfLastKnownStream
					) {
						logger(`Tracking pause action due to wake`, getVideoTimestamp(), getSecondsPlayed());
						const _targetIdString = getSessionTargetIdString();
						const target_id_string = _targetIdString ? { target_id_string: _targetIdString } : {};
						trackEvent({
							action: ActionEvent.Pause,
							action_type: ActionType.Passive,
							...target_id_string,
							miscellaneous: JSON.stringify({
								relative_timestamp: getVideoTimestamp(),
								seconds_played: getSecondsPlayed(timeOfLastKnownStream),
								valid_passcode_lists: validPasscodeLists,
								playback_rate: playbackRate
							})
						});
						lastActionType.current = undefined; // DO NOT REMOVE or we'll get an infinite loop
						// do not delete `${sessionUuid}.TIME_OF_LAST_STREAM` from local storage here or we'll get an infinite loop
					}
				}
			}
		});

		return () => {
			Signals.off('video-player-is-idle');
			if (sleepInterval.current) {
				clearInterval(sleepInterval.current);
			}
		};
	}, [dispatch, getSecondsPlayed, getVideoTimestamp, sessionUuid, trackEvent, validPasscodeLists, getSessionTargetIdString, playbackRate]);

	useEffect(() => {
		// has enough data to send the tracking messages
		const canTrack = !!(sessionUuid && eventId && blProfileId && channel && blProfileUserToken);

		// if there is a course await the course uuid and requirement and only track while the video is live
		if (expectCourse && !(courseUuid && requirementId && isLive)) {
			return;
		}

		// if this is a broadcast, track. If this is any kind of recording with a known duration from the player, track.
		// otherwise, we do not know whether to track or not.
		const hasEnoughVideoData = isBroadcast || (!isBroadcast && durationInSeconds);

		if (canTrack && hasEnoughVideoData && playing) {
			if (workerInitTimeout.current) {
				clearTimeout(workerInitTimeout.current);
			}

			workerInitTimeout.current = setTimeout(() => {
				if (workerInitialized.current) return;

				const timing = isBroadcast ? {
					isLive: true,
					startTime: startTimestamp,
					endTime: endTimestamp
				} : {
					isLive: false,
					duration: durationInSeconds
				};

				worker.postMessage({
					type: 'init',
					payload: {
						sessionUuid,
						blProfileUserToken,
						action: {
							event_id: eventId,
							bl_profile: blProfileId,
							channel_id: channel,
							session_id: sessionId,
							course_uuid: courseUuid,
							requirement_id: requirementId
						},
						timing,
						trackingOrigin: HvHostMap.eventData,
						is_live: isBroadcast,
						is_course: courseTrack,
						course_uuid: courseUuid,
						requirement_id: requirementId,
						watched_seconds: playedSecondsRef.current
					}
				});

				workerInitialized.current = true;
			}, 500);
		}
	}, [
		sessionUuid,
		blProfileId,
		channel,
		eventId,
		sessionId,
		durationInSeconds,
		startTimestamp,
		endTimestamp,
		isBroadcast,
		blProfileUserToken,
		courseTrack,
		courseUuid,
		requirementId,
		playing,
		expectCourse,
		isLive
	]);

	return (
		<></>
	);
};

export default Tracking;
