import { BlProfile, BreakoutRoomPatch, CreateNonRegistrationProfiles, Dictionary, Session, DBSession, SessionTabsLayout, SecondarySessionVideo, BreakoutRoom, FiresideSessionSettings, IBreakoutSession } from "../types/working-model";
import { Get, GetHv, PatchHv, Delete, Post, PostHv, Put, Patch, HvHostMap, } from "./helpers";
import { getHostUrl } from "../utils/utils";

export function GetSessionsForEvent(token: string, uuid: string): Promise<DBSession[]> {
	return Get(`/v3/admin/events/sessions/${uuid}`, token);
}
export function GetSingleSession(token: string, sessionId: number): Promise<Session> {
	return Get(`/v3/admin/events/session/${sessionId}`, token);
}

export function UpdateAllSessionsOverlayChat(token: string, eventUuid: string, overlayChat: boolean): Promise<any> {
	return Put(`/v3/admin/events/sessions/overlay-chat/all/${eventUuid}`, token, { overlayChat });
}

export function UpdateAllSessionsLayoutTabs(token: string, eventUuid: string, tabs: SessionTabsLayout): Promise<any> {
	return Put(`/v3/admin/events/sessions/layout-tabs/all/${eventUuid}`, token, { tabs });
}

export function TransformModuleGroups(token: string, session: Session): Promise<any> {
	return Post('/v3/admin/events/sessions/transform-module-groups', token, session);
}

export function GetSessionRtmp(token: string, session_uuid: string, language: string): Promise<{ rtmps_address: string, stream_key: string, expire_at: number }> {
	return Get(`/v3/admin/events/session/rtmp/${session_uuid}/${language}`, token);
}

export function CreateNonRegisteredBLProfile(data: CreateNonRegistrationProfiles): Promise<{ result: BlProfile, token: string }> {
	return Post('/v3/admin/events/live-session/non-registration-profile', '', data);
}

interface IEditBreakoutSessionOptions {
	return_results?: boolean;
}

export function EditBreakoutSession(
	session: Dictionary,
	session_id: number,
	token: string,
	options?: IEditBreakoutSessionOptions,
): Promise<any> {
	const queryString = options?.return_results ? '?return_results=true' : '';
	return Put(`/v3/admin/breakout-sessions/${session_id}${queryString}`, token, session);
}

export function GetBreakoutRoomsForSession(session_id: number, token: string): Promise<any> {
	return Get(`/v3/admin/breakout-sessions/${session_id}/rooms`, token);
}

export function GetBreakoutRoomsForSessionHv(session_uuid: string, token: string): Promise<any> {
	return GetHv(HvHostMap.eventData, `/e3-breakout-room/${session_uuid}`, token);
}

export function RefreshBreakoutRoomsForSessionHv(session_uuid: string, token: string): Promise<any> {
	return GetHv(HvHostMap.eventData, `/refresh-e3-breakout-room/${session_uuid}`, token);
}

export function SaveBreakoutRoomsForSession(
	rooms: any[],
	use_zoom: boolean,
	session_id: number,
	token: string,
	options?: IEditBreakoutSessionOptions,
): Promise<any> {
	const queryString = options?.return_results ? '?return_results=true' : '';
	return Post(`/v3/admin/breakout-sessions/${session_id}/rooms${queryString}`, token, { rooms, use_zoom });
}

export function SaveGoogleRefreshForBreakoutSession(
	session_id: number,
	code: string,
	token: string,
): Promise<boolean> {
	return Put(`/v3/admin/breakout-sessions/${session_id}/google`,
		token,
		{
			code,
			siteUrl: getHostUrl(),
		});
}

export function removeGoogleRefreshForBreakoutSession(
	session_id: number,
	token: string,
): Promise<boolean> {
	return Delete(`/v3/admin/breakout-sessions/${session_id}/google`, token, {});
}

export function GetGoogleMeetRoom(sessionUuid: string, breakoutUuid: string, serviceAccountEnabled: boolean, token: string): Promise<BreakoutRoom> {
	return PostHv(
		HvHostMap.eventData,
		`/e3-breakout-room/${sessionUuid}/${breakoutUuid}/getGoogleMeetRoom`,
		{ serviceAccountEnabled }, // Force service account authoriztion or use Oauth
		token,
	);
}

export function GetGoogleMeetRoomForFireside(sessionUuid: string, serviceAccountEnabled: boolean | undefined, token: string): Promise<FiresideSessionSettings> {
	return PostHv(
		HvHostMap.eventData,
		`/e3-fireside-settings/${sessionUuid}`,
		{ serviceAccountEnabled: !!serviceAccountEnabled }, // Force service account authoriztion or use Oauth
		token,
	);
}

export function UpdateBreakoutBannerImage(image: string | null, sessionId: number, token: string): Promise<IBreakoutSession> {
	return Put(`/v3/admin/events/breakout-sessions/${sessionId}/banner-image`, token, { image }, true);
}

export function UpdateUseBreakoutBackgroundImage(use_background_image: boolean, sessionId: number, token: string): Promise<IBreakoutSession> {
	return Put(`/v3/admin/events/breakout-sessions/${sessionId}/use-background-image`, token, { use_background_image }, true);
}

export function UpdateBreakoutBackgroundImage(image: string | null, sessionId: number, token: string): Promise<IBreakoutSession> {
	return Put(`/v3/admin/events/breakout-sessions/${sessionId}/background-image`, token, { image }, true);
}

// opentok session functions
export function JoinBreakoutRoom(sessionUuid: string, breakoutUuid: string, token: string, opentokId: string | null): Promise<any> {
	return GetHv(HvHostMap.eventData, `/e3-breakout-room/${sessionUuid}/${breakoutUuid}${opentokId ? `?rejoinOpentokId=${opentokId}` : ''}`, token);
}

export function GetBreakoutRoomUsage(sessionUuid: string, token: string): Promise<any> {
	return GetHv(HvHostMap.eventData, `/e3-breakout-room-usage/${sessionUuid}`, token);
}

export function LeaveBreakoutRoom(sessionUuid: string, breakoutUuid: string, opentokId: string, token: string): Promise<string> {
	return PostHv(HvHostMap.eventData, `/events-leave-breakout-room/${sessionUuid}/${breakoutUuid}/${opentokId}`, token, '');
}

export function GetBreakoutRoomToken(opentokId: string, token: string): Promise<any> {
	return GetHv(HvHostMap.eventData, `/e3-get-opentok-token/opentok/${opentokId}/token`, token);
}

export function GetFiresidesOpentokToken(opentokId: string, token: string, publisher: boolean): Promise<any> {
	return GetHv(HvHostMap.eventData, `/e3-get-opentok-token/opentok/${opentokId}/firesides/token?isPublisher=${publisher}`, token);
}

export function GetOpenTokToken(opentokId: string, token: string, publisher: boolean): Promise<any> {
	return GetHv(HvHostMap.eventData, `/e3-get-opentok-token/opentok/${opentokId}/token?isPublisher=${publisher}`, token);
}

export function GetBreakoutRoomTestInfo(token: string, isFiresides?: boolean): Promise<any> {
	return GetHv(HvHostMap.eventData, `/e3-get-room-test-info/opentok/test?isFiresides=${isFiresides ?? 'false'}`, token);
}

export function PatchBreakoutRoom(token: string, breakoutRoomPatch: BreakoutRoomPatch): Promise<any> {
	return PatchHv(HvHostMap.eventData, `/e3-patch-breakout-room/breakout-sessions/${breakoutRoomPatch.session}/rooms/${breakoutRoomPatch.id}`, breakoutRoomPatch, token, true);
}

export function PatchBreakoutRooms(token: string, breakoutRoomPatches: BreakoutRoomPatch[]): Promise<any> {
	return PatchHv(HvHostMap.eventData, `/e3-patch-breakout-rooms/breakout-sessions/${breakoutRoomPatches[0].session}/rooms`, breakoutRoomPatches, token, true);
}
export function UpdateSecondarySessionVideo(secondaryVideo: Omit<SecondarySessionVideo, "id" | "uuid">, token: string): Promise<SecondarySessionVideo> {
	return Post('/v3/admin/events/session/secondaryVideo', token, { secondaryVideo });
}

export function GetSecondarySessionVideo(sessionId: number, token: string): Promise<SecondarySessionVideo | { response: string; }> {
	return Get(`/v3/admin/events/session/secondaryVideo/${sessionId}`, token);
}

export function DeleteSecondarySessionVideo(sessionId: number, token: string): Promise<any> {
	return Delete('/v3/admin/events/session/secondaryVideo', token, { sessionId });
}

export function PatchToggleTestStream(sessionUuid: string, isTestStream: boolean, token: string): Promise<any> {
	return Patch(`/v3/admin/events/session/${sessionUuid}/test-stream`, token, { testStream: isTestStream }, true, true);
}

export function UpdateSessionCustomUrl(token: string, sessionUuid: string, enabled: boolean, customUrl: string, eventUuid: string): Promise<{ custom_url_enabled: boolean, custom_url: string }> {
	return Post(`/v3/admin/events/session/${sessionUuid}/custom-url`, token, { enabled, customUrl, eventUuid });
}

export function FetchSessionBackupUrl(token: string, sessionUuid: string): Promise<{ backup_url_enabled: boolean, backup_url: string }> {
	return Get(`/v3/admin/events/session/${sessionUuid}/backup-url`, token);
}

export function GetRecordingJWT(path?: string, token?: string): Promise<{ jwtToken: string }> {
	let endpoint = '/e3-get-recording-jwt';

	if (path) {
		endpoint += `?path=${path}`;
	}

	return GetHv(HvHostMap.eventData, endpoint.toString(), token);
}

export function FetchSessionCustomUrl(token: string, sessionUuid: string): Promise<{ custom_url_enabled: boolean, custom_url: string }> {
	return Get(`/v3/admin/events/session/${sessionUuid}/custom-url`, token);
}
