import { CourseRequirementType, EPassable, ICourse, ProgressItem, RequirementKey, UserCourseCompletionState, UserCourseCompletionStats } from "types/courses";
import { BrandliveEvent } from "types/working-model";

export const checkPassability = (event: BrandliveEvent, course: ICourse, progress: Record<number, ProgressItem>) => {
	const requirements = course.requirements;
	if (!requirements) {
		return EPassable.undetermined;
	}

	let passable = EPassable.yes;
	const sessionsMap = new Map(event.sessions.map(session => [session.session, session]));
	for (const requirement of requirements) {
		const session = sessionsMap.get(requirement.session_id);

		// course requires a non-existant session (maybe user doesn't have access to it)
		// so the course cannot be passed
		if (!session) {
			passable = EPassable.no;
			break;
		}

		// on-demand sessions have no ending timestamp and so can never end
		const sessionEnded = (session.end_timestamp || Infinity) < Date.now();
		const sessionDuration = (session.end_timestamp || Infinity) - (session.timestamp || 0);
		const remainingDuration = (session.end_timestamp || Infinity) - Date.now();
		const sessionProg = progress[requirement.session_id];

		if (sessionEnded && !sessionProg) {
			passable = EPassable.no;
			break;
		}

		switch (requirement.type) {
			case CourseRequirementType.pulse_check: {
				// there are no points, so the requirement is invalid
				// prevent divide by zero errors
				if (!requirement.points) {
					continue;
				}

				// estimated milliseconds between pulse checks
				const pulseInterval = Math.floor(sessionDuration / requirement.points);

				// prevent divide by zero errors
				if (!pulseInterval) {
					passable = EPassable.no;
					break;
				}

				// estimated number of pulse checks that are still scheduled in the future
				// if it's close the user might still receive a pulse check as they aren't sent on a perfectly regular interval
				const estimatedUnsent = Math.round(remainingDuration / pulseInterval);

				// number of pulse checks user has already acknowledged
				const userHasAcknowledged = sessionProg?.pulse_checks_acknowledged || 0;

				// number of pulse checks user still needs to acknowledge to pass the course
				const userNeeds = requirement.required_amount - userHasAcknowledged;

				// user needs to ack more pulse checks than there are remaining in the session
				if (userNeeds > estimatedUnsent) {
					passable = EPassable.no;
				}

				break;
			}

			case CourseRequirementType.watched_minute: {
				// session is over and the user hasn't watched enough to pass the course
				if (sessionEnded && ((sessionProg?.watched_seconds || 0) / 60) < requirement.required_amount) {
					passable = EPassable.no;
					break;
				}

				const remainingMinutes = remainingDuration / 1000 / 60;
				const userHasWatched = (sessionProg?.watched_seconds || 0) / 60;
				const userNeeds = requirement.required_amount - userHasWatched;

				// user needs to watch more minutes than there are minutes remaining in the session
				if (userNeeds > remainingMinutes) {
					passable = EPassable.no;
					break;
				}
			}
		}
	}

	return passable;
};

export const userStatsToPassState = (course: ICourse, stats: UserCourseCompletionStats) => {
	if (!course.requirements) {
		return {
			passState: UserCourseCompletionState.Wait,
			passDate: undefined,
			progress: {}
		};
	}

	const requirements = course.requirements;
	const progress: Record<number, ProgressItem> = {};
	let hasStats = false;

	for (const requirement of requirements) {
		if (!progress[requirement.session_id]) {
			progress[requirement.session_id] = {
				watched_seconds: 0,
				pulse_checks_acknowledged: 0
			};
		}

		const key: RequirementKey = `req-${requirement.id}`;
		const stat = stats[key];
		if (stat) {
			hasStats = true;
		}

		const statAmount = Number(stats[key] || 0);

		switch (requirement.type) {
			case CourseRequirementType.pulse_check: {
				progress[requirement.session_id].pulse_checks_acknowledged = statAmount;
				break;
			}

			case CourseRequirementType.watched_minute: {
				progress[requirement.session_id].watched_seconds = statAmount;
				break;
			}
		}
	}

	return {
		passState: stats.state,
		passDate: stats.date,
		progress
	};
};

export const estimatePassState = (course: ICourse, progress: Record<number, ProgressItem>) => {
	if (!course.requirements) {
		return false;
	}

	const requirements = course.requirements;
	const total = requirements.length;
	let completed = 0;

	for (const requirement of requirements) {
		const prog = progress[requirement.session_id];
		if (!prog) {
			continue;
		}

		switch (requirement.type) {
			case CourseRequirementType.pulse_check: {
				if (prog.pulse_checks_acknowledged >= requirement.required_amount - 1) {
					completed++;
				}
				break;
			}

			case CourseRequirementType.watched_minute: {
				if (prog.watched_seconds * 60 >= requirement.required_amount - 1) {
					completed++;
				}
				break;
			}
		}
	}

	return completed >= total;
};
